import { ElementExecutor } from '@apexcura/core'
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import Utils from "../../utils";
import { CONSTANTS } from "../../utils/app-constants";
import Storage from "../../utils/local-storage";
import { useDispatch } from 'react-redux';
import useExitPopUp from '../../hooks/useExitPopUp';
import { setLoading } from '../../redux/appState/appStateSlice';

const { FAILURE_MSG, CATCH_ERROR_MSG, AUTO_CLOSE } = CONSTANTS;

const editdocs = () => ({
  className: "w-full bg-white rounded-lg p-8 flex flex-col gap-3 items-center",
  schema: [
    {
      name: "upload_fields",
      className: "w-full flex flex-col gap-2",
      fields: [
        {
          name: "name_and_type",
          className: "grid grid-cols-2 flex gap-2",
          fields: [
            {
              name: "name",
              element: "input-text",
              placeholder: "",
              label: "Name",
              containerClassName: "w-full flex flex-col",
              labelClassName:
                "text-sm mb-1 text-gray-500 font-semibold text-start",
              className:
                "rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none",
              required: true,
              visible: true,
            },
            {
              name: "document_type",
              className: "rounded-md text-gray-700 font-semibold",
              element: "single-select",
              containerClassName: "flex flex-col",
              labelClassName:
                "text-sm mb-1 text-gray-500 font-semibold text-start",
              label: "Type",
              required: true,
              placeholder: "Select the type of documents",
              value:{
                label:"form",
                value:"form"
              },
              disabled:true,
              options: [
                {
                  label: "pdf",
                  value: "application/pdf",
                },
                {
                  label: "doc/docx",
                  value: ".doc,docx",
                },
                {
                  label:"form",
                  value:"form"
                }
              ],
            },
          ],
        },
        {
          name: "formcontent_1",
          element: "input-textarea",
          label: "Hospital Information",
          required:true,
          autosize  :true,
          minRows:3,
          maxRows:6,
          containerClassName: "flex flex-col w-full gap-2",
          labelClassName: "text-sm text-gray-500 font-semibold text-start",
          className:
            "w-full rounded-md  px-3 py-2 text-gray-700 font-semibold focus:outline-none",
          visible: true,
        },
        {
          name: "formcontent_2",
          element: "input-textarea",
          label: "Hospital Services/specializes",
          required:false,
          autosize  :true,
          minRows:3,
          maxRows:6,
          containerClassName: "flex flex-col w-full gap-2",
          labelClassName: "text-sm text-gray-500 font-semibold text-start",
          className:
            "w-full rounded-md  px-3 py-2 text-gray-700 font-semibold focus:outline-none",
          visible: true,
        },
        {
          name: "formcontent_3",
          element: "input-textarea",
          label: "Hospital Facilities/Salient Features",
          required:false,
          autosize  :true,
          minRows:3,
          maxRows:3,
          containerClassName: "flex flex-col w-full gap-2",
          labelClassName: "text-sm text-gray-500 font-semibold text-start",
          className:
            "w-full rounded-md  px-3 py-2 text-gray-700 font-semibold focus:outline-none",
          visible: true,
        },
        {
          name: "formcontent_4",
          element: "input-textarea",
          label: "Hospital Address",
          required:false,
          autosize  :true,
          minRows:3,
          maxRows:6,
          containerClassName: "flex flex-col w-full gap-2",
          labelClassName: "text-sm text-gray-500 font-semibold text-start",
          className:
            "w-full rounded-md  px-3 py-2 text-gray-700 font-semibold focus:outline-none",
          visible: true,
        },
        {
          name: "formcontent_5",
          element: "input-textarea",
          label: "Hospital Contact Details",
          required:false,
          autosize  :true,
          minRows:3,
          maxRows:3,
          containerClassName: "flex flex-col w-full gap-2",
          labelClassName: "text-sm text-gray-500 font-semibold text-start",
          className:
            "w-full rounded-md  px-3 py-2 text-gray-700 font-semibold focus:outline-none",
          visible: true,
        },
        {
          name: "formcontent_6",
          element: "input-textarea",
          label: "Hospital Timings",
          required:false,
          autosize  :true,
          minRows:3,
          maxRows:6,
          containerClassName: "flex flex-col w-full gap-2",
          labelClassName: "text-sm text-gray-500 font-semibold text-start",
          className:
            "w-full rounded-md  px-3 py-2 text-gray-700 font-semibold focus:outline-none",
          visible: true,
        },
        {
          name: "formcontent_7",
          element: "input-textarea",
          label: "Our Mission And Values",
          required:false,
          autosize  :true,
          minRows:3,
          maxRows:3,
          containerClassName: "flex flex-col w-full gap-2",
          labelClassName: "text-sm text-gray-500 font-semibold text-start",
          className:
            "w-full rounded-md  px-3 py-2 text-gray-700 font-semibold focus:outline-none",
          visible: true,
        },
        {
          name: "formcontent_8",
          element: "input-textarea",
          label: "Hospital Awards And Recognition",
          required:false,
          autosize  :true,
          minRows:3,
          maxRows:3,
          containerClassName: "flex flex-col w-full gap-2",
          labelClassName: "text-sm text-gray-500 font-semibold text-start",
          className:
            "w-full rounded-md  px-3 py-2 text-gray-700 font-semibold focus:outline-none",
          visible: true,
        },
        {
          name: "formcontent_9",
          element: "input-textarea",
          label: "Doctors Information",
          required:false,
          autosize  :true,
          minRows:3,
          maxRows:6,
          containerClassName: "flex flex-col w-full gap-2",
          labelClassName: "text-sm text-gray-500 font-semibold text-start",
          className:
            "w-full rounded-md  px-3 py-2 text-gray-700 font-semibold focus:outline-none",
          visible: true,
        },
        {
          name: "formcontent_10",
          element: "input-textarea",
          label: "Vaccinations",
          required:false,
          autosize  :true,
          minRows:3,
          containerClassName: "flex flex-col w-full gap-2",
          labelClassName: "text-sm text-gray-500 font-semibold text-start",
          className:
            "w-full rounded-md  px-3 py-2 text-gray-700 font-semibold focus:outline-none",
          visible: true,
        },
        {
          name: "formcontent_11",
          element: "input-textarea",
          label: "Patient Reviews About Hospital",
          required:false,
          autosize  :true,
          minRows:3,
          maxRows:6,
          containerClassName: "flex flex-col w-full gap-2",
          labelClassName: "text-sm text-gray-500 font-semibold text-start",
          className:
            "w-full rounded-md  px-3 py-2 text-gray-700 font-semibold focus:outline-none",
          visible: true,
        },
        {
          name: "formcontent_12",
          element: "input-textarea",
          label: "Other Information",
          required:false,
          autosize  :true,
          minRows:3,
          maxRows:3,
          containerClassName: "flex flex-col w-full gap-2",
          labelClassName: "text-sm text-gray-500 font-semibold text-start",
          className:
            "w-full rounded-md  px-3 py-2 text-gray-700 font-semibold focus:outline-none",
          visible: true,
        },
      ],
    },
    {
      name: "cancel_and_submit",
      fields: [
        {
          name: "cancel",
          label: "Cancel",
          element: "button",
          className:
            "p-2 px-3  text-default-500 bg-gray-200 text-black rounded-md  font-semibold",
        },
        {
          name: "submit",
          label: "Submit",
          element: "button",
          className:
            "p-2 px-3  text-default-500 bg-[#3341bb]  rounded-md text-white font-semibold",
        },
      ],
      className: "flex self-end gap-2",
    },
  ],
});

const EditBottraining = () => {
    const [schema, setSchema] = useState<any>({
    ...editdocs()
    });
    const navigate=useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const id = params?.botId || "";

    useEffect(() => {
        if (id) {
            API_GET_Details(id);
        }
    }, [id]);
    const org_id = Storage.getItem("details")?.["org_id"];

    const getPayLoad = (e: any): any => {
      const payload: any = {
        org_id: org_id,
        title: "",
        type: "",
        headings_and_contents: []
      };
    
      const nameAndTypeFields = e?.schema[0]?.fields.find((field: any) => field.name === "name_and_type")?.fields;
      payload.title = nameAndTypeFields?.find((field: any) => field.name === "name")?.value || "";
      payload.type = nameAndTypeFields?.find((field: any) => field.name === "document_type")?.value?.value || "";
    
      e?.schema[0]?.fields.forEach((field: any) => {
        if (field.name.startsWith('formcontent_')) {
          payload.headings_and_contents.push({
            heading: field.label || "",
            content: field.value || ""
          });
        }
      });
    
      return payload;
    };
    const validateData = (fields: any[]): string | false => {
      for (const field of fields) {
        if (
          field.required &&
          ((typeof field.value === "string" && field.value.trim() === "") ||
            (Array.isArray(field.value) && field.value.length === 0) ||
            field.value === null ||
            field.value === undefined)
        ) {
          return field.label;
        }
        if (field.fields) {
          const result = validateData(field.fields);
          if (result) {
            return result;
          }
        }
      }
      return false;
    };
    const API_GET_Details = async (id: any) => {
        try {
          const res = await Utils.makeApiCall(
            `/bot-training/${id}/${org_id}`,
            'GET'
          );
          if (res.status) {
            updateSchemaWithApiData(res.data);
          } else {
            toast.error(res.message || FAILURE_MSG, {
              position: 'top-center',
              autoClose: AUTO_CLOSE,
            });
          }
        } catch (error) {
          console.error('Failed to fetch schema', error);
          toast.error(CATCH_ERROR_MSG, {
            position: 'top-center',
            autoClose: AUTO_CLOSE,
          });
        }
    };
    const updateSchemaWithApiData = (data: any) => {
      setSchema((prev: any) => {
        const updatedSchema = prev.schema.map((field: any) => {
          if (field.name === "upload_fields") {
            field.fields = field.fields.map((subfield: any) => {
              if (subfield.name === "name_and_type") {
                subfield.fields = subfield.fields.map((nameTypeField: any) => {
                  if (nameTypeField.name === "name") {
                    nameTypeField.value = data.title;
                  }
                  return nameTypeField;
                });
              } else if (subfield.name.startsWith("formcontent_")) {
                const matchingContent = data.headings_and_contents.find(
                  (contentItem: any) => contentItem.heading === subfield.label
                );
                if (matchingContent) {
                  subfield.value = matchingContent.content || "";
                }
                
                // (data.headings_and_contents.map((eh: any)=>{if (eh.heading===subfield.label){
                //   subfield.value=eh.content
                // }}));
                
                
                // const index = parseInt(subfield.name.split("_")[1]) - 1;
                
                // if (data.headings_and_contents[index]) {
                //   subfield.value = data.headings_and_contents[index].content || "";
                // }
              }
              return subfield;
            });
          }
          return field;
        });
        console.log(updatedSchema);
        
        return { ...prev, schema: updatedSchema };
      });
    };
    const handleSelectedRecord=async(e:any)=>{
        if(e.name === "cancel"){
            navigate("/bot-training")
        }
        if(e.name==="submit") {
          setDetectChange(false)
          const validationResponse = validateData(schema.schema);
          if (validationResponse) {
              toast.error(`${validationResponse} cannot be empty`, {
                position: "top-center",
                autoClose: 800,
              });
              return
          } 
          dispatch(setLoading(true))
          try{
            const payLoad:any = getPayLoad(schema);
            const res:any=await Utils.makeApiCall(`/bot-training/${id}`,"PUT",payLoad)
            if (res.status) {
              toast.success(res.message, {
                position: "top-center",
                autoClose: AUTO_CLOSE,
              });
              navigate("/bot-training");
            } else {
              toast.error(res.message || FAILURE_MSG, {
                position: 'top-center',
                autoClose: AUTO_CLOSE,
              });
            }
          }catch (error) {
            console.error('Failed to send the data', error);
            toast.error(CATCH_ERROR_MSG, {
              position: 'top-center',
              autoClose: AUTO_CLOSE,
            });
          }finally{
            dispatch(setLoading(false))
          }
        }
    }

    const [detectChange, setDetectChange] = useState(false)

    // Exit popup's custom hook
    useExitPopUp(detectChange)

  return (
    <div>
      <ElementExecutor
        data={schema}
        selectedRecord={(e: any)=>{
          handleSelectedRecord(e)
          if(["name", "document_type", "comments", "files", "forminput_1", "formcontent_1", "forminput_2", "formcontent_2", "forminput_3", "formcontent_3",  "forminput_4", "formcontent_4",  "forminput_5", "formcontent_5",  "forminput_6", "formcontent_6",  "forminput_7", "formcontent_7"].includes(e?.name) ){
            setDetectChange(true)
          }
        }}

      />
    </div>
  )
}

export default EditBottraining
