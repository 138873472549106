"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AutoCompleteInput = void 0;
const react_1 = __importStar(require("react"));
const antd_1 = require("antd");
require("../styles/index.css");
const truncateTextByWords = (text, wordLimit) => {
    const wordsArray = text.split(' '); // Split the text into words
    if (wordsArray.length <= wordLimit) {
        return text; // If the number of words is less than or equal to the limit, return the original text
    }
    return wordsArray.slice(0, wordLimit).join(' ') + '...'; // Truncate and add ellipsis
};
const renderItem = (label, contact, index, wordLimit = 18) => {
    const truncatedLabel = label ? truncateTextByWords(label, wordLimit) : '';
    return {
        key: index?.toString() || '',
        value: index?.toString() || '',
        labelText: label || ' ',
        valueText: contact || '',
        label: (react_1.default.createElement(antd_1.Flex, { align: "center", justify: "space-between" },
            contact,
            react_1.default.createElement(antd_1.Typography.Text, { ellipsis: true }, truncatedLabel))),
    };
};
const AutoCompleteInput = (props) => {
    const [allOptions, setAllOptions] = (0, react_1.useState)(props.items &&
        props.items.map((item) => renderItem(item.label, item.contact, item.key)));
    const [options, setOptions] = (0, react_1.useState)(props.items &&
        props.items.map((item) => renderItem(item.label, item.contact, item.key)));
    const [value, setValue] = (0, react_1.useState)();
    const [searchText, setSearchText] = (0, react_1.useState)('');
    const [debouncedSearchText, setDebouncedSearchText] = (0, react_1.useState)('');
    (0, react_1.useEffect)(() => {
        if (props.items) {
            const newOptions = props.items.map((item) => renderItem(item.label, item.contact, item.key));
            setAllOptions(newOptions);
            setOptions(newOptions);
        }
    }, [props.items]);
    (0, react_1.useEffect)(() => {
        if (props.value) {
            setValue(props.value);
        }
    }, [props.value]);
    // Effect for debouncing the search text
    (0, react_1.useEffect)(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchText(searchText);
        }, 300); // Set delay in milliseconds
        // Cleanup function to clear the timeout if searchText changes
        return () => {
            clearTimeout(handler);
        };
    }, [searchText]);
    // Effect to filter options based on the debounced search text
    (0, react_1.useEffect)(() => {
        if (debouncedSearchText) {
            const filteredOptions = allOptions &&
                allOptions.filter((option) => option.valueText
                    .toLowerCase()
                    .includes(debouncedSearchText.toLowerCase()));
            setOptions(filteredOptions);
        }
        else {
            setOptions(allOptions); // Reset options if search text is empty
        }
    }, [debouncedSearchText, allOptions]);
    const handleChange = (value) => {
        const newOption = { key: value, value: value };
        setValue(newOption);
        if (props.onChange) {
            props.onChange(newOption);
        }
    };
    const onSearch = (searchText) => {
        /^\d+$/.test(searchText) &&
            searchText.length >= 10 &&
            setSearchText(searchText); // Set the search text that will be debounced
    };
    const onSelect = (value, option) => {
        if (props.onChange) {
            const newOption = {
                key: option.key,
                value: option.valueText,
                text: option.labelText,
            };
            props.onChange(newOption);
        }
    };
    return (react_1.default.createElement("div", { className: props.containerClassName },
        props.label && (react_1.default.createElement("p", { className: props.labelClassName },
            props.label,
            ' ',
            props.required ? react_1.default.createElement("span", { style: { color: 'red' } }, "*") : '')),
        react_1.default.createElement("div", null,
            react_1.default.createElement(antd_1.AutoComplete, { placeholder: props.placeholder, allowClear: props.allowClear, id: props.name, options: options, disabled: props.disabled, status: props.required && props.errorText ? 'error' : '', className: props.className, onChange: handleChange, onSearch: onSearch, onSelect: onSelect, value: value?.value })),
        props.required ? (react_1.default.createElement("p", { className: props.errorClassName }, props.errorText && props.errorText)) : null));
};
exports.AutoCompleteInput = AutoCompleteInput;
