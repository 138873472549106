import React, { useEffect, useState } from 'react'
import { ElementExecutor } from '@apexcura/core'
import moment from 'moment'
import { toast } from 'react-toastify'
import Utils from '../../utils';
import { CONSTANTS } from "../../utils/app-constants";
import LoadingState from "./../LoadingState";
import { HandleModalDataParams, Item, LeadStatus } from '../../utils/types'
import Storage from '../../utils/local-storage'
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveMenu, setTopbar } from '../../redux/uiSchema/uiSchemaSlice';
import { getPagePermissions } from '../../redux/auth/authSlice';
import { Tooltip,Avatar } from "antd";
import {Popover} from "antd"

const { BASE_URL, FAILURE_MSG, CATCH_ERROR_MSG, AUTO_CLOSE } = CONSTANTS;
const LeadStatusStyles:any = {
  "New": "bg-[#29AAE1]/10 text-[#29AAE1]",
  "Open": "bg-cyan-500/5 text-cyan-500",
  "In-Progress": "bg-yellow-500/10 text-yellow-500",
  "Qualified": "bg-green-500/5 text-green-500",
  "Callback": "bg-fuchsia-500/5 text-fuchsia-500",
  "Closed": "bg-red-500/5 text-red-500",
  "Confirmed":"bg-green-500/5 text-green-500"
};
export const ViewAppointments = () => {
  const [schema, setSchema] = useState<any>({
    className:
      'w-full bg-white rounded-lg py-[10px] px-[20px] flex flex-col gap-3',
    schema: [
      {
        name: 'buttons',
        fields: [
          {
            name: 'btn_download',
            label: 'Download',
            element: 'button',
            icon: 'download',
            visible: false,
            className: 'secondary-button',
            action: 'download_list',
            iconsClassName: 'size-4',
          },
          {
            name: "btn_add",
            label: "Add",
            element: "button",
            icon: "add",
            visible: false,
            className: "add-button hover:shadow-lg ",
            iconsClassName: "size-4",
          },
        ],
        className: "w-full flex justify-end gap-2"
      },
      {
        name: 'filters',
        fields: [
          {
            name:"date_and_search",
            className:"flex gap-2 justify-center items-center ",
            fields:[
              {
                name: "search",
                label: "",
                placeholder: "Name, mobile..",
                element: "input-text",
                isSearch:true,
                value: null,
                className: "",
                icon:"search",
                iconsClassName:"w-[15px] flex justify-center items-center"
              },
              {
                  name:"segments",
                  element:"segmented",
                  segmentedOptions:[
                    {
                        label:(<Tooltip title="Today">1D</Tooltip>),
                        value:"TD"
                    },
                    {
                        label:(<Tooltip title="This Week">1W</Tooltip>),
                        value:"TW"
                    },
                    {
                        label:(<Tooltip title="This Month">1M</Tooltip>),
                        value:"TM"
                    },
                  ],
                  visible:true,
                  value:"TD"
              },
              {
                name:"left_button",
                label: '',
                element: 'button',
                icon: 'left',
                action: '',
                iconsClassName: 'size-2',
                className:"pb-1"
                // isSVGStylesOverride: false,
              },
              {
                name: 'date',
                label: '',
                placeholder: 'Select a date',
                element: 'daterangepicker',
                value: [moment().format('DD-MM-YYYY'), moment().format("DD-MM-YYYY")],
                className: 'flex-1 w-[240px]',
                disabled:false,
              },
              {
                name:"right_button",
                label: '',
                element: 'button',
                icon: 'right',
                action: '',
                iconsClassName: 'size-2',
                className:"pb-1"
                // isSVGStylesOverride: false,
              },
              
            ]
          },
          {
            name:"refresh_and_download",
            className:"flex gap-2 justify-center items-center ",
            fields:[
              {
                name: 'branch-filter',
                // label: 'Doctor',
                element: 'single-select',
                containerClassName:'w-full flex flex-row',
                placeholder:'Select Branch',
                className: 'w-[200px]',
                options:[]
              },
              {
                name: 'doctor-filter',
                // label: 'Doctor',
                element: 'single-select',
                containerClassName:'w-full flex flex-row',
                placeholder:'Select Doctor',
                className: 'w-[200px]',
                options:[]
              },
            ]
          },
          
        ],
        className: 'flex items-center justify-between gap-2',
      },
      {
        className: 'mt-2',
        name: "appointments_table",
        element: 'table',
        loading: true,
        variant: 'plain',
        count:0,
        size: 'small',
        pagination: true, 
        view: true,
        visible: true,
        thead: [
          { name: 'patient_name', label: 'Name', key: 'patient_name', fixed:"left",
            render: (e:any) =>{               
              if(e){
                  return (
                    <div className="flex gap-1">
                        <Avatar size={"small"} style={{backgroundColor:`${Utils.stringToColour(e)}`}}> 
                            <p className={`capitalize`}>{Utils.initials(e)}</p>
                        </Avatar> 
                        {<p className={`capitalize`}>{e}</p>}
                    </div>
            
                  )
              }
            }
          },
          {
            name: 'phone_number',
            label: 'Mobile',
            key: 'phone_number',
          },
          {name:'date', label:"Appt. Date", key:'date'},
          // {name:'age', label:"Age", key:'age'},
          // {name:'gender', label:"Gender", key:'gender'},
          {name:'formattedAge', label:"Age/Gender", key:'formattedAge'},
          {name:'start_time', label:"Start Time", key:'startTime'},
          {name: 'doctor_name', label: 'Doctor', key: 'doctor_name', render: (e:any) => <p className={`capitalize`}>{e}</p>},
          { name: 'department_name', label: 'Department', key: 'department_name', render: (e:any) => <p className={`capitalize`}>{e}</p> },
          { name: 'appointment_status', label: 'Status', key: 'status',render:(e:any)=>{
            
            const bgColor:string = LeadStatusStyles[e] || "bg-[#F2F2F2]/50 text-default-300"
            return <span className={`w-full p-1 px-2 rounded-md font-medium ${bgColor}`}>{e}</span>
          }  },
          // {
          //   name: 'Actions',
          //   label: 'Actions',
          //   key: 'actions',
          //   render:(e:any,record:any)=>{
          //     return <button 
          //     onClick={()=>{handleShowPopup(record)}}><img src = {
          //       `${BASE_URL}/api/public/assets/images/edit.svg`
          //   } alt = "edit"
          //   /></button>
          //   } 
          // }
          {
            name: 'actions',
            label: '',
            key: 'actions',
            fixed: 'right',
            sortable:false,
            render: (e: any, record: any) => {
              
              return (
                <div className='w-full flex gap-1 justify-center items-center'>
                  {record?.DialCallDuration?.length!==0 && record?.RecordingUrl && record?.RecordingUrl?.toLowerCase() !== "null" && <div
                      // onKeyDown={() =>
                      //   handleSelectedRecord({ name: "audio", value: record })
                      // }
                      // tabIndex={0}
                      // role="button"
                      role= "presentation"
                      onClick={(e) => {
                        e.stopPropagation(); 
                        handleSelectedRecord({ name: "audio", value: record })
                        }
                      }
                    >
                      <Tooltip title="Audio & Transcript">
                      <img
                        src={
                          `${BASE_URL}/api/public/assets/images/audio.svg`
                        }
                        alt="View the record"
                        className='w-[80px] h-[20px]'
                      />
                      </Tooltip>
                    </div>}
                    {<div
                      className='w-[20px] h-[20px] p-1 cursor-pointer  flex items-center justify-center bg-white rounded-md'
                      role='presentation'
                      onClick={ (e) => {
                        e.stopPropagation(); 
                      }
                      }
                    >
                      {/* <Tooltip title="Quick actions"> */}
                        <Popover
                          content={
                            <div className='flex flex-col gap-2'>
                              {/* <p className='text-primary font-medium text-sm'>Quick Actions</p>
                              <hr/> */}
                              {/* {[{"name": "comments", "label": `${"  "}Add a Comment`, icon:"comments.svg"}, {name: "book_appointment", label: "Book an appointment", icon: "appointments.svg"}].map((item:any)=>{
                                return <button key={item.name} className='text-xs font-medium hover:shadow-sm transition-all duration-600 text-left text-gray-900 rounded-md p-1 flex items-center gap-1'>
                                  <img src={`${BASE_URL}/api/public/assets/images/${item.icon}`} alt={item.icon} className='w-[20px] h-[20px]'/>{item.label}
                                </button>;
                              })} */}
                              {/* <button key="Edit" className='text-xs font-medium hover:shadow-sm transition-all duration-600 text-left text-gray-900 rounded-md p-1 flex items-center gap-1' onClick={()=>{handleShowPopup(record)}}>
                                  <img src={`${BASE_URL}/api/public/assets/images/edit.svg`} alt="comment.svg" className='w-[14px] h-[14px]'/> Edit
                              </button> */}
                              {record?.appointment_status !== "Confirmed" && <button key="Confirm" className='text-xs font-medium hover:shadow-sm transition-all duration-600 text-left text-gray-900 rounded-md p-1 flex items-center gap-1' onClick={()=>{handleComfirmAppointment(record?.id)}}>
                                  <img src={`${BASE_URL}/api/public/assets/images/confirm-appointment.svg`} alt="comment.svg" className='w-[14px] h-[14px]'/> Confirm Appointment
                              </button>}
                              {compareDate(record?.date) && record?.appointment_status === "Tentative" && <button key="Reschedule" className='text-xs font-medium hover:shadow-sm transition-all duration-600 text-left text-gray-900 rounded-md p-1 flex items-center gap-1' onClick={()=>{navigate(`/appointments/reschedule/${record?.id}`)}}>
                                  <img src={`${BASE_URL}/api/public/assets/images/reschedule-appointment.svg`} alt="appointments.svg" className='w-[14px] h-[14px]'/> Reschedule
                              </button>}
                              <button key="Cancel" className='text-xs font-medium hover:shadow-sm transition-all duration-600 text-left text-gray-900 rounded-md p-1 flex items-center gap-1' onClick={()=>{handleCancelAppointment(record?.id)}}>
                                  <img src={`${BASE_URL}/api/public/assets/images/cancel-appointment.svg`} alt="comment.svg" className='w-[14px] h-[14px]'/> Cancel Appointment
                              </button>
                            </div>
                          }
                          placement="bottomRight"
                          title=""
                          trigger="hover"
                        >
                          <p className='text-2xl font-bold' >+</p>
                        </Popover>
                      {/* </Tooltip> */}
                    </div>}
                    {/* {view && <div
                      onKeyDown={() =>
                        handleSelectedRecord({ name: "view", value: record })
                      }
                      tabIndex={0}
                      role="button"
                      onClick={() =>
                        handleSelectedRecord({ name: "view", value: record })
                      }
                    >
                      <Tooltip title="View">
                      <img
                        src={
                          `${BASE_URL}/api/public/assets/images/eye.svg`
                        }
                        alt="View the record"
                        className='w-[80px] h-[15px]'
                      />
                      </Tooltip>
                    </div>} */}
                    
                </div>
              );
            },
          }
        ],
        rowClick:true,
        tbody: [],
        modalContent: {
          className: "flex flex-col gap-2 overflow-auto max-h-[400px] p-[10px]",
          fields: [],
        },
      },
    ],
  });

  const [dateRange, setDateRange] = useState<[string, string]>([moment().format('DD-MM-YYYY'), moment().format("DD-MM-YYYY")]);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [doctorId,setDoctorId] = useState("");
  const [branchId,setBranchId] = useState("")
  const location=useLocation();
  const dispatch=useDispatch();
  const navigate=useNavigate()
  const [pagination,setPagination]=useState({pagenum:1,limit:10});

  const org_id = Storage.getItem("details")?.["org_id"];

  useEffect(()=>{
    (async()=>{
      const doctors = await API_GET_Doctors_List()
      await API_GET_Appointments({ startDate: dateRange[0], endDate: dateRange[1], searchtext: searchText});  
      setSchema((prev:any)=>{
        return{
          ...prev,
          schema:prev.schema.map((field:any)=>{
            if(field.name === 'filters'){
              return{
                ...field,
                fields:field.fields.map((f:any)=>{
                  if(f.name === 'refresh_and_download'){
                    return{
                      ...f,
                      fields:f.fields.map((ff:any)=>{
                        if(ff.name === 'doctor-filter'){
                          return{
                            ...ff,
                            options:[{
                              value:'',
                              label: "All",
                            },...doctors]
                          }
                        }
                        return ff
                      })
                    }
                  }
                  return f
                })
              }
            }
            return field
          })
        }
      })
    })()
  },[branchId])

  useEffect(() => {
    if (location.state?.source === "Leads") {
      dispatch(setTopbar(location.state?.source))
      dispatch(setActiveMenu("/leads"))
    }
  }, [location.state?.source, dispatch]);

  useEffect(()=>{
    (async()=>{
      const branches = await Get_User_Branches()
      setBranchId(branches[0].branch_id)
      setSchema((prev:any)=>{
          return{
              ...prev,
              schema:prev.schema.map((field:any)=>{
                  if(field.name === 'filters'){
                      return{
                          ...field,
                          fields:field.fields.map((f:any)=>{
                              if(f.name === 'refresh_and_download'){
                                  return{
                                      ...f,
                                      fields:f.fields.map((ff:any)=>{
                                        if(ff.name === 'branch-filter'){
                                          return{
                                            ...ff,
                                            options:branches.map((branch:any)=>{
                                              return{
                                                  label:branch.branch_name,
                                                  value:branch.branch_id
                                              }
                                            }),
                                            value:{
                                                label:branches[0].branch_name,
                                                value:branches[0].branch_id
                                            }
                                          }
                                        }
                                        return ff
                                      })
                                  }
                              }
                              return f
                          })
                      }
                  }
                  return field
              })
          }
      })
  })()
  },[])

  const compareDate = (dateString: string) => {
    const [day, month, year] = dateString.split('-');
    
    const inputDate = new Date(`${year}-${month}-${day}`);
    
    const today = new Date();
    const todayDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());

    return inputDate >= todayDate;
}


  useEffect(() => {
    const id = setTimeout(() => {
      API_GET_Appointments({ startDate: dateRange[0], endDate: dateRange[1], searchtext: searchText});
    }, 0);

    return () => {
      clearTimeout(id);
    }
  }, [searchText,doctorId])

  useEffect(()=>{
    if(dateRange[0]==="" && dateRange[1]===""){
      change_segments_value(null)
  }
  const first = moment(dateRange[0], 'DD-MM-YYYY');
  const second = moment(dateRange[1], 'DD-MM-YYYY');
  const diffInDays = second.diff(first, 'days');

        if (diffInDays === 0) {
            change_segments_value("TD")

        } else if (diffInDays === 6) {
            change_segments_value("TW")
        } else if (diffInDays === 29) {
            change_segments_value("TM")
        }else{
            change_segments_value(null)
        }
        setSchema((prevSchema: any) => {
          const updatedSchema = Utils.updateSchemaRecursively(prevSchema, (field: any) => {
           if(field.name==="date"){
            return {...field,value:dateRange}
           }
           if(field.name==="left_button"){
            return {...field,loading:!dateRange[0]}
           }
           if(field.name === "right_button") {
            const isStartDateMissing = dateRange[0].length===0;
            const isEndDateToday = dateRange[1] === moment().format("DD-MM-YYYY");
        
            return {
                ...field,
                loading: isStartDateMissing || isEndDateToday
            };
        }
        
            return field;
          });
        
          return updatedSchema;
        });
  },[dateRange])

  const change_segments_value=(val:string | null)=>{
    setSchema((prev:any)=>{
        const updatedSchema=prev.schema.map((field:any)=>{
            if(field.name==="filters"){
              field.fields=field.fields.map((ffield:any)=>{
                if(ffield.name==="date_and_search"){
                    ffield.fields=ffield.fields.map((fffield:any)=>{
                        if(fffield.name==="segments"){
                            fffield.value=val;
                        }
                        return fffield;
                    })
                }
                return ffield;
              })
            }
            return field;
        })
        return {...prev,schema:updatedSchema}
    })
  }

  const handleCancelAppointment=(id:any)=>{
    API_POST_Change_Appointment_Status(id, "CANCELLED") 
  }

  const handleComfirmAppointment=(id:any)=>{
    API_POST_Change_Appointment_Status(id, "CONFIRMED") 
  }

  const API_GET_Appointments = async ({
    page = 1,
    limit = 30,
    startDate = "",
    endDate = "",
    searchtext = "",
    departmentId = "",
  } = {}) => {
    setSchema((prev: any) => Utils.handleLoading(prev, true));
    try {
      const res = await Utils.makeApiCall(`/appointments?search=${searchtext}&page=${page}&limit=${limit}&startDate=${startDate}&endDate=${endDate}&doctorId=${doctorId}&departmentId=${departmentId}&branchId=${branchId}`, "GET");
      if (!res.error) {
        const add = true
        const view = true
        const download = true
        setSchema((prev: any) => {
          return{
            ...prev,
            schema:prev.schema.map((s: any) => {
                if (s.element === "table") {
                  s.count = res.total;
                  s["tbody"] = res.appointments.map((acc: any) => {
                    const createdAt = acc["createdAt"]
                      ? moment(acc["createdAt"]).format("DD-MM-YYYY HH:mm")
                      : "";

                    return {
                      id:acc.id,
                      doctor_name:acc.doctor.name,
                      patient_name:acc.patient?.name,
                      phone_number:acc.patient?.mobile,
                      gender:acc.patient?.gender,
                      age:acc.patient?.age,
                      formattedAge:acc.patient?.formattedAge,
                      department_name:acc.department.name,
                      department:acc.department,
                      date:acc.date,
                      start_time:acc.startTime,
                      end_time:acc.endTime,
                      appointment_status:acc.appointmentStatus ? acc.appointmentStatus.charAt(0).toUpperCase() + acc.appointmentStatus.slice(1).toLowerCase() :"", 
                      patient:acc.patient,
                      doctor:acc.doctor,
                      duration:acc.duration,
                      createdAt 
                    };
                  });
                  const hasactions = s["thead"].some((ef:any)=>ef.name === "actions");
                  
                  s["loading"] = false;
                } else if (s.name === "buttons") {
                  s.fields.map((f:any)=>{
                        if (f.name === "btn_add"){
                          f.visible = add
                        } else if (f.name === "btn_download"){
                          f.visible = download
                        }
                  })
                }
                return s;
              })
          }
          
        });
      } else {
        toast.error(res.message ? res.message : FAILURE_MSG, { position: "top-center", autoClose: AUTO_CLOSE });
      }
    } catch (error) {
      toast.error(CATCH_ERROR_MSG, { position: "top-center", autoClose: AUTO_CLOSE });
    }
    setSchema((prev: any) => Utils.handleLoading(prev, false));
  };

  const API_GET_Doctors_List = async()=>{
    const res = await Utils.makeApiCall(`/doctors?branchId=${branchId}`, "GET");
    const doctorsList = res.data.map((doc:any)=>{
      return{
        value:doc._id,
        label:doc.name
      }
    })
    return doctorsList
  }

  const API_POST_Change_Appointment_Status = async(appointmentId:any,status:any)=>{
    try {
      const res = await Utils.makeApiCall(`/appointments/changeStauts`, "POST", {
        appointmentId: appointmentId,
        status: status,
      });
      if (!res.error) {
        handleClosePopup()
        toast.success(res.message, { position: "top-center", autoClose: AUTO_CLOSE });
        API_GET_Appointments({
          page: pagination.pagenum,
          limit: pagination.limit,
          startDate: dateRange[0],
          endDate: dateRange[1],
        });
      } else {
        toast.error(res.message ? res.message : FAILURE_MSG, { position: "top-center", autoClose: AUTO_CLOSE });
      }
    } catch (error) {
      toast.error(CATCH_ERROR_MSG, { position: "top-center", autoClose: AUTO_CLOSE });
    }

  }

  const Get_User_Branches = async()=>{
    const response = await Utils.makeApiCall(`/getUserBranches`,'GET')
    return response.data
}

  const downloadToExcel = async () => {
    try {
      const tableSchema = schema.schema.find((s: any) => s.element === "table");
      const { thead } = tableSchema;
      setLoading(true);

      const response = await Utils.makeApiCall(`/appointments?org_id=${org_id}&page=1&limit=10&startDate=${dateRange[0]}&endDate=${dateRange[1]}&download=true&search=${searchText}`, "GET");
      const data = response.appointments;
      setLoading(false);

      if (!data || data.length === 0) {
        toast.error("No data available to download", { position: "top-center", autoClose: AUTO_CLOSE });
        return;
      }

      const excelData = data.map((appt:any)=>{
        return{
          patient_name:appt.patient.name,
          phone_number:appt.patient.mobile,
          date:appt.date,
          startTime:appt.startTime,
          endTime:appt.endTime,
          doctor_name:appt.doctor.name,
          department_name:appt.department.name,
          status:appt.appointmentStatus
        }
      })

      Utils.exportToExcel(thead, excelData, "appointments");
    } catch (error) {
      toast.error("Failed to download data as Excel", { position: "top-center", autoClose: AUTO_CLOSE });
    }
  };

  const handleShowPopup = async(record:any) =>{
    console.log('record is ',record)
    const appointmentPopup = {
      name:"appointment_popup",
      element:"modal",
      visible:true,
      containerClassName:'w-[10%]',
      fields: [
        {
            name: 'modal_title',
            className:
                'w-full bg-primary/5 p-2 pl-4 flex items-center rounded-t-lg justify-between',
            fields: [
                {
                    name: 'modal_title_key',
                    element: 'div',
                    label: 'Appointment details',
                    className:
                        'font-semibold text-lg text-primary',
                },
                {
                    name: 'modal_close',
                    element: 'button',
                    label: 'X',
                    className:
                        'w-[40px] h-[40px] flex justify-center items-center rounded-full bg-primary text-xl text-white',
                },
            ],
        },
        {
            name: 'modal_body',
            className:
                'flex flex-col max-h-[400px] p-4 overflow-auto gap-1',
            fields: [
              {
                className:'grid grid-cols-2 gap-2 w-[100%]',
                fields:[
                        {
                            element:'div',
                            label:'Patient: ',
                            className:'text-[#69757B] justify-self-end'
                        },
                        {
                            element:'div',
                            label:`${record.patient.name}`,
                            className:'text-[#37474F]'
                        },
                        record.patient.mobile?{
                            element:'div',
                            label:'Mobile: ',
                            className:'text-[#69757B] justify-self-end'
                        }:{},
                        record.patient.mobile?{
                            element:'div',
                            label:`${record.patient.mobile}`,
                            className:'text-[#37474F]'
                        }:{},
                        record.patient.birthDate?{
                            element:'div',
                            label:'DOB: ',
                            className:'text-[#69757B] justify-self-end'
                        }:{},
                        record.patient.birthDate?{
                            element:'div',
                            label:`${record.patient.birthDate}`,
                            className:'text-[#37474F]'
                        }:{},
                        record.patient.age?{
                            element:'div',
                            label:'Age: ',
                            className:'text-[#69757B] justify-self-end'
                        }:{},
                        record.patient.age?{
                            element:'div',
                            label:`${record.patient.age}`,
                            className:'text-[#37474F]'
                        }:{},
                        record.patient.gender?{
                            element:'div',
                            label:'Gender: ',
                            className:'text-[#69757B] justify-self-end'
                        }:{},
                        record.patient.gender?{
                            element:'div',
                            label:`${record.patient.gender}`,
                            className:'text-[#37474F]'
                        }:{},
                        {
                            element:'div',
                            label:'Doctor: ',
                            className:'text-[#69757B] justify-self-end pt-4'
                        },
                        {
                            element:'div',
                            label:`${record.doctor_name}`,
                            className:'text-[#37474F] pt-4'
                        },
                        {
                            element:'div',
                            label:'Dept: ',
                            className:'text-[#69757B] justify-self-end'
                        },
                        {
                            element:'div',
                            label:`${record.department.name}`,
                            className:'text-[#37474F]'
                        },
                          {
                              element:'div',
                              label:'Date: ',
                            className:'text-[#69757B] justify-self-end pt-4'
                          },
                          {
                              element:'div',
                              label:`${record.date}`,
                            className:'text-[#37474F] pt-4'
                          },
                          {
                              element:'div',
                              label:'Time: ',
                            className:'text-[#69757B] justify-self-end'
                          },
                          {
                              element:'div',
                              label:`${record.start_time}-${record.end_time} (${record.duration})`,
                            className:'text-[#37474F]'
                          }
                ]
              },
              {
                className:'flex items-center gap-2 justify-end border-t-2 pt-4',
                fields:[
                  {
                    element:'button',
                    name:'reschedule-appointment',
                    label:<div className='flex justify-center align-center gap-1'><img src = {`${BASE_URL}/api/public/assets/images/reschedule-appointment.svg`} alt="reschedule"></img> <span> Reschedule</span></div>,
                    appointment:record.id,  
                    className:"px-2 py-2 border border-primary text-primary rounded-md font-semibold",
                  },
                  {
                    element:'button',
                    name:'cancel-appointment',
                    label:<div className='flex justify-center align-center gap-1'><img src = {`${BASE_URL}/api/public/assets/images/cancel-appointment.svg`} alt = 'cancel'></img><span className="flex items-center"> Cancel</span></div>,
                    appointment:record.id,
                    className:"px-2 py-2 border border-[#F396AA] text-[#E41B47] rounded-md font-semibold",
                  },
                  {
                    element:'button',
                    name:'confirm-appointment',
                    label:<div className='flex justify-center align-center gap-1'><img src = {`${BASE_URL}/api/public/assets/images/confirm-appointment-reverse-type.svg`} alt = 'cancel'></img><span className="flex items-center"> Confirm</span></div>,
                    appointment:record.id,
                    className:"px-2 py-2 bg-primary text-white rounded-md font-semibold",
                  }
                ]
              }
            ],
        },
    ],
    }
    setSchema((prev:any)=>{
      
      return{
        ...prev,
        schema:[...prev.schema, appointmentPopup]
      }
    })
  }

  const handleClosePopup = async()=>{
    setSchema((prev:any)=>{
      const filteredSchema = prev.schema.filter((field: any) => field.name !== "appointment_popup");
      return{
        ...prev,
        schema:filteredSchema
      }
    })
  }


  const handleSelectedRecord = (e: any) => {
    console.log(e);
    if (e.name === "btn_download") {
      downloadToExcel();
    }
    if(e.name==="btn_add"){
      navigate("/appointments/add")
    }
    if (e.name === "search") {
      setSearchText(e.value);
      setSchema((prevState: any) => {
        const updatedSchema = prevState.schema.map((s: any) => {
          if (s.element === "table") {
            s.value = { name: 'pagination', page: 1, pageSize: 30 };
          }
          return s;
        });
        return { ...prevState, schema: updatedSchema };
      });
    }
    if (e.name === "date") {
      if (e.value === "") {

        setDateRange(["", ""]);

        setSchema((prevState: any) => {
          const updatedSchema = prevState.schema.map((s: any) => {
            if (s.element === "table") {
              s.value = { name: 'pagination', page: 1, pageSize: 30 };
            }
            return s;
          });
          return { ...prevState, schema: updatedSchema };
        });
        API_GET_Appointments({ startDate: "", endDate: "" });
      } else {
        setSchema((prevState: any) => {
          const updatedSchema = prevState.schema.map((s: any) => {
            if (s.element === "table") {
              s.value = { name: 'pagination', page: 1, pageSize: 30 };
            }
            return s;
          });
          return { ...prevState, schema: updatedSchema };
        });
        setDateRange(e.value);
        API_GET_Appointments({ startDate: e.value[0], endDate: e.value[1] });
      }
    }
    if (e.name == "btn_refresh") {
      API_GET_Appointments({
        page: 1,
        limit: 10,
        startDate: dateRange[0],
        endDate: dateRange[1],
        searchtext: searchText
      });
    }
    if (e.name === "segments") {
      if (e?.value === "TD") {
          setDateRange([
              moment().format('DD-MM-YYYY'),
              moment().format('DD-MM-YYYY'),
          ]);
          API_GET_Appointments({
              startDate: moment().format('DD-MM-YYYY'),
              endDate: moment().format('DD-MM-YYYY'),
              searchtext: searchText,
          })
          setSchema((prev: any) => {
              const updatedSchema = prev.schema.map((field: any) => {
                  if (field.name === "filters") {
                      field.fields = field.fields.map((ffield: any) => {
                          if (ffield.name === "date_and_search") {
                              ffield.fields = ffield.fields.map((ff: any) => {
                                  if (ff.name === "date") {
                                      ff.value = [
                                          moment().format('DD-MM-YYYY'),
                                          moment().format('DD-MM-YYYY'),
                                      ];
                                  }
                                  return ff;
                              });
                          }
                          return ffield;
                      });
                  }
                  
                  if(field.element==="table"){
                    field.value={
                        name: 'pagination',
                        page: 1,
                        pageSize: 30,
                    }
                }
                  return field;
              });
              return { ...prev, schema: updatedSchema };
          });
      }
      
      if (e?.value === "TM") {
          setDateRange([
              moment().subtract(29, 'days').format('DD-MM-YYYY'),
              moment().format('DD-MM-YYYY'),
          ]);
          API_GET_Appointments({
              startDate: moment().subtract(29, 'days').format('DD-MM-YYYY'),
              endDate: moment().format('DD-MM-YYYY'),
              searchtext: searchText,
          })
          setSchema((prev: any) => {
              const updatedSchema = prev.schema.map((field: any) => {
                  if (field.name === "filters") {
                      field.fields = field.fields.map((ffield: any) => {
                          if (ffield.name === "date_and_search") {
                              ffield.fields = ffield.fields.map((ff: any) => {
                                  if (ff.name === "date") {
                                      ff.value = [
                                          moment().subtract(29, 'days').format('DD-MM-YYYY'),
                                          moment().format('DD-MM-YYYY'),
                                      ];
                                  }
                                  return ff;
                              });
                          }
                          return ffield;
                      });
                  }
                  if(field.element==="table"){
                    field.value={
                        name: 'pagination',
                        page: 1,
                        pageSize: 30,
                    }
                }
                  return field;
              });
              return { ...prev, schema: updatedSchema };
          });
      }
      if (e?.value === "TW") {
          setDateRange([
              moment().subtract(6, 'days').format('DD-MM-YYYY'),
              moment().format('DD-MM-YYYY'),
          ]);
          API_GET_Appointments({
              startDate: moment().subtract(6, 'days').format('DD-MM-YYYY'),
              endDate: moment().format('DD-MM-YYYY'),
              searchtext: searchText,
          })
          setSchema((prev: any) => {
              const updatedSchema = prev.schema.map((field: any) => {
                  if (field.name === "filters") {
                      field.fields = field.fields.map((ffield: any) => {
                          if (ffield.name === "date_and_search") {
                              ffield.fields = ffield.fields.map((ff: any) => {
                                  if (ff.name === "date") {
                                      ff.value = [
                                          moment().subtract(6, 'days').format('DD-MM-YYYY'),
                                          moment().format('DD-MM-YYYY'),
                                      ];
                                  }
                                  return ff;
                              });
                          }
                          return ffield;
                      });
                  }
                  if(field.element==="table"){
                    field.value={
                        name: 'pagination',
                        page: 1,
                        pageSize: 30,
                    }
                }
                  return field;
              });
              return { ...prev, schema: updatedSchema };
          });
      }
    }
    if (e.name === 'doctor-filter'){
      if(e?.value?.value) {
        setDoctorId(e.value.value)
      } else {
        setDoctorId('');
      }
      
    }
    if (e.name === 'branch-filter'){
      setBranchId(e.value.value)
      setDoctorId('')
      setSchema((prev:any)=>{
        return{
          ...prev,
          schema:prev.schema.map((field:any)=>{
            if(field.name === 'filters'){
              return{
                ...field,
                fields:field.fields.map((f:any)=>{
                  if(f.name === 'refresh_and_download'){
                    return{
                      ...f,
                      fields:f.fields.map((ff:any)=>{
                        if(ff.name === 'doctor-filter'){
                          return{
                            ...ff,
                            value:{
                              value:'',
                              label: "All",
                            }
                          }
                        }
                        return ff
                      })
                    }
                  }
                  return f
                })
              }
            }
            return field
          })
        }
      })
    }
    if (e.name === "appointments_table") {

      if (e.value?.name === "pagination") {
        const { page, pageSize } = e.value;
        setPagination((prev:any)=>{
          return {...prev,pagenum:page,limit:pageSize}
        });
        API_GET_Appointments({
          page: page,
          limit: pageSize,
          startDate: dateRange[0],
          endDate: dateRange[1],
          searchtext: searchText
        });
      }
      else if(e.value.name === "view"){
        handleShowPopup(e.value.value)
      }
    }
    if(e.name === 'modal_close'){
      handleClosePopup()
    }
    if(e.name === 'cancel-appointment'){
      handleCancelAppointment(e.appointment)
    }
    if(e.name === 'confirm-appointment'){
      handleComfirmAppointment(e.appointment)
    }
    if(e.name === 'reschedule-appointment'){
      navigate(`/appointments/reschedule/${e.appointment}`)
    }
    if (e.name === "left_button") {
      
      const today = moment().format('DD-MM-YYYY');

      let dateDifference = moment(dateRange[1], 'DD-MM-YYYY').diff(moment(dateRange[0], 'DD-MM-YYYY'), 'days');
    
      dateDifference = dateDifference === 0 ? 1 : dateDifference;
      
      if (dateDifference === 1) {
          const newStartDate = moment(dateRange[0], 'DD-MM-YYYY').subtract(1, 'days').format('DD-MM-YYYY');
          const newEndDate = moment(dateRange[1], 'DD-MM-YYYY').subtract(1, 'days').format('DD-MM-YYYY');
       
          // const validatedStartDate = moment(newStartDate, 'DD-MM-YYYY').isAfter(today) ? today : newStartDate;
          // const validatedEndDate = moment(newEndDate, 'DD-MM-YYYY').isAfter(today) ? today : newEndDate;
  
          // dispatch(setDateRange([newStartDate, newEndDate]));
          
          setDateRange([newStartDate,newEndDate]);
          API_GET_Appointments({
            page: 1,
            startDate: newStartDate,
            endDate: newEndDate,
            searchtext: searchText
          });
          
      } else {
        
          const newEndDate = moment(dateRange[0], 'DD-MM-YYYY').subtract(1, 'days').format('DD-MM-YYYY');
          

          const newStartDate = moment(newEndDate, 'DD-MM-YYYY').subtract(dateDifference, 'days').format('DD-MM-YYYY');

          // const validatedStartDate = moment(newStartDate, 'DD-MM-YYYY').isAfter(today) ? today : newStartDate;
          // const validatedEndDate = moment(newEndDate, 'DD-MM-YYYY').isAfter(today) ? today : newEndDate;
      
          // dispatch(setDateRange([validatedStartDate, validatedEndDate]));
         
          setDateRange([newStartDate,newEndDate]);
          API_GET_Appointments({
              page:1,
              startDate: newStartDate,
              endDate: newEndDate,
              searchtext: searchText,
          });
      }
    }
    if (e.name === "right_button") {
      const today = moment().format('DD-MM-YYYY');
      
      let dateDifference = moment(dateRange[1], 'DD-MM-YYYY').diff(moment(dateRange[0], 'DD-MM-YYYY'), 'days');
      
      dateDifference = dateDifference === 0 ? 1 : dateDifference;
 
      if (dateDifference === 1) {
          const newStartDate = moment(dateRange[1], 'DD-MM-YYYY').add(1, 'days').format('DD-MM-YYYY');
          const newEndDate = moment(dateRange[1], 'DD-MM-YYYY').add(1, 'days').format('DD-MM-YYYY');
          
        //   const validatedStartDate = moment(newStartDate, 'DD-MM-YYYY').isAfter(moment(today, 'DD-MM-YYYY')) ? today : newStartDate;
        //   const validatedEndDate = moment(newEndDate, 'DD-MM-YYYY').isAfter(moment(today, 'DD-MM-YYYY')) ? today : newEndDate;
  
          // dispatch(setDateRange([newStartDate, newEndDate]));
          
          setDateRange([newStartDate,newEndDate]);
          API_GET_Appointments({
            page:1,
              startDate: newStartDate,
              endDate: newEndDate,
              searchtext: searchText,
          });
      } else {

          const newStartDate = moment(dateRange[1], 'DD-MM-YYYY').add(1, 'days').format('DD-MM-YYYY');

          const newEndDate = moment(newStartDate, 'DD-MM-YYYY').add(dateDifference, 'days').format('DD-MM-YYYY');
      
          // const validatedStartDate = moment(newStartDate, 'DD-MM-YYYY').isAfter(moment(today, 'DD-MM-YYYY')) ? today : newStartDate;
          // const validatedEndDate = moment(newEndDate, 'DD-MM-YYYY').isAfter(moment(today, 'DD-MM-YYYY')) ? today : newEndDate;
      
          // dispatch(setDateRange([validatedStartDate, validatedEndDate]));
          
          setDateRange([newStartDate,newEndDate]);
          API_GET_Appointments({
            page:1,
              startDate: newStartDate,
              endDate: newEndDate,
              searchtext: searchText,
          });
      }
  }
  };



  return (
    <div className="bg-[#EFF1F9]">
      <LoadingState isLoading={loading} />
      <ElementExecutor
        data={schema}
        selectedRecord={(e: any) => {
          handleSelectedRecord(e);
        }}
      />
    </div>
  );
};
// const refresh_leads = async () => {
//   const response = await Utils.makeApiCall('/leads', 'POST', {})
//   if (response.status) {
//     get_leads_details()
//   }
// }

// const handleSelectedRecord = (e: any) => {
//   if (e.name == 'btn_refresh') {
//     refresh_leads()
//   } else if (e.name == 'modal_close') {
//     handleModaldata({ data: [], visible: false })
//   } else if (e.name === 'table') {
//     const val = e['value'] ? e['value']?.['conversation'] : ''
//     if (val) {
//       API_GET_Conversations(val, e['value'])
//     }
//   }
// }



// return (
//   <div className="bg-[#EFF1F9]">
//     <ElementExecutor
//       data={schema}
//       setData={() => { }}
//       selectedRecord={(e: any) => {
//         handleSelectedRecord(e)
//       }}
//     />
//   </div>
// )



