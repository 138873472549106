import { ElementExecutor } from '@apexcura/core';
import React, { useEffect, useState } from 'react'
import { CONSTANTS } from '../../utils/app-constants';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../redux/appState/appStateSlice';
import Utils from '../../utils';
import { toast } from 'react-toastify';
import Storage from '../../utils/local-storage';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { TimePicker } from 'antd';
const { FAILURE_MSG, CATCH_ERROR_MSG, AUTO_CLOSE, BASE_URL } = CONSTANTS;

const localizer = momentLocalizer(moment);

const CustomToolbar = (toolbar:any) => {
    const goToBack = () => {
        toolbar.onNavigate('PREV');
    };

    const goToNext = () => {
        toolbar.onNavigate('NEXT');
    };

    const goToToday = () => {
        toolbar.onNavigate('TODAY');
    };

    const goToView = (view:any) => {
        toolbar.onView(view);
    };

    return (
        <div className="rbc-toolbar">
            <span className="rbc-btn-group">
                
                <button onClick={goToToday}>
                    Today
                </button>
            </span>
            <span className="rbc-toolbar-label ">
                <button onClick={goToBack} style={{border:"none", backgroundColor: "transparent", outline: "none", boxShadow: "none"}}>
                    <img src={`${BASE_URL}/api/public/assets/images/back.svg`} alt='Back'/>
                </button>
                {toolbar.label}
                <button onClick={goToNext} style={{border:"none", transition: 'none', transform:'rotate(180deg)', backgroundColor: "transparent", outline: "none", boxShadow: "none"}}>
                    <img src={`${BASE_URL}/api/public/assets/images/back.svg`} alt='Next'/>
                </button>
            </span>
            <span className="rbc-btn-group">
                <button onClick={() => goToView('day')} className={toolbar.view === 'day' ? 'rbc-active' : ''}>
                    Day
                </button>
                <button onClick={() => goToView('week')} className={toolbar.view === 'week' ? 'rbc-active' : ''}>
                    Week
                </button>
            </span>
        </div>
    );
};

const slotBookingModal = () => {
    return {
        name: "slot_booking_modal",
        element: "modal",
        containerClassName:"!w-[50%]  ",
        className: 'rounded-lg !w-full',
        visible: false,
        fields: [
              {
                name: "modal_head",
                className: "flex justify-between items-center p-4 bg-primary/5",
                fields: [
                    {
                        name:'modal_title_key',
                        className:'font-semibold text-lg  text-[#3341BB]',
                        element:'div',
                        label:'Add Event'
                    },
                    {
                        name: "slot_modal_close",
                        element: "button",
                        label: "X",
                        className:
                          "!w-[35px] !h-[35px] rounded-full bg-primary text-xl text-white flex justify-center items-center",
                    },
                ]
            },
            {
                name:'modal-body',
                className:' p-[10px] w-full overflow-scroll [&::-webkit-scrollbar]:hidden flex flex-col gap-3',
                fields:[
                    {
                        name: "event_type",
                        element: "input-text",
                        placeholder: "Eg. Surgery",
                        label: "Event Name",
                        containerClassName: "w-full flex",
                        labelClassName:
                          "text-sm text-gray-500 font-semibold text-start mt-1 min-w-[30%]",
                        className:
                          "rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none",
                        required: true,
                        visible: true,
                    },
                    {
                        name: "event_description",
                        element: "input-textarea",
                        // placeholder: "",
                        label: "Event Description",
                        containerClassName: "basis-4/6 w-full flex",
                        labelClassName:
                          "text-sm text-gray-500 font-semibold text-start mt-1 min-w-[30%]",
                        className:
                          "rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none",
                        visible: true,
                    },
                    {
                        name: "event_date",
                        element: "datepicker",
                        label: "Date",
                        containerClassName: "w-full flex",
                        labelClassName:
                          "text-sm text-gray-500 font-semibold text-start mt-1 min-w-[30%]",
                        className:
                          "rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none",
                        // required: true,
                        visible: true,
                        enabled_dates: "from_today"
                    },
                    {
                        name: "event_duration",
                        element: "timerangepicker",
                        label: "Duration",
                        containerClassName: "w-full flex",
                        labelClassName:
                          "text-sm text-gray-500 font-semibold text-start mt-1 min-w-[30%]",
                        className:
                          "rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none",
                        // required: true,
                        visible: true,
                        // disabled: true
                    }
                ]
            },
            {
                name: "modal_buttons",
                className: "w-full flex justify-end p-4",
                fields: [
                    {
                        name: "delete_slot_button",
                        className: "p-2 px-3 text-default-500 bg-[#f54c49] rounded-md text-white font-semibold min-w-[15%]",
                        label:"Delete Event",
                        element:'button',
                        visible: false
                    },
                    {
                        name: "submit_cancel",
                        className:"flex gap-4 min-w-[65%] justify-end",
                        fields: [
                            {
                                name:'cancel_slot_button',
                                label:"Cancel",
                                element:'button',
                                className:"text-primary p-2 px-3 text-default-500 bg-white border border-primary rounded-md font-semibold min-w-[30%]", 
                                visible:true
                            },
                            {
                                
                                name: "save_slot_button",
                                label: "Save",
                                element: "button",
                                className:"p-2 px-3 text-default-500 bg-[#3341bb] rounded-md text-white font-semibold min-w-[30%]",
                                visible:true
                                
                            }
                        ]
                    }
                ]
            }
        ]
    }
}

const DoctorEventComponent = ({selectedDoctor, selectedDate}:any) => {
    const branch_id = Storage.getItem("details")?.["branch_id"];
    const [schema, setSchema] = useState<any>({
        className: "w-full bg-white rounded-t-lg flex flex-col gap-3 ",
        schema: [
            // {
            //     name: "filters_section",
            //     className: "w-full flex justify-end",
            //     fields : [
            //         {
            //             name: "doctor_filter",
            //             element: "single-select",
            //             placeholder: "Select Doctor",
            //             // label: "Branch(es)",
            //             containerClassName: "w-[20%] flex flex-col col-span-full",
            //             // labelClassName:
            //             //   "text-sm mb-1 text-gray-500 font-semibold text-start",
            //             className:
            //               "rounded-md  text-gray-700 font-semibold focus:outline-none",
            //             required: true,
            //             visible: true,
            //             // mode: "multiple",
            //             value:null,
            //             endPoint: `/allDoctors?branchId=${branch_id}`,
            //             options:[]
            //           },
            //     ]
            // },
            // {
            //     name: "tabs",
            //     element: "tabs",
            //     containerClassName: "p-0 m-0",
            //     className: "flex items-center gap-1",
            //     options: [
            //         {
            //         key: "avaliable_timings",
            //         label: "Available Timings",
            //         },
            //         {
            //         key: "doctor_events",
            //         label: "Events",
            //         },
            //     ],
            //     value: {
            //         key: "avaliable_timings",
            //         label: "Available Timings",
            //     }
            // },
            {...slotBookingModal()}
        ]
    })

    const dispatch = useDispatch();
    // const [selectedDoctor, setSelectedDoctor] = useState<any>();
    const [events, setEvents] = useState<any>([]);
    const [availableSlots, setAvailableSlots] = useState<any>([]);
    const [selectedSlot, setSelectedSlot] = useState<any>();
    const [calenderDetails, setCalenderDetails] = useState<any>({startDate : moment().startOf('week').format('YYYY-MM-DD'), range : 7});
    useEffect(() => {
        const processFields = (obj: any) => {
            if (Array.isArray(obj)) {
                obj.forEach(processFields);
            } else if (obj && typeof obj === 'object') {
                if (obj.endPoint) {
                API_GET_Options({ name: obj.name, url: obj.endPoint });
                }
                Object.values(obj).forEach(processFields);
            }
        };
        const schemaArr = schema?.schema
        schemaArr.forEach(processFields);
        setSchema((prev: any) => {
            return { ...prev, schema: schemaArr }
        })

    }, [])

    useEffect(() => {
        if(selectedDoctor) {
            fetchEventDetails();
        }
    }, [selectedDoctor, selectedDate]);

    const fetchEventDetails = async () => {
        if (selectedDoctor) {
            dispatch(setLoading(true))
            try {
                const res = await Utils.makeApiCall(`/doctorEvents?doctorId=${selectedDoctor}`, "GET");
                if (res.status) {
                    const data = res.data?.map((eve:any) => {
                        const startTime = new Date(eve?.start_time);
                        let endTime = new Date(eve?.end_time);

                        if (startTime.getHours() === 0 && startTime.getMinutes() === 0 && 
                            endTime.getHours() === 23 && endTime.getMinutes() === 59) {
                            
                            endTime = new Date(endTime);
                            endTime.setDate(endTime.getDate() + 1);
                            endTime.setHours(0, 0, 0, 0);
                        }

                        return {
                            title: eve?.event_type,
                            start: startTime,
                            end: endTime,
                            id: eve?._id,
                            description: eve?.description
                        };
                    })
                    setEvents(data);
                } else {
                    toast.error(res.message, { position: "top-center", autoClose: 2000 });
                }
                
            } catch (err) {
                console.error("Failed to fetch event details", err);
            }
            dispatch(setLoading(false))
        }
    };

    const API_GET_Options = async({name = "", url = ""}) => {
        dispatch(setLoading(true))
        try{
            const res = await Utils.makeApiCall(url, "GET");
            if (res.status) {
                setSchema((prev: any) => {
                    const updateFields: any = (obj: any) => {
                      if (Array.isArray(obj)) return obj.map(updateFields);
                      if (obj && typeof obj === "object") {
                        if (obj.name === name) {
                            let options = [];
                            if (name === "doctor_filter") {
                                options = res.data.map((op: any) => ({ value: op._id, label: op.name }));
                            }
                            obj.options = options;
                            if(options.length){
                                obj.value = options[0];
                                // setSelectedDoctor(options[0]?.value)
                            }
                        }
                        Object.values(obj).map(updateFields);
                      }
                    };
          
                    updateFields(prev.schema);
                    return { ...prev };
                });

            } else {
                toast.error(res.message, { position: "top-center", autoClose: 2000 });
            }

        } catch(error) {
            console.error("Failed to fetch schema", error);
        }
    }

    const handleSelectSlot = (e:any) => {
        if (e.start.getHours() === 0 && e.end.getHours() === 0 && e.end.getMinutes() === 0) {
            const adjustedEnd = new Date(e.end);
            adjustedEnd.setHours(23, 59, 0, 0);
            e.end = adjustedEnd;
        }
        setSelectedSlot("")
        setSchema((prev: any) => {
            const updateFields: any = (obj: any) => {
              if (Array.isArray(obj)) return obj.map(updateFields);
              if (obj && typeof obj === "object") {
                if(obj.name === "slot_booking_modal"){
                    obj.visible = true;
                } else if(obj.name === "event_duration"){
                    // obj.value = `${moment(e?.start).format('hh:mm a')} - ${moment(e?.end).format('hh:mm a')}`;
                    obj["start_time"] = moment(e?.start).toISOString();
                    obj["end_time"] = moment(e?.end).toISOString();
                    obj.value = [moment(e?.start).format('hh:mm a'), moment(e?.end).format('hh:mm a')]
                } else if(obj.name === "event_date") {
                    obj.value = moment(e?.start).format('DD-MM-YYYY');
                } else if (obj.name === "modal_title_key") {
                    obj.label = "Add Event"
                } else if(obj.name === "delete_slot_button"){
                    obj.visible = false
                } else if(obj.name === "modal_buttons"){
                    obj.className = "w-full flex justify-end p-4"
                }
                Object.values(obj).map(updateFields);
              }
            };
            updateFields(prev.schema);
            return { ...prev };
        });
    };

    const handleSelectEvent = (e:any) => {
        setSelectedSlot(e?.id)
        setSchema((prev: any) => {
            const updateFields: any = (obj: any) => {
              if (Array.isArray(obj)) return obj.map(updateFields);
              if (obj && typeof obj === "object") {
                if(obj.name === "slot_booking_modal"){
                    obj.visible = true;
                } else if(obj.name === "event_duration"){
                    // obj.value = `${moment(e?.start).format('hh:mm a')} - ${moment(e?.end).format('hh:mm a')}`;
                    obj["start_time"] = moment(e?.start).toISOString();
                    obj["end_time"] = moment(e?.end).toISOString();
                    obj.value = [moment(e?.start).format('hh:mm a'), moment(e?.end).format('hh:mm a')]
                } else if(obj.name === "event_date") {
                    obj.value = moment(e?.start).format('DD-MM-YYYY');
                } else if (obj.name === "modal_title_key") {
                    obj.label = "Edit Event"
                } else if (obj.name === "event_type") {
                    obj.value = e?.title
                } else if(obj.name === "delete_slot_button"){
                    obj.visible = true
                } else if(obj.name === "modal_buttons"){
                    obj.className = "w-full flex justify-between p-4"
                } else if (obj.name === "event_description") {
                    obj.value = e?.description
                }
                Object.values(obj).map(updateFields);
              }
            };
            updateFields(prev.schema);
            return { ...prev };
        });
    };

    const handleValidation = (schemaArr:any) => {
        let errorMessage = "";
        const processFields = (obj: any) => {
            if (Array.isArray(obj)) {
                obj.forEach(processFields);
            } else if (obj && typeof obj === 'object') {
                if (obj.name === "event_type" && (obj.value?.trim() === "" || obj.value?.trim() === null ||obj.value?.trim() === undefined ) ) {
                    errorMessage = obj.label;
                } else if(obj.name === "event_date" && !obj.value) {
                    errorMessage = obj.label;
                }
                Object.values(obj).forEach(processFields);
            }
        };
        schemaArr.forEach(processFields);
        return errorMessage || false
    }

    const handlePostSlot = async() => {
        try{
            const validate = handleValidation(schema?.schema);
            if(validate){
                toast.error(`${validate} cannot be empty`, {
                    position: "top-center",
                    autoClose: AUTO_CLOSE,
                });
                return;
            }
            dispatch(setLoading(true))
            const payload = getPayload(schema.schema);
            payload.start_time = moment(`${payload.date} ${payload.start_time}`, "YYYY-MM-DD hh:mm A").toISOString();
            payload.end_time = moment(`${payload.date} ${payload.end_time}`, "YYYY-MM-DD hh:mm A").toISOString();
            let res;
            if(selectedSlot) {
                res = await Utils.makeApiCall(`/updateDoctorEvent?eventId=${selectedSlot}`, "PUT", payload)
            } else {
                res = await Utils.makeApiCall("/doctorEvent", "POST", payload);
            }
            if(res.status) {
                await fetchEventDetails()
                closeSlotModal();
            } else {
                toast.error(res.message, { position: "top-center", autoClose: 2000 });
            }
            
        } catch(err) {
            console.error("Failed to save the Event", err);
        }
        dispatch(setLoading(false))
    }

    const handleRemoveEvent = async() => {
        try{
            if(selectedSlot) {
                const res = await Utils.makeApiCall(`/removeDoctorEvent?eventId=${selectedSlot}`, "PUT")
                if(res.status) {
                    await fetchEventDetails()
                    closeSlotModal();
                } else {
                    toast.error(res.message, { position: "top-center", autoClose: 2000 });
                }
            }
        } catch(err) {
            console.error("Failed to save the Event", err);
        }
        dispatch(setLoading(false))
    }

    const getPayload = (schemaArr:any) => {
        const payload = {
            doctor_id : selectedDoctor,
            date: "",
            start_time: "",
            end_time: "",
            event_type: "",
            branch_id: branch_id,
            description: ""
        }
        const processFields = (obj: any) => {
            if (Array.isArray(obj)) {
                obj.forEach(processFields);
            } else if (obj && typeof obj === 'object') {
                if(obj.name === "event_type") {
                    payload.event_type = obj.value;
                } else if(obj.name ===  "event_duration") {
                    payload.start_time = obj.value[0];
                    payload.end_time = obj.value[1];
                    
                } else if (obj.name === "event_date") {
                    payload.date = moment(obj.value, 'DD-MM-YYYY').format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z'
                } else if(obj.name === "event_description") {
                    payload.description = obj.value;
                }
                Object.values(obj).forEach(processFields);
            }
        };
        schemaArr.forEach(processFields);
        return payload;
    }

    const closeSlotModal = () => {
        setSchema((prev: any) => {
            const updateFields: any = (obj: any) => {
              if (Array.isArray(obj)) return obj.map(updateFields);
              if (obj && typeof obj === "object") {
                if(obj.name === "slot_booking_modal"){
                    obj.visible = false;
                } else if(obj.name === "event_type") {
                    obj.value = ""
                } else if(obj.name === "event_description") {
                    obj.value = ""
                }
                Object.values(obj).map(updateFields);
              }
            };
            updateFields(prev.schema);
            return { ...prev };
        });
    }

    const handleSelectedRecord = (e:any) => {
        console.log(e);
        if(e.name === "doctor_filter"){
            // setSelectedDoctor(e?.value?.value)
        }
        if (e.name === "slot_modal_close" || e.name === "cancel_slot_button"){
            closeSlotModal();
        }
        if(e.name === "save_slot_button") {
            handlePostSlot();
        }
        if(e.name === "delete_slot_button") {
            handleRemoveEvent();
        }
    }

    const handleRangeChange = (range:any) => {
        if(range.length === 7) {
            setCalenderDetails({startDate: moment(range[0]).format('YYYY-MM-DD'), range: 7})
        } else if(range.length === 1) {
            setCalenderDetails({startDate: moment(range[0]).format('YYYY-MM-DD'), range: 1})
        }
    };

    return (
        <div>
            <ElementExecutor
                data={schema}
                selectedRecord={(e:any) => {
                    handleSelectedRecord(e)
                }}
            
            />
            <div className='w-full bg-white rounded-b-lg flex flex-col gap-3 '>
                <Calendar
                    localizer={localizer}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 450 }}
                    selectable
                    defaultView="day"
                    views={['week', 'day']}
                    defaultDate={selectedDate}
                    onSelectSlot={(slotInfo) => {
                        const today = new Date();
                        if (new Date(slotInfo.start) >= today) {
                            handleSelectSlot(slotInfo);
                        }
                    }}
                    onSelectEvent={handleSelectEvent}
                    onRangeChange={handleRangeChange}
                    components={{
                        toolbar: CustomToolbar,
                        // event: CustomEvent
                    }}
                />
            </div>
        </div>
    )

} 

export default DoctorEventComponent;