import { ElementExecutor } from '@apexcura/core';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CONSTANTS } from '../../utils/app-constants';
import Utils from '../../utils';
import Storage from '../../utils/local-storage';
import { TimePicker } from 'antd';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../redux/appState/appStateSlice';
const { FAILURE_MSG, CATCH_ERROR_MSG, AUTO_CLOSE } = CONSTANTS;

const AddUsers = () => {
    const [schema, setSchema] = useState<any>({
      className: "w-full bg-white rounded-lg p-8 flex flex-col gap-3 items-center",
      schema: []
    });
    const navigate=useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const userId = params?.userId || "";
    const { pathname } = useLocation();
    const slugs = pathname.split("/");
    const isView = slugs.includes("view");
    const [designation, setDesignation] = useState({label: "" ,value: '' });

    const org_id = Storage.getItem("details")?.["org_id"];
    const branch_id = Storage.getItem("details")?.["branch_id"];

    useEffect(() => {
      const schemaArr =  [
        {
          name: "data_fields",
          className: "w-full flex flex-col gap-4",
          fields: [
            {
              name:"name_email_password_fields",
              className:"grid grid-cols-3 gap-2 ",
              fields:[
                {
                  name: "designation",
                  element: "single-select",
                  placeholder: "Eg: Agent",
                  label: "Designation",
                  containerClassName: "w-full flex flex-col col-span-1",
                  labelClassName:
                    "text-sm mb-1 text-gray-500 font-semibold text-start",
                  className:
                    "rounded-md  text-gray-700 font-semibold focus:outline-none",
                  required: true,
                  visible: true,
                  endPoint:"/user-designations",
                  options:[]
                },
                // {
                //   name: "fullname",
                //   element: "input-text",
                //   placeholder: "Eg: Jon doe",
                //   label: "Name",
                //   containerClassName: "w-full flex flex-col",
                //   labelClassName:
                //     "text-sm mb-1 text-gray-500 font-semibold text-start",
                //   className:
                //     "rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none",
                //   required: true,
                //   visible: true,
                // },
                {
                  name: "data_pair",
                  className: "flex flex-col w-full",
                  fields: [
                      {
                          name: "field-name",
                          className: "text-sm mb-1 text-gray-500 font-semibold text-start",
                          label: <div>Name <span style={{color:"red"}}>*</span></div>,
                          element: "div",
                          // required:true
                      },
                      {
                          name: "name-input",
                          className:"flex gap-1 col-span-1 w-full",
                          fields:[
                            {
                                name: "name_prefix",
                                element: "single-select",
                                placeholder: "",
                                containerClassName: "w-[20%]",
                                className: "rounded-md text-gray-700 font-semibold focus:outline-none",
                                visible: true,
                                value: {label:"Mr", value: "Mr"},
                                options: [{label:"Mr", value: "Mr"}, {label:"Ms", value: "Ms"}],
                                disabled:false
                            },
                            {
                              name:"fullname",
                              element:"input-text",
                              className:"rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none"
                            }
                          ]
                      },
                  ]
              },
              {
                name: "phonenumber",
                element: "input-number",
                placeholder: "Eg: 8123456790",
                label: "Mobile",
                containerClassName: "w-full flex flex-col",
                labelClassName:
                  "text-sm mb-1 text-gray-500 font-semibold text-start",
                className:
                  "rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none",
                required: true,
                visible: true,
              },
                {
                  name: "email",
                  element: "input-text",
                  placeholder: "Eg: example@gmail.com",
                  label: "Email",
                  containerClassName: "w-full flex flex-col ",
                  labelClassName:
                    "text-sm mb-1 text-gray-500 font-semibold text-start",
                  className:
                    "rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none",
                  required: true,
                  visible: true,
                },
                {
                  name: "password",
                  element: "input-password",
                  placeholder: "Enter a password",
                  label: "Password",
                  containerClassName: "w-full flex flex-col ",
                  labelClassName:
                  "text-sm mb-1 text-gray-500 font-semibold text-start",
                  className:
                    "rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none",
                  required: true,
                  visible: true,
                  
                },
                
                {
                  name: "branches",
                  element: "single-select",
                  placeholder: "Select branch(es)",
                  label: "Branch(es)",
                  containerClassName: "w-full flex flex-col col-span-full",
                  labelClassName:
                    "text-sm mb-1 text-gray-500 font-semibold text-start",
                  className:
                    "rounded-md  text-gray-700 font-semibold focus:outline-none",
                  required: true,
                  visible: true,
                  mode: "multiple",
                  value:null,
                  endPoint: "/getUserBranches",
                  options:[]
                },
                {
                  name: "qualification",
                  element: "input-text",
                  placeholder: "",
                  label: "Qualification",
                  containerClassName: "w-full flex flex-col",
                  labelClassName:
                    "text-sm mb-1 text-gray-500 font-semibold text-start",
                  className:
                    "rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none",
                  required: false,
                  visible: false,
                },
                {
                  name: "department",
                  element: "single-select",
                  placeholder: "",
                  label: "Department",
                  containerClassName: "w-full flex flex-col col-span-1",
                  labelClassName:
                    "text-sm mb-1 text-gray-500 font-semibold text-start",
                  className:
                    "rounded-md  text-gray-700 font-semibold focus:outline-none",
                  required: false,
                  visible: false,
                  endPoint:`/departmentsDropdown?org_id=${org_id}&branch_id=${branch_id}`,
                  options:[]
                },
                {
                  name: "slotsize",
                  element: "input-number",
                  placeholder: "",
                  label: <span>Slot Size <span className='text-gray-400 font-normal'>(in mins)</span></span>,
                  containerClassName: "w-full flex flex-col",
                  labelClassName:
                    "text-sm mb-1 text-gray-500 font-semibold text-start",
                  className:
                    "rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none",
                  required: false,
                  visible: false,
                },
                {
                  name: "registration",
                  element: "input-text",
                  placeholder: "",
                  label: "Registration No.",
                  containerClassName: "w-full flex flex-col",
                  labelClassName:
                    "text-sm mb-1 text-gray-500 font-semibold text-start",
                  className:
                    "rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none",
                  // required: false,
                  visible: false,
                },
                {
                  name: "specialist",
                  element: "input-textarea",
                  placeholder: "",
                  label: "Specialist",
                  containerClassName: "w-full flex flex-col col-span-2",
                  labelClassName:
                    "text-sm mb-1 text-gray-500 font-semibold text-start",
                  className:
                    "rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none",
                  required: false,
                  visible: false,
                },
                {
                  className:"gap-2 w-full col-span-full ",
                  name:"upload_1",
                  fields:[
                    {
                      element:"div",
                      name:"long-sign-label",
                      label:"Long Sign",
                      className:"text-gray-500 font-semibold text-sm"
                    },
                    {
                      name: "long-sign",
                      element: "upload",
                      label: "Upload",
                      accept: "image/*",
                      multiple: false,
                      allowOne:true,
                      className: "",
                      visible: true,
                      value: null,

                    }
                  ],
                  visible: false
                },
                {
                  className:"gap-2 w-full col-span-full",
                  name:"upload_2",
                  fields:[
                    {
                      element:"div",
                      name:"short-sign-label",
                      label:"Short Sign",
                      className:"text-gray-500 font-semibold text-sm "
                    },
                    {
                      name: "short-sign",
                      element: "upload",
                      label: "Upload",
                      accept: "image/*",
                      multiple: false,
                      allowOne:true,
                      className: "",
                      visible: true,
                      value: null,
                    }
                  ],
                  visible: false
                },
                {
                  name:"doctor_timings",
                  label:"Doctors Timings",
                  className:"text-sm mb-1 text-gray-500 font-semibold text-start mt-2 col-span-3",
                  element:"div",
                  visible: false,
                },
                {
                  name: "doctor_table",
                  element:"time-schedule-table",
                  className: "mt-2 col-span-full",
                  value: {
                    Sunday: { from: null, to: null, id:''},
                    Monday: { from: null, to: null, id:'' },
                    Tuesday: { from: null, to: null, id:'' },
                    Wednesday: { from: null, to: null, id:''},
                    Thursday: { from: null, to: null, id:''},
                    Friday: { from: null, to: null, id:''},
                    Saturday: { from: null, to: null, id:''}
                  }
                },
                {
                  name: "access_policies",
                  element: "single-select",
                  placeholder: "Eg: Dashboard Full Access",
                  label: "Permission(s)",
                  containerClassName: "w-full flex flex-col col-span-full",
                  labelClassName:
                    "text-sm mb-1 text-gray-500 font-semibold text-start",
                  className:
                    "rounded-md  text-gray-700 font-semibold focus:outline-none",
                  required: true,
                  visible: true,
                  mode: "multiple",
                  value:null,
                  endPoint: "/getAllPoliciesToAssignUsers",
                  options:[]
                },
              ]
            },
            {
              name:"access_policies_permissions",
              className:"flex flex-col gap-2",
              visible: false,
              fields:[
                {
                  name:"access_policies_label",
                  label:"Policy Permissions",
                  className:"text-sm mb-1 text-gray-500 font-semibold text-start",
                  element:"div"
                },
                {
                  className: "",
                  name: "table",
                  element: "table",
                  variant: "plain",
                  size: "small",
                  count: 0,
                  visible: true,
                  loading: false,
                  thead: [
                      { name: "page_name", label: "Page", key: "page_name" },
                      { name: "readonly", label: "Read Only", key: "readonly" },               
                      { name: "view", label: "View", key: "view" },               
                      { name: "add", label: "Add", key: "add" },               
                      { name: "edit", label: "Edit", key: "edit" },               
                      { name: "delete", label: "Delete", key: "delete" },               
                      { name: "download", label: "Download", key: "download" },               
                  ],
                  tbody: [
                    
                  ],
                },
              ]
            }
          ],
        },
        {
          name: "cancel_and_submit",
          fields: [
            {
              name: "cancel",
              label: "Cancel",
              element: "button",
              className:
                "p-2 px-3 text-default-500 bg-gray-200 text-black rounded-md font-semibold",
            },
            {
              name: "submit",
              label: "Submit",
              element: "button",
              visible: !isView,
              className:
                "p-2 px-3 text-default-500 bg-[#3341bb] rounded-md text-white font-semibold",
            },
          ],
          className: "flex self-end gap-2 mt-4",
        },
      ];
      const processFields = (obj: any) => {
        if (Array.isArray(obj)) {
          obj.forEach(processFields);
        } else if (obj && typeof obj === 'object') {
          if (obj.endPoint || obj.firstEndpoint) {
            API_GET_Options({ name: obj.name, url: obj.endPoint || obj.firstEndpoint });
          }
          Object.values(obj).forEach(processFields);
        }
      };
      schemaArr.forEach(processFields);
      setSchema((prev: any) => {
        return { ...prev, schema: schemaArr }
      })
    }, [])

    useEffect(() => {
      if(designation && designation?.value === "4"){
        setSchema((prev:any) => {
            const newSchema = {...prev}
            newSchema?.schema?.map((f:any) => {
              if(f?.name === "data_fields"){
                f?.fields?.map((ff:any) => {
                  if(ff.name === "name_email_password_fields"){
                    ff?.fields?.map((fff:any) => {
                      if(["qualification", "department", "doctor_table", "doctor_timings", "slotsize", "registration", "specialist", "upload_1", "upload_2"].includes(fff?.name)){
                        fff.visible = true;
                        if(fff?.name === "qualification" || fff.name === "department" || fff.name === "slotsize"){
                          fff.required = true
                        }
                      }
                      if(fff.name === "data_pair"){
                        fff.fields[1].fields[0].value = "Dr";
                        fff.fields[1].fields[0].disabled = true;
                      }
                    })
                  }
                })
              }
            })
            return newSchema;
        })
      } else {
        setSchema((prev:any) => {
          const newSchema = {...prev}
          newSchema?.schema?.map((f:any) => {
            if(f?.name === "data_fields"){
              f?.fields?.map((ff:any) => {
                if(ff.name === "name_email_password_fields"){
                  ff?.fields?.map((fff:any) => {
                    if(["qualification", "department", "doctor_table", "doctor_timings", "slotsize", "registration", "specialist", "upload_1", "upload_2"].includes(fff?.name)){
                      fff.visible = false;
                      if(fff?.name === "qualification" || fff.name === "department" || fff.name === "slotsize"){
                        fff.required = false
                      }
                     
                    }
                    if(fff.name === "data_pair"){
                      fff.fields[1].fields[0].value = "Mr.";
                      fff.fields[1].fields[0].disabled = false;
                    }
                  })
                }
              })
            }
          })
          return newSchema;
      })
      }
    }, [designation])

    const API_GET_Options = async ({ name = "", url = "" }) => {
      dispatch(setLoading(true))
      try {
        const res = await Utils.makeApiCall(url, "GET");
        if (res.status) {
          setSchema((prev: any) => {
            const updateFields: any = (obj: any) => {
              if (Array.isArray(obj)) return obj.map(updateFields);
              if (obj && typeof obj === "object") {
                if (obj.name === name) {
                  let options = [];
                  if (name === "access_policies") {
                    options = res.data.map((op: any, index: number) => ({ ...op, key: index, page_name: op.label, value: op.role_id, label: op.role }));
                  } else if (name === "branches") {
                    options = res.data.map((op: any) => ({ value: op.branch_id, label: op.branch_name }));
                  }else if (name === "designation") {
                    options = res.data.map((op:any)=>({label:op.label,value:JSON.stringify(op.value)}))
                  } else if (name === "department") {
                    options = res.data.map((op:any)=>({label:op.dept_name,value:JSON.stringify(op._id)}))
                  } else {
                    options = res.data.map((op: any) => ({ value: op.id, label: op.name }));
                  }
                  obj.options = options;
                }
                Object.values(obj).map(updateFields);
              }
            };
  
            updateFields(prev.schema);
            return { ...prev };
          });
        } else {
          toast.error(res.message, { position: "top-center", autoClose: 2000 });
        }
      } catch (error) {
        console.error("Failed to fetch schema", error);
      }
  
      dispatch(setLoading(false))
    };
    

    useEffect(() => {
      if (userId) {
          API_GET_Details(userId);
      }
  }, [userId]);
  function urlToEnhancedBase64FileObject(url:any, orgName:string) {
    return new Promise((resolve, reject) => {
      fetch(url)
        .then(response => response.blob())
        .then(blob => {
          const fileName = url.split('/').pop() || 'image-from-url';
          const file:any = new File([blob], fileName, { type: blob.type });
          
          const reader = new FileReader();
          reader.onloadend = function() {
            const base64data = reader.result;
            file["uid"]=`rc-upload-${Date.now()}-${Math.floor(Math.random() * 10)}`;
            const fileObject = {
              uid: `rc-upload-${Date.now()}-${Math.floor(Math.random() * 10)}`,
              name: orgName,
              originFileObj: file,
              status: 'done',
              thumbUrl: base64data,
              url: base64data,
            };
            resolve(fileObject);
          };
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        })
        .catch(error => reject(error));
    });
  }

    const API_GET_Details=async (userId:string)=>{
      dispatch(setLoading(true))
      try {
        const res = await Utils.makeApiCall(`/user-management/${userId}/${org_id}`,'GET')
        if (res.status) 
        {
          let doctorData;
          if(res?.data?.designation_id === 4){
            const res2 = await Utils.makeApiCall(`/doctorDetails?user_id=${res?.data?.id}`,'GET');
            if(res2.status){
              doctorData = res2.data;
            } else {
              toast.error(res.message ? res.message : FAILURE_MSG, {
                position: 'top-center',
                autoClose: AUTO_CLOSE,
            })
            }
          }
          handleGetDetails(res.data, doctorData);
        }
        else {
            toast.error(res.message ? res.message : FAILURE_MSG, {
                position: 'top-center',
                autoClose: AUTO_CLOSE,
            })
        }
      } catch (error) {
          toast.error(CATCH_ERROR_MSG, {
              position: 'top-center',
              autoClose: AUTO_CLOSE,
          })
          console.error('Failed to fetch schema', error)
      }
      dispatch(setLoading(false))
    }
    const handleGetDetails= async(data:any, doctorData:any)=>{
      let access_policies:any = [];
      const dap = data?.access_policies || [];
      const branches = data?.branches || [];
      const processFileUpload = async (file: any) => {
        if (file) {
          try {
            return await urlToEnhancedBase64FileObject(CONSTANTS.BASE_URL + "/api/" + file.path, file.original_name);
          } catch (error) {
            console.error('Error:', error);
            return null;
          }
        }
        return null;
      };
      const [longSign, shortSign]:any = await Promise.all([
        processFileUpload(doctorData?.long_sign),
        processFileUpload(doctorData?.short_sign)
      ]);
      setSchema((prev:any)=>{
        const updatedSchema=prev.schema.map((field:any)=>{
            if(field.name==="data_fields"){
              field.fields=field.fields.map((ffield:any)=>{
                if(ffield.name==="name_email_password_fields"){
                  ffield.className="grid grid-cols-3 gap-2";
                  ffield.fields=ffield.fields.map((fffield:any)=>{
                    if(fffield.name==="access_policies"){
                      fffield.value=dap.map((ee:any)=>({value: ee.role_id, label: ee.role}))
                      access_policies = fffield.options;
                      fffield.containerClassName= "w-full flex flex-col col-span-3 "
                    } else if(fffield.name==="branches"){
                      fffield.value=branches.map((ee:any)=>({value: ee.branch_id, label: ee.branch_name}))
                      fffield.containerClassName= "w-full flex flex-col col-span-2 "
                    } else if(fffield.name==="password"){
                      fffield.visible=false
                      fffield.required=false
                    } else if(fffield.name==="designation"){
                      fffield.visible=true
                      fffield.required=true
                      fffield.containerClassName="w-full flex flex-col col-span-1"
                      fffield.value={value:data?.designation_id,label: data?.designation_name}
                      setDesignation({value:data?.designation_id?.toString(),label: data?.designation_name})
                    } else if(fffield.name === "qualification"){
                        fffield.value= doctorData?.qualification
                    } else if(fffield.name === "department"){
                      fffield.value={value : JSON.stringify(doctorData?.department_id)}
                    } else if(fffield.name === "slotsize") {
                      fffield.value = doctorData?.slot_size
                    } else if(fffield.name === "registration") {
                      fffield.value = doctorData?.tsmc_registration_no
                    } else if(fffield.name === "specialist") {
                      fffield.value = doctorData?.speciality
                    } else if(fffield.name === "data_pair") {
                      fffield.fields[1].fields[1].value = data?.fullname
                      fffield.fields[1].fields[0].value = data?.prefix
                    } else if(fffield.name === "upload_1") {
                      if(doctorData?.long_sign){
                        // urlToEnhancedBase64FileObject(CONSTANTS.BASE_URL+"/api/"+ doctorData?.long_sign?.path, doctorData?.long_sign?.original_name)
                        //   .then((fileObject:any) => {
                        //     console.log(fileObject);
                            
                        //     fffield.fields[1].value =[{...fileObject}];
                        //   })
                        //   .catch(error => console.error('Error:', error)); 
                        fffield.fields[1].value = [{ ...longSign }];
                      } 
                    } else if(fffield.name === "upload_2") {
                      if(doctorData?.short_sign){
                        // urlToEnhancedBase64FileObject(CONSTANTS.BASE_URL+"/api/"+ doctorData?.short_sign?.path, doctorData?.short_sign?.original_name)
                        //   .then((fileObject:any) => {
                        //     console.log(fileObject);
                            
                        //     fffield.fields[1].value =[{...fileObject}];
                        //   })
                        //   .catch(error => console.error('Error:', error)); 
                        fffield.fields[1].value = [{ ...shortSign }];
                      } 
                    } else if(fffield.name === "doctor_table"){              

                    doctorData?.doctor_timings?.forEach((day: any) => {
                      switch (day?.day) {
                          case "SUNDAY":
                              fffield.value.Sunday.from = day?.start_time || null;
                              fffield.value.Sunday.to = day?.end_time || null;
                              fffield.value.Sunday.id = day?._id || '';
                              break;
                          case "MONDAY":
                              fffield.value.Monday.from = day?.start_time || null;
                              fffield.value.Monday.to = day?.end_time || null;
                              fffield.value.Monday.id = day?._id || '';
                              break;
                          case "TUESDAY":
                              fffield.value.Tuesday.from = day?.start_time || null;
                              fffield.value.Tuesday.to = day?.end_time || null;
                              fffield.value.Tuesday.id = day?._id || '';
                              break;
                          case "WEDNESDAY":
                              fffield.value.Wednesday.from = day?.start_time || null;
                              fffield.value.Wednesday.to = day?.end_time || null;
                              fffield.value.Wednesday.id = day?._id || '';
                              break;
                          case "THURSDAY":
                              fffield.value.Thursday.from = day?.start_time || null;
                              fffield.value.Thursday.to = day?.end_time || null;
                              fffield.value.Thursday.id = day?._id || '';
                              break;
                          case "FRIDAY":
                              fffield.value.Friday.from = day?.start_time || null;
                              fffield.value.Friday.to = day?.end_time || null;
                              fffield.value.Friday.id = day?._id || '';
                              break;
                          case "SATURDAY":
                              fffield.value.Saturday.from = day?.start_time || null;
                              fffield.value.Saturday.to = day?.end_time || null;
                              fffield.value.Saturday.id = day?._id || '';
                              break;
                          default:
                              break;
                      }
                  });
                  


                    } else {
                      fffield.value=data[fffield.name]
                    }
                    fffield.disabled = isView;
                    return fffield;
                  })
                }
                return ffield;
              })
            }
          return field;
        })
        const policies = dap.map((op:any)=>{
          const policy = access_policies.find((oo:any)=> oo.role_id === op.role_id);
          if (policy){
            return policy;
          }
        }).filter(Boolean);
        handlePreview(policies);
        return {...prev,schema:updatedSchema}
      })
    }

    const validateTimings = (timings:any) => {
      for (const timing of timings) {
        const { from, to } = timing;
        
        if ((from && !to) || (!from && to)) {
            toast.error(`Both "From" and "To" must be present for ${timing.day}.`,{position:"top-center",autoClose:AUTO_CLOSE})
            return false;
        }
        if (from && to) {
            const [fromHours, fromMinutes] = from.split(':').map(Number);
            const [toHours, toMinutes] = to.split(':').map(Number);
            const fromTotalMinutes = fromHours * 60 + fromMinutes;
            const toTotalMinutes = toHours * 60 + toMinutes;
            if (fromTotalMinutes >= toTotalMinutes) {
                toast.error(`"From" time should be less than "To" time for ${timing.day}` ,{position:"top-center",autoClose:AUTO_CLOSE})
                return false;
            }
        }
    }
      return true;
    }

    const API_POST_Data=async ()=>{
      dispatch(setLoading(true))
      const payLoad= getPayload(schema) as any;
      payLoad.forEach((value:any, key:any) => {
        console.log(key, value);
      });
      if(!payLoad){
        return;
      }
      try{
        let res:any={};
        if(userId){
           res=await Utils.makeApiCall(`/user-management/${userId}`,"PUT",payLoad);
           let res2;
           if(designation?.value === "4"){
              payLoad.append("branch_id", branch_id);
              payLoad.append("user_id", res?.data?.id);
              res2 = await Utils.makeFormdataApiCall(`/doctorDetails?user_id=${userId}`,"PUT", payLoad);
          }
        }else{
           res=await Utils.makeApiCall("/user-management","POST",payLoad);
           console.log(res?.data?.id);
           let res2;
           if(designation?.value === "4"){
              payLoad.append("branch_id", branch_id);
              payLoad.append("user_id", res?.data?.id);
              res2 = await Utils.makeFormdataApiCall("/doctor","POST", payLoad);
            }
        }
        if(res.status){
          toast.success(res.message,{position:"top-center",autoClose:AUTO_CLOSE})
          navigate("/users")
        }else{
          toast.error(res.message ? res.message : FAILURE_MSG,{position:"top-center",autoClose:AUTO_CLOSE})
        }
      }
      catch(error){
        toast.error(CATCH_ERROR_MSG,{position:"top-center",autoClose:AUTO_CLOSE})
        console.log("Error while sending the data.",error)
      }finally{
        dispatch(setLoading(false))
      }

    }

    const getPayload=(e:any):object =>{
      const payload:any={
        org_id,
        fullname:"",
        phonenumber:"",
        email:"",
        designation_id:"",
        access_policies:[],
        branches: [],
        qualification: "",
        department: "",
        doctor_timings:[]
      };
      const processFields = (obj: any) => {
        if (obj["fields"] && obj["fields"].length) {
          obj.fields.forEach(processFields);
        } else {
          if (["fullname", "phonenumber", "email", "qualification", "slotsize", "registration", "specialist", "long-sign", "short-sign"].includes(obj.name)){
            payload[obj.name] = obj.value;
          } else if (obj.name==="designation"){
            payload["designation_id"]=obj?.value?.value;
          } else if (obj.name === "password"){
            if(!userId){
              payload[obj.name]=obj.value;
            }
          } else if (["branches", "access_policies"].includes(obj.name)){
            payload[obj.name] = obj?.value?.map((ee:any)=> ee.value);
          } else if(obj.name==="department") {
            if (obj?.value?.value) {
              payload["department"] = JSON.parse(obj?.value?.value);
            }
          } else if(obj.name==="name_prefix") {
            payload["name_prefix"] = obj?.value?.value || obj?.value;
            
          } else if(obj?.name === "doctor_table"){
            payload["doctor_timings"] = [
              { 
                  day: "SUNDAY", 
                  from: obj?.value?.Sunday?.from, 
                  to: obj?.value?.Sunday?.to,
                  id: obj?.value?.Sunday?.id
              },
              { 
                  day: "MONDAY", 
                  from: obj?.value?.Monday?.from, 
                  to: obj?.value?.Monday?.to,
                  id: obj?.value?.Monday?.id
              },
              { 
                  day: "TUESDAY", 
                  from: obj?.value?.Tuesday?.from, 
                  to: obj?.value?.Tuesday?.to,
                  id: obj?.value?.Tuesday?.id
              },
              { 
                  day: "WEDNESDAY", 
                  from: obj?.value?.Wednesday?.from, 
                  to: obj?.value?.Wednesday?.to,
                  id: obj?.value?.Wednesday?.id
              },
              { 
                  day: "THURSDAY", 
                  from: obj?.value?.Thursday?.from, 
                  to: obj?.value?.Thursday?.to,
                  id: obj?.value?.Thursday?.id
              },
              { 
                  day: "FRIDAY", 
                  from: obj?.value?.Friday?.from, 
                  to: obj?.value?.Friday?.to,
                  id: obj?.value?.Friday?.id
              },
              { 
                  day: "SATURDAY", 
                  from: obj?.value?.Saturday?.from, 
                  to: obj?.value?.Saturday?.to,
                  id: obj?.value?.Saturday?.id
              }
          ];
          
          }
        }
      };
      e.schema.forEach(processFields); 
      if(designation?.value === "4" && !validateTimings(payload?.doctor_timings)){
        setLoading(false);
        return {};
      }
      console.log(payload);
       
      const formData = new FormData();
      Object.keys(payload).forEach(key => {
        if (Array.isArray(payload[key])) {
          if (key === "long-sign" || key === "short-sign") {
            payload[key].forEach((file: any) => {
              formData.append(key, file.originFileObj);
            });
          } else if (key === "doctor_timings") {
            formData.append('doctor_timings', JSON.stringify(payload[key]));
          } else {
            payload[key].forEach((item: any) => {
              formData.append(`${key}[]`, item);
            });
          }
        } else {
          formData.append(key, payload[key]);
        }
      });

      return formData;
    }
  
    const handleSelectedRecord = (e: any) => { 
      console.log(e)     
      if (e.name === "cancel") {
        navigate("/users");
      }
      if (e.name === "access_policies") {
        handlePreview(e.value);
      }
      if (e.name === "designation") {
        setDesignation(e.value);
      }
      if(e.name==="submit"){
        const validationResponse = Utils.handleValidation(schema.schema);
        const nameValue = schema.schema[0]?.fields[0]?.fields[1]?.fields[1]?.fields[1]?.value;
        if(!nameValue){
          toast.error(`Name cannot be empty`, {
            position: "top-center",
            autoClose: AUTO_CLOSE,
          });
        } else if (validationResponse) {
              toast.error(`${validationResponse} cannot be empty`, {
                position: "top-center",
                autoClose: AUTO_CLOSE,
              });
          }
          else{
            API_POST_Data();
          }
      }
    };

    function handlePreview(e: any) {
      const routeAccessMap = new Map<string, any>();
    
      // Iterate through the records and store only the highest priority access
      e.forEach((r: any) => {
        const existingEntry = routeAccessMap.get(r?.route);
        if (!existingEntry || r?.is_full_access) {
          routeAccessMap.set(r?.route, {
            route: r?.route,
            page_name: r?.page_name,
            is_full_access: r?.is_full_access,
            readonly: r?.readonly ? "✔" : "",
            view: r?.view ? "✔" : "",
            add: r?.add ? "✔" : "",
            edit: r?.edit ? "✔" : "",
            download: r?.download ? "✔" : "",
            delete: r?.delete ? "✔" : "",
          });
        }
      });
    
      // Convert the map back to an array
      const PERMS = Array.from(routeAccessMap.values());
      setSchema((prev: any) => {
        const updatedSchema = prev.schema.map((field: any) => {
          if (field.name === "data_fields") {
            field.fields = field.fields.map((subfield: any) => {
              if (subfield.name === "access_policies_permissions") {
                if (PERMS?.length) {
                  if (subfield.fields) {
                    subfield.fields = subfield.fields.map((ffield: any) => {
                      if (ffield.name === "table") {
                        ffield.tbody = PERMS;
                      }
                      // console.log(ffield);
                      return ffield;
                    });
                  }
                  subfield.visible = true;
                } else {
                  subfield.visible = false;
                }
              }
              return subfield;
            });
          }
          return field;
        });
        return { ...prev, schema: updatedSchema };
      });
    }
  return (
    <div>
      <ElementExecutor
            data={schema}
            setData={(e:any)=>setSchema({...e})}
            selectedRecord={handleSelectedRecord}
        />
    </div>
  )
}

export default AddUsers
