import React from 'react'
// import Comingsoonimg from "../assets/images/comingsoon.gif";

const ComingSoon = () => {
  return (
    <div className='w-full h-[400px] flex justify-center items-center'>
        {/* <img src={Comingsoonimg} alt="ComingSoon" className='w-full h-full object-contain'/>       */}
        <p className='text-gray-400 text-xl font-semibold tracking-widest uppercase'>Coming soon</p>
    </div>
  )
}

export default ComingSoon
