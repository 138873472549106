import { Layout, Skeleton } from "antd";
import React from "react";
const { Content } = Layout;

const RecentInteractionSkeleton = () => {
  return (
    <Content className={`flex flex-col gap-2 p-1 w-full items-center`}>
      {[...Array(5)].map((_, index) => (
        <Skeleton.Button
          key={index}
          active={true}
          size="default"
          shape="square"
          style={{ height: 58 }}
          block={true}
        />
      ))}
    </Content>
  );
};

export default RecentInteractionSkeleton;
