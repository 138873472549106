import { ElementExecutor } from "@apexcura/core"
import { useEffect, useState } from "react"
import LoadingState from "../LoadingState"
import Utils from "../../utils"
import moment from "moment"
import { toast } from "react-toastify"
import { CONSTANTS } from "../../utils/app-constants"
import DoctorEventComponent from "../doctor-events/DoctorEventComponent"

const { BASE_URL, FAILURE_MSG, CATCH_ERROR_MSG, AUTO_CLOSE } = CONSTANTS;

const schemaObj = {
    schema:[
        {
            name:'doctors-container',
            className:'flex flex-row gap-2 overflow-x-auto w-full',
            fields:[
                
            ]
        },
        {
            name:"doctor_event_modal",
            element:"modal",
            visible:false,
            containerClassName:'!w-[80%]',
            className: 'rounded-lg !w-full',
            fields: [
              {
                  name: 'modal_title',
                  className:
                      'w-full bg-primary/5 p-2 pl-4 flex items-center rounded-t-lg justify-between',
                  fields: [
                      {
                          name: 'modal_title_key',
                          element: 'div',
                          label: 'Doctor Schedule',
                          className:
                              'font-semibold text-lg text-primary',
                      },
                      {
                          name: 'doctor_event_modal_close',
                          element: 'button',
                          label: 'X',
                          className:
                              'w-[40px] h-[40px] flex justify-center items-center rounded-full bg-primary text-xl text-white',
                      },
                  ],
              },
              {
                  name: 'modal-body',
                  className:
                      'max-h-[500px] p-4 overflow-auto gap-1',
                  fields: [
                    {   
                        name : "doctor_event_component",
                        element:'div',
                        label: <DoctorEventComponent/>,
                        className: "w-full"
                    }
                  ],
              },
          ],
        }

    ]
}

export const SecreatryDashboard = () => {
    const [schema,setSchema] = useState<any>(schemaObj)
    const [loading,setLoading] = useState<boolean>(false)

    const [doctorsList,setDoctorsList] = useState<any>(null)
    const [dates,setDates] = useState<any>(null)

    useEffect(()=>{
        (async()=>await getAllDoctors())()
    },[])

    
    useEffect(()=>{
        if(!doctorsList) return

        const formattedDate = moment().format('DD-MM-YYYY');

        const datesObject = doctorsList.reduce((acc:any, doctor:any) => ({
            ...acc,
            [doctor.id]: formattedDate
        }), {});

        setDates(datesObject);

        (async()=>{
            const appointmentsPromises = doctorsList.map((doctor:any) => 
            GET_Appointments(doctor.id,moment(new Date()).format('DD-MM-YYYY'),''))
            const appointmentsResults = await Promise.all(appointmentsPromises);
            const fields = doctorsList.map((doctor:any, index:any) => {
                const appointmentStats = appointmentsResults[index].stats
                    return {
                    name: 'doctor-${doctor.id}',
                    className: 'w-[33vw] rounded-lg shadow-md border border-[#f0f0f0] flex-shrink-0 bg-white flex flex-col gap-2, h-[80vh] overflow-y-auto',
                    id:doctor.id,
                    fields: [
                    {
                        className:'flex flex-row justify-between p-2 border-b-2 items-center',
                        fields:[
                            {
                                // element: 'div',
                                className:'font-semibold text-primary flex flex-row gap-1 items-center',
                                // label: <div className="flex flex-row gap-2">{doctor.name} <img src={`${BASE_URL}/api/public/assets/images/settings.svg`} alt="Doctor Settings" className="pl-2" /></div>,
                                fields:[
                                    {
                                        element:'div',
                                        label:doctor.name
                                    },
                                    {
                                        name:`doctor-settings-${doctor.id}`,
                                        element:'image',
                                        doctorId:doctor.id,
                                        img:`${BASE_URL}/api/public/assets/images/settings.svg`,
                                        alt:"Doctor Settings"
                                    }
                                ]
                            },
                            {
                                className:'flex flex-row gap-1 items-center justify-end',
                                fields:[
                                    {
                                        name:`doctor-refresh-${doctor.id}`,
                                        element:'image',
                                        doctorId:doctor.id,
                                        img:`${BASE_URL}/api/public/assets/images/refresh.svg`,
                                        alt:"Refresh"
                                    },
                                    {
                                        name:`date-picker-${doctor.id}`,
                                        element:'datepicker',
                                        id:doctor.id,
                                        containerClassName:'w-[60%] items-self-end',
                                        enabled_dates:'enable_all',
                                        value:moment().format('DD-MM-YYYY'),
                                        allowClear:false
                                    }
                                ]
                            },
                        ]
                    },
                    // {
                    //     name:'appointment-report-section',
                    //     className:'grid grid-cols-3 p-4 gap-1 pb-1',
                    //     fields:[
                    //         {
                    //             element:'div',
                    //             name:'total-count',
                    //             label:`Total: ${appointmentStats?.total}`
                    //         },
                    //         {
                    //             element:'div',
                    //             name:'waiting-count',
                    //             label:`Waiting: ${appointmentStats?.waiting}`
                    //         },
                    //         {
                    //             element:'div',
                    //             name:'not-arrived-count',
                    //             label:`Not Arrived: ${appointmentStats.notArrived}`
                    //         },
                    //         {
                    //             element:'div',
                    //             name:'in-progress-count',
                    //             label:`In Progress: ${appointmentStats.inProgress}`
                    //         },
                    //         {
                    //             element:'div',
                    //             name:'completed-count',
                    //             label:`Completed: ${appointmentStats.completed}`
                    //         },
                    //     ]
                    // },
                    {
                        name:'search-total-section',
                        className:'flex flex-row p-2 justify-between items-center',
                        fields:[
                            {
                                name:'total-section',
                                className:'bg-[#F8F4FF] rounded-md border border-[#C0C4EA] flex flex-row justify-between p-1 pl-2 pr-2 gap-2 w-[30%] items-center',
                                fields:[
                                    {
                                        name:'total-count',
                                        element:'div',
                                        label:appointmentStats.total
                                    },
                                    {
                                        element:'div',
                                        className:'text-xs',
                                        label:'Total Patients'
                                    }
                                ]
                            },
                            {
                                name:`search-box-${doctor.id}`,
                                id:doctor.id,
                                element:'input-text',
                                icon:"search",
                                isSearch:true,
                                containerClassName:'w-[50%] h-[34px]',
                                placeholder:'search by name or phone number',
                                iconsClassName:"w-[15px] flex justify-center items-center"
                            },
                            // {
                            //     name:`date-picker-${doctor.id}`,
                            //     element:'datepicker',
                            //     id:doctor.id,
                            //     enabled_dates:'enable_all',
                            //     value:moment().format('DD-MM-YYYY'),
                            //     allowClear:false
                            // }
                        ]
                    },
                    {
                        name:'appointments-report-section',
                        className:'flex gap-2 text-sm p-2 border-b-2',
                        fields:[
                            {
                                name:'inprogress-section',
                                className:'flex  gap-1',
                                fields:[
                                    {
                                        element:'div',
                                        label:'In Progress: '
                                    },
                                    {
                                        name:'inprogress-count',
                                        element:'div',
                                        className:'bg-[#C0C4EA] px-1 border border-[#EBECF8] rounded-full',
                                        label:appointmentStats.inProgress
                                    }
                                ]
                            },
                            {
                                name:'waiting-section',
                                className:'flex  gap-1',
                                fields:[
                                    {
                                        element:'div',
                                        label:'Waiting: '
                                    },
                                    {
                                        name:'waiting-count',
                                        element:'div',
                                        className:'bg-[#EBEDED] border px-1 border-[#C1C6C8] rounded-full',
                                        label:appointmentStats.waiting
                                    }
                                ]
                            },
                            {
                                name:'completed-section',
                                className:'flex flex-row gap-1',
                                fields:[
                                    {
                                        element:'div',
                                        label:'Completed: '
                                    },
                                    {
                                        name:'completed-count',
                                        element:'div',
                                        className:'bg-[#E6F2F0] px-1 border border-[#B0D5D1] rounded-full',
                                        label:appointmentStats.completed
                                    }
                                ]
                            },
                            {
                                name:'notarrived-section',
                                className:'flex flex-row gap-1',
                                fields:[
                                    {
                                        element:'div',
                                        label:'Not Arrived: '
                                    },
                                    {
                                        name:'notarrived-count',
                                        element:'div',
                                        className:'bg-[#FCE8ED] px-1 border border-[#F7B8C6] rounded-full',
                                        label:appointmentStats.notArrived
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        name: 'appointments-section',
                        className: 'field field-col overflow-y-auto gap-4 p-2',
                        fields: appointmentsResults[index]?.data?.map((appointment: any) => (getAppointmentDetailsSchema(doctor.id,appointment))) || []
                    }
                    ]
                }}
            )
            setSchema((prev:any)=>{
                return{
                    ...prev,
                    schema:prev.schema.map((field:any)=>{
                        if(field.name === 'doctors-container'){
                            return{
                                ...field,
                                fields:fields
                            }
                        }
                        return field
                    })
                }
            })
        }
        )()
    },[doctorsList])

    const getAllDoctors = async() => {
        const doctors = await GET_Doctors_List()
        setDoctorsList(doctors)
    }

    const getAppointmentDetailsSchema = (doctorId:any,appointment:any)=>{
       return {
            name: 'appointment-card',
            id: appointment._id,
            className: 'flex flex-row border-b-2 p-1 justify-between items-center',
            fields: [
                {
                    name: 'patient-details',
                    className: 'flex flex-col',
                    fields: [
                        {
                            element: 'div',
                            label: <div className="flex flex-row"><div className={`text-[#4B5A61] font-semibold ${appointment.visit_status==='COMPLETED'?'text-[#C1C6C8]':''}`}>{appointment.patient?.name[0]?.text}</div><div className={`text-[#69757B] ${appointment.visit_status==='COMPLETED'?'text-[#C1C6C8]':''}`}> - {appointment.patient?.telecom[0]?.value}</div></div>
                        },
                        {
                            element: 'div',
                            className:`text-[#69757B] ${appointment.visit_status==='COMPLETED'?'text-[#C1C6C8]':''}`,
                            label: <div>{appointment.patient?.age || "N/A"} / {appointment.patient?.gender || "N/A"}</div>
                        },
                        {
                            element:'div',
                            label:<div className={`text-[#69757B] ${appointment.visit_status==='COMPLETED'?'text-[#C1C6C8]':''}`}>Appt time: {appointment.start_time}</div>
                        }
                    ]
                },
                {
                    className:'flex flex-col gap-1 items-end',
                    fields:[
                        appointment.priority==='EMERGENCY'?{
                            element:'image',
                            img:`${BASE_URL}/api/public/assets/images/appointment-emergency.svg`,
                            alt:"Emergency icon"
                        }:{},
                        appointment.priority==='VIP'?{
                            element:'image',
                            img:`${BASE_URL}/api/public/assets/images/appointment-vip.svg`,
                            alt:"VIP Icon"
                        }:{},
                        appointment.visit_status === 'COMPLETED'?{
                            element:'div',
                            label:"Completed",
                            className:'text-[#8DB174]'
                        }:{
                            element: 'button',
                            name:'appointment-status-change-button',
                            id: appointment._id,
                            className:appointment.appointment_status === 'TENTATIVE'?'text-primary hover:bg-[#EBECF8] rounded-md border border-white hover:border hover:border-[#C0C4EA] p-2':appointment.visit_status === 'INPROGRESS'?'text-[#ED6684] hover:bg-[#FFE0F3] hover:border hover:border-[#F7C0C0] border border-white p-2 rounded-md':'text-[#54A59C] hover:border border border-white rounded-md hover:bg-[#F0FFFC] hover:border-[#99D8D9] p-2 w-[90px]',
                            doctorId:doctorId,
                            disabled:appointment.visit_status === 'COMPLETED'?false:true,
                            status: appointment.appointment_status === 'TENTATIVE'?0:appointment.visit_status === 'INPROGRESS'?2:1,
                            label: appointment.appointment_status === 'TENTATIVE'?'Confirm':appointment.visit_status === 'INPROGRESS'?'Check out':'Check in'
                        },
                        appointment.visit_status==='WAITING'?{
                            element:'div',
                            className:'text-xs',
                            label:`Wait Time: ${appointment.wait_time}`
                        }:{}
                    ]
                },
                
            ]
            }
    }

    const updateAppointmentStatus = async(doctorId:any,date:any,search:any='')=>{
        const doctor = doctorsList.find((doc:any)=>doc.id===doctorId)
        const appointmentResults = await GET_Appointments(doctor.id,date,search)
        const appt = appointmentResults?.data?.map((appointment: any) => (getAppointmentDetailsSchema(doctorId,appointment))) || []
        const appointmentStats = appointmentResults.stats
        setSchema((prev:any)=>{
            return{
                ...prev,
                schema:prev.schema.map((field:any)=>{
                    if(field.name === 'doctors-container'){
                        return{
                            ...field,
                            fields:field.fields.map((f:any)=>{
                                if(f.id === doctorId){
                                    return {
                                        ...f,
                                        fields:f.fields.map((ff:any)=>{
                                            if(ff.name === 'search-total-section'){
                                                return{
                                                    ...ff,
                                                    fields:ff.fields.map((fff:any)=>{
                                                        if(fff.name === 'total-section'){
                                                            return{
                                                                ...fff,
                                                                fields:fff.fields.map((ffff:any)=>{
                                                                    if(ffff.name === 'total-count'){
                                                                        return{
                                                                            ...ffff,
                                                                            label:appointmentStats.total
                                                                        }
                                                                    }
                                                                    return ffff
                                                                })
                                                            }
                                                        }
                                                        return fff
                                                    })
                                                }
                                            }
                                            if(ff.name === 'appointments-report-section'){
                                                return{
                                                    ...ff,
                                                    fields:ff.fields.map((fff:any)=>{
                                                        if(fff.name === 'waiting-section'){
                                                            return{
                                                                ...fff,
                                                                fields:fff.fields.map((ffff:any)=>{
                                                                    if(ffff.name === 'waiting-count'){
                                                                        return{
                                                                            ...ffff,
                                                                            label:appointmentStats.waiting
                                                                        }
                                                                    }
                                                                    return ffff
                                                                })
                                                            }
                                                        }
                                                        if(fff.name === 'notarrived-section'){
                                                            return{
                                                                ...fff,
                                                                fields:fff.fields.map((ffff:any)=>{
                                                                    if(ffff.name === 'notarrived-count'){
                                                                        return{
                                                                            ...ffff,
                                                                            label:appointmentStats.notArrived
                                                                        }
                                                                    }
                                                                    return ffff
                                                                })
                                                            }
                                                        }
                                                        if(fff.name === 'inprogress-section'){
                                                            return{
                                                                ...fff,
                                                                fields:fff.fields.map((ffff:any)=>{
                                                                    if(ffff.name === 'inprogress-count'){
                                                                        return{
                                                                            ...ffff,
                                                                            label:appointmentStats.inProgress
                                                                        }
                                                                    }
                                                                    return ffff
                                                                })
                                                            }
                                                        }
                                                        if(fff.name === 'completed-section'){
                                                            return{
                                                                ...fff,
                                                                fields:fff.fields.map((ffff:any)=>{
                                                                    if(ffff.name === 'completed-count'){
                                                                        return{
                                                                            ...ffff,
                                                                            label:appointmentStats.completed
                                                                        }
                                                                    }
                                                                    return ffff
                                                                })
                                                            }
                                                        }
                                                        return fff
                                                    })
                                                }
                                            }
                                            if(ff.name === 'appointments-section'){
                                                return {
                                                    ...ff,
                                                    fields:appt
                                                }
                                            }
                                            return ff
                                        })
                                    }
                                }
                                return f
                            })
                        }
                    }
                    return field
                })
            }
        })
    }

    const GET_Appointments = async(doctorId:any,date:any,search:any)=>{
        setLoading(true)
        const res = await Utils.makeApiCall(`/secretary/appointments?doctorId=${doctorId}&date=${date}&searchText=${search}`,"GET")
        if(!res.error){
            setLoading(false)
        }else{
            setLoading(false)
        }
        return res
    }

    const GET_Doctors_List = async() =>{
        // const res = await Utils.makeApiCall(`/doctors?branchId=${branchId}`, "GET");
        const res = await Utils.makeApiCall(`/doctors?branchId=AAB2408023`, "GET");
        const doctorsList = res.data.map((doc:any)=>{
        return{
            id:doc._id,
            name:doc.name
        }
        })
        return doctorsList
    }

    const POST_Confirm_Appointment = async(appointmentId:any)=>{
        try {
            setLoading(true)
            const res = await Utils.makeApiCall(`/appointments/changeStauts`, "POST", {
              appointmentId: appointmentId,
              status: "CONFIRMED",
            });
            if (!res.error) {
                setLoading(false)
              toast.success(res.message, { position: "top-center", autoClose: AUTO_CLOSE });
            } else {
                setLoading(false)
              toast.error(res.message ? res.message : FAILURE_MSG, { position: "top-center", autoClose: AUTO_CLOSE });
            }
          } catch (error) {
            toast.error(CATCH_ERROR_MSG, { position: "top-center", autoClose: AUTO_CLOSE });
          }
    }

    const POST_Change_Visit_Status = async(appointmentId:any,status:any)=>{
        // const response = Utils.makeApiCall("","POST")
        // return
        try {
            setLoading(true)
            const res = await Utils.makeApiCall(`/secretary/changeAppointmentVisitStatus`, "POST", {
              appointmentId: appointmentId,
              visitStatus: status==2?'COMPLETED':'INPROGRESS',
            });
            if (!res.error) {
                setLoading(false)
              toast.success(res.message, { position: "top-center", autoClose: AUTO_CLOSE });
            } else {
                setLoading(false)
              toast.error(res.message ? res.message : FAILURE_MSG, { position: "top-center", autoClose: AUTO_CLOSE });
            }
          } catch (error) {
            toast.error(CATCH_ERROR_MSG, { position: "top-center", autoClose: AUTO_CLOSE });
          }
    }

    const handleSelectedRecord = async(e:any)=>{
        console.log(e)
        if(e.name === 'appointment-status-change-button'){
            if(e.status == 0){
                await POST_Confirm_Appointment(e.id)
            }else{
                if(e.status == 3) return
                await POST_Change_Visit_Status(e.id,e.status)
            }

            await updateAppointmentStatus(e.doctorId,dates[e.doctorId])
        }
        if(e.name.startsWith('date-picker')){
            console.log(e)
            setDates((prevDates:any) => ({
                ...prevDates,
                [e.id]: e.value
             }));
             
            await updateAppointmentStatus(e.id,e.value)
        }
        if(e.name.startsWith('search-box')){
            await updateAppointmentStatus(e.id,dates[e.id],e.value)
        }
        if(e.name.startsWith('doctor-refresh')){
            await updateAppointmentStatus(e.doctorId,dates[e.doctorId],'')
        }
        if(e.name.startsWith('doctor-settings')){
            setSchema((prev:any) => {
                return {
                    ...prev,
                    schema: prev.schema.map((sec:any) => {
                        if(sec.name === "doctor_event_modal") {
                            sec.visible = true;
                            sec.fields = sec.fields.map((f:any) => {
                                if(f.name === "modal-body") {
                                    f.fields = f.fields.map((ff:any) => {
                                        if(ff.name === "doctor_event_component") {
                                            ff = {   
                                                name : "doctor_event_component",
                                                element:'div',
                                                label: <DoctorEventComponent selectedDoctor={e.doctorId} selectedDate={moment(dates[e.doctorId], 'DD-MM-YYYY').toDate()}/>,
                                                className: "w-full"
                                            }
                                        }
                                        return ff;
                                    })
                                }
                                return f;
                            })
                        }
                        return sec;
                    })
                }
            })
        }
        if(e.name === 'doctor_event_modal_close'){
            setSchema((prev:any) => {
                return {
                    ...prev,
                    schema: prev.schema.map((sec:any) => {
                        if(sec.name === "doctor_event_modal") {
                            sec.visible = false;
                        }
                        return sec;
                    })
                }
            })
        }
    }
    return (
        <div>
            <LoadingState isLoading={loading} />
            <ElementExecutor data={schema} selectedRecord={handleSelectedRecord}/>
        </div>
    )
}