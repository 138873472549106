import { useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Storage from '../utils/local-storage';

const useActivityTimeout = (timeout: number = 15 * 60 * 1000) => {
  const navigate = useNavigate();
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const isWindowVisible = useRef(true);

  const logout = useCallback(() => {
    const cleanupEvent = new Event("cleanupChildScript");
    window.dispatchEvent(cleanupEvent);
    Storage.clearAll();
    navigate('/');
    window.location.reload();
  }, [navigate]);

  const resetTimer = useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(logout, timeout);
  }, [logout, timeout]);

  const handleVisibilityChange = useCallback(() => {
    if (document.visibilityState === 'visible') {
      if (!isWindowVisible.current) {
        resetTimer();
        isWindowVisible.current = true;
      }
    } else {
      isWindowVisible.current = false;
    }
  }, [resetTimer]);

  useEffect(() => {
    const handleActivity = () => {
      resetTimer();
    };

    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);
    window.addEventListener('touchstart', handleActivity);
    document.addEventListener('visibilitychange', handleVisibilityChange);

    resetTimer();

    return () => {
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
      window.removeEventListener('touchstart', handleActivity);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [resetTimer, handleVisibilityChange]);

  return null;
};

export default useActivityTimeout;
