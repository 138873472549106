import { ElementExecutor } from '@apexcura/core'
import React, { useEffect, useState } from 'react'; 
import LoadingState from '../LoadingState';
import { useNavigate, useParams } from 'react-router-dom';
import Utils from '../../utils';
import { toast } from 'react-toastify';
import { CONSTANTS } from '../../utils/app-constants';
const { FAILURE_MSG, CATCH_ERROR_MSG, AUTO_CLOSE } = CONSTANTS;
const schemaObj = ()=>{
    return{
        className:'flex flex-col gap-4',
        schema:[
            {
                name:'filter-section',
                className:'w-full flex gap-4 justify-between',
                fields:[
                    {
                        name:'date-select',
                        element:'datepicker',
                        label:"Date",
                        enabled_dates: "from_today",
                        className: "min-w-[200px]"
                    },
                    {
                        name: "patient-name",
                        element: "input-text",
                        placeholder: "",
                        label: "Patient Name",
                        containerClassName: "min-w-[30%] flex flex-col",
                        labelClassName:
                          "text-sm mb-1 text-gray-500 font-semibold text-start",
                        className:
                          "rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none ",
                        visible: true,
                        disabled: true
                    },
                    {
                        name: "doctor_dropdown",
                        element: "single-select",
                        placeholder: "",
                        label: "Doctor",
                        containerClassName: "min-w-[30%] flex flex-col col-span-1",
                        labelClassName:
                          "text-sm mb-1 text-gray-500 font-semibold text-start",
                        className:
                          "rounded-md  text-gray-700 font-semibold focus:outline-none",
                        options:[]
                      },
                ]
            },
            {
                name:'appointment-section',
                className:'flex flex-col gap-2 bg-white',
                fields:[
                ]
            },
            {
                name:'buttons-section',
                className:'w-full flex justify-end gap-3',
                fields:[
                    {
                        name:'cancel-button',
                        element:'button',
                        className:'bg-white rounded-md self-end p-2',
                        label:'Cancel'
                    },
                    {
                        name:'reschedule-appointment-button',
                        element:'button',
                        className:'bg-primary text-white rounded-md self-end p-2',
                        label:'Reschedule Appointment'
                    }
                ]
            },
        ]
    }
}

const EditAppointment = () => {
    const [loading,setLoading] = useState(false)
    const [schema,setSchema] = useState<any>(schemaObj())
    const [currentDate,setCurrentDate] = useState(new Date().toLocaleDateString('en-GB').split('/').join('-'))
    const [currentSlot,setCurrentSlot] = useState<any>();
    const [appointmentDetails, setAppointmentDetails] =useState<any>();
    const params = useParams();
    const appointmentId = params?.appointmentId || "";
    const VISIBLE_SLOTS=35;
    const navigate=useNavigate()

    useEffect(() => {
        const fetchAppointmentDetails = async () => {
            await Get_Appointment_Details(appointmentId);
        };
    
        if (appointmentId) {
            fetchAppointmentDetails();
        }
    }, [appointmentId]);

    const Get_Appointment_Details = async(id:any) => {
        try {
            const res = await Utils.makeApiCall(`/getAppointmentDetails?appointmentId=${id}`, 'GET')
            if (res.status && res.data) {
                const res2 = await Utils.makeApiCall(`/doctors?branchId=${res?.data?.branch_id}`, "GET");
                if(res2.status) {
                    setSchema ((prev:any) => {
                        return{
                            ...prev,
                            schema : prev?.schema?.map((sec:any) => {
                                if(sec.name === "filter-section"){
                                    sec.fields = sec.fields.map((f:any) => {
                                        if(f.name === "doctor_dropdown"){
                                            f.options = res2.data.map((doc:any)=>{
                                                return{
                                                  value:doc._id,
                                                  label:doc.name,
                                                  data: doc
                                                }
                                              })
                                        }
                                        return f;
                                    })
                                }
                                return sec;
                            })
                        }
                    })
                }
                setAppointmentDetails(res?.data)
                await updateSlots(res?.data?.doctor_id);
                setSchema ((prev:any) => {
                    return{
                        ...prev,
                        schema : prev?.schema?.map((sec:any) => {
                            if(sec.name === "filter-section"){
                                sec.fields = sec.fields.map((f:any) => {
                                    if(f.name === "date-select"){
                                        f.value = new Date().toLocaleDateString('en-GB').split('/').join('-');
                                    }
                                    if(f.name === "patient-name"){
                                        f.value = res?.data?.patient_id?.name[0]?.text;
                                    }
                                    if(f.name === "doctor_dropdown"){
                                        f.value = res?.data?.doctor_id?._id
                                    }
                                    return f;
                                })
                            }
                            return sec;
                        })
                    }
                })

            } else {
                toast.error(res.message ? res.message : FAILURE_MSG, {
                    position: 'top-center',
                    autoClose: 2000,
                })
            }

        } catch (error) {
            toast.error(CATCH_ERROR_MSG, { position: 'top-center', autoClose: AUTO_CLOSE });
            console.error('Failed to fetch schema', error)
        }

    }
    const isValidAppointment = ()=>{
        if(!currentSlot){
            toast.error('Please select a valid slot', { position: "top-center", autoClose: AUTO_CLOSE });
            return false
        }

        return true
    }

    const Get_Available_Slots = async(doctorId:string)=>{
        const response = await Utils.makeApiCall(`/appointments/getAvailableSlots?doctorId=${doctorId}&date=${currentDate}`,'GET')
        return response
    }
    useEffect(()=>{
        (async()=>await updateSlots(appointmentDetails?.doctor_id))()
    },[currentDate])

    useEffect(()=>{
        if(!currentSlot) return
        setSchema((prev:any)=>{
            return{
                ...prev,
                schema:prev.schema.map((field:any)=>{
                    if(field.name === 'appointment-section'){
                        return{
                            ...field,
                            fields : field.fields.map((ff:any)=>{
                                if(ff.name === 'slots-container'){
                                    return{
                                        ...ff,
                                        fields:ff.fields.map((f:any)=>{
                                            if(f.name === 'slot-button-grid'){
                                                return{
                                                    ...f,
                                                    fields:f.fields.map((ff:any)=>{
                                                        if(ff.doctorId === currentSlot.doctorId && 
                                                            ff.startTime === currentSlot.startTime && 
                                                            ff.endTime === currentSlot.endTime &&
                                                            ff.date === currentSlot.date){
                                                            ff.className = 'slot-button-active'
                                                        }else{
                                                            if(ff.className!=='hidden' && ff.name!=='expand-slots-button'){
                                                                ff.className = 'slot-button'
                                                            }
                                                        }
                                                        return ff
                                                    })
                                                }
                                            }
                                            return f
                                        })
                                    }
                                }
                                return ff
                            })
                        }
                    }
                    return field
                })
            }
        })
    },[currentSlot])

    const updateSlots = async (doctor:any) => {
        setLoading(true);
        let num = 0;
    
        try {
            const slots = await Get_Available_Slots(doctor._id);
            
            const fields = [{
                className: 'flex flex-col p-4 border-b-2 border-gray-200 rounded-sm',
                name: 'slots-container',
                doctorId: doctor._id,
                fields: [
                    {
                        className: 'grid grid-cols-3 pl-2 pr-4 pb-2 items-center',
                        fields: [
                            {
                                element: 'div',
                                label: (
                                    <span>
                                        <span className='text-[#00796B]'>{doctor.name}</span>
                                        <span className='text-[#4B5A61]'> - {doctor?.department_id?.dept_name}</span>
                                    </span>
                                ),
                                className: 'text-xl'
                            },
                            {
                                element: 'div',
                                label: (
                                    <span>
                                        <span>Available Slots: </span>
                                        <span className='font-semibold'>{slots?.availableSlots?.length}</span>
                                    </span>
                                ),
                                className: 'text-md justify-self-center'
                            },
                            {
                                element: 'div',
                                label: (
                                    <span>
                                        <span>Slot Size: </span>
                                        <span className='font-semibold'>{doctor?.slot_size} minutes</span>
                                    </span>
                                ),
                                className: 'text-md justify-self-end'
                            }
                        ]
                    },
                    slots?.availableSlots?.length !== 0 ? {
                        name: 'slot-button-grid',
                        className: 'flex gap-2 flex-wrap bg-white min-h-[30px] justify-items-center',
                        fields: [
                            ...slots.availableSlots.map((duration: any) => {
                                const defaultStyle = 'slot-button';
                                const style = num < VISIBLE_SLOTS ? defaultStyle : defaultStyle;
                                num++;
                                return {
                                    name: 'slot-button',
                                    element: 'button',
                                    label: duration.startTime,
                                    doctorId: doctor?._id,
                                    className: style,
                                    date: slots.date,
                                    duration: doctor?.slot_size,
                                    startTime: duration.startTime,
                                    endTime: duration.endTime
                                };
                            }),
                            // slots.availableSlots.length > VISIBLE_SLOTS ? {
                            //     name: 'expand-slots-button',
                            //     element: 'button',
                            //     doctorId: doctor?._id,
                            //     label: 'View All',
                            //     className: 'font-semibold text-primary justify-self-end underline underline-offset-2',
                            //     status: 'view'
                            // } : null
                        ].filter(Boolean)
                    } : {
                        element: 'div',
                        className: 'p-4',
                        label: 'No available slots'
                    }
                ]
            }];
            setSchema((prev: any) => {
                return {
                    ...prev,
                    schema: prev.schema.map((field: any) => {
                        if (field.name === 'appointment-section') {
                            return {
                                ...field,
                                fields: fields 
                            };
                        }
                        return field;
                    })
                };
            });
        } catch (error) {
            console.error("Error fetching available slots:", error);
        } finally {
            setLoading(false);
        }
    }

    const handleReschedule = async() => {
        const response = await Utils.makeApiCall(`/reschedule?appointmentId=${appointmentId}`,'PUT',{
            startTime:currentSlot.startTime,
            endTime:currentSlot.endTime,
            date:currentSlot.date,
            doctorId: currentSlot.doctorId
        })
        return response
    }

    const handleSelectedRecord = async(e:any) => {
        console.log(e);
        if(e.name === 'slot-button'){
            setCurrentSlot({
                doctorId:e.doctorId,
                date:e.date,
                startTime:e.startTime,
                endTime:e.endTime,
                duration:e.duration
            })
        }

        if(e.name === 'date-select'){
            setCurrentDate(e.value)
            if(!e.value){
                setCurrentDate(new Date().toLocaleDateString('en-GB').split('/').join('-'))
            }
        }
        if(e.name === 'cancel-button'){
            navigate("/appointments")
        }

        if(e.name === 'doctor_dropdown'){
            updateSlots(e?.value?.data)   
        }

        if(e.name === 'reschedule-appointment-button'){
            if(!isValidAppointment()) {
                return
            }
            const res = await handleReschedule()
            if(!res.error){
                // handleClosePopup()
                // updateSlots()
                // resetPatientForm()
                navigate('/appointments')
                toast.success(res.message, { position: "top-center", autoClose: AUTO_CLOSE });
            }
        }

        if(e.name === 'expand-slots-button'){
            const status = e.status
            setSchema((prev:any)=>{
                return{
                    ...prev,
                    schema:prev.schema.map((field:any)=>{
                        if(field.name === 'appointment-section'){
                            return{
                                ...field,
                                fields : field.fields.map((ff:any)=>{
                                    if(ff.name === 'slots-container' && ff.doctorId === e.doctorId){
                                        return{
                                            ...ff,
                                            fields:ff.fields.map((f:any)=>{
                                                if(f.name === 'slot-button-grid' && e.status === 'view'){
                                                    return{
                                                        ...f,
                                                        fields:f.fields.map((ff:any)=>{
                                                            if(ff.className === 'hidden'){
                                                                ff.className = 'slot-button'
                                                            }
                                                            if(ff.name === 'expand-slots-button'){
                                                                ff.status = 'hide'
                                                                ff.label = 'Hide'
                                                            }
                                                            return ff
                                                        })
                                                    }
                                                }
                                                if(f.name === 'slot-button-grid' && e.status === 'hide'){
                                                    let num = 0;
                                                    return{
                                                        ...f,
                                                        fields:f.fields.map((ff:any)=>{
                                                            if(num<VISIBLE_SLOTS) {
                                                                num++ 
                                                                return ff
                                                            }
                                                            
                                                            if(ff.name === 'expand-slots-button'){
                                                                ff.status = 'view'
                                                                ff.label = 'View All'
                                                            }else{
                                                                ff.className = 'hidden'
                                                            }
                                                            num++
                                                        
                                                            return ff
                                                        })
                                                    }
                                                }
                                                return f
                                            })
                                        }
                                    }
                                    return ff
                                })
                            }
                        }
                        return field
                    })
                }
            })
        }
        
    }
    console.log("schema", schema);
    
    return (
        <div>
            <LoadingState isLoading={loading} />
            <ElementExecutor data={schema} selectedRecord={handleSelectedRecord}/>
        </div>
    )

}

export default EditAppointment;