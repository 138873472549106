import {
  MdHistory,
  MdOutlineComment,
  MdOutlineCommentsDisabled,
} from "react-icons/md";
import { LeadStatus } from "../../utils/types";

export const detailsSchema = (): any => ({
  className: "",
  schema: [
    {
      name: "details_modal",
      element: "modal",
      containerClassName: "!w-[90%]",
      className: "rounded-md !w-full",
      visible: true,
      fields: [
        {
          name: "modal_title",
          className:
            "w-full bg-primary/5 p-2 pl-4 flex items-center rounded-t-lg justify-between",
          fields: [
            {
              name: "modal_title_key",
              element: "div",
              label: "Call details",
              className: "font-semibold text-lg text-primary",
            },
            {
              name: "modal_close",
              element: "button",
              label: "X",
              className:
                "w-[40px] h-[40px] flex justify-center items-center rounded-full bg-primary text-xl text-white",
            },
          ],
        },
        {
          name: "modal_header",
          className: "grid grid-cols-4 gap-2 shadow-sm p-2 px-4 relative z-10",
          cols: [
            {
              name: "patient_name",
              label: "Name",
            },
            {
              name: "phno",
              label: "Mobile",
            },
            {
              name: "age",
              label: "Age",
            },
            {
              name: "createdAt",
              label: "Registered on",
            },
          ],
          fields: [],
        },
        {
          name: "modal_body",
          className: "!h-[80vh] grid grid-cols-12 p-2 gap-2 overflow-auto",
          fields: [
            {
              name: "main_content",
              className: "col-span-9 bg-white rounded-md flex flex-col",
              fields: [],
            },
            {
              name: "recent_interactions",
              className:
                "bot-conversations col-span-3 w-full bg-white border-[1px] col-span-1 p-1 flex flex-col h-[76dvh] rounded-md sticky top-0 gap-2",
              fields: [
                {
                  name: "recent_interactions_text",
                  label: (
                    <span className="flex items-center gap-1">
                      <MdHistory className="text-xl" /> Recent Interactions
                    </span>
                  ),
                  className: "text-lg font-semibold pt-1 pl-1 text-primary",
                  element: "div",
                },
                {
                  name: "interaction_types",
                  element: "input-radio",
                  containerClassName: "chips pl-2",
                  optionType: "button",
                  isNotChatbot: true,
                  visible: true,
                  options: [
                    {
                      label: "All",
                      value: "all",
                    },
                    {
                      label: "Calls",
                      value: "calls",
                    },
                    {
                      label: "Leads",
                      value: "leads",
                    },
                    {
                      label: "Chats",
                      value: "chats",
                    },
                  ],
                  value: {
                    label: "All",
                    value: "all",
                  },
                },
                {
                  name: "recent_interactions_cards",
                  className: " overflow-y-auto gap-1 h-full",
                  fields: [],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
});

const callCommentsSchema = (): any => ({
  name: "comments_container",
  className: "w-full col-span-2 h-full flex flex-col border rounded-md",
  fields: [
    {
      name: "comments_heading",
      className:
        "font-semibold text-lg text-primary sticky -top-2 bg-white shadow-sm px-2 rounded-md rounded-b-none",
      element: "div",
      label: (
        <span className="flex items-center gap-2">
          <MdOutlineComment /> Comments
        </span>
      ),
    },
    {
      name: "comments_body",
      visible: true,
      className:
        "flex flex-1 flex-col overflow-auto bg-white divide-y p-2 h-[30vh]",
      fields: [
        {
          name: "empty",
          label: (
            <span className="flex items-center gap-2">
              <MdOutlineCommentsDisabled /> No Comments
            </span>
          ),
          element: "div",
          className: "flex items-center justify-center w-full h-full",
        },
      ],
    },
    {
      name: "comments_footer",
      className: "sticky -bottom-2",
      fields: [
        {
          name: "data_pair",
          className:
            "mt-auto flex gap-2 bg-gray-50 p-4 pb-2 rounded-tl-lg rounded-tr-lg",
          fields: [
            {
              name: "call-tags",
              className: " font-medium text-gray-700 mt-1",
              label: "CallType: ",
              element: "div",
            },
            {
              name: "call-tags-input",
              element: "single-select",
              placeholder: "",
              containerClassName: "w-full flex flex-col col-span-full",
              className:
                "rounded-md text-gray-700 font-semibold focus:outline-none",
              visible: true,
              mode: "tags",
              value: null,
              options: [],
              customColor: true,
            },
          ],
        },
        {
          name: "comments_input_container",
          className:
            "p-4 pt-2 bg-gray-50 rounded-bl-lg rounded-br-lg flex flex-row gap-2",
          visible: true,
          fields: [
            {
              name: "comment_input",
              element: "input-textarea",
              minRows: 1,
              maxRows: 5,
              className: "p-1 px-2",
              placeholder: "Enter your comment here",
              containerClassName:
                "basis-5/6 bg-white rounded-md overflow-visible ",
            },
            {
              name: "comment_submit",
              element: "button",
              label: "Comment",
              className:
                "basis-1/6 p-1 px-3 bg-primary rounded-md text-sm text-white font-medium",
            },
          ],
        },
      ],
    },
  ],
});

export const callDetailsFields = (): any => [
  {
    name: "view_calls_header_data",
    className:
      "flex flex-wrap gap-2 text-base border items-center rounded-md p-2 mb-2 bg-white",

    fields: [
      {
        name: "call_type",
        label: "",
        className: "",
        element: "div",
      },
      {
        name: "call_section",
        className: "flex flex-col gap-1",
        fields: [
          {
            name: "data_pair",
            className: " flex gap-[2px]",
            fields: [
              {
                name: "call_from_value",
                className: " font-bold text-gray-600 capitalize",
                label: "--",
                element: "div",
              },
              {
                name: "call_to_value",
                className: " font-bold text-gray-600 capitalize",
                label: "--",
                element: "div",
              },
            ],
          },
          {
            name: "data_pair",
            className: "flex gap-1",
            fields: [
              {
                name: "call_date_value",
                className: " text-xs text-gray-400 capitalize",
                label: "--",
                element: "div",
              },
              {
                name: "call_duration_value",
                className: " text-xs text-gray-400 capitalize",
                label: "--",
                element: "div",
              },
            ],
          },
        ],
      },
      // {
      //   name: "button_section",
      //   className: "flex gap-1 justify-end grow w-auto sm:w-auto",
      //   fields: [
      //     {
      //       name: "appointment_button",
      //       element: "button",
      //       label : "Book Appointment",
      //       className: "bg-primary text-white py-1 px-2 rounded-lg",
      //       visible: true
      //     }
      //   ]
      // }
    ],
  },
  {
    name: "comments_and_recent_interactions",
    className: "h-full",
    fields: [{ ...callCommentsSchema() }],
  },
  {
    name:'appointment-booking-modal',
    element:'modal',
    visible:false,
    containerClassName:'!w-[96%]',
    className:"!w-full",
    fields: [
      {
          name: 'modal-title',
          className:
              'w-full bg-primary/5 p-2 pl-4 flex items-center rounded-t-lg justify-between',
          fields: [
              {
                  name: 'modal-title-key',
                  element: 'div',
                  label: 'Book Appointment',
                  className:
                      'font-semibold text-lg text-primary',
              },
              {
                  name: 'appointment-booking-modal-close',
                  element: 'button',
                  label: 'X',
                  className:
                      'w-[40px] h-[40px] flex justify-center items-center rounded-full bg-primary text-xl text-white',
              },
          ],
      },
      {
          name: 'modal-body',
          className: 'flex flex-col max-h-[500px] p-4 overflow-auto gap-1',
          fields: [
              
          ],
      },
    ],
  }
];

export const chatDetailsFields = (): any => [
  {
    name: "view_chat_header_data",
    className:
      "flex flex-wrap items-center gap-2 text-base border rounded-md p-2 mb-2 bg-white",

    fields: [
      {
        name: "medium_icon",
        label: "",
        className: "",
        element: "div",
      },
      {
        name: "chat_section",
        className: "flex flex-col gap-1",
        fields: [
          {
            name: "data_pair",
            className: " flex gap-[2px]",
            fields: [
              {
                name: "medium",
                className: " font-bold text-gray-600 capitalize",
                label: "--",
                element: "div",
              },
              {
                name: "conversation_text",
                className: " font-bold text-gray-600 capitalize",
                label: "Conversation",
                element: "div",
              },
            ],
          },
          {
            name: "data_pair",
            className: "flex gap-1",
            fields: [
              {
                name: "-",
                className: " text-xs text-gray-400",
                label: "From",
                element: "div",
              },
              {
                name: "chat_start",
                className: " text-xs text-gray-500 font-medium",
                label: "--",
                element: "div",
              },
              {
                name: "-",
                className: " text-xs text-gray-400",
                label: " to ",
                element: "div",
              },
              {
                name: "chat_end",
                className: " text-xs text-gray-500 font-medium",
                label: "--",
                element: "div",
              },
              {
                name: "chat_duration",
                className: " text-xs text-gray-400 italic capitalize",
                label: "--",
                element: "div",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "conversations",
    className:
      "col-span-9 flex flex-col p-4 overflow-auto gap-1 bot-conversations",
    fields: [],
  },
];

export const leadDetailsFields = (): any => [
  {
    name: "view_lead_header_data",
    className:
      "flex flex-wrap items-center gap-2 text-base border rounded-md p-2 bg-white",

    fields: [
      {
        name: "source_icon",
        label: "",
        className: "",
        element: "div",
      },
      {
        name: "lead_section",
        className: "flex flex-col gap-1",
        fields: [
          {
            name: "data_pair",
            className: " flex gap-[2px]",
            fields: [
              {
                name: "lead_text",
                className: " font-bold text-gray-600",
                label: "Lead from",
                element: "div",
              },
              {
                name: "source",
                className: " font-bold text-gray-600 capitalize",
                label: "",
                element: "div",
              },
            ],
          },
          {
            name: "data_pair",
            className: "flex gap-1",
            fields: [
              {
                name: "reason_text",
                className: " text-xs text-gray-400",
                label: "Reason: ",
                element: "div",
              },
              {
                name: "reason",
                className: " text-xs text-gray-500 font-medium whitespace-normal",
                label: "",
                element: "div",
              },
            ],
          },
        ],
      },
      // {
      //   name: "button_section",
      //   className: "flex gap-1 justify-end grow w-auto sm:w-auto",
      //   fields: [
      //     {
      //       name: "appointment_button",
      //       element: "button",
      //       label : "Book Appointment",
      //       className: "bg-primary text-white py-1 px-2 rounded-lg",
      //       visible: true
      //     }
      //   ]
      // }
    ],
  },
  {
    name: "lead_details_tabs_container",
    className: "col-span-9 h-full",
    fields: [
      {
        name: "leads_modal_tabs",
        element: "tabs",
        containerClassName: "p-0 m-0 sticky -top-2 bg-white z-10",
        className: "flex items-center gap-1 ",
        options: [
          {
            key: "comments",
            label: "Comments",
          },
        ],
        value: { key: "comments", label: "Comments" },
      },
      {
        name: "tabs_body",
        className: "",
        fields: [
          { ...leadCommentsSchema() },
          { ...leadConversationSchema() },
          { ...leadMessageSchema() },
        ],
      },
    ],
  },
  {
    name:'appointment-booking-modal',
    element:'modal',
    visible:false,
    containerClassName:'!w-[96%]',
    className:"!w-full",
    fields: [
      {
          name: 'modal-title',
          className:
              'w-full bg-primary/5 p-2 pl-4 flex items-center rounded-t-lg justify-between',
          fields: [
              {
                  name: 'modal-title-key',
                  element: 'div',
                  label: 'Book Appointment',
                  className:
                      'font-semibold text-lg text-primary',
              },
              {
                  name: 'appointment-booking-modal-close',
                  element: 'button',
                  label: 'X',
                  className:
                      'w-[40px] h-[40px] flex justify-center items-center rounded-full bg-primary text-xl text-white',
              },
          ],
      },
      {
          name: 'modal-body',
          className: 'flex flex-col p-4 max-h-[500px] overflow-auto gap-1',
          fields: [
              
          ],
      },
    ],
  }
];

export const leadCommentsSchema = () => ({
  name: "comments_modal_container",
  className:
    "w-full col-span-2 h-[56vh] flex flex-col justify-between border rounded-md",
  fields: [
    {
      name: "comments_body",
      visible: true,
      className: "flex flex-1 flex-col overflow-auto bg-white divide-y p-2",
      fields: [
        {
          name: "empty",
          label: "No comments",
          element: "div",
          className: "flex items-center justify-center w-full h-full",
        },
      ],
    },
    {
      name: "comments_input_container",
      className:
        "p-2 bg-gray-50 rounded-bl-lg rounded-br-lg flex flex-row gap-2 sticky -bottom-2",
      visible: true,
      fields: [
        {
          name: "lead_status_dropdown",
          className: "rounded-md text-gray-700 font-semibold",
          element: "single-select",
          containerClassName: "flex flex-col basis-1/6",
          labelClassName: "text-sm mb-1 text-gray-500 font-semibold text-start",
          required: true,
          placeholder: "Status",
          options: [
            {
              label: "Qualified",
              value: LeadStatus.qualified,
            },
            {
              label: "Converted",
              value: LeadStatus.converted,
            },
            {
              label: "Discarded",
              value: LeadStatus.discarded,
            },
          ],
        },
        {
          name: "comment_input",
          element: "input-textarea",
          minRows: 1,
          maxRows: 5,
          placeholder: "Enter your comment here",
          containerClassName: "basis-4/6 bg-white rounded-lg overflow-visible ",
        },
        {
          name: "comment_submit",
          element: "button",
          label: "Comment",
          className:
            "basis-1/6 mt-auto h-[35px] bg-primary rounded-lg text-white",
        },
      ],
    },
  ],
});

export const leadConversationSchema = () => ({
  name: "conversations_body",
  visible: false,
  className: "flex flex-col  p-4 overflow-auto gap-1 bot-conversations",
  fields: [
    {
      name: "conversation_blank",
      element: "div",
      className: "basis-1/6",
      label: "No conversations...",
    },
  ],
});

export const leadMessageSchema = () => ({
  name: "messages_body",
  visible: false,
  className: " flex gap-2 flex-col p-4 overflow-y-auto ",
  fields: [
    {
      name: "message_blank",
      element: "div",
      className: "basis-1/6",
      label: "No message...",
    },
  ],
});
