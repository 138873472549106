import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { CONSTANTS } from '../utils/app-constants'
import Utils from '../utils'
import { ElementExecutor } from '@apexcura/core'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { setActiveTab } from '../redux/campaigns/campaignSlice'
import Storage from '../utils/local-storage'
import LoadingState from './LoadingState'
import {Popover,Tooltip } from "antd"
const { BASE_URL } = CONSTANTS;
const { FAILURE_MSG, CATCH_ERROR_MSG, AUTO_CLOSE } = CONSTANTS

const peak_usage_time_graph=()=>({
    name: 'peak_usage_time',
    element: 'vertical-barchart',
    val:0,
    borderRadius:5,
    // width: 380,
    height: 240,
    className: 'bg-white rounded-md',
    centerLabel1: 'High',
    xAxisTitle:"Hours",
    yAxisTitle:"Chat Count",
    color:"#669df6",
    fontSize:"12px",
    labelsfontSize:"12px",
    axisLabelClassName:"text-xs font-medium text-black",
    centerLabel2:
        'Improve chatbot training data',
    visible: true,
    showLegend:false,
    // yAxixMinValue: 0,
    yAxixMaxValue: 200,
    pointWidth: 20,
    data: [
        
    ],
})

const campaigns_graph=()=>({
    name: 'campaigns_chart',
    element: 'groupedstack-barchart',
    
    showLegend:true,
    className: ' w-full  rounded-md bg-white ',
    layout:"horizontal",
    xAxisTitle:"Days",
    yAxisTitle:"Outreaches Details",
    fontSize:"12px",
    labelsfontSize:"12px",
    axisLabelClassName:"text-xs font-medium text-black",
    pointWidth: 20,
    borderRadius:5,
    val:0,
    height: 260,
    isStack: true,
    label: '',
    labelClassName:
        'text-sm text-[#254AAA] font-semibold mt-2 text-left',
    visible: true,
    categories: [],
    seriesData: [
    ],
})

const leads_graph=()=>({
    name: 'leads_generated',
    className: 'rounded-md mb-5 bg-white px-2 ',
    element: 'linechart',
    
    showLegend:false,
    xAxisTitle:"Days",
    yAxisTitle:"No Of Leads",
    showTooltip:true,
    fontSize:"12px",
    labelsfontSize:"12px",
    axisLabelClassName:"text-xs font-medium text-black",
    color:"#669df6",
    label: '',
    labelClassName:
        'w-[80%] font-semibold text-base text-primary my-5 ',
    // width:380,
    height: 260,
    pointWidth: 20,
    categories:  [
    ],
    data:[ 

    ]
})

const campaigns_line_graph=()=>({
    name: 'campaigns_linechart',
    className: 'rounded-md mb-5 bg-white px-2 ',
    element: 'linechart',
    
    showLegend:false,
    xAxisTitle:"Days",
    yAxisTitle:"No Of Outreaches",
    fontSize:"12px",
    labelsfontSize:"12px",
    showTooltip:true,
    axisLabelClassName:"text-xs font-medium text-black",
    color:"#669df6",
    label: '',
    labelClassName:
        'w-[80%] font-semibold text-base text-primary my-5 ',
    // width:380,
    height: 260,
    pointWidth: 20,
    categories:  [
    ],
    data:[ 

    ]
    
})

const botactivityschema: any = [
    {
        name: 'pie_and_graph',
        visible: true,
        className: 'w-full gap-2',
        fields: [
            {
                name: 'pie_chart_details',
                visible: true,
                className: 'w-full flex flex-col gap-2 mt-4 justify-center items-center',
                fields: [
                    {
                        name: 'search_container',
                        className: 'flex flex-col gap-2 overflow-hidden w-[70%]',
                        fields: [
                            {
                                name: "search_container",
                                element: "input-text",
                                label: "",
                                icon:"search",
                                iconsClassName:"w-[15px] flex justify-center items-center",
                                isSearch:true,
                                placeholder: "Natural Language search",
                                containerClassName: 'w-full flex flex-col rounded-3xl overflow-hidden bg-white',
                                className: 'w-full px-4 rounded-3xl py-3 bg-white overflow-hidden focus:outline-none border-none',
                            },
                            {
                                name: "suggestion_container",
                                className: "flex gap-1 justify-center items-center px-2 pb-2 mb-2",
                                fields: [
                                    {
                                        name: "label",
                                        label: "Type your question. Ex: Give me the performance of call centre agents in last month",
                                        element: "div",
                                        className: "text-xs text-gray-500"
                                    }
                                ]
                            }
                        ],
                    },
                    {
                        name: 'pie_charts',
                        className: 'w-full grid grid-cols-2 gap-2',
                        fields: [
                            {
                                name: 'suggestions1',
                                className: 'bg-white rounded-md flex flex-col gap-2 overflow-hidden items-center justify-center col-span-2',
                                fields: [
                                    {
                                        name:"suggestions1_label",
                                        className:"flex gap-3 self-start items-center mt-1 pl-5 pr-2 w-full justify-between",
                                        fields:[
                                            {
                                                name:"label_and_value",
                                                className:"flex gap-2 items-center",
                                                fields:[
                                                    {
                                                        name: "peak_usage_time_label",
                                                        element: "span",
                                                        label: "Chat Engagement Analysis",
                                                        className: "  cursor-pointer  text-sm font-semibold text-[#254AAA]",
                                                        data:[]
                                                    },
                                                    {
                                                        name: 'peak_usage_time_eye_icon',
                                                        element: 'div',
                                                        label: <Popover content="Displays the number of chats received each hour in 24 hours of selected date range." overlayStyle={{width:'350px' }}><img src={`${BASE_URL}/api/public/assets/images/info.svg`} alt='Info_icon' className="size-4 cursor-pointer"/></Popover>,
                                                        // imgClassName:"size-4 cursor-pointer",
                                                        // img:"${BASE_URL}/api/public/assets/images/info.svg",
                                                        visible: true,
                                                    },
                                                ]
                                            },
                                            {
                                                name: 'peak_usage_time_expand_icon',
                                                element: 'span',
                                                label: <img src={`${BASE_URL}/api/public/assets/images/expand.svg`} alt='Info_icon' className="size-3 cursor-pointer"/>,
                                                visible: true,
                                                title:"Chat Engagement Analysis"
                                            },
                                        ]
                                    },
                                    {...peak_usage_time_graph()}
                                ]

                            },
                            {
                                name:"bot_conversations",
                                className:"bg-white rounded-md flex overflow-hidden flex-col gap-2 items-center ",
                                fields:[
                                    {
                                        name:"rye_and_label",
                                        className:"flex gap-3 self-start items-center mt-1 ml-5 rounded-md",
                                        fields:[
                                            {
                                                element:"span",
                                                name:"bot_conversations_text",
                                                label:"Automated Interaction Insights",
                                                className:"text-sm cursor-pointer text-[#254AAA] font-semibold "
                                            },
                                            {
                                                name: 'bot_conv_eye_icon',
                                                element: 'div',
                                                label: <Popover content="Summarizes the total number of bot sessions and the types of inquiries made." overlayStyle={{width:'350px' }}><img src={`${BASE_URL}/api/public/assets/images/info.svg`} alt='Info_icon' className="size-4 cursor-pointer"/></Popover>,
                                                visible: true,
                                            },
                                        ]
                                    },
                                    {
                                        name: 'bot_conv',
                                        label:"",
                                        // width: 200,
                                        height: 260,
                                        legendAlign:"right",
                                        legendVerticalAlign:"middle",
                                        showTooltip: false,
                                        showLegend:true,
                                        labelClassName:
                                            'text-sm text-[#254AAA] font-semibold self-start ml-5',
                                        element: 'circle-doughnut',
                                        className:
                                            ' rounded-md  px-2 pb-5',
                                        visible: true,
                                        centerLabel1: 'Total Sessions',
                                        layout:"vertical",
                                        centerLabel2: '',
                                        legendTitle: "",
                                        legendClassName: "text-xs text-[#254AAA] font-semibold mt-2 text-left",
                                        data: [
                                            {
                                                label: 'Registration',
                                                value: 0,
                                                color: '#FFCB8A',
                                            },
                                            {
                                                label: 'Book Appointment',
                                                value: 0,
                                                color: '#CADBBF',
                                            },
                                            {
                                                label: 'Other',
                                                value: 0,
                                                color: '#AEA29F',
                                            },
                                        ],
                                    },
                                ]
                            },
                            {
                                name: 'suggestions3',
                                className: 'bg-white rounded-md overflow-hidden flex flex-col gap-2 items-center ',
                                fields: [
                                    {
                                        name: 'suggestions3_text',
                                        className:"flex gap-3 self-start items-center mt-1 ml-5",
                                        fields: [
                                            {
                                                name: "calls_data_label",
                                                element: "span",
                                                label: "Call Center Performance Overview",
                                                className: " text-sm  cursor-pointer self-start  font-semibold text-[#254AAA]",
                                            },
                                            {
                                                name: 'calls_data_eye_icon',
                                                element: 'div',
                                                label: <Popover content="Breaks down the types of calls received, including answered, special requests and hangup calls." overlayStyle={{width:'350px' }}><img src={`${BASE_URL}/api/public/assets/images/info.svg`} alt='Info_icon' className="size-4 cursor-pointer"/></Popover>,
                                                visible: true,
                                            },
                                        ]
                                    },
                                    {
                                        name: 'calls_data',
                                        element: 'variable-piechart',
                                        className: 'bg-white rounded-md px-2',
                                        
                                        centerLabel1: 'Medium',
                                        innerSize:'40%',
                                        legendAlign:"center",
                                        legendVerticalAlign:"bottom",
                                        showTooltip: false,
                                        showLegend:true,
                                        distance:"-40%",
                                        fontSize :"12px",
                                        // width: 380,
                                        height: 260,
                                        centerLabel2:
                                            'Optimise response tim algos',
                                        visible: true,
                                        data: [
                                            
                                        ],
                                    },
                                ]

                            },
                            {
                                name: 'suggestions8',
                                className: 'bg-white rounded-md flex flex-col gap-2 overflow-hidden items-center justify-center',
                                fields: [
                                    {
                                        name:"suggestions4_text",
                                        className:"flex gap-3 self-start items-center mt-1 pl-5 pr-2 w-full justify-between",
                                        fields:[
                                            {
                                                name:"label_and_value",
                                                className:"flex gap-2 items-center",
                                                fields:[
                                                    {
                                                        name: "campaigns_label",
                                                        element: "span",
                                                        label: "Patient Outreach",
                                                        className: " text-sm  cursor-pointer self-start  font-semibold text-[#254AAA]",
                                                    },
                                                    {
                                                        name: 'campaigns_eye_icon',
                                                        element: 'div',
                                                        label: <Popover content="Tracks the number of outreaches executed on given dates." overlayStyle={{width:'350px' }}><img src={`${BASE_URL}/api/public/assets/images/info.svg`} alt='Info_icon' className="size-4 cursor-pointer"/></Popover>,
                                                        // imgClassName:"size-4 cursor-pointer",
                                                        // img:"${BASE_URL}/api/public/assets/images/info.svg",
                                                        visible: true,
                                                    },
                                                ]
                                            },
                                            {
                                                name: 'campaigns_linechart_expand_icon',
                                                element: 'span',
                                                label: <img src={`${BASE_URL}/api/public/assets/images/expand.svg`} alt='Info_icon' className="size-3 cursor-pointer"/>,
                                                visible: true,
                                                title:"Patient Outreach"
                                            },
                                        ]
                                    },
                                    {...campaigns_line_graph()}
                                ]

                            },
                            {
                                name: 'suggestions5',
                                className: 'bg-white rounded-md flex flex-col gap-2 overflow-hidden items-center justify-center',
                                fields: [
                                    {
                                        name:"suggestions4_text",
                                        className:"flex gap-3 self-start items-center mt-1 pl-5 pr-2 w-full justify-between",
                                        fields:[
                                            {
                                                name:"label_and_value",
                                                className:"flex gap-2 items-center",
                                                fields:[
                                                    {
                                                        name: "campaigns_label",
                                                        element: "span",
                                                        label: "Patient Outreach Details",
                                                        className: " text-sm  cursor-pointer self-start  font-semibold text-[#254AAA]",
                                                    },
                                                    {
                                                        name: 'campaigns_eye_icon',
                                                        element: 'div',
                                                        label: <Popover content="Tracks the number of outreaches executed on given dates." overlayStyle={{width:'350px' }}><img src={`${BASE_URL}/api/public/assets/images/info.svg`} alt='Info_icon' className="size-4 cursor-pointer"/></Popover>,
                                                        visible: true,
                                                    },
                                                ]
                                            },
                                            {
                                                name: 'campaigns_expand_icon',
                                                element: 'span',
                                                label: <img src={`${BASE_URL}/api/public/assets/images/expand.svg`} alt='Info_icon' className="size-3 cursor-pointer"/>,
                                                visible: true,
                                                title:"Patient Outreach Details"
                                            },
                                        ]
                                    },
                                    {...campaigns_graph()}
                                ]

                            },
                            {
                                name: 'suggestions2',
                                className: 'bg-white rounded-md flex flex-col gap-2 items-center overflow-hidden justify-center col-span-2',
                                fields: [
                                    {
                                        name:"suggestion2_text",
                                        className:"flex gap-3 self-start items-center mt-1 pl-5 pr-2 w-full justify-between",
                                        fields:[
                                            {
                                                name:"label_and_value",
                                                className:"flex gap-2 items-center",
                                                fields:[
                                                    {
                                                        name: "leads_generated_label",
                                                        element: "span",
                                                        label: "Lead Acquisition Performance",
                                                        className: " text-sm  cursor-pointer self-start  font-semibold text-[#254AAA]",
                                                    },
                                                    {
                                                        name: 'leads_generated_icon',
                                                        element: 'div',
                                                        label: <Popover content="This shows the number of leads generated on specific dates." overlayStyle={{width:'350px' }}><img src={`${BASE_URL}/api/public/assets/images/info.svg`} alt='Info_icon' className="size-4 cursor-pointer"/></Popover>,
                                                        // imgClassName:"size-4 cursor-pointer",
                                                        // img:"${BASE_URL}/api/public/assets/images/info.svg",
                                                        visible: true,
                                                    }
                                                ]
                                            },
                                            {
                                                name: 'leads_generated_expand_icon',
                                                element: 'span',
                                                label: <img src={`${BASE_URL}/api/public/assets/images/expand.svg`} alt='Info_icon' className="size-3 cursor-pointer"/>,
                                                visible: true,
                                                title:"Lead Acquisition Performance"
                                            },
                                        ]
                                    },
                                    {...leads_graph()}
                                ]

                            },
                        ],
                    },
                ],
            }
        ],
    },
]


const Reports = () => {
    const details = Storage.getItem("details");
    const is_multibranch=details?.is_multi_branch;
    const [schema, setSchema] = useState<any>({
        className: 'flex flex-col w-full gap-2',
        schema: [
            {
                name: 'schema_form',
                className: 'flex flex-col gap-2 w-full ',
                fields: [...botactivityschema],
            },
            {
                name:'graph-modal-container',
                visible:false,
                element:"modal",
                containerClassName:"!w-[90%]",
                className:'!w-full',
                fields:[
                    {
                        name:'graph-modal',
                        className:'flex flex-col w-full bg-white border shadow-lg rounded-lg overflow-hidden min-h-[200px]',
                        fields:[
                            {
                                name:'modal_title',
                                className:'bg-primary/5 flex justify-between items-center p-2 pl-4 w-full text-lg',
                                fields:[
                                    {
                                        name:'modal_title_key',
                                        className:'font-semibold text-lg',
                                        element:'div',
                                        label:''
                                    },
                                    {
                                        name:'graph-modal-close',
                                        element:'button',
                                        label:'X',
                                        className:'w-[40px] h-[40px] rounded-full bg-primary text-xl text-white flex justify-center items-center'
                                    }
                                ]
                            },
                            {
                                name:'modal-body',
                                className:' p-[10px] w-full overflow-scroll [&::-webkit-scrollbar]:hidden',
                                fields:[
                                    
                                ]
                            }
                            
                        ]
                    },  
                ]
            }
        ],
    })
    const navigate = useNavigate()
    const [loading, setLoading] = useState<boolean>(false);
    const [botactivity, setBotactivity] = useState([...botactivityschema])
    const [dateRange, setDateRange] = useState<[string, string]>([
        moment().subtract(7, 'days').format('DD-MM-YYYY'),
        moment().format('DD-MM-YYYY'),
    ])
    const [tabs, setTabs] = useState('conversation')
    const [insightsResponse,setInsightsResponse] = useState<any>( [
        {}
    ])
    const dispatch=useDispatch()
    const [leadsData,setLeadsData]=useState<any>({})
    const [CampaignsData,setCampaignsData]=useState<any>({})
    const [chatsData,setChatsData]=useState<any>({})

    const change_the_dashboards_data = (data: any) => {
        if (tabs === "conversation") {
          setSchema((prevSchema:any) => ({
            ...prevSchema,
            schema: prevSchema.schema.map((item:any) => {
              if (item.name === 'schema_form') {
                return {
                  ...item,
                  fields: item.fields.map((field:any) => {
                    if (field.name === 'pie_and_graph') {
                      return {
                        ...field,
                        fields: field.fields.map((subfield:any) => {
                          if (subfield.name === 'pie_chart_details') {
                            return {
                              ...subfield,
                              fields: subfield.fields.map((pieField:any) => {
                                if(pieField.name==="botactivity_details"){
                                    pieField.fields.map((fffield: any) => {
                                        if (["total_chatbot_sessions", "total_new_users", "average_handle_time", "total_contained_sessions"].includes(fffield.name)) {
                                            fffield.fields = fffield.fields.map((ffffield: any) => {
                                                if (["total_chatbots_sessions_text_and_value", "total_new_users_text_and_value", "total_average_handle_text_and_value", "total_contained_sessions_text_and_value"].includes(ffffield.name)) {
                                                    ffffield.fields.map((fffffield: any) => {
                                                        if (fffffield.name === "total_chatbot_sessions_percentage") {
                                                            fffffield.label = data?.total_chatbot_sessions
 
                                                        }
                                                        if (fffffield.name === "average_handle_time_percentage") {
                                                            fffffield.label = data?.avg_bot_time
 
                                                        }
                                                        if (fffffield.name === "total_new_users_value") {
                                                            fffffield.label = data?.new_users
                                                        }
                                                        if (fffffield.name === "total_contained_sessions_percentage") {
                                                            fffffield.label = data?.contained_sessions
                                                        }
                                                    })
                                                }
                                                return ffffield
                                            })
                                        }
 
                                        return fffield
                                    })
                                }
                                if (pieField.name === 'pie_charts') {
                                  return {
                                    ...pieField,
                                    fields: pieField.fields.map((chartField:any) => {
                                      // Handle suggestions1
                                      if (chartField.name === 'suggestions1') {
                                        return {
                                          ...chartField,
                                          fields: chartField.fields.map((usageField:any) => {
                                            if (usageField.name === 'peak_usage_time') {
                                              return {
                                                ...usageField,
                                                yAxixMaxValue: data?.suggestions_peak_usage_time.reduce((max: number, item: any) => 
                                                  item.value > max ? item.value : max, 0),
                                                data: data?.suggestions_peak_usage_time
                                              };
                                            }
                                            return usageField;
                                          })
                                        };
                                      }
                                      if(chartField.name === 'bot_conversations'){
                                        chartField.fields=chartField.fields.map((fffield:any)=>{
                                            if(fffield.name==="bot_conv"){
                                                fffield.data=data?.inquiry_type
                                                fffield.centerLabel2=data?.total_sessions;
                                            }
                                            return fffield;
                                        })
                                                                                    
                                        }
                                        if(chartField.name==="age_graph"){
                                            chartField.fields=chartField.fields.map((fffffield:any)=>{
                                                if(fffffield.name==="age_graph1"){
                                                    const data1 = data?.age_demographics.map((item: { label: any; value: any,color:any }) => ({
                                                        label: item.label,
                                                        value: item.value,
                                                        color:item.color
                                                      }));
                                                      
                                                      const ratio1 = data?.age_demographics.map((item: { male: any; female: any }) => ({
                                                        male: item.male,
                                                        female: item.female
                                                      }));
                                                    fffffield.data=data1;
                                                    fffffield.ratio=ratio1;
                                                }
                                                return fffffield;
                                            })
                                        }
                                      return chartField;
                                    })
                                  };
                                }
                                return pieField;
                              })
                            };
                          }
                          return subfield;
                        })
                      };
                    }
                    return field;
                  })
                };
              }
              return item;
            })
          }));
        }
    };
    
    const change_calls_data = (data: any) => {
        setSchema((prevSchema:any) => ({
          ...prevSchema,
          schema: prevSchema.schema.map((item:any) => {
            if (item.name === 'schema_form') {
              return {
                ...item,
                fields: item.fields.map((field:any) => {
                  if (field.name === 'pie_and_graph') {
                    return {
                      ...field,
                      fields: field.fields.map((subfield:any) => {
                        if (subfield.name === 'pie_chart_details') {
                          return {
                            ...subfield,
                            fields: subfield.fields.map((pieField:any) => {
                              if (pieField.name === 'pie_charts') {
                                return {
                                  ...pieField,
                                  fields: pieField.fields.map((suggestionField:any) => {
                                    if (suggestionField.name === 'suggestions3') {
                                      return {
                                        ...suggestionField,
                                        fields: suggestionField.fields.map((callDataField:any) => {
                                          if (callDataField.name === 'calls_data') {
                                            return {
                                              ...callDataField,
                                              data: data
                                            };
                                          }
                                          return callDataField;
                                        })
                                      };
                                    }
                                    return suggestionField;
                                  })
                                };
                              }
                              return pieField;
                            })
                          };
                        }
                        return subfield;
                      })
                    };
                  }
                  return field;
                })
              };
            }
            return item;
          })
        }));
    };
      
    const change_leads_data = (data: any) => {
    // console.log(data)
    setSchema((prevSchema:any) => ({
        ...prevSchema,
        schema: prevSchema.schema.map((item:any) => {
        if (item.name === 'schema_form') {
            return {
            ...item,
            fields: item.fields.map((field:any) => {
                if (field.name === 'pie_and_graph') {
                return {
                    ...field,
                    fields: field.fields.map((subfield:any) => {
                    if (subfield.name === 'pie_chart_details') {
                        return {
                        ...subfield,
                        fields: subfield.fields.map((pieField:any) => {
                            if (pieField.name === 'pie_charts') {
                            return {
                                ...pieField,
                                fields: pieField.fields.map((suggestionField:any) => {
                                if (suggestionField.name === 'suggestions2') {
                                    return {
                                    ...suggestionField,
                                    fields: suggestionField.fields.map((leadsField:any) => {
                                        if (leadsField.name === 'leads_generated') {
                                        return {
                                            ...leadsField,
                                            categories: data?.categories,
                                            data:data?.lineChartData
                                        };
                                        }
                                        return leadsField;
                                    })
                                    };
                                }
                                return suggestionField;
                                })
                            };
                            }
                            return pieField;
                        })
                        };
                    }
                    return subfield;
                    })
                };
                }
                return field;
            })
            };
        }
        return item;
        })
    }));
    };
      
    const change_campaigns_data = (data: any) => {
    setSchema((prevSchema:any) => ({
        ...prevSchema,
        schema: prevSchema.schema.map((item:any) => {
        if (item.name === 'schema_form') {
            return {
            ...item,
            fields: item.fields.map((field:any) => {
                if (field.name === 'pie_and_graph') {
                return {
                    ...field,
                    fields: field.fields.map((subfield:any) => {
                    if (subfield.name === 'pie_chart_details') {
                        return {
                        ...subfield,
                        fields: subfield.fields.map((pieField:any) => {
                            if (pieField.name === 'pie_charts') {
                            return {
                                ...pieField,
                                fields: pieField.fields.map((suggestionField:any) => {
                                if (suggestionField.name === 'suggestions8') {
                                    return {
                                    ...suggestionField,
                                    fields: suggestionField.fields.map((campaignField:any) => {
                                        if (campaignField.name === 'campaigns_linechart') {
                                        return {
                                            ...campaignField,
                                            data: data?.lineChartData,
                                            categories:data?.categories
                                        };
                                        }
                                        return campaignField;
                                    })
                                    };
                                }
                                if (suggestionField.name === 'suggestions5') {
                                    return {
                                        ...suggestionField,
                                        fields: suggestionField.fields.map((campaignField:any) => {
                                            if (campaignField.name === 'campaigns_chart') {
                                            return {
                                                ...campaignField,
                                                seriesData: data?.seriesData,
                                                categories: data?.categories
                                            };
                                            }
                                            return campaignField;
                                        })
                                        };           
                                }
                                
                                return suggestionField;
                                })
                            };
                            }
                            return pieField;
                        })
                        };
                    }
                    return subfield;
                    })
                };
                }
                return field;
            })
            };
        }
        return item;
        })
    }));
    };

    const change_insights_data=(data:any)=>{
        setSchema((prev:any)=>{
            const updatedSchema=prev.schema.map((item:any)=>{
                if(item.name==="schema_form"){
                    item.fields=item.fields.map((field:any)=>{
                        if(field.name==="pie_and_graph"){
                            field.fields=field.fields.map((ffield:any)=>{
                                if(ffield.name==="insights_and_graphs"){
                                    ffield.fields=ffield.fields.map((fffield:any)=>{
                                        if(fffield.name==="insights-container "){
                                            fffield.className='flex flex-col gap-4 bg-white sticky top-[78px] px-3 py-2 rounded-md pb-5',
                                            fffield.fields=fffield.fields.map((ffffield:any)=>{
                                                if(ffffield.name==="insights-cards"){
                                                    ffffield.fields=data.map((insight:any,index:number)=>{
                                                        return{
                                                            name:`insight-item-${index}`,
                                                            className:` hover:shadow-md cursor-pointer border border-[#E41B47] bg-[#FFF4F7] rounded-lg p-[5px] flex flex-col items-center justify-center gap-2`,
                                                            element:"clickable-widget",
                                                            fields:[
                                                                {
                                                                    name: 'smiley_icon',
                                                                    className: 'size-7',
                                                                    element: 'image',
                                                                    img:`${BASE_URL}/api/public/assets/images/smiley-sad.svg`,
                                                                    visible: true,
                                                                },
                                                                {
                                                                    name:"value_and_status",
                                                                    className:"flex justify-between items-center gap-2",
                                                                    fields:[
                                                                        {
                                                                            name:`insight-status-${index}`,
                                                                            className:"text-base font-semibold font-semibold text-[#E41B47]",
                                                                            element:"div",
                                                                            label:`${insight.status}`,
                                                                            visible:false
                                                                        },
                                                                        {
                                                                            name:`insight-title-${index}`,
                                                                            className:'text-xs cursor-pointer text-black',
                                                                            element:'div',
                                                                            label:`${insight.title}`
                                                                        },
                                                                    ]
                                                                }
                                                            ]
                                                        }
                                                    })
                                                }
                                                if(ffffield.name==="insights-title"){
                                                    ffffield.label=<p className = "flex flex-row self-start  gap-2 items-center text-base justify-center"><span><img src={`${BASE_URL}/api/public/assets/images/insights.svg`} alt="insights" style={{fill: '#254AAA'}}/></span>Smart AI Insights -<span className='text-[#E41B47] bg-[#FFF4F7] p-1 rounded-lg'>{data?.length}</span></p>
                                                }
                                                return ffffield;
                                            })
                                        }
                                        return fffield;
                                    })
                                }
                                return ffield;
                            })
                        }
                        return field;
                    })
                }
                return item;
            })
            return {...prev,schema:updatedSchema}
        })
    }

    const change_insights_with_no_data=()=>{
        setSchema((prev:any)=>{
            const updatedSchema=prev.schema.map((item:any)=>{
                if(item.name==="schema_form"){
                    item.fields=item.fields.map((field:any)=>{
                        if(field.name==="pie_and_graph"){
                            field.fields=field.fields.map((ffield:any)=>{
                                if(ffield.name==="insights_and_graphs"){
                                    ffield.fields=ffield.fields.map((fffield:any)=>{
                                        if(fffield.name==="insights-container "){
                                            fffield.className='flex flex-col gap-4 bg-white sticky top-[78px] px-3 py-2 rounded-md pb-5 h-screen',
                                            fffield.fields=fffield.fields.map((ffffield:any)=>{
                                                if(ffffield.name==="insights-cards"){
                                                    ffffield.fields=[{
                                                        name:`insight-indicator`,
                                                        className:` border  rounded-lg p-[5px] flex flex-col items-center justify-center gap-2 h-full`,
                                                        fields:[
                                                            // {
                                                            //     name: 'smiley_icon',
                                                            //     className: 'size-7',
                                                            //     element: 'image',
                                                            //     img:`${BASE_URL}/api/public/assets/images/smiley-sad.svg`,
                                                            //     visible: true,
                                                            // },
                                                            {
                                                                name:"value_indicator",
                                                                className:"flex justify-between items-center gap-2",
                                                                fields:[
                                        
                                                                    {
                                                                        name:`insight-title_indicator`,
                                                                        className:'text-base text-gray-500',
                                                                        element:'div',
                                                                        label:`No Insights Found`
                                                                    },
                                                                ]
                                                            }
                                                        ]
                                                    }]
                                                }
                                                if(ffffield.name==="insights-title"){
                                                    ffffield.label=<p className = "flex flex-row self-start gap-2 items-center text-base justify-center"><span><img src={`${BASE_URL}/api/public/assets/images/insights.svg`} alt="insights" style={{fill: '#254AAA'}}/></span>Smart AI Insights -<span className='text-[#E41B47] bg-[#FFF4F7] p-1 rounded-lg'>00</span></p>
                                                }
                                                return ffffield;
                                            })
                                        }
                                        return fffield;
                                    })
                                }
                                return ffield;
                            })
                        }
                        return field;
                    })
                }
                return item;
            })
            return {...prev,schema:updatedSchema}
        })
    }

    const API_POST_SendWhatsappPDF=async ()=>{
        setLoading(true);
        try {
            const res: any = await Utils.makeApiCall(`/generate-closing-report`);
            if (res.status) {
                toast.success(res.message, { position: 'top-center', autoClose: AUTO_CLOSE })
            } else {
                toast.error(res.message ? res.message : FAILURE_MSG, { position: 'top-center', autoClose: AUTO_CLOSE })
            }
        } catch (error) {
            toast.error(CATCH_ERROR_MSG, { position: 'top-center', autoClose: AUTO_CLOSE });
            console.error('Failed to fetch schema', error);
        }
        setLoading(false);
    }

    const API_GET_DASHBOARDS_Data = async ({ source = "", startDate = "", endDate = "" }) => {
        try {
            const res: any = await Utils.makeApiCall(`/analytics?org_id=${details?.["org_id"]}&type=${source}&startDate=${startDate}&endDate=${endDate}`, "GET");
            if (res.status) {
                change_the_dashboards_data(res.data)
                setChatsData(res.data)

            } else {
                toast.error(res.message ? res.message : FAILURE_MSG, { position: 'top-center', autoClose: AUTO_CLOSE })
            }
        } catch (error) {
            toast.error(CATCH_ERROR_MSG, { position: 'top-center', autoClose: AUTO_CLOSE });
            console.error('Failed to fetch schema', error);
        }
    }
    const API_GET_CALLS_Data=async ({ source = "", startDate = "", endDate = "" })=>{
        try {
            const res: any = await Utils.makeApiCall(`/analytics?org_id=${details?.["org_id"]}&type=${source}&startDate=${startDate}&endDate=${endDate}`, "GET");
            if (res.status) {
                change_calls_data(res.data);

            } else {
                toast.error(res.message ? res.message : FAILURE_MSG, { position: 'top-center', autoClose: AUTO_CLOSE })
            }
        } catch (error) {
            toast.error(CATCH_ERROR_MSG, { position: 'top-center', autoClose: AUTO_CLOSE });
            console.error('Failed to fetch schema', error);
        }
    }
    const API_GET_Leads_Data=async ({ source = "", startDate = "", endDate = "" })=>{
        try {
            const res: any = await Utils.makeApiCall(`/analytics?org_id=${details?.["org_id"]}&type=${source}&startDate=${startDate}&endDate=${endDate}`, "GET");
            if (res.status) {
                change_leads_data(res.data);
                setLeadsData(res.data)

            } else {
                toast.error(res.message ? res.message : FAILURE_MSG, { position: 'top-center', autoClose: AUTO_CLOSE })
            }
        } catch (error) {
            toast.error(CATCH_ERROR_MSG, { position: 'top-center', autoClose: AUTO_CLOSE });
            console.error('Failed to fetch schema', error);
        }
    }
    const API_GET_Campaigns_Data=async ({  startDate = "", endDate = "" })=>{
        try {
            const res: any = await Utils.makeApiCall(`/analytics?org_id=${details?.["org_id"]}&type=campaigns_graphs&startDate=${startDate}&endDate=${endDate}`, "GET");
            if (res.status) {
                change_campaigns_data(res.data);
                setCampaignsData(res.data)

            } else {
                toast.error(res.message ? res.message : FAILURE_MSG, { position: 'top-center', autoClose: AUTO_CLOSE })
            }
        } catch (error) {
            toast.error(CATCH_ERROR_MSG, { position: 'top-center', autoClose: AUTO_CLOSE });
            console.error('Failed to fetch schema', error);
        }
    }
    const API_GET_Insights_Data=async ({  startDate = "", endDate = "" })=>{
        try {
            const res: any = await Utils.makeApiCall(`/getInsightsByDateAndOrgId?org_id=${details?.["org_id"]}&start_date=${startDate}&end_date=${endDate}`, "GET");
            if (res.status) {
                if(res.data.length===0){
                    change_insights_with_no_data();
                } else{
                    change_insights_data(res.data);
                }
                setInsightsResponse(res.data)

            } else {
                toast.error(res.message ? res.message : FAILURE_MSG, { position: 'top-center', autoClose: AUTO_CLOSE })
            }
        } catch (error) {
            toast.error(CATCH_ERROR_MSG, { position: 'top-center', autoClose: AUTO_CLOSE });
            console.error('Failed to fetch schema', error);
        }
    }
    
    useEffect(()=>{
        API_GET_Insights_Data({ startDate: dateRange[0], endDate: dateRange[1] })
    },[dateRange])
    
    const change_segments_value=(val:string | null)=>{
        setSchema((prev:any)=>{
            const updatedSchema=prev.schema.map((field:any)=>{
                if(field.name==="tabs_and_datepicker"){
                    field.fields=field.fields.map((ffield:any)=>{
                        if(ffield.name==="name_and_segments"){
                            ffield.fields=ffield.fields.map((fffield:any)=>{
                                if(fffield.name==="segments"){
                                    fffield.value=val;
                                }
                                return fffield;
                            })
                        }
                        return ffield;
                    })
                }
                return field;
            })
            return {...prev,schema:updatedSchema}
        })
    }

    useEffect(() => {
        const first = moment(dateRange[0], 'DD-MM-YYYY');
        const second = moment(dateRange[1], 'DD-MM-YYYY');
        const diffInDays = second.diff(first, 'days');
        const today = moment().startOf('day');
    
        if (first.isSame(today, 'day') && second.isSame(today, 'day')) {
            change_segments_value("TD")
        } else if (diffInDays === 7 && second.isSame(today, 'day')) {
            change_segments_value("TW")
        } else if (diffInDays === 30 && second.isSame(today, 'day')) {
            change_segments_value("TM")
        }else{
            change_segments_value(null)
        }

        API_GET_CALLS_Data({ source: "calls", startDate: dateRange[0], endDate: dateRange[1] });

        if (tabs === "conversation") {
            API_GET_DASHBOARDS_Data({ source: "bot_activity", startDate: dateRange[0], endDate: dateRange[1] });
        }
        if (tabs === "campaign") {
            API_GET_DASHBOARDS_Data({ source: "campaigns", startDate: dateRange[0], endDate: dateRange[1] })
        }
        API_GET_Campaigns_Data({ startDate: dateRange[0], endDate: dateRange[1] })
        
        API_GET_Leads_Data({ source: "leads", startDate: dateRange[0], endDate: dateRange[1] })
        
        setSchema({ ...schema })
    }, [tabs, dateRange])

    const handleGraphModalVisibility=(name:any,title:any)=>{

        setSchema((prev:any)=>{
            const updatedSchema=prev.schema.map((field:any)=>{
                if(field.name==="graph-modal-container"){
                    
                    field.fields=field.fields.map((ffield:any)=>{
                        if(ffield.name==="graph-modal"){
                            ffield.fields=ffield.fields.map((fffield:any)=>{
                                if(fffield.name==="modal_title"){
                                    fffield.fields=fffield.fields.map((ffffield:any)=>{
                                        if(ffffield.name==="modal_title_key"){
                                            ffffield.label=title
                                        }
                                        return ffffield;
                                    })
                                }
                                if(fffield.name==="modal-body"){
                                    if(name==="leads_generated_expand_icon"){
                                        fffield.fields=[{
                                            ...leads_graph(),
                                            categories:leadsData?.categories,
                                            data:leadsData?.lineChartData,
                                            height:400,
                                            className:'rounded-md mb-5 bg-white px-2'
                                        }]
                                    }
                                    if(name==="peak_usage_time_expand_icon"){
                                        fffield.fields=[{
                                            ...peak_usage_time_graph(),
                                            yAxixMaxValue: chatsData?.suggestions_peak_usage_time.reduce((max: number, item: any) => 
                                                item.value > max ? item.value : max, 0),
                                            data:chatsData?.suggestions_peak_usage_time,
                                            height:400,
                                            className:'rounded-md mb-5 bg-white px-2'
                                        }]
                                    }
                                    if(name==="campaigns_linechart_expand_icon"){
                                        fffield.fields=[{
                                            ...campaigns_line_graph(),
                                            data:CampaignsData?.lineChartData,
                                            categories:CampaignsData?.categories,
                                            height:400,
                                            className:'rounded-md mb-5 bg-white px-2'
                                        }]
                                    }
                                    if(name==="campaigns_expand_icon"){
                                        fffield.fields=[{
                                            ...campaigns_graph(),
                                            seriesData:CampaignsData?.seriesData,
                                            categories:CampaignsData?.categories,
                                            height:400,
                                            className:'rounded-md mb-5 bg-white px-2'
                                        }]
                                    }
                                }
                                return fffield;
                            })
                        }
                        return ffield;
                    })
                    field.visible=true
                }
                return field;
            })
            return {...prev,schema:updatedSchema}
        })

        setLoading(false)
    }

    const handleOnChange = (e: any) => {
        // console.log(e)
        if (e.name === 'date') {
            if (e.value === '') {
                setDateRange(['', ''])
                // API_GET_CALLS_Data({startDate:"",endDate:""})
            } else {
                setDateRange(e.value)
                // API_GET_CALLS_Data({startDate:e.value[0],endDate:e.value[1]})
            }
        }
        if (e.name === 'bot_conversations_text' || e.name==="total_chatbot_sessions" || e.name==="average_handle_time" ||e.name==="total_contained_sessions" ||e.name==="peak_usage_time_label") {
            navigate('/bot-activity-log',{ state: { source: 'Bot Activity Log' } })
        }
        if (e.name === 'leads_view_full' || e.name==="leads_generated_label") {
            navigate('/leads',{ state: { source: 'Leads' } })
        }
        if (e.name === 'campaigns_label') {
            navigate('/campaigns', { state: { source: 'Campaigns' } })
        }
        if (e.name === 'sms_view_full') {
            dispatch(setActiveTab("sms"))
            navigate('/campaigns', { state: { source: 'Campaigns' } })
        }
        if (e.name === 'tabs') {
            setTabs(e.value.key)
        }
        if(e.name.startsWith('insight-title-') || e.name.startsWith('insight-content-') || e.name.startsWith('insight-item-')){
            setLoading(true);
            const index = e.name.split('-')[2]
            
            setSchema((prev:any)=>{
                const updatedSchema=prev.schema.map((field:any)=>{
                    if(field.name==="insight-modal-container"){
                        field.visible=true
                        field.fields=field.fields.map((ffield:any)=>{
                            if(ffield.name==="insight-modal"){
                                ffield.fields=ffield.fields.map((fffield:any)=>{
                                    if(fffield.name==="modal_title"){
                                        fffield.fields=fffield.fields.map((ffffield:any)=>{
                                            if(ffffield.name==="modal_title_key"){
                                                ffffield.label=insightsResponse[index]?.title
                                            }
                                            return ffffield;
                                        })
                                    }
                                    if(fffield.name==="modal-body"){
                                        fffield.fields=[
                                            {
                                                name:'insight-modal-explanation-title',
                                                element:'div',
                                                className:'text-[#254AAA] bg-[5px] text-lg font-semibold',
                                                label:`Explanation:`
                                            },
                                            {
                                                name:'insight-modal-explanation',
                                                element:'div',
                                                label:`${insightsResponse[index]?.explanation}`
                                            },
                                            {
                                                name:'insight-modal-suggestion-title',
                                                element:'div',
                                                className:'mt-[5px] mb-[5px] text-lg text-[#254AAA] font-semibold',
                                                label:`Suggestion:`
                                            },
                                            {
                                                name:'insight-modal-explanation',
                                                element:'div',
                                                label:`${insightsResponse[index]?.suggestion}`
                                            }
                                        ]
                                    }
                                    return fffield;
                                })
                            }
                            return ffield;
                        })
                    }
                    return field
                })
                return {...prev,schema:updatedSchema}
            })
            setLoading(false);
        }
        if(e.name == 'insight-modal-close'){
            setSchema((prev:any)=>{
                const updatedSchema=prev.schema.map((field:any)=>{
                    if(field.name==="insight-modal-container"){
                        field.visible=false
                    }
                    return field
                })
                return {...prev,schema:updatedSchema}
            })
        }
        if(e.name==="total_new_users"){
            navigate("/patients",{ state: { source: 'Patients' }});
        }
        if(e.name==="calls_data_label"){
            navigate("/calls",{ state: { source: 'Calls' }});
        }
        if(e.name==="segments"){
            if (e.value==="TD"){
                setDateRange([
                    moment().format('DD-MM-YYYY'),
                    moment().format('DD-MM-YYYY'),
                ])
                setSchema((prev:any)=>{
                    const updatedSchema=prev.schema.map((field:any)=>{
                        if(field.name==="tabs_and_datepicker"){
                            field.fields=field.fields.map((ffield:any)=>{
                                if(ffield.name==="name_and_segments"){
                                    ffield.fields=ffield.fields.map((fffield:any)=>{
                                        if(fffield.name==="date"){
                                            fffield.value=[
                                                moment().format('DD-MM-YYYY'),
                                                moment().format('DD-MM-YYYY'),
                                            ]
                                        }
                                        return fffield;
                                    })
                                }
                                return ffield;
                            })
                        }
                        return field                    
                    })
                    return {...prev,schema:updatedSchema}
                })
            }
            if (e.value==="YD"){
                setDateRange([
                    moment().subtract(1, 'days').format('DD-MM-YYYY'),
                    moment().subtract(1, 'days').format('DD-MM-YYYY'),
                ])
                setSchema((prev:any)=>{
                    const updatedSchema=prev.schema.map((field:any)=>{
                        if(field.name==="tabs_and_datepicker"){
                            field.fields=field.fields.map((ffield:any)=>{
                                if(ffield.name==="name_and_segments"){
                                    ffield.fields=ffield.fields.map((fffield:any)=>{
                                        if(fffield.name==="date"){
                                            fffield.value=[
                                                moment().subtract(1, 'days').format('DD-MM-YYYY'),
                                                moment().subtract(1, 'days').format('DD-MM-YYYY'),
                                            ]
                                        }
                                        return fffield;
                                    })
                                }
                                return ffield;
                            })
                        }
                        return field                    
                    })
                    return {...prev,schema:updatedSchema}
                })
            }
            if (e.value==="TW"){
                setDateRange([
                    moment().subtract(7, 'days').format('DD-MM-YYYY'),
                    moment().format('DD-MM-YYYY'),
                ])
                setSchema((prev:any)=>{
                    const updatedSchema=prev.schema.map((field:any)=>{
                        if(field.name==="tabs_and_datepicker"){
                            field.fields=field.fields.map((ffield:any)=>{
                                if(ffield.name==="name_and_segments"){
                                    ffield.fields=ffield.fields.map((fffield:any)=>{
                                        if(fffield.name==="date"){
                                            fffield.value=[
                                                moment().subtract(7, 'days').format('DD-MM-YYYY'),
                                                moment().format('DD-MM-YYYY'),
                                            ]
                                        }
                                        return fffield;
                                    })
                                }
                                return ffield;
                            })
                        }
                        return field                    
                    })
                    return {...prev,schema:updatedSchema}
                })
            }
            if (e.value==="TM"){
                setDateRange([
                    moment().subtract(30, 'days').format('DD-MM-YYYY'),
                    moment().format('DD-MM-YYYY'),
                ])
                setSchema((prev:any)=>{
                    const updatedSchema=prev.schema.map((field:any)=>{
                        if(field.name==="tabs_and_datepicker"){
                            field.fields=field.fields.map((ffield:any)=>{
                                if(ffield.name==="name_and_segments"){
                                    ffield.fields=ffield.fields.map((fffield:any)=>{
                                        if(fffield.name==="date"){
                                            fffield.value=[
                                                moment().subtract(30, 'days').format('DD-MM-YYYY'),
                                                moment().format('DD-MM-YYYY'),
                                            ]
                                        }
                                        return fffield;
                                    })
                                }
                                return ffield;
                            })
                        }
                        return field                    
                    })
                    return {...prev,schema:updatedSchema}
                })
            }
        }
        if(e.name==="peak_usage_time_expand_icon" || e.name==="leads_generated_expand_icon" || e.name==="campaigns_expand_icon" || e.name==="campaigns_linechart_expand_icon"){
            setLoading(true)
            handleGraphModalVisibility(e?.name,e?.title);
        }
        if(e.name==="graph-modal-close"){
            setSchema((prev:any)=>{
                const updatedSchema=prev.schema.map((field:any)=>{
                    if(field.name==="graph-modal-container"){
                        field.visible=false
                    }
                    return field;
                })
                return {...prev,schema:updatedSchema}
            })
        }
        if(e.name==="compare"){
            navigate("/compareUnits")
        }
        if(e.name==="closing_report"){
            API_POST_SendWhatsappPDF();
        }
    }

    // console.log(schema)
    return (
        <div className="w-full h-full bg-[#EFF1F9] pb-5 font-roboto ">
             <LoadingState isLoading={loading} />
            <ElementExecutor
                data={schema}
                selectedRecord={(e: any) => {
                    handleOnChange(e)
                }}
            />
        </div>
    )
}

export default Reports
