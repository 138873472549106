import { ElementExecutor } from "@apexcura/core";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Utils from "../../utils";
import { toast } from "react-toastify";
import Storage from "../../utils/local-storage";
import useExitPopUp from "../../hooks/useExitPopUp";
import { useDispatch } from "react-redux";
import { setLoading } from "../../redux/appState/appStateSlice";

const adddocs = () => ({
  className: "w-full bg-white rounded-lg p-8 flex flex-col gap-3 items-center",
  schema: [
    {
      name: "upload_fields",
      className: "w-full flex flex-col gap-2",
      fields: [
        {
          name: "name_and_type",
          className: "grid grid-cols-2 flex gap-2",
          fields: [
            {
              name: "name",
              element: "input-text",
              placeholder: "",
              label: "Name",
              containerClassName: "w-full flex flex-col",
              labelClassName:
                "text-sm mb-1 text-gray-500 font-semibold text-start",
              className:
                "rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none",
              required: true,
              visible: true,
            },
            {
              name: "document_type",
              className: "rounded-md text-gray-700 font-semibold",
              element: "single-select",
              containerClassName: "flex flex-col",
              labelClassName:
                "text-sm mb-1 text-gray-500 font-semibold text-start",
              label: "Type",
              required: true,
              placeholder: "Select the type of documents",
              value:{
                label:"form",
                value:"form"
              },
              options: [
                {
                  label: "pdf",
                  value: "application/pdf",
                },
                // {
                //   label: "doc/docx",
                //   value: ".doc,docx",
                // },
                {
                  label:"form",
                  value:"form"
                }
              ],
            },
          ],
        },
        {
          name: "comments",
          element: "input-textarea",
          placeholder: "",
          label: "Comments",
          containerClassName: "flex flex-col w-full gap-2",
          labelClassName: "text-sm text-gray-500 font-semibold text-start",
          className:
            "w-full rounded-md mb-4 px-3 py-2 text-gray-700 font-semibold focus:outline-none",
          visible: false,
          not_visible_for:"form"
        },
        {
          name: "files",
          element: "upload",
          not_visible_for:"form",
          fileSize:10,
          fileType:['image/png','application/pdf'],
          label: "Upload",
          accept: "application/pdf",
          allowOne:true,
          className: "",
          required: false,
          visible: false,
          value: null,
        },
        {
          name: "formcontent_1",
          element: "input-textarea",
          label: "Hospital Information",
          required:true,
          autosize  :true,
          minRows:3,
          maxRows:6,
          containerClassName: "flex flex-col w-full gap-2",
          labelClassName: "text-sm text-gray-500 font-semibold text-start",
          className:
            "w-full rounded-md  px-3 py-2 text-gray-700 font-semibold focus:outline-none",
          visible: true,
        },
        {
          name: "formcontent_2",
          element: "input-textarea",
          label: "Hospital Services/specializes",
          required:false,
          autosize  :true,
          minRows:3,
          maxRows:6,
          containerClassName: "flex flex-col w-full gap-2",
          labelClassName: "text-sm text-gray-500 font-semibold text-start",
          className:
            "w-full rounded-md  px-3 py-2 text-gray-700 font-semibold focus:outline-none",
          visible: true,
        },
        {
          name: "formcontent_3",
          element: "input-textarea",
          label: "Hospital Facilities/Salient Features",
          required:false,
          autosize  :true,
          minRows:3,
          maxRows:3,
          containerClassName: "flex flex-col w-full gap-2",
          labelClassName: "text-sm text-gray-500 font-semibold text-start",
          className:
            "w-full rounded-md  px-3 py-2 text-gray-700 font-semibold focus:outline-none",
          visible: true,
        },
        {
          name: "formcontent_4",
          element: "input-textarea",
          label: "Hospital Address",
          required:false,
          autosize  :true,
          minRows:3,
          maxRows:6,
          containerClassName: "flex flex-col w-full gap-2",
          labelClassName: "text-sm text-gray-500 font-semibold text-start",
          className:
            "w-full rounded-md  px-3 py-2 text-gray-700 font-semibold focus:outline-none",
          visible: true,
        },
        {
          name: "formcontent_5",
          element: "input-textarea",
          label: "Hospital Contact Details",
          required:false,
          autosize  :true,
          minRows:3,
          maxRows:3,
          containerClassName: "flex flex-col w-full gap-2",
          labelClassName: "text-sm text-gray-500 font-semibold text-start",
          className:
            "w-full rounded-md  px-3 py-2 text-gray-700 font-semibold focus:outline-none",
          visible: true,
        },
        {
          name: "formcontent_6",
          element: "input-textarea",
          label: "Hospital Timings",
          required:false,
          autosize  :true,
          minRows:3,
          maxRows:6,
          containerClassName: "flex flex-col w-full gap-2",
          labelClassName: "text-sm text-gray-500 font-semibold text-start",
          className:
            "w-full rounded-md  px-3 py-2 text-gray-700 font-semibold focus:outline-none",
          visible: true,
        },
        {
          name: "formcontent_7",
          element: "input-textarea",
          label: "Our Mission And Values",
          required:false,
          autosize  :true,
          minRows:3,
          maxRows:3,
          containerClassName: "flex flex-col w-full gap-2",
          labelClassName: "text-sm text-gray-500 font-semibold text-start",
          className:
            "w-full rounded-md  px-3 py-2 text-gray-700 font-semibold focus:outline-none",
          visible: true,
        },
        {
          name: "formcontent_8",
          element: "input-textarea",
          label: "Hospital Awards And Recognition",
          required:false,
          autosize  :true,
          minRows:3,
          maxRows:3,
          containerClassName: "flex flex-col w-full gap-2",
          labelClassName: "text-sm text-gray-500 font-semibold text-start",
          className:
            "w-full rounded-md  px-3 py-2 text-gray-700 font-semibold focus:outline-none",
          visible: true,
        },
        {
          name: "formcontent_9",
          element: "input-textarea",
          label: "Doctors Information",
          required:false,
          autosize  :true,
          minRows:3,
          maxRows:6,
          containerClassName: "flex flex-col w-full gap-2",
          labelClassName: "text-sm text-gray-500 font-semibold text-start",
          className:
            "w-full rounded-md  px-3 py-2 text-gray-700 font-semibold focus:outline-none",
          visible: true,
        },
        {
          name: "formcontent_10",
          element: "input-textarea",
          label: "Vaccinations",
          required:false,
          autosize  :true,
          minRows:3,
          containerClassName: "flex flex-col w-full gap-2",
          labelClassName: "text-sm text-gray-500 font-semibold text-start",
          className:
            "w-full rounded-md  px-3 py-2 text-gray-700 font-semibold focus:outline-none",
          visible: true,
        },
        {
          name: "formcontent_11",
          element: "input-textarea",
          label: "Patient Reviews About Hospital",
          required:false,
          autosize  :true,
          minRows:3,
          maxRows:6,
          containerClassName: "flex flex-col w-full gap-2",
          labelClassName: "text-sm text-gray-500 font-semibold text-start",
          className:
            "w-full rounded-md  px-3 py-2 text-gray-700 font-semibold focus:outline-none",
          visible: true,
        },
        {
          name: "formcontent_12",
          element: "input-textarea",
          label: "Other Information",
          required:false,
          autosize  :true,
          minRows:3,
          maxRows:3,
          containerClassName: "flex flex-col w-full gap-2",
          labelClassName: "text-sm text-gray-500 font-semibold text-start",
          className:
            "w-full rounded-md  px-3 py-2 text-gray-700 font-semibold focus:outline-none",
          visible: true,
        },
      ],
    },
    {
      name: "cancel_and_submit",
      fields: [
        {
          name: "cancel",
          label: "Cancel",
          element: "button",
          className:
            "p-2 px-3  text-default-500 bg-gray-200 text-black rounded-md  font-semibold",
        },
        {
          name: "submit",
          label: "Submit",
          element: "button",
          className:
            "p-2 px-3  text-default-500 bg-[#3341bb]  rounded-md text-white font-semibold",
        },
      ],
      className: "flex self-end gap-2",
    },
  ],
});


const Addbottraining = () => {
  const [schema, setSchema] = useState<any>({
    ...adddocs()
  });
  //const [data, setData] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const org_id = Storage.getItem("details")?.["org_id"];

  const getPayLoad = (e: any): FormData | any=> {
    if(e?.schema[0]?.fields[0]?.fields[1]?.value.label !=="form"){
        const formdata = new FormData();
        const name = e?.schema[0]?.fields[0]?.fields[0]?.value;
        const type = e?.schema[0]?.fields[0]?.fields[1]?.value.label;
        const comments = e?.schema[0]?.fields[1]?.value;
        const files = e?.schema[0]?.fields[2]?.value;
        

        let filelinks = [];
        if (files && files.length > 0) {
          filelinks = files.map((f: any) => f.originFileObj);
        }
          
        formdata.append("title", name);
        formdata.append("type", type);
        formdata.append("org_id", org_id);
        formdata.append("comments", comments || "");
        formdata.append("bottraining_docs",filelinks);
        if (filelinks.length > 0) {
          filelinks.forEach((file: File, i: any) => {
            formdata.append(`bottraining_docs`, file);
          });
        }
        return formdata;
    } else{
      const payload:any={
        org_id:org_id,
        title:"",
        type:"",
        headings_and_contents:[]
      }
      payload.title=e?.schema[0]?.fields[0]?.fields[0]?.value;
      payload.type=e?.schema[0]?.fields[0]?.fields[1]?.value.label;
      
      for(let i=3;i<e?.schema[0]?.fields.length;i++){
        const obj:any={
          heading:"",
          content:""
        }
        obj.heading=e?.schema[0]?.fields[i]?.label;
        obj.content=e?.schema[0]?.fields[i]?.value;
        payload.headings_and_contents.push(obj)
      }
      return payload;
    }
    
  };

  const validateData = (fields: any[]): string | false => {
    for (const field of fields) {
      if (
        field.required &&
        ((typeof field.value === "string" && field.value.trim() === "") ||
          (Array.isArray(field.value) && field.value.length === 0) ||
          field.value === null ||
          field.value === undefined)
      ) {
        return field.label;
      }
      if (field.fields) {
        const result = validateData(field.fields);
        if (result) {
          return result;
        }
      }
    }
    return false;
  };


  const [detectChange, setDetectChange] = useState(false)

  // Exit popup's custom hook
  useExitPopUp(detectChange)

  const handleSelectedRecord = async (e: any) => {

    if (e?.name === "document_type") {
      if(e?.value?.value !== "form"){
        const valueToBeUpdated = e?.value?.value;

        setSchema((prev:any)=>{
          const updatedSchema=prev.schema.map((field:any)=>{
            if(field.name==="upload_fields"){
              field.fields=field.fields.map((ffield:any)=>{
                if(ffield.name==="files"){
                  ffield.accept=valueToBeUpdated;
                  ffield.visible=true;
                }
                if(ffield.name==="comments"){
                  ffield.visible=true;
                }
                if(ffield.name.includes("formcontent_")){
                  ffield.visible=false;
                  ffield.required=false
                }
                return ffield;
              })
            }
            return field;
          })
          return {...prev,updatedSchema:schema}
        })
      }
      else{
        setSchema((prev:any)=>{
          const updatedSchema=prev.schema.map((field:any)=>{
            if(field.name==="upload_fields"){
              field.fields=field.fields.map((ffield:any)=>{
                if(ffield.name==="files"){
                  ffield.visible=false;
                  ffield.required=false;
                }
                if(ffield.name==="comments"){
                  ffield.visible=false;
                }
                if(ffield.name.includes("formcontent_")){
                  if(ffield.name==="formcontent_1"){
                    ffield.required=true;
                  }
                  ffield.visible=true;
                }
                return ffield;
              })
            }
            return field;
          })
          return {...prev,schema:updatedSchema}
        })
      }
    }
    if (e?.name === "submit") {
      setDetectChange(false)
      const validationResponse = validateData(schema.schema);
      if (validationResponse) {
        if (validationResponse === "Upload")
          toast.error("Please upload the files", {
            position: "top-center",
            autoClose: 800,
          });
        else
          toast.error(`${validationResponse} cannot be empty`, {
            position: "top-center",
            autoClose: 800,
          });
      } else {
        dispatch(setLoading(true))
        const payLoad:any = getPayLoad(schema);
        
        try {
          let response:any={};
          if(schema?.schema[0]?.fields[0]?.fields[1]?.value.label!=="form"){
             response = await Utils.makeFormdataApiCall(
              "/botTraining",
              "POST",
              payLoad
            );
          }else{
            response=await Utils.makeApiCall(
              "/botTraining",
              "POST",
              payLoad
            );
          }
          
          
          if (response.status) {
            toast.success(response.message, {
              position: "top-center",
              autoClose: 2000,
            });
            navigate("/bot-training");
          } else {
            if (response.message)
              toast.error(response.message, {
                position: "top-center",
                autoClose: 2000,
              });
          }
        } catch (error) {
          toast.error("An error occurred, please try again later.", {
            position: "top-center",
            autoClose: 2000,
          });
        }
        finally {
          dispatch(setLoading(false))
        }
      }
    }
    if (e?.name === "cancel") {
      navigate("/bot-training");
    }
  };

  return (
    <div className="">
      <ElementExecutor
        data={schema}
        setData={(e: any) => {
          setSchema({ ...e });
        }}
        selectedRecord={(e: any) => {
          handleSelectedRecord(e);
          if(["name", "document_type", "comments", "files", "forminput_1", "formcontent_1", "forminput_2", "formcontent_2", "forminput_3", "formcontent_3",  "forminput_4", "formcontent_4",  "forminput_5", "formcontent_5",  "forminput_6", "formcontent_6",  "forminput_7", "formcontent_7"].includes(e?.name) ){
            setDetectChange(true)
          }
        }}
      />
    </div>
  );
};

export default Addbottraining;
