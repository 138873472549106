import React from 'react'
import Logo from '../../src/assets/images/apex-star-logo.svg'
const Loader = () => {
    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-white">
            <div className="relative flex justify-center items-center">
                <div className="absolute animate-spin w-16 h-16 border-4 border-gray-300 border-t-4 border-t-white rounded-full "/>
                <img src={Logo} alt="Logo" />
            </div>
        </div>
    )
}

export default Loader
