"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SplitButton = void 0;
const react_1 = __importStar(require("react"));
const icons_1 = require("../constants/icons");
const antd_1 = require("antd");
const SplitButton = (props) => {
    const [position, setPosition] = (0, react_1.useState)('');
    const [hoveredItem, setHoveredItem] = (0, react_1.useState)(null);
    const handleChange = (e) => {
        setPosition(e.target.value);
        if (props.onChange) {
            props.onChange(e.target.value);
        }
    };
    const handleMouseEnter = (value) => {
        setHoveredItem(value);
    };
    const handleMouseLeave = () => {
        setHoveredItem(null);
    };
    const RadioButton = (eachItem) => {
        // Determine if the item is either hovered or selected
        const isActive = hoveredItem === eachItem.value || position === eachItem.value;
        // Define the style for the icon when active
        const activeIconStyle = {
            filter: 'brightness(1.2) contrast(1.5) saturate(2.5) hue-rotate(200deg) sepia(1) saturate(10) hue-rotate(-160deg)',
        };
        return (react_1.default.createElement(antd_1.Radio.Button, { value: eachItem.value, key: eachItem.value, onMouseEnter: () => handleMouseEnter(eachItem.value), onMouseLeave: handleMouseLeave },
            react_1.default.createElement("span", { className: props.className ? props.className : '' },
                eachItem.icon ? (react_1.default.createElement("span", { className: props.iconsClassName }, eachItem.isSVGStylesOverride === false ? (react_1.default.createElement("img", { src: eachItem.icon, className: props.iconsClassName, style: isActive ? activeIconStyle : {} })) : eachItem.icon !== undefined ? (react_1.default.createElement("span", { style: isActive ? activeIconStyle : {} }, icons_1.icons[eachItem.icon])) : (''))) : (''),
                eachItem.label)));
    };
    return (react_1.default.createElement(antd_1.Radio.Group, { value: position, onChange: handleChange }, props.splitButton?.map((eachItem) => eachItem.tooltip ? (react_1.default.createElement(antd_1.Tooltip, { title: eachItem.tooltip, key: eachItem.value }, RadioButton(eachItem))) : (RadioButton(eachItem)))));
};
exports.SplitButton = SplitButton;
