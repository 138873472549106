"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectElement = void 0;
const react_1 = __importStar(require("react"));
const antd_1 = require("antd");
// Function to filter options based on user input
const filterOptions = (input, option) => {
    return (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
};
// Custom tag rendering function
const customTagRender = (props) => {
    const { label, closable, onClose, color } = props;
    const onPreventMouseDown = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };
    return (react_1.default.createElement(antd_1.Tag, { color: color, onMouseDown: onPreventMouseDown, closable: closable, onClose: onClose, style: { marginRight: 3 } },
        label,
        " "));
};
const SelectElement = (props) => {
    const [selectedValue, setSelectedValue] = (0, react_1.useState)(props.value);
    (0, react_1.useEffect)(() => {
        setSelectedValue(props.value);
    }, [props.value]);
    const handleChange = (value) => {
        if (!value) {
            const newValue = props.defaultValue ? props.defaultValue : undefined;
            setSelectedValue(newValue);
            if (props.onChange && newValue !== selectedValue) {
                props.onChange(newValue);
            }
            return;
        }
        if (props.onChange) {
            if (typeof value === 'string') {
                const matchedOption = props.options?.find((eachOption) => eachOption.value === value);
                if (!matchedOption) {
                    props.onChange({ value, label: value });
                }
                else {
                    props.onChange(matchedOption);
                }
            }
            else {
                const selectedOptions = value
                    ? value.map((eachVal) => {
                        return (props.options?.find((eachOption) => eachOption.value === eachVal) || { value: eachVal, label: eachVal });
                    })
                    : [];
                props.onChange(selectedOptions);
            }
        }
        setSelectedValue(value);
    };
    return (react_1.default.createElement("div", { className: props.containerClassName },
        props.label && (react_1.default.createElement("p", { className: props.labelClassName },
            props.label,
            ' ',
            props.required ? react_1.default.createElement("span", { style: { color: 'red' } }, "*") : '')),
        react_1.default.createElement(antd_1.Select, { open: props.isopen, options: props.options, placeholder: props.placeholder, allowClear: props.allowClear, filterOption: filterOptions, showSearch: true, className: props.className, onChange: handleChange, mode: props.mode, value: selectedValue, disabled: props.disabled, status: props.required && props.errorText ? 'error' : '', tokenSeparators: [','], dropdownRender: props.dropdownRender && props.dropdownRender, optionRender: props.optionRender && props.optionRender, tagRender: props.customColor
                ? (tagProps) => {
                    const foundOption = props.options?.find((opt) => opt.value === tagProps.value);
                    const color = foundOption?.color || 'default';
                    return customTagRender({
                        ...tagProps,
                        label: typeof tagProps.label === 'string' ? tagProps.label : '',
                        color: color,
                    });
                }
                : undefined }),
        props.required ? (react_1.default.createElement("p", { className: props.errorClassName }, props.errorText && props.errorText)) : ('')));
};
exports.SelectElement = SelectElement;
// import React from 'react';
// import { Select } from 'antd';
// import { ElementType } from '../Types/types';
// import '../styles/index.css';
// // import { icons } from '../constants/icons';
// export const SelectElement = (props: ElementType) => {
//   // const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//   //   if (props.onChange) {
//   //     props.onChange(e.target.value);
//   //   }
//   // };
//   return (
//     <div className={props.containerClassName}>
//       {props.label && (
//         <p className={props.labelClassName}>
//           {props.label}{' '}
//           {props.required ? <span style={{ color: 'red' }}>*</span> : ''}
//         </p>
//       )}
//       <div>
//         <Select
//           placeholder={props.placeholder}
//           allowClear={props.allowClear}
//           id={props.name}
//           value={props.value as string}
//           // suffix={props.suffix}
//           // prefix={
//           //   props.isSearch ? (
//           //     <div className={props.iconsClassName}>
//           //       {icons[props.icon as string]}
//           //     </div>
//           //   ) : (
//           //     props.prefix
//           //   )
//           // }
//           // type={props.type}
//           options={props.options}
//           disabled={props.disabled}
//           status={props.required && props.errorText ? 'error' : ''}
//           className={props.className}
//           // onChange={(e) => {
//           //   handleChange(e);
//           // }}
//         />
//       </div>
//       {props.required ? (
//         <p className={props.errorClassName}>
//           {props.errorText && props.errorText}
//         </p>
//       ) : (
//         ''
//       )}
//     </div>
//   );
// };
