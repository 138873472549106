import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { ElementExecutor } from '@apexcura/core';
import { toast } from 'react-toastify';
import { CONSTANTS } from '../utils/app-constants';
import Utils from '../utils';
import Storage from '../utils/local-storage';
import { useDispatch, useSelector } from 'react-redux';
import { getPagePermissions } from '../redux/auth/authSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { setActiveMenu, setTopbar } from '../redux/uiSchema/uiSchemaSlice';
import {Tooltip,Avatar, Popconfirm,Popover, Select } from "antd"


import type { SelectProps } from 'antd';
import { FaRegFaceSmileBeam } from "react-icons/fa6";
import { MdCall, MdTextFields } from "react-icons/md";
import { setLoading } from '../redux/appState/appStateSlice';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { setDateRange, setFilters, setSearchText ,setPagination,setCallType} from '../redux/calls/callSlice';
import Loading from '../components/Loading';
import DetailsModal from '../components/DetailsModal';
import { AddAppointmentComponent } from './appointments/AddAppointmentCompontent';
const { BASE_URL, FAILURE_MSG, CATCH_ERROR_MSG, AUTO_CLOSE } = CONSTANTS;

const newCards:any=[
    {
        name: 'avg_sent_score',
        visible: true,
        className: 'rounded-[4px] bg-white flex gap-2 items-center justify-between p-[10px] shadow-[0px_4px_8px_0px_rgba(0,0,0,0.05)]',
        element: '',
        fields: [
            {
              name:"avg_sent_score_icon_and_value",
              className:"flex gap-2  justify-center items-center",
              fields:[
                {
                  name: 'avg_sent_score_icon',
                  element: 'image',
                  img:`${BASE_URL}/api/public/assets/images/sentiment_score.svg`,
                  imgClassName:"w-8 h-8  cursor-default",
                  visible: true,
                },
                {
                  name: 'avg_sent_score_text',
                  className: 'text-sm font-semibold text-[#37474F]',
                  element: 'div',
                  label: <span>Satisfaction Score <small>(Avg)</small></span>,
                  visible: true,
                },
              ]
            },
            {
              name: 'avg_sent_score_value',
              className:'text-lg font-semibold text-[#254AAA]',
              element: 'div',
              label: "0%",
              visible: true,
          },
        ],
    },
    {
      name: 'avg_call_quality',
      visible: true,
      className: 'rounded-[4px] bg-white  flex gap-2 items-center justify-between p-[10px] shadow-[0px_4px_8px_0px_rgba(0,0,0,0.05)]',
      element: '',
      fields: [
          {
            name:"avg_call_quality_icon_and_value",
            className:"flex gap-2 justify-center items-center",
            fields:[
              {
                name: 'avg_call_quality_icon',
                element: 'image',
                img:`${BASE_URL}/api/public/assets/images/callback_quality.svg`,
                imgClassName:"w-8 h-8  cursor-default",
                visible: true,
              },
              {
                name: 'avg_call_quality_text',
                className: 'text-sm font-semibold text-[#37474F]',
                element: 'div',
                label: <span>Avg Call Quality </span>,
                visible: true,
              },
            ]
          },
          {
            name: 'avg_call_quality_value',
            className:'text-lg font-semibold text-[#254AAA]',
            element: 'div',
            label: 0,
            visible: true,
        },
      ],
    },
]

const Calls = () => {
  const [selectedCell, setSelectedCell] = useState<any>()
  const[ multiselectschema, setMultiselectschema] = useState<any>({
    className: 'w-full',
    schema: [{
      name: "call-tags",
      element: "single-select",
      placeholder: "",
      containerClassName: "w-full flex flex-col col-span-full",
      className: "rounded-md text-gray-700 font-semibold focus:outline-none min-w-[150px] max-w-[300px] call-tags",
      visible: true,
      mode: "tags",
      value: null,
      options: [],
      disabled: false,
      customColor:true,
    }]
  })
  const [tagOptions, setTagOptions] = useState<SelectProps['options']>([]);
  const [selectedRecord, setSelectedRecord] = useState<any>();
  
  const {dateRange,searchText,filters,pagination,calltype,callerData} = useSelector((store:any) => store.calls);
  const {page,pageSize}=pagination
    const [schema, setSchema] = useState<any>({
        className: 'w-full',
        schema: [
          {
              name: 'call_details',
              visible: true,
              className: 'grid grid-cols-6 gap-2 font-semibold w-full',
              fields:[]
          },
          {
            name: 'filters',
            fields: [
              {
                name:"date_and_search",
                className:"flex gap-2 justify-center items-center ",
                fields:[
                  {
                    name: "search",
                    label: "",
                    placeholder: "Search by Customer Number",
                    isSearch:true,
                    element: "input-text",
                    value: searchText,
                    containerClassName: 'w-60 flex flex-col rounded-3xl overflow-hidden',
                    className: 'w-full rounded-3xl p-2 px-3 font-medium bg-white overflow-hidden',
                    icon:"search",
                    iconsClassName:"w-[15px] mr-1 flex justify-center items-center",
                    
                  },
                  {
                    name:"segments",
                    element:"segmented",
                    segmentedOptions:[
                      {
                          label:(<Tooltip title="One Day">1D</Tooltip>),
                          value:"TD"
                      },
                      {
                          label:(<Tooltip title="One Week">1W</Tooltip>),
                          value:"TW"
                      },
                      {
                          label:(<Tooltip title="One Month">1M</Tooltip>),
                          value:"TM"
                      },
                    ],
                    visible:true,
                    value:"TD",
                  },
                  {
                    name:"left_button",
                    label: '',
                    element: 'button',
                    icon: 'left',
                    action: '',
                    iconsClassName: 'size-2',
                    className:"pb-1"
                    // isSVGStylesOverride: false,
                  },
                  {
                    name: 'date',
                    label: '',
                    placeholder: 'Select a date',
                    element: 'daterangepicker',
                    value:dateRange,
                    className: 'flex-1 w-[240px]',
                  },
                  {
                    name:"right_button",
                    label: '',
                    element: 'button',
                    icon: 'right',
                    action: '',
                    iconsClassName: 'size-2',
                    className:"pb-1"
                    // isSVGStylesOverride: false,
                  },
                ]
              },
              {
                name:"refresh_and_download",
                className:"flex gap-2 justify-center items-center ",
                fields:[
                  {
                    name: 'btn_refresh',
                    label: 'Refresh',
                    element: 'button',
                    icon: 'refresh',
                    className: 'secondary-button',
                    action: 'download_list',
                    iconsClassName: 'size-4',
                  },
                  {
                    name: 'btn_download',
                    label: 'Download',
                    element: 'button',
                    icon: 'download',
                    visible: false,
                    className: 'secondary-button',
                    action: 'download_list',
                    iconsClassName: 'size-4',
                  },
                ]
              },
            ],
            className: 'flex justify-between items-center gap-2 bg-white p-4 rounded-none rounded-t-[4px] mt-2',
          },
          {
            count:0,
            className: 'bg-white p-4 rounded-none rounded-b-[4px] pt-0',
            name: "table",
            element: 'table',
            indexClickable:false,
            loading: true,
            variant: 'plain',
            expandIconStyles: true,
            size: 'small',
            pagination: true,
            value:[{name:"pagination",page,pageSize},{name:"filters",value:{status:filters.status}}],
            rowClick: true,
            view: true,
            visible: true,
            // value:null,
            thead: [
              {
                name:"Name",label:"Customer",key:"Name",fixed:"left",
                render:(e:any,record:any)=>{
                  return (
                    <div className="flex gap-1 items-center">
                      {
                        record?.Name ? <Avatar size={"small"} style={{backgroundColor:`${Utils.stringToColour(e)}`}}> 
                        <p className={`capitalize`}>{Utils.initials(e)}</p>
                </Avatar>: <p className={`capitalize`}>{Utils.initials(e)}</p>
                      }
                      <div className={"capitalize"}>{e}<p className='text-xs'>({record?.Direction.toLowerCase()==="outgoing"? record?.CallTo :record?.CallFrom || "--"})</p></div>
                    </div>
                  )
                }
              },
              // { name: 'From', label: 'From', key: 'From' },
              { name: 'DialWhomNumber', label: 'Agent', key: 'DialWhomNumber', render: (e:any,record:any) =>{
                return( 
                  <p className='text-center'>{record?.Direction.toLowerCase()==="outgoing"? record?.CallFrom :e || "--"}</p>
                ) }},
              // { name: 'To', label: 'IVR', key: 'To' },
              // {name: 'Direction', label: 'Direction', key: 'Direction'},
              {
                name: 'Direction',
                label: 'Direction',
                key: 'Direction',
              },
              {
                name: 'StartTime',
                label: 'Arrived on',
                key: 'StartTime',
              },
              {
                name: 'formattedDuration',
                label: 'Duration',
                key: 'formattedDuration',
                render: (e: any) => <p className='text-center'>{e && e!=="0"?e : "--"}</p>
              },
              // {
              //   name:"status",
              //   label:"Status",
              //   key:"status",
              //   filters: [
              //     { text: 'None', value: 'None' },
              //     { text: 'Open', value: 'Open' },
              //     { text: 'Pending', value: 'Pending' },
              //     { text: 'Closed', value: 'Closed' },
              //   ],
              //   render:(e:any,record:any)=>{
              //     const colors:any = {
              //       "None": "bg-[#29AAE1]/10 text-[#29AAE1]",
              //       "Open": "bg-fuchsia-500/5 text-fuchsia-500",
              //       "Pending": "bg-yellow-500/10 text-yellow-500",
              //       "Closed/Callback-done": "bg-green-500/5 text-green-500",
              //       "Closed/Call-back done": "bg-green-500/5 text-green-500",
              //       "Callback": "bg-fuchsia-500/5 text-fuchsia-500",
              //       "Closed": "bg-green-500/5 text-green-500  ",
              //   };
              //     const bgColor:string = colors[record?.status];
              //     return <span className={`w-full p-1 px-2 rounded-md font-medium ${bgColor}`}>{e}</span>
              //   }
              // },
              { name: 'status', label: 'Status',clickable:false, key: 'status',
                // filters:[
                //   { text: 'Call Back Request', value: 'call_back' },
                //   { text: 'Ambulance Request', value: 'ambulance_request' },
                //   { text: 'Call Was Successful', value: 'answered_calls' },
                //   { text: 'Client Hungup', value: 'hangup_sessions' },
                //   { text: 'Missed Call', value: 'missed_calls' },],
                // filters:[
                //   { text: 'Call Back Request', value: 'call_attempt' },
                //   { text: 'Answered', value: 'answered' },
                //   { text: 'Missed', value: 'missed' },],
                render:(e:any,record:any)=>{
                const colors:any = {
                  "call_attempt": "bg-[#29AAE1]/5 text-[#29AAE1]",
                  "callback": "bg-[#29AAE1]/5 text-[#29AAE1]",
                  "Ambulance Request": "bg-[#BF0E0E]/5 text-[#BF0E0E]",
                  "answered": "bg-[#4DA723]/5 text-[#4DA723]",
                  "Client Hungup": "bg-[#F59F0D]/5 text-[#F59F0D]",
                  "missed": "bg-[#F9A07A]/5 text-[#F37748]"
                };
                const status = record?.status === "" ? "--" : e;
                const bgColor:string = colors[record?.status_id] || "bg-[#F2F2F2]/50 text-default-300"
                return <div className='flex gap-2 items-center'> 
                  <span className={`w-full p-1 px-2 rounded-md font-medium ${bgColor}`}>{status}</span> {record?.status_id === "missed" && record?.isParent && 
                  <div
                    role="button"
                    tabIndex={0}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSelectedRecord({ name: "call", value: record });
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" || e.key === " ") {
                        e.stopPropagation();
                        handleSelectedRecord({ name: "call", value: record });
                      }
                    }}
                  >
                    <Tooltip title={`Call ${record?.Name}`}>
                      <img
                        src={`${BASE_URL}/api/public/assets/images/call.svg`}
                        alt="call the record"
                        className="w-[20px] h-[15px] mt-1 cursor-pointer"
                      />
                    </Tooltip>
                  </div>
                }
                </div>
                
              } },
              // {
              //   name:"",
              //   label:"",
              //   key:"outbound calls",
              //   sortable:false,
              //   render:(e:any,record:any)=>{
              //     return (
              //       record?.status_id === "missed" && record?.isParent && (
              //         <div
              //           role="button"
              //           tabIndex={0}
              //           onClick={(e) => {
              //             e.stopPropagation();
              //             handleSelectedRecord({ name: "call", value: record });
              //           }}
              //           onKeyDown={(e) => {
              //             if (e.key === "Enter" || e.key === " ") {
              //               e.stopPropagation();
              //               handleSelectedRecord({ name: "call", value: record });
              //             }
              //           }}
              //         >
              //           <Tooltip title="Call">
              //             <img
              //               src={`${BASE_URL}/api/public/assets/images/call.svg`}
              //               alt="call the record"
              //               className="w-[20px] h-[15px] mt-1 cursor-pointer"
              //             />
              //           </Tooltip>
              //         </div>
              //       )
              //     ) 
              //   }
              // },
              {
                name: 'call-tags',
                label: 'Call Type',
                key: 'call-tags',
                render: (e: any, record: any) => {
                  let select : any;
                  const flag = true
                  return (
                    <div role="presentation" className={record?._id} id={record?._id}
                      onClick={(e) => {
                      e.stopPropagation();
                      // const targetElement = document.getElementById(record?._id);
                      
                      // if (targetElement instanceof HTMLElement) {
                      //     select = targetElement.getAttribute('id');
                      // }
                      // flag = !(select == record?._id)
                      // setSelectedCell(record?._id);
          
                      // console.log(record?._id, select, flag);
                      
                      // setMultiselectschema((prev:any) => {
                      //   const updatedSchema = prev?.schema?.map((f:any) => {
                      //     f.disabled = true
                      //   })
                      //   return {...prev, updatedSchema}
                      // })
                    }}>
                      <ElementExecutor
                        data={displayTags(record, flag)}
                        selectedRecord={(e: any) => {
                          handleSelectedRecord({name: e.name, value: e.value, call_id: record?._id});
                        }}
                      />
                    </div>
                  );
                }
                
              },
              {
                name: 'sentiment_score',
                label: 'Satisfaction Score',
                key: 'sentiment_score',
                render: (e: any) => <p className='text-center'>{e ? `${(e*100).toFixed(2)}%` : "--"}</p>
              },
              {
                name: 'quality_score',
                label: "Quality Score",
                key: 'quality_score',
                sortable:false,
                render:(e:any,record:any)=>{
                  const colors:any = {
                    "<50": "bg-[#BF0E0E]/5 text-[#BF0E0E]",
                    "81-100": "bg-[#4DA723]/5 text-[#4DA723]",
                    "51-80": "bg-[#F9A07A]/5 text-[#F37748]"
                  };
                  const call_duration=parseFloat((parseInt(record?.DialCallDuration)*50/90).toFixed(2)) > 50?50:parseFloat((parseInt(record?.DialCallDuration)*50/90).toFixed(2))
                  // console.log(call_duration)
                  // const value:any=(Call Duration in secs*50/90))+((record?.call_duration)*50)
                  const content:any=<div className='flex flex-col gap-1 w-[300px] break-words'>
                    <p className='flex gap-2'>Call Duration Score : <span className='font-semibold'> {call_duration}</span></p>
                    <p className='flex gap-2'>Sentiment Score :<span className='font-semibold'> { (record?.sentiment_score*50).toFixed(2) }</span></p>
                    {/* <p>Formula:(ceiling to 50 (Call Duration in secs*50/90))+(Sentiment Score*50) </p> */}
                    <p className='flex gap-2'>Total value:<span className='font-semibold'>{e}</span></p>
                  </div>
                  const bgColor:string =e<=50? colors["<50"]: e>=51 && e<=80?colors["51-80"]: colors["81-100"]
                  if(record?.sentiment_score && record?.quality_score){
                    return <div className='flex justify-center items-center gap-1'>
                                  <Popover content={content}><span className={`p-1 px-2 rounded-md font-medium ${bgColor}`}>{e || "--"}</span></Popover>
                                </div>
                          
                  }
                  return <div className='flex justify-center'><span className={`p-1 px-2 rounded-md font-medium ${bgColor}`}>{e || "--"}</span></div>
                }
              },
            ],
            tbody: [],
            modalContent: {
              className: "flex flex-col gap-2 overflow-auto max-h-[400px] p-[10px]",
              fields: [],
            },
          },
          {
            name: 'view_modal_container',
            containerClassName:"  ",
            className: 'rounded-lg !w-full',
            element: "modal",
            width: 600,
            visible: false,
            fields:[
              {
                name: 'modal_title',
                className:
                  'w-full bg-primary/5 p-2 pl-4 flex items-center justify-between',
                fields: [
                  {
                    name: 'modal_title_key',
                    element: 'div',
                    label: 'Call Transcripts',
                    className:
                      'font-semibold text-lg text-primary',
                  },
                  {
                    name: 'modal_close',
                    element: 'button',
                    label: 'X',
                    className:
                      'w-[40px] h-[40px] flex justify-center items-center rounded-full bg-primary text-xl text-white',
                  },
                ],
              },
              {
                name: 'modal_body',
                className:
                  'flex flex-col p-4 !max-h-[80vh] overflow-auto gap-3',
                fields: [
                  {
                    name:"audio",
                    element:"div",
                    label:"",
                    className:"h-[20%] w-full"
                  },
                  {
                    name:"audio_text",
                    element:"div",
                    label:"Call Transcription",
                    className:"text-xl font-medium text-center"
                  },
                  {
                    name: "audio_transcription",
                    className: "flex flex-col gap-1 ",
                    fields: [

                    ]
                  },
                  // {
                  //   name:"audio_transcription",
                  //   element:"div",
                  //   label:"",
                  //   className:"h-[60%] w-full overflow-scroll [&::-webkit-scrollbar]:hidden font-base text-base text-black"
                  // },
                ],
              },
            ]
          },
          {
            name: "tag-modal",
            containerClassName:"!w-[50%]",
            className:
                'rounded-lg',
            visible:false,
            element:"modal",
            fields: [
              {
                  name: 'modal_title',
                  className:
                      'w-full bg-[#F2F2F2] p-2 pl-4 flex items-center rounded-t-lg justify-between',
                  fields: [
                      {
                          name: 'modal_title_key',
                          element: 'div',
                          label: 'Add Tag',
                          className:
                              'font-semibold text-lg text-primary',
                      },
                      {
                          name: 'modal_close',
                          element: 'button',
                          label: 'X',
                          className:
                              'w-[40px] h-[40px] flex justify-center items-center rounded-full bg-primary text-xl text-white',
                      },
                  ],
              },
              {
                name: "modal-body",
                className:
                      'w-full bg-[#F2F2F2] p-2 pl-4 flex flex-col items-center rounded-t-lg',
                fields: [
                  {
                    name: "tagname",
                    element: "input-text",
                    placeholder: "",
                    label: "Tag",
                    containerClassName: "w-full flex flex-col",
                    labelClassName:
                      "text-sm mb-1 text-gray-500 font-semibold text-start",
                    className:
                      "rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none",
                    required: true,
                    visible: true,
                  },
                  {
                    name: "cancel_and_submit_update",
                    className: "w-full justify-end flex gap-2 pt-4",
                    fields: [
                      {
                        name: "cancel_feature_config",
                        label: "Cancel",
                        element: "button",
                        className:
                          "p-2 px-3  text-default-500 bg-gray-200 text-black rounded-md font-semibold",
                      },
                      {
                        name: "submit_feature_config",
                        label: "Save",
                        element: "button",
                        className:
                          "p-2 px-5  text-default-500 bg-primary  rounded-md text-white font-semibold",
                      },
                    ],
                   
                  },
                ]
              },
              
            ]
          },
          {
            name:'appointment-booking-modal',
            element:'modal',
            visible:false,
            containerClassName:'!w-[96%]',
            className:"!w-full",
            fields: [
              {
                  name: 'modal-title',
                  className:
                      'w-full bg-primary/5 p-2 pl-4 flex items-center rounded-t-lg justify-between',
                  fields: [
                      {
                          name: 'modal-title-key',
                          element: 'div',
                          label: 'Book Appointment',
                          className:
                              'font-semibold text-lg text-primary',
                      },
                      {
                          name: 'appointment-booking-modal-close',
                          element: 'button',
                          label: 'X',
                          className:
                              'w-[40px] h-[40px] flex justify-center items-center rounded-full bg-primary text-xl text-white',
                      },
                  ],
              },
              {
                  name: 'modal-body',
                  className: 'flex flex-col max-h-[500px] p-4 overflow-auto gap-1',
                  fields: [
                      
                  ],
              },
            ],
          },
          {
            name: "outbound_calls_modal",
            containerClassName:"!w-[20%] z-[1002] fixed mt-[30px] right-5",
            style:{ zIndex: 1001 },
            className:
                'rounded-lg flex flex-col items-center pb-4',
            visible:false,
            mask: false, 
            maskClosable: false,
            draggable:true,
            element:"modal",
            fields:[
              {
                name:"end_button",
                className:"self-end cursor-pointer rounded-full mt-3 mr-3 ",
                element:"div",
                label:<Popconfirm 
                title="Are you sure you want to close the modal?" 
                onConfirm={()=> handleSelectedRecord({name: 'close_call_modal'})}
                okButtonProps={{
                  style: { backgroundColor: 'gray' }
                }}
                okText="Close"
                icon={<ExclamationCircleOutlined style={{ color: 'red' }} />}
                >
                <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18 6L6 18" stroke="#000000" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M6 6L18 18" stroke="#000000" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>


              </Popconfirm>
              },
              {
                name:"avatar",
                className:"mt-2",
                label:<Avatar size={70} style={{backgroundColor:`${Utils.stringToColour("")}`,fontSize: '30px'}}> 
                              <p className={`capitalize`}>{Utils.initials("")}</p>
                      </Avatar>,
                element:"div"
              },
              {
                name:"name",
                label:"",
                className:"text-lg mt-2 font-semibold",
                element:"div"
              },
              {
                name:"mobile_number",
                label:"",
                className:"text-md font-semibold text-gray-4",
                element:"div"
              },
              {
                name:"status",
                label:"",
                className:"text-sm font-light mt-2",
                element:"div"
              },
              {
                name:"red_and_green_buttons",
                className:"flex  items-center gap-2 mt-1",
                fields:[
                  {
                    name:"green_button",
                    className:"rounded-full bg-gray-400 p-4 cursor-pointer",
                    element:"button",
                    label:<svg width="22" height="22" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M0 2.57143C0 1.15127 1.15127 0 2.57143 0H3.74711C4.48457 0 5.1274 0.501906 5.30627 1.21735L6.25409 5.00865C6.41092 5.63597 6.17652 6.29618 5.65922 6.68416L4.5504 7.51577C4.43526 7.60213 4.41023 7.72858 4.44257 7.81687C5.41587 10.4738 7.52615 12.5841 10.1831 13.5574C10.2714 13.5898 10.3979 13.5647 10.4842 13.4496L11.3158 12.3408C11.7038 11.8235 12.364 11.5891 12.9913 11.7459L16.7826 12.6937C17.4981 12.8726 18 13.5154 18 14.2529V15.4286C18 16.8487 16.8487 18 15.4286 18H13.5C6.04416 18 0 11.9558 0 4.5V2.57143Z" fill="#000000"/>
                    </svg>,
                    loading:true,
                    visible:false,
                  },
                  {
                    name:"end_button",
                    className:"bg-gray-400 p-4  cursor-pointer rounded-full ",
                    element:"div",
                    visible:false,
                    label:<Popconfirm 
                    title="Are you sure you want to close the modal?" 
                    onConfirm={()=> handleSelectedRecord({name: 'close_call_modal'})}
                    okButtonProps={{
                      style: { backgroundColor: 'gray' }
                    }}
                    okText="Close"
                    icon={<ExclamationCircleOutlined style={{ color: 'red' }} />}
                    >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M18 6L6 18" stroke="#ffffff" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                      <path d="M6 6L18 18" stroke="#ffffff" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>


                  </Popconfirm>
                  }
                ]
              }
            ]
          }
        ],
    });
    // const [dateRange, setDateRange] = useState<[string, string]>([
    //   moment().subtract(7, 'days').format('DD-MM-YYYY'),
    //   moment().format('DD-MM-YYYY'),
    // ])
    // const [tablePageDetails, setTablePageDetails] = useState<any>({page:1, pageSize: 10})
    const appointmentBookingModal = (mobile:string, name:string)=>{
      console.log('here')
      return {
              element:'div',
              label:<div><AddAppointmentComponent mobile={mobile} name={name}/></div>
            }
    }

    const [agentDateRange, setAgentDateRange] = useState<[string, string]>([
      moment().subtract(7, 'days').format('DD-MM-YYYY'),
      moment().format('DD-MM-YYYY'),
    ])

    // const [searchText, setSearchText] = useState('');
    const [agentSearchText, setAgentSearchText] = useState('');
    const [outgoingData,setOutgoingData]=useState<any>({});
    // const [pagination,setPagination]=useState({pagenum:1,limit:10});
    // const [filters,setFilters]=useState({status:[]})
    const dispatch=useDispatch()
    const location=useLocation()
    const navigate=useNavigate();
    // const [calltype,setCalltype]=useState("");
    const tagDebounceTime = useRef<ReturnType<typeof setTimeout> | null>(null);

    const org_id = Storage.getItem("details")?.["org_id"];

    const pagePermissions = useSelector(getPagePermissions)
    const ws = useRef<WebSocket | null>(null);
    const heartbeatInterval = useRef<NodeJS.Timeout | null>(null);
    const reconnectInterval = useRef<NodeJS.Timeout | null>(null);
    const [callsData, setCallsData] = useState([]);
    const serverUrl = CONSTANTS.SOCKET_URL;

    const change_calls_data = (data: any) => {
      const download = pagePermissions.find((r:any) => r.route === location.pathname)?.download || false;
      const add = pagePermissions.find((r:any) => r.route === location.pathname)?.add || false;
      const view = pagePermissions.find((r:any) => r.route === location.pathname)?.view || false;
      setSchema((prev:any)=>{
        const updatedschema=prev.schema.map((field:any)=>{
          if(field.name==="call_details"){
            field.fields=field.fields.map((ffield:any)=>{
              ffield.fields=ffield.fields.map((fffield:any)=>{
                if(fffield.name==="total_calls_value"){
                  fffield.label=data?.counts?.totalCalls;
                }
                if(fffield.name==="answered_calls_value"){
                  fffield.label=data?.counts?.totalAnsweredCalls;
                }
                if(fffield.name==="total_missed_calls_value"){
                  fffield.label=data?.counts?.totalMissedCalls;
                }
                if(fffield.name==="total_callback_requests_value"){
                  fffield.label=data?.counts?.totalCalledBacks;
                }
                if(fffield.name==="total_ambulance_requests_value"){
                  fffield.label=data?.counts?.totalAmbulanceRequest;
                }
                if(fffield.name==="hangup_calls_value"){
                  fffield.label=data?.counts?.totalHangupSessions;
                }
                if(fffield.name==="avg_sent_score_value"){
                  fffield.label= <>
                  {data?.counts?.avgSentimentScore * 100}
                  <span style={{ fontSize: "12px" }}>%</span>
                </>
                }
                if(fffield.name==="avg_call_quality_value"){
                  fffield.label=data?.counts?.avgQualityScore;
                }
                return fffield;
              })
              return ffield;
            })
          }
          if(field.name==="filters"){
            field.fields=field.fields.map((ffield:any)=>{
              if(ffield.name==="refresh_and_download"){
                ffield.fields=ffield.fields.map((fffield:any)=>{
                  if(fffield.name==="btn_download"){
                    fffield.visible = download
                  }
                  return fffield;
                })
              }
              return ffield;
            })
          }
          if(field.name==="table"){
            field.loading = false;
            field.count = data?.total;
            field.tbody=data?.data?.map((item:any) => {
              item.StartTime = moment(item.StartTime, "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY HH:mm");
              if(item?.children){
                item.children=item.children.map((field:any)=>{
                  field.StartTime=moment(field.StartTime, "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY HH:mm");
                  return field;
                })
              }
              // item["sentiment_score"]=Math.floor(Math.random() * 100) + 1;
              // item["quality_score"]=Math.floor(Math.random() * 100) + 1;
              return item;
            });
            const hasactions = field["thead"].some((ef:any)=>ef.name === "actions");
              if ((view || add) && !hasactions){
                field['thead'].push({
                  name: 'actions',
                  label: '',
                  key: 'actions',
                  fixed: 'right',
                  sortable:false,
                  render: (e: any, record: any) => {
                    return (
                      <div className='w-full flex gap-1 justify-end items-center'>
                        {record?.DialCallDuration?.length!==0 && record?.RecordingUrl && record?.RecordingUrl?.toLowerCase() !== "null" && <div
                            // onKeyDown={() =>
                            //   handleSelectedRecord({ name: "audio", value: record })
                            // }
                            // tabIndex={0}
                            // role="button"
                            role= "presentation"
                            onClick={(e) => {
                              e.stopPropagation(); 
                              handleSelectedRecord({ name: "audio", value: record })
                              }
                            }
                          >
                            <Tooltip title="Audio & Transcript">
                            <img
                              src={
                                `${BASE_URL}/api/public/assets/images/audio.svg`
                              }
                              alt="View the record"
                              className='w-[30px] h-[20px] cursor-pointer'
                            />
                            </Tooltip>
                          </div>}
                          {/* {record?.status_id === "missed" && (
                            <div
                              role="button"
                              tabIndex={0}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleSelectedRecord({ name: "call", value: record });
                              }}
                              onKeyDown={(e) => {
                                if (e.key === "Enter" || e.key === " ") {
                                  e.stopPropagation();
                                  handleSelectedRecord({ name: "call", value: record });
                                }
                              }}
                            >
                              <Tooltip title="Call">
                                <img
                                  src={`${BASE_URL}/api/public/assets/images/call.svg`}
                                  alt="call the record"
                                  className="w-[20px] h-[15px] mt-1 cursor-pointer"
                                />
                              </Tooltip>
                            </div>
                          )} */}
                          {/* {<div
                            className='w-[20px] h-[20px] p-1 cursor-pointer  flex items-center justify-center bg-white rounded-md'
                            role='presentation'
                            onClick={ (e) => {
                              e.stopPropagation(); 
                            }
                            }
                          >
                            <Tooltip title="Quick actions">
                              <Popover
                                content={
                                  <div className='flex flex-col gap-2'>
                                    {[
                                      {"name": "comments", "label": `${"  "}Add a Comment`, icon:"comments.svg"}, 
                                      {name: "book_appointment", label: "Book an appointment", icon: "appointments.svg"}
                                    ].map((item:any)=>{
                                      return <button key={item.name} onClick={()=>{
                                          handleSelectedRecord({...item,...record})
                                        }} className='text-xs font-medium hover:shadow-sm transition-all duration-600 text-left text-gray-900 rounded-md p-1 flex items-center gap-1'>
                                        <img src={`${BASE_URL}/api/public/assets/images/${item.icon}`} alt={item.icon} className='w-[20px] h-[20px]'/>{item.label}
                                      </button>;
                                    })}
                                  </div>
                                }
                                placement="bottomRight"
                                title=""
                                trigger="hover"
                              >
                                <button className='text-2xl font-bold' >+</button>
                              </Popover>
                            </Tooltip>
                          </div>} */}
                          
                          
                      </div>
                    );
                  },
                })
              }
          }
          return field;
        })
        return {...prev,schema:updatedschema}
      })
    }


    useEffect(() => {
      if (location.state?.source === "Calls") {
        dispatch(setTopbar(location.state?.source))
        dispatch(setActiveMenu("/calls"))
      }
    }, [location.state?.source, dispatch]);

    useEffect(() => {
      const id = setTimeout(() => {
        API_GET_CALLS_Data({
              startDate: dateRange[0],
              endDate: dateRange[1],
              searctext: searchText,
              callType:calltype,
              status:filters.status
          })
      }, 500)

      return () => {
          clearTimeout(id)
      }
    }, [searchText])

    useEffect(()=>{

        if(dateRange[0]==="" && dateRange[1]===""){
            change_segments_value(null)
        }
        const first = moment(dateRange[0], 'DD-MM-YYYY');
        const second = moment(dateRange[1], 'DD-MM-YYYY');
        const diffInDays = second.diff(first, 'days');
    
        if (diffInDays === 0) {
            change_segments_value("TD")

        } else if (diffInDays === 6) {
            change_segments_value("TW")
        } else if (diffInDays === 29) {
            change_segments_value("TM")
        }else{
            change_segments_value(null)
        }

        setSchema((prevSchema: any) => {
          const updatedSchema = Utils.updateSchemaRecursively(prevSchema, (field: any) => {
           if(field.name==="date"){
            return {...field,value:dateRange}
           }
           if(field.name==="left_button"){
            return {...field,loading:!dateRange[0]}
           }
           if(field.name === "right_button") {
            const isStartDateMissing = dateRange[0].length===0;
            const isEndDateToday = dateRange[1] === moment().format("DD-MM-YYYY");
        
            return {
                ...field,
                loading: isStartDateMissing || isEndDateToday
            };
        }
        
            return field;
          });
        
          return updatedSchema;
        });
    },[dateRange,dispatch])
    
  const displayTags = (record: any, select:any) => {
    
    const updatedSchema = multiselectschema?.schema?.map((f: any) => {
      if (f.name === "call-tags") {
        return {
          ...f,
          value: record["call_tags"] || [],
          id : record?._id,
          // disabled : select
        };
      }
      return f; 
    });
    return {
      ...multiselectschema,
      schema: updatedSchema,
    };
  };

  const handleTagOptions = async() => {
    const res = await Utils.makeApiCall("/getCallTags", "GET");
    if(res.status) {
      setMultiselectschema((prev:any) => {
        const schema = prev?.schema?.map((f:any) => {
          if(f.name === "call-tags"){
            f.options = res?.data?.map((option:any) => ({label: option?.name, value: option?._id, color: option?.colour || "#8c8c88"}))
          }
          return f;
        })
        return {...prev, schema}
      })
    }
  }

    const change_segments_value=(val:string | null)=>{
      setSchema((prev:any)=>{
          const updatedSchema=prev.schema.map((field:any)=>{
              if(field.name==="filters"){
                field.fields=field.fields.map((ffield:any)=>{
                  if(ffield.name==="date_and_search"){
                    ffield.fields=ffield.fields.map((fffield:any)=>{
                      if(fffield.name==="segments"){
                        fffield.value=val
                      }
                      return fffield;
                    })
                  }
                  return ffield;
                })
              }
              return field;
          })
          return {...prev,schema:updatedSchema}
      })
    }

    const fetchCardsSchema = async() => {
      try{
        const res = await Utils.makeApiCall('/schema?schema_for=calls_cards', "GET")
        // const response = await axios.get("http://localhost:3001/api/schema?shema_for=calls_cards");
        // const res = response?.data;
        // console.log("ok", res);
        
        if (res.status) {
          setSchema((prev: any) => {
            const updatedSchema = prev?.schema?.map((f: any) => {
              if (f.name === "call_details") {
                f.className = res?.data?.length < 4 ?`grid grid-cols-${res?.data?.length + 2} gap-2 font-semibold w-full` : f.className;
                f.fields = [...res.data.map((ff: any) => {
                  if (!ff?.fields?.[0]?.fields?.[0]?.img?.startsWith(BASE_URL)) {
                    ff.fields[0].fields[0].img = BASE_URL + ff.fields[0].fields[0].img;
                  }
                  return ff;
                }),...newCards]
              }
              return f;
            });
            return { ...prev, schema: updatedSchema };
          });
        }
      } catch (e){
        console.log("error while fetching cards schema", e);
      }
    }

    useEffect(() => {
      fetchCardsSchema();
      handleTagOptions();
    }, [])

    // useEffect(() => {
    //   const serverUrl = CONSTANTS.SOCKET_URL;
    // const ws = new WebSocket(serverUrl);
    //   // Listen for WebSocket messages
    //   ws.onopen = () =>{
    //     console.log('Connected to WebSocket server.');
    //   }
    //   ws.onmessage = (event) => {
    //     const message = JSON.parse(event.data);

    //     // console.log("1010", event.data);
        
    //     if (message?.status === "Ongoing") {
    //       change_outbound_modal(message?.status);
    //     }
    //     if (["Missed", "Answered"].includes(message?.status)) {
    //       close_callback_modal();
    //     }
    //     if (message.type === 'ivr_calls') {

    //       if(message.org_id === org_id){
    //         console.log("Socket triggered!!!");
            
    //         API_GET_CALLS_Data();
    //       }
    //     }
    //   };
    //   ws.onclose = () =>{
    //     console.log("connection closed!!");
        
    //   }
  
    //   // Clean up WebSocket connection when component unmounts
    //   return () => {
    //     ws.close();
    //   };
    // }, []);
    // useEffect(() => {
    //   console.log(filters);
      
    //   if (filters !== null) {
    //     // Trigger the API call once filters are updated
    //     API_GET_CALLS_Data({
    //       startDate: dateRange[0],
    //       endDate: dateRange[1],
    //       searctext: searchText,
    //       callType: calltype,
    //       callStatus: filters,  // now it will have the updated filters
    //     });
    //   }
    // }, [filters]);

    const setupWebSocket = () => {
      ws.current = new WebSocket(serverUrl);
  
      ws.current.onopen = () => {
        // console.log("Connected to WebSocket server.");
        startHeartbeat();
      };
  
      ws.current.onmessage = (event) => {
        const message = JSON.parse(event.data);
        console.log("Received message:", message);
        console.log(message.status)
        // Handle different WebSocket message types
        if (message?.status === "Ongoing") {
          change_outbound_modal(message?.status);
        }
        if (["Missed", "Answered"].includes(message?.status)) {
          close_callback_modal();
        }
        if (message.type === "ivr_calls" && message.org_id === org_id) {
          // console.log("Socket triggered data fetch!---------------------------------");
          API_GET_CALLS_Data({details:true});
        }
      };
  
      ws.current.onclose = () => {
        console.log("WebSocket connection closed. Attempting to reconnect...");
        stopHeartbeat();
        attemptReconnect();
      };
  
      ws.current.onerror = (error) => {
        console.error("WebSocket error:", error);
        ws.current?.close();
      };
    };
  
    // Heartbeat mechanism to keep WebSocket connection alive
    const startHeartbeat = () => {
      heartbeatInterval.current = setInterval(() => {
        if (ws.current?.readyState === WebSocket.OPEN) {
          ws.current.send(JSON.stringify({ type: "ping" }));
          console.log("Sent heartbeat (ping)");
        }
      }, 30000); // Every 30 seconds
    };
  
    // Stop heartbeat interval
    const stopHeartbeat = () => {
      if (heartbeatInterval.current) {
        clearInterval(heartbeatInterval.current);
        heartbeatInterval.current = null;
      }
    };
    
  
    // Attempt to reconnect WebSocket if connection is lost
    const attemptReconnect = () => {
      reconnectInterval.current = setTimeout(() => {
        console.log("Reconnecting to WebSocket...");
        setupWebSocket();
      }, 100); // Reconnect after 5 seconds
    };
  
    // Clean up WebSocket and intervals on component unmount
    useEffect(() => {
      setupWebSocket();
  
      return () => {
        ws.current?.close();
        stopHeartbeat();
        if (reconnectInterval.current) {
          clearTimeout(reconnectInterval.current);
        }
      };
    }, []);

    const API_GET_CALLS_Data=async ({
      page = pagination.page,
      limit = pageSize,
      startDate = dateRange[0],
      endDate = dateRange[1],
      searctext = "",
      callType="",
      status=[],
      details=false,
    }:any = {})=>{
      // setSchema((prev: any) => Utils.handleLoading(prev, true))
      dispatch(setLoading(true))
      try {
          // const callStatusString = callStatus&&callStatus ? callStatus.join(",") : "";
          const statusString = status&&status.length ? status.join(",") : "";
          
          const res: any = await Utils.makeApiCall(`/getIvrExotelCalls?org_id=${org_id}&page=${page}&limit=${limit}&startDate=${startDate}&endDate=${endDate}&search=${searctext}&status=${statusString}`, "GET");
          if (res.status) {
              change_calls_data(res);
              if(details){
                setSelectedRecord(res?.data[0])
              }

          } else {
              toast.error(res.message ? res.message : FAILURE_MSG, { position: 'top-center', autoClose: AUTO_CLOSE })
          }
      } catch (error) {
          toast.error(CATCH_ERROR_MSG, { position: 'top-center', autoClose: AUTO_CLOSE });
          console.error('Failed to fetch schema', error);
      }
      dispatch(setLoading(false))
      // setSchema((prev: any) => Utils.handleLoading(prev, false))
    }
    const API_GET_CALLS_Scores=async ()=>{
      try {
          const res: any = await Utils.makeApiCall(`/getCallsScores`, "GET");
          if (!res.status) {
              toast.error(res.message ? res.message : FAILURE_MSG, { position: 'top-center', autoClose: AUTO_CLOSE })
          }
      } catch (error) {
          toast.error(CATCH_ERROR_MSG, { position: 'top-center', autoClose: AUTO_CLOSE });
          console.error('Failed to fetch schema', error);
      }
    }
    const API_GET_REFRESH_CALLS_Data=async ({
      page = 1,
      limit = 30,
      startDate = "",
      endDate = "",
      searctext = "",
      callType=""
    } = {})=>{
      dispatch(setLoading(true))
      try {
          const res: any = await Utils.makeApiCall(`/getIvrExotelCalls?org_id=${org_id}&page=${page}&limit=${limit}&startDate=${startDate}&endDate=${endDate}&searchText=${searctext}`, "GET");
          if (res.status) {
              change_calls_data(res);

          } else {
              toast.error(res.message ? res.message : FAILURE_MSG, { position: 'top-center', autoClose: AUTO_CLOSE })
          }
      } catch (error) {
          toast.error(CATCH_ERROR_MSG, { position: 'top-center', autoClose: AUTO_CLOSE });
          console.error('Failed to fetch schema', error);
      }
      finally{
        dispatch(setLoading(false))
      }
    }

    const downloadToExcel = async () => {
      try {
        const tableSchema = schema.schema.find((s: any) => s.element === "table");
        const { thead } = tableSchema;
        dispatch(setLoading(true))

        const response = await Utils.makeApiCall(`/getIvrExotelCalls?page=1&limit=10&startDate=${dateRange[0]}&endDate=${dateRange[1]}&download=true&search=${searchText}`, "GET");
        // const formattedData: any = response?.data.map((item: any) => {
        //   const formattedStartTime = moment(item.StartTime).format('DD-MM-YYYY HH:mm:ss');
        //   let formattedDuration = '';
        //   let formattedDialCallStatus = item.DialCallStatus;
  
        //   if (item.DialCallStatus.toLowerCase() === "completed") {
        //       const durationInSeconds = item.DialCallDuration;
        //       const duration = moment.duration(durationInSeconds, 'seconds');
              
        //       if (duration.asHours() >= 1) {
        //           formattedDuration = `${Math.floor(duration.asHours())}h ${duration.minutes()}m ${duration.seconds()}s`;
        //       } else if (duration.asMinutes() >= 1) {
        //           formattedDuration = `${Math.floor(duration.asMinutes())}m ${duration.seconds()}s`;
        //       } else {
        //           formattedDuration = `${duration.seconds()}s`;
        //       }
  
        //       formattedDialCallStatus = "Call was successful";
        //   } else if (item.digits === "\"2\"") {
        //       formattedDialCallStatus = "Ambulance request";
        //   } else if (item.digits === "\"1\"") {
        //       formattedDialCallStatus = "Callback request";
        //   }
  
        //   const formattedDirection = item.Direction.charAt(0).toUpperCase() + item.Direction.slice(1).toLowerCase();
  
        //   return {
        //       ...item,
        //       DialCallDuration: formattedDuration,
        //       StartTime: formattedStartTime,
        //       originalStartTime: item.StartTime,
        //       DialCallStatus: formattedDialCallStatus,
        //       Direction: formattedDirection
        //   };
        // });
  
        // const sortedData = formattedData.sort((a:any, b:any) => {
        //     return moment(b.originalStartTime).valueOf() - moment(a.originalStartTime).valueOf();
        // });
        // const data = sortedData.map(({ originalStartTime, ...item }: any) => item);
        dispatch(setLoading(false))

        if (!response?.data || response?.data.length === 0) {
          toast.error("No data available to download", { position: "top-center", autoClose: AUTO_CLOSE });
          return;
        }

        Utils.exportToExcel(thead, response?.data, "Calls");
      } catch (error) {
        console.error("Error downloading Excel:", error);
        toast.error("Failed to download data as Excel", { position: "top-center", autoClose: AUTO_CLOSE });
      }
      finally{
        dispatch(setLoading(false))
      }
    };

    const setTablePagination=()=>{
      setSchema((prevState: any) => {
        const updatedSchema = prevState.schema.map((s: any) => {
          if (s.name === "table") {
            dispatch(setPagination({ name: 'pagination', page: 1, pageSize }))
            s.value = [{ name: 'pagination', page: 1, pageSize }];
          }
          return s;
        });
        return { ...prevState, schema: updatedSchema };
      });
    }

    const fetchAudio = async (url:any) => {
      const response = await fetch(url, {
          headers: {
              'Authorization': 'Basic ' + btoa('00d1ed376737e1388536fb2afbfda8e31c4d0e2e8e4731c5:809c45c638cb4a64a02daf60ce5d7eefadfde89306d6c7c9'),
          },
      });
      const blob = await response.blob();
      const audioUrl = URL.createObjectURL(blob);
      return audioUrl;
  };

    const change_model_data=async (url:any,res:any, CallFrom: any)=>{
      // const audioUrl=await fetchAudio(url)
      // // console.log(audioUrl)
      // if(audioUrl){
        function formatTime(seconds:any) {
          const minutes = Math.floor(seconds / 60);
          const remainingSeconds = seconds % 60;
        
          // Format the minutes and seconds with leading zeros
          const formattedMinutes = String(minutes).padStart(2, "0");
          const formattedSeconds = String(remainingSeconds).padStart(2, "0");
        
          return `${formattedMinutes}:${formattedSeconds}`;
        }
        

        setSchema((prev:any)=>{
          const updatedSchema=prev.schema.map((field:any)=>{
            if(field.name==="view_modal_container"){
              field.visible=true,
              field.fields=field.fields.map((ffield:any)=>{
                if(ffield.name==="modal_title"){
                  ffield?.fields?.map((l: any)=>{
                    if(l.name==="modal_title_key"){
                      l.label = `Call Transcripts (${CallFrom})`
                    }
                  })
                }
                if(ffield.name==="modal_body"){
                  ffield.fields=ffield.fields.map((fffield:any)=>{
                    // if(fffield.name === "audio_transcription"){
                    //   fffield.label=res?.transcript;
                    // }
                    if(fffield.name==="audio_transcription"){
                       if(res?.length > 0){
                        fffield.fields = []
                        res?.map((d: any,index:any)=>{
                          fffield?.fields.push(
                             {
                              name: "transcript_" + index,
                              className: "flex gap-2 items-start w-full py-1",
                              fields: [
                                {
                                  name: "transcript_time_" + index,
                                  label: `${formatTime(parseInt(d?.timestamp[0]))}`,
                                  className: "text-sm font-semibold w-20 flex-shrink-0 pt-2 whitespace-nowrap",
                                  element: "div",
                                },
                                {
                                  name: "transcript_message" + index,
                                  label: d?.text,
                                  className: "p-2 px-4 bg-[#f5f8fa] text-start rounded-2xl max-w-[70%]",
                                  element: "div",
                                },
                              ],
                            }
                          //   {
                          //   name: "transcript_msg",
                          //   className: `flex flex-col !gap-0 items-start ${d.type === "AGENT" ? "calls-agent-transcript" : "calls-patient-transcript"}`,
                          //   label: "",
                          //   element: "",
                          //   fields: [
                          //     // {
                          //     //   name: "",
                          //     //   className: `text-[9px] font-light ${d.type === "AGENT" ? "text-gray-200" : "text-gray-400"}`,
                          //     //   label: d.type,
                          //     //   element: "div",
                          //     // },
                          //     {
                          //       name: "",
                          //       className: "",
                          //       label: d.message,
                          //       element: "div",
                          //     }
                          //   ]
                          // }
                        )
                        })
                       }
                    }
                    if(fffield.name==="audio"){
                      fffield.label=<audio controls controlsList="nodownload" className='w-[100%]'><source src={`${BASE_URL}/api/callrecordings?audio=${url}`} type="audio/mpeg" /> <track kind="captions" src="your-captions-file.vtt" srcLang="en" label="English captions" /> Your browser does not support the audio element.</audio>
                    }
                    return fffield;
                  })
                }
                return ffield;
              })
            }
            return field;
          })
          return {...prev,schema:updatedSchema}
        })
      // }
    } 

    const outboundCallsPayload = (record: any) => {
      // If the record itself is incoming, process it directly
      if (record?.Direction.toLowerCase() === "incoming") {
          return {
              From: record?.DialWhomNumber,
              To: record?.CallFrom,
              CallerId: record?.CallTo,
              callId: record?.CallSid,
              org_id,
          };
      } 
      // Otherwise, find the incoming call in children records
      else {
          const incomingChild = record?.children?.find((child: any) => 
              child?.Direction.toLowerCase() === "incoming"
          );
          
          if (incomingChild) {
              return {
                  From: incomingChild?.DialWhomNumber,
                  To: incomingChild?.CallFrom,
                  CallerId: incomingChild?.CallTo,
                  callId: incomingChild?.CallSid,
                  org_id,
              };
          }
      }
      
      // Return null if no incoming call is found
      return null;
  };

    const API_MAKE_Voice_call=async (record:any)=>{

      const body:any=outboundCallsPayload(record);
      dispatch(setLoading(true))
      try {
        // change_outbound_modal("Calling...",record);
          const res: any = await Utils.makeApiCall(`/make-voice-call`, "POST",body);
          if (res?.status) {
            dispatch(setLoading(false))
            setSchema((prevSchema: any) => {
              const updatedSchema = Utils.updateSchemaRecursively(prevSchema, (field: any) => {
                if(field.name==="outbound_calls_modal"){
                  field.visible=true
                }
                if(field.name==="name"){
                  field.label=record?.Name
                }
                if(field.name==="mobile_number"){
                  field.label=record?.From
                }
                if(field.name==="status"){
                  field.label=res?.message
                }
                if(field.name==="avatar"){
                  field.label=<Avatar size={70} style={{backgroundColor:`${Utils.stringToColour(record?.Name)}`,fontSize: '30px'}}> 
                                <p className={`capitalize`}>{Utils.initials(record?.Name)}</p>
                              </Avatar>
                }
                return field;
              });
            
              return updatedSchema;
            });

          } else {
              toast.error(res.message ? res.message : FAILURE_MSG, { position: 'top-center', autoClose: AUTO_CLOSE })
          }
      } catch (error) {
          toast.error(CATCH_ERROR_MSG, { position: 'top-center', autoClose: AUTO_CLOSE });
          console.error('Failed to fetch schema', error);
      }
      dispatch(setLoading(false))
    }

    const change_outbound_modal=(status:any)=>{
      setSchema((prevSchema: any) => {
        const updatedSchema = Utils.updateSchemaRecursively(prevSchema, (field: any) => {
          if(field.name==="status"){
            field.label=status
          }
          return field;
        });
      
        return updatedSchema;
      });
    }
    

    const API_GET_AUDIO_TEXT=async (record:any)=>{
      if(record?.callTranscript?.length>0){
        change_model_data(record?.RecordingUrl,record?.callTranscript, record?.CallFrom)
        return;
      }
      dispatch(setLoading(true))
      try {
          const res: any = await Utils.makeApiCall(`/getCallTranscripts?id=${record?._id}`, "GET");
          if (res.status) {
            change_model_data(record?.RecordingUrl,res?.data?.transcript, record?.CallFrom);
          } else {
              toast.error(res?.message ? res?.message : FAILURE_MSG, { position: 'top-center', autoClose: AUTO_CLOSE })
          }
          dispatch(setLoading(false))
      } catch (error) {
          toast.error(CATCH_ERROR_MSG, { position: 'top-center', autoClose: AUTO_CLOSE });
          console.error('Failed to fetch schema', error);
      }
      dispatch(setLoading(false))
    }

    const closeCallModal = () => {
      setSchema((prev:any) => {
        const updatedSchema = prev.schema?.map((sec:any) => {
          if(sec.name === "tag-modal"){
            sec.visible = false;
          }
          if (sec.name==="")
          return sec;
        })
        return {...prev,schema:updatedSchema}
      })
      // API_GET_CALLS_Data({
      //   page: 1,
      //           limit: pageSize,
      //           startDate: dateRange[0],
      //           endDate: dateRange[1],
      //           searctext: searchText,
      //           callType:calltype,
      //           status:filters.status
      // })
    }

    const handleCallTags = async(e:any) => {
      if (tagDebounceTime.current) {
        clearTimeout(tagDebounceTime.current);
      }
      tagDebounceTime.current = setTimeout(async () => {
        try {
          let flag = false;
          const payload: any[] = [];
    
          for (const val of e?.value || []) {
            if (val?.label === val?.value) {
              // console.log(e);
              flag = true;
              const res = await Utils.makeApiCall("/call-tags", "POST", { tag_name: val?.label });
              if (res.status) {
                payload.push(res?.data?._id);
                await handleTagOptions();
                
              } else {
                continue;
              }
            } else {
              payload.push(val?.value);
            }
          }
          const updateRes = await Utils.makeApiCall(`/updateCallTag?call_id=${e.call_id}`, "PUT", payload);
          if (!updateRes.status) {
            toast.error(CATCH_ERROR_MSG, { position: "top-center", autoClose: AUTO_CLOSE });
            // console.log("Error while sending the data.");
          } else {
            if (flag) {
              await API_GET_CALLS_Data({
                page: page,
                limit: pageSize,
                startDate: dateRange[0],
                endDate: dateRange[1],
                searctext: searchText,
                callType:calltype,
                status:filters.status
              });
            }
          }
        } catch (err) {
          toast.error(CATCH_ERROR_MSG, { position: "top-center", autoClose: AUTO_CLOSE });
          console.log("Error while sending the data.", err);
        }
      }, 0);
    };    
    const close_callback_modal=()=>{
      setSchema((prevSchema: any) => {
        const updatedSchema = Utils.updateSchemaRecursively(prevSchema, (field: any) => {
          if(field.name==="outbound_calls_modal"){
            field.visible=false
          }
          // if(field.name==="green_button"){
          //   field.loading=false,
          //   field.className="bg-green-400 p-4 rounded-full cursor-pointer"
          // }
          return field;
        });
      
        return updatedSchema;
      });
    }

    // console.log(outgoingData)
    const handleSelectedRecord=(e:any)=>{
      // console.log(e);
      if (e.name === "left_button") {
        const today = moment().format('DD-MM-YYYY');

        let dateDifference = moment(dateRange[1], 'DD-MM-YYYY').diff(moment(dateRange[0], 'DD-MM-YYYY'), 'days');
      
        dateDifference = dateDifference === 0 ? 1 : dateDifference;
    
        if (dateDifference === 1) {
            const newStartDate = moment(dateRange[0], 'DD-MM-YYYY').subtract(1, 'days').format('DD-MM-YYYY');
            const newEndDate = moment(dateRange[1], 'DD-MM-YYYY').subtract(1, 'days').format('DD-MM-YYYY');
         
            // const validatedStartDate = moment(newStartDate, 'DD-MM-YYYY').isAfter(today) ? today : newStartDate;
            // const validatedEndDate = moment(newEndDate, 'DD-MM-YYYY').isAfter(today) ? today : newEndDate;
    
            dispatch(setDateRange([newStartDate, newEndDate]));
    
            API_GET_CALLS_Data({
                startDate: newStartDate,
                endDate: newEndDate,
                searctext: searchText,
                callType: calltype,
                status: filters.status,
            });
        } else {
      
            const newEndDate = moment(dateRange[0], 'DD-MM-YYYY').subtract(1, 'days').format('DD-MM-YYYY');
            

            const newStartDate = moment(newEndDate, 'DD-MM-YYYY').subtract(dateDifference, 'days').format('DD-MM-YYYY');

            // const validatedStartDate = moment(newStartDate, 'DD-MM-YYYY').isAfter(today) ? today : newStartDate;
            // const validatedEndDate = moment(newEndDate, 'DD-MM-YYYY').isAfter(today) ? today : newEndDate;
        
            dispatch(setDateRange([newStartDate, newEndDate]));

            API_GET_CALLS_Data({
                startDate: newStartDate,
                endDate: newEndDate,
                searctext: searchText,
                callType: calltype,
                status: filters.status,
            });
        }
      }
      if (e.name === "right_button") {
        const today = moment().format('DD-MM-YYYY');
        
        // Parse dateRange dates with format 'DD-MM-YYYY'
        const startDate = moment(dateRange[0], 'DD-MM-YYYY');
        const endDate = moment(dateRange[1], 'DD-MM-YYYY');
    
        // Calculate the date difference
        let dateDifference = endDate.diff(startDate, 'days');
        dateDifference = dateDifference === 0 ? 1 : dateDifference;
    
        // Case when dateDifference is 1
        if (dateDifference === 1) {
            const newStartDate = endDate.add(1, 'days').format('DD-MM-YYYY');
            const newEndDate = endDate.format('DD-MM-YYYY'); // endDate has already been incremented
    
            // Validate the new dates
            // const validatedStartDate = moment(newStartDate, 'DD-MM-YYYY').isAfter(moment(today, 'DD-MM-YYYY')) ? today : newStartDate;
            // const validatedEndDate = moment(newEndDate, 'DD-MM-YYYY').isAfter(moment(today, 'DD-MM-YYYY')) ? today : newEndDate;
    
            dispatch(setDateRange([newStartDate, newEndDate]));
    
            API_GET_CALLS_Data({
                startDate: newStartDate,
                endDate: newEndDate,
                searctext: searchText,
                callType: calltype,
                status: filters.status,
            });
        } else {
            // Case when dateDifference is more than 1
            const newStartDate = endDate.add(1, 'days').format('DD-MM-YYYY');
            const newEndDate = moment(newStartDate, 'DD-MM-YYYY').add(dateDifference, 'days').format('DD-MM-YYYY');
    
            // Validate the new dates
            // const validatedStartDate = moment(newStartDate, 'DD-MM-YYYY').isAfter(moment(today, 'DD-MM-YYYY')) ? today : newStartDate;
            // const validatedEndDate = moment(newEndDate, 'DD-MM-YYYY').isAfter(moment(today, 'DD-MM-YYYY')) ? today : newEndDate;
    
            dispatch(setDateRange([newStartDate, newEndDate]));
    
            API_GET_CALLS_Data({
                startDate: newStartDate,
                endDate: newEndDate,
                searctext: searchText,
                callType: calltype,
                status: filters.status,
            });
        }
      }
    
    
      if(e.name === "modal_close" || e.name === "cancel_feature_config"){
        closeCallModal();
      }
      if(e.name === "submit_feature_config") {
        // createCallTag()
      }
      if(e.name === "call-tags"){
        handleCallTags(e);
      }
      if (e.name === "btn_download") {
        downloadToExcel();
      }
      if(e.name === "search"){
        dispatch(setSearchText(e.value))
        dispatch(setPagination({ name: 'pagination', page: 1, pageSize }))
        setSchema((prevState: any) => {
          const updatedSchema = prevState.schema.map((s: any) => {
            if (s.element === "table") {
              s.value = [{ name: 'pagination', page: 1, pageSize }];
            }
            return s;
          });
          return { ...prevState, schema: updatedSchema };
        });
      }
      if (e.name === 'date') {
        dispatch(setPagination({ name: 'pagination', page: 1, pageSize }))
        setSchema((prevState: any) => {
          const updatedSchema = prevState.schema.map((s: any) => {
            if (s.element === "table") {
              s.value = [{ name: 'pagination', page: 1, pageSize }];
            }
            return s;
          });
          return { ...prevState, schema: updatedSchema };
        });
        if (e.value === '') {
          
            dispatch(setDateRange(['', '']))
            API_GET_CALLS_Data({ startDate: "", endDate: "",searctext:searchText,callType:calltype,status:filters.status })
        } else {
            dispatch(setDateRange(e.value))
            API_GET_CALLS_Data({ startDate: e.value[0], endDate: e.value[1],searctext:searchText,callType:calltype,status:filters.status });
        }
      }
      if(e.name === "btn_refresh"){
        setTablePagination();
        // handleCardsClick("total_calls");
        API_GET_CALLS_Scores();
        API_GET_REFRESH_CALLS_Data({
          // page: pagination.pagenum,
          // limit: pagination.limit,
          startDate: dateRange[0],
          endDate: dateRange[1],
          searctext: "",
          callType:""
          
        })
      }
      if (e.name === "table") {
        // console.log(e)
      Array.isArray(e.value)?e.value.map((item: any)=>{
        if (item.name==="pagination" &&( pagination.page!==item.page || pagination.pageSize!==item.pageSize)){
          
          dispatch(setPagination(item))
          
          API_GET_CALLS_Data({
            page: item.page,
            limit: item.pageSize,
            startDate: dateRange[0],
            endDate: dateRange[1],
            searctext: searchText,
            callType:calltype,
            status:filters.status
          });
        }
      //  else if (item.name==="filters"&& filters.status!==item.value.status){
      //     // console.log(item)
      //     const {status}=item.value 
      //     // console.log(item.value)
      //     dispatch(setFilters(item.value||{}))
      //     dispatch(setCallType(""))
      //     API_GET_CALLS_Data({
      //       startDate: dateRange[0],
      //       endDate: dateRange[1],
      //       searctext: searchText,
      //       callType:"",
      //       status:status,
      //       page:1
      //     });
      //     setSchema((prevState: any) => {
      //       const updatedSchema = prevState.schema.map((s: any) => {
      //         if (s.element === "table-element") {
      //           s.value = [{ name: 'pagination', page: 1, pageSize }];
      //         }
      //         if(s.name==="call_details"){
      //           s.fields=s.fields.map((field:any)=>{
      //             field.className="calls-top-card";
      //             return field;
      //           })
      //         }
      //         return s;
      //       });
      //       return { ...prevState, schema: updatedSchema };
      //     });
      //   }
      }):""
        if (e?.["value"]?.name === "view") {
          const id =  e["value"]?.["value"]?.["_id"] || e["value"]?.["_id"];
          setSelectedRecord(e?.value?.value);
          // navigate(`/calls/view/${id}`)
        }
      }
      if(["total_calls","answered_calls","total_missed_calls","total_calledback_requests"].includes(e.name)){
        // dispatch(setCallType(e?.call_type))
        dispatch(setFilters({status:[e?.call_type]}))
        dispatch(setPagination({page:1,pageSize}))
        API_GET_CALLS_Data({
          startDate: dateRange[0],
          endDate: dateRange[1],
          searctext: searchText,
          callType:e?.call_type,
          status:[e?.call_type],
          page:1
        });
        setSchema((prevState: any) => {
          const updatedSchema = prevState.schema.map((s: any) => {
            if (s.element === "table") {
              s.value = [{ name: 'pagination', page: 1, pageSize },{name:"filters",value:{status:[]}}];
            }
            if(s.name==="call_details"){
              s.fields=s.fields.map((field:any)=>{
                field.className =`calls-top-card ${field.name === e.name ? "active":""}`
                return field;
              })
            }
            
            return s;
          });
          return { ...prevState, schema: updatedSchema };
        });
      }
      if (e.name === "call") {
        setSchema((prev:any)=>{
          prev.schema.map((item:any)=>{
            if(item.name==="outbound_calls_modal"){
              if(item.visible){
                toast.warning("Cannot make call while in another call",{position:"top-center",autoClose:AUTO_CLOSE})
              } else{
                // console.log(e?.value)
                API_MAKE_Voice_call(e?.value);
              }
            }
            return item;
          })
          return prev;
        })
      }
      if(e?.name==="close_call_modal"){
        close_callback_modal();
      }
    
      if (e.name === "segments") {
        if (e.value === "TD") {
            dispatch(setDateRange([
                moment().format('DD-MM-YYYY'),
                moment().format('DD-MM-YYYY'),
            ]));
            // dispatch(setPagination({ name: 'pagination', page: 1, pageSize }))
            API_GET_CALLS_Data({
                startDate: moment().format('DD-MM-YYYY'),
                endDate: moment().format('DD-MM-YYYY'),
                searctext: searchText,
                callType:calltype,
                status:filters.status

            })
            setSchema((prev: any) => {
                const updatedSchema = prev.schema.map((field: any) => {
                    if (field.name === "filters") {
                        field.fields = field.fields.map((ffield: any) => {
                            if (ffield.name === "date_and_search") {
                                ffield.fields = ffield.fields.map((ff: any) => {
                                    if (ff.name === "date") {
                                        ff.value = [
                                            moment().format('DD-MM-YYYY'),
                                            moment().format('DD-MM-YYYY'),
                                        ];
                                    }
                                    return ff;
                                });
                            }
                            return ffield;
                        });
                    }
                    if(field.element==="table"){
                      field.value = [{ name: 'pagination', page: 1, pageSize }];
                  }
                    return field;
                });
                return { ...prev, schema: updatedSchema };
            });
        }
        if (e.value === "TM") {
            
            // dispatch(setPagination({ name: 'pagination', page: 1, pageSize }))
            API_GET_CALLS_Data({
                startDate: moment().subtract(29, 'days').format('DD-MM-YYYY'),
                endDate:moment().format('DD-MM-YYYY'),
                searctext: searchText,
                callType:calltype,
                status:filters.status
            })
            setSchema((prev: any) => {
                const updatedSchema = prev.schema.map((field: any) => {
                    if (field.name === "filters") {
                        field.fields = field.fields.map((ffield: any) => {
                            if (ffield.name === "date_and_search") {
                                ffield.fields = ffield.fields.map((ff: any) => {
                                    if (ff.name === "date") {
                                        ff.value = [
                                          moment().subtract(29, 'days').format('DD-MM-YYYY'),
                                          moment().format('DD-MM-YYYY'),
                                        ];
                                    }
                                    return ff;
                                });
                            }
                            return ffield;
                        });
                    }
                    if(field.element==="table"){
                      field.value = [{ name: 'pagination', page: 1, pageSize }];
                  }
                    return field;
                });
                return { ...prev, schema: updatedSchema };
            });
            dispatch(setDateRange([
              moment().subtract(29, 'days').format('DD-MM-YYYY'),
              moment().format('DD-MM-YYYY'),
          ]));
        }
        if (e.value === "TW") {
           
            // dispatch(setPagination({ name: 'pagination', page: 1, pageSize }))
            API_GET_CALLS_Data({
                startDate: moment().subtract(6, 'days').format('DD-MM-YYYY'),
                endDate: moment().format('DD-MM-YYYY'),
                searctext: searchText,
                callType:calltype,
                status:filters.status,
            })
            setSchema((prev: any) => {
                const updatedSchema = prev.schema.map((field: any) => {
                    if (field.name === "filters") {
                        field.fields = field.fields.map((ffield: any) => {
                            if (ffield.name === "date_and_search") {
                                ffield.fields = ffield.fields.map((ff: any) => {
                                    if (ff.name === "date") {
                                        ff.value = [
                                            moment().subtract(6, 'days').format('DD-MM-YYYY'),
                                            moment().format('DD-MM-YYYY'),
                                        ];
                                    }
                                    return ff;
                                });
                            }
                            return ffield;
                        });
                    }
                    if(field.element==="table"){
                      field.value = [{ name: 'pagination', page: 1, pageSize }];
                  }
                    return field;
                });
                return { ...prev, schema: updatedSchema };
            });
            dispatch(setDateRange([
              moment().subtract(6, 'days').format('DD-MM-YYYY'),
              moment().format('DD-MM-YYYY'),
          ]));
        }
      }
      if(e?.value?.name==="view"){
        setSelectedRecord(e?.value?.value);
        // navigate(`/calls/view/${e?.value.value?._id}`)
      }
      if(e.name==="audio"){
        API_GET_AUDIO_TEXT(e?.value)
      }

      if(e.name==="modal_close"){
        setSchema((prev:any)=>{
          return {...prev,schema:prev.schema.map((field:any)=>{
            if(field.name==="view_modal_container"){
              field.visible=false;
              field.fields=field.fields.map((ffield:any)=>{
                if(ffield.name==="modal_body"){
                  ffield.fields=ffield.fields.map((fffield:any)=>{
                    if(fffield.name==="audio_transcription"){
                      fffield.fields = ""
                    }
                    if(fffield.name==="audio"){
                      fffield.label=""
                    }
                    return fffield;
                  })
                }
                return ffield;
              })
            }
            return field;
          })}
          return {...prev}
        })
      }

      if(e.name === 'book_appointment'){
        // navigate(`/appointments/add?source=CALL&mobile=${e.From}`)
        const apptBooking = appointmentBookingModal(e.From.slice(-10),e.Name)
        setSchema((prev:any)=>{
          return{
            ...prev,
            schema:prev.schema.map((field:any)=>{
              if(field.name === 'appointment-booking-modal'){
                return{
                  ...field,
                  visible:true,
                  fields:field.fields.map((f:any)=>{
                    if(f.name === 'modal-body'){
                      return{
                        ...f,
                        fields:[apptBooking]
                      }
                    }
                    return f
                  })
                }
              }
              return field
            })
          }
        })
      }

      if(e.name === 'appointment-booking-modal-close'){
        setSchema((prev:any)=>{
          return{
            ...prev,
            schema:prev.schema.map((field:any)=>{
              if(field.name === 'appointment-booking-modal'){
                return{
                  ...field,
                  visible:false
                }
              }
              return field
            })
          }
        })
      }
    }


  return (
    <div className="bg-[#EFF1F9]">
      <ElementExecutor
        data={schema}
        selectedRecord={(e: any) => {
          handleSelectedRecord(e);
          // console.log(e);
          
        }}
      />
      {selectedRecord?.["_id"] && <DetailsModal selectedRecord={{...selectedRecord, interaction_type: "Call" }} onClose={(e:boolean)=> {
        setSelectedRecord(null);
        if (e){
          API_GET_CALLS_Data({
            page: page,
            limit: pageSize,
            startDate: dateRange[0],
            endDate: dateRange[1],
            searctext: searchText,
            callType:calltype,
            status:filters.status
          })
        }
      }}/>}
    </div>
  )
}

export default Calls;
