import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../rootReducer'
import { UISchemaState } from './types'
import "../../input.css"
import Storage from '../../utils/local-storage'

const initialState: UISchemaState = {
    schemas: [],
    topBar: {},
    sideBar: {},
    branches: [],
}

const uiSchemaSlice = createSlice({
    name: 'uiSchema',
    initialState,
    reducers: {
        setUISchemas: (state, action: PayloadAction<Record<string, any>>) => {
            const { data } = action.payload
            state.schemas = data
            const topbarObj = data.find(
                (d: any) => d['_id'] === '66c047b1e66fb1e2df387edb'
            )
            if (topbarObj && topbarObj?.schema) {
                // Create a deep copy of topbarObj.schema
                const data = localStorage.getItem('details')
                const details = data ? JSON.parse(data) : {}
                topbarObj.schema.map((sec: any) => {
                    if (sec.element === 'navbar') {
                        if (sec?.fields && sec?.fields?.length) {
                            sec.fields = sec.fields.map((item: any) => {
                                if (item.name === 'snippet-copy') {
                                    return {
                                        ...item,
                                        primaryText: details?.fullname || '--',
                                        // secondaryText: details?.email || '--',
                                    }
                                }
                                return item
                            })
                        }
                        return { ...sec, fields: [...sec.fields] }
                    }
                    if (sec.name === "branches"){
                        sec.visible = details?.is_multi_branch
                    }
                    return { ...sec }
                })
            }
            state.topBar = topbarObj || {}
            const sidebarObj = data.find(
                (d: any) => d['_id'] === '6661628c92a10faebd365096'
            )
            state.sideBar = sidebarObj || {}
        },
        setTopbar: (state, action: PayloadAction<string>) => {
            const nameToBeChanged = action.payload
            state.topBar.schema[0].fields[0].fields[1].label = nameToBeChanged
        },
        setSidebarItems: (
            state,
            action: PayloadAction<Array<Record<string, any>>>
        ) => {
            const items = action.payload
            state.sideBar['schema'][0]['items'] = items
        },
        setSidebarCounts: (
            state,
            action: PayloadAction<Record<string, any>>
        ) => {
            const counts = action.payload
            state.sideBar['schema'][0]['items'] = state.sideBar['schema'][0]['items'].map((item:any) => {
                if (item.id === 4 && counts["leads"]) {
                    return {...item, isCount: true, count: counts["leads"]}
                } else if (item.id === 10 && counts["calls"]) {
                    return {...item, isCount: true, count: counts["calls"]}
                } else if (item.id === 3 && counts["patients"]) {
                    return {...item, isCount: true, count: counts["patients"]}
                }
                return item;
            })
        },
        setTopbarBranches: (state, action: PayloadAction<Array<Record<string, any>>>)=>{
            if(state && state?.topBar && state?.topBar?.schema){
                state.topBar['schema'].forEach((item:any)=>{
                    if(item.name === "branches"){
                        item.options = action.payload

                    }

                })
                state.branches = action.payload
            }
        },
        setActiveBranch: (state, action: PayloadAction<Record<string, any>>)=>{
            const branch = action?.payload?.branch;
            const reload = action?.payload?.reload;
            if(state && state?.topBar && state?.topBar?.schema){
                state.topBar['schema'].forEach((item:any)=>{
                    if(item.name === "branches"){
                        item["value"] = branch
                    }
                });
                const det = Storage.getItem('details');
                Storage.setItem("details", {...det, branch_id: branch.value, branch_name: branch.label});
                if (reload) {
                    setTimeout(() => {
                        window.location.reload();
                    }, 50);
                }
            }
        },
        setActiveMenu: (state, action: PayloadAction<string>) => {
            const activeMenuValue = action.payload
            if (state.sideBar.schema && state.sideBar.schema[0]) {
                state.sideBar.schema[0].value = activeMenuValue
            }
        },
        setBackiconvisibility:(state,action:PayloadAction<boolean>)=>{
            state.topBar.schema[0].fields[0].fields[0].visible = action.payload;
            return state;
        },
        setProfileName:(state,action:PayloadAction<string>)=>{
            state.topBar.schema[0].fields[2].primaryText=action.payload;
        },
        setProfileImage:(state,action:PayloadAction<any>)=>{
            state.topBar.schema[0].fields[2].img=action.payload;
            state.topBar.schema[0].fields[2].imgClassName="border-gray-500 p-1";

        },
        setTopBarVisibility: (state, action: PayloadAction<boolean>) => {
            const baseClasses = "w-full sticky z-10 sticky-content bg-white top-0 font-semibold p-4 shadow-md transition-all duration-300 ease-in";
            if (action.payload) {
              state.topBar.className = `${baseClasses} -translate-y-full opacity-0`;
            } else {
              state.topBar.className = `${baseClasses} translate-y-0 opacity-100 `;
            }
          },
    },
})

export const getInitialSchemas = (state: RootState) => state.uiSchema.schemas
export const { setUISchemas,setTopBarVisibility, setTopbar, setSidebarItems, setSidebarCounts, setTopbarBranches, setActiveBranch, setActiveMenu,setBackiconvisibility,setProfileName,setProfileImage } =
    uiSchemaSlice.actions

export default uiSchemaSlice.reducer
