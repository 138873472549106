import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICampaignInitState } from "./types";

const initialState: ICampaignInitState = {
    activeTab: "whatsapp"
}
const campaignSlice = createSlice({
    name: "campaign",
    initialState,
    reducers: {
        setActiveTab: (state, action: PayloadAction<string>) =>{
            state.activeTab = action.payload;
        }
    }
})
export const { setActiveTab } = campaignSlice.actions;
export default campaignSlice.reducer;