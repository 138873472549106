import moment from 'moment';
import React, { useEffect, useState } from 'react'
import LoadingState from './LoadingState';
import { ElementExecutor } from '@apexcura/core';
import { toast } from 'react-toastify';
import { CONSTANTS } from '../utils/app-constants';
import Utils from '../utils';
const { FAILURE_MSG, CATCH_ERROR_MSG, AUTO_CLOSE } = CONSTANTS;

const ContactusList = () => {
    const [schema, setSchema] = useState<any>({
        className:
          'w-full bg-white rounded-lg py-[10px] px-[20px] flex flex-col gap-3',
        schema: [
          {
            name: 'filters',
            fields: [
              {
                name: "search",
                label: "",
                placeholder: "Enter Hospital Name...",
                element: "input-text",
                value: null,
                className: "",
              },
              {
                name: 'date',
                label: '',
                placeholder: 'Select a date',
                element: 'daterangepicker',
                value: [moment().subtract(7, 'days').format('DD-MM-YYYY'), moment().format("DD-MM-YYYY")],
                className: 'flex-1',
              },
              {
                name: 'btn_download',
                label: 'Download',
                element: 'button',
                icon: 'download',
                className: 'secondary-button',
                action: 'download_list',
                iconsClassName: 'size-4',
              },
            ],
            className: 'flex self-end gap-2',
          },
          {
            className: 'mt-2',
            name: "table",
            element: 'table',
            loading: true,
            variant: 'plain',
            size: 'small',
            pagination: true,
            view: true,
            visible: true,
            thead: [
              { name: 'fullname', label: 'Name', key: 'fullname' },
              { name: 'email', label: 'Email', key: 'email' },
              {name: 'mobilenumber', label: 'Mobile', key: 'mobilenumber'},
              {
                name: 'profession',
                label: 'Profession',
                key: 'profession',
              },
              { name: 'hospitalname', label: 'Hospital Name', key: 'hospitalname' },
              {
                name: 'city',
                label: 'City',
                key: 'city',
              },
              {
                name: 'message',
                label: 'Message',
                key: 'message',
              },
              {
                name:"createdAt",
                label:"Created At",
                key:"createdAt"
              }
            ],
            tbody: [],
            modalContent: {
              className: "flex flex-col gap-2 overflow-auto max-h-[400px] p-[10px]",
              fields: [],
            },
          },
        ],
    });
    const [loading,setLoading]=useState(false);
    const [dateRange, setDateRange] = useState<[string, string]>([
      moment().subtract(7, 'days').format('DD-MM-YYYY'),
      moment().format('DD-MM-YYYY'),
    ])

    const [searchText, setSearchText] = useState('');

    useEffect(() => {
      API_GET_Data({ startDate: dateRange[0], endDate: dateRange[1] })
    }, [])

    useEffect(() => {
      const id = setTimeout(() => {
          API_GET_Data({
              startDate: dateRange[0],
              endDate: dateRange[1],
              searctext: searchText,
          })
      }, 300)

      return () => {
          clearTimeout(id)
      }
    }, [searchText])


    const downloadToExcel = async () => {
      try {
          const tableSchema = schema.schema.find(
              (s: any) => s.element === 'table'
          )
          const { thead } = tableSchema
          setLoading(true)

          const response = await Utils.makeApiCall(
              `/contactForm/getAllContactDetails?page=1&limit=30&startDate=${dateRange[0]}&endDate=${dateRange[1]}&download=true&search=${searchText}`,
              'GET'
          )
          const data = response.data
          setLoading(false)

          if (!data || data.length === 0) {
              toast.error('No data available to download', {
                  position: 'top-center',
                  autoClose: 2000,
              })
              return
          }

          Utils.exportToExcel(thead, data, 'contactus-list')
      } catch (error) {
          console.error('Error downloading Excel:', error)
          toast.error('Failed to download data as Excel', {
              position: 'top-center',
              autoClose: 2000,
          })
      }
    }

    const API_GET_Data = async ({
      page = 1,
      limit =30,
      startDate = '',
      endDate = '',
      searctext = '',
      } = {}) => {
      try {
          const res = await Utils.makeApiCall(
              `/contactForm/getAllContactDetails?page=${page}&limit=${limit}&startDate=${startDate}&endDate=${endDate}&search=${searctext}`,
              'GET'
          )
          if (res.status) {
              setSchema((prev: any) => {
                  const ps = prev.schema
                  ps.map((s: any) => {
                      if (s.element === 'table') {
                          s.count = res.total
                          s["tbody"] = res.data.map((acc: any) => {
                            const createdAt = acc["createdAt"]
                              ? moment(acc["createdAt"]).format("DD-MM-YYYY HH:mm:ss")
                              : "";
                            return { ...acc, createdAt };
                          });
                          s['loading'] = false;
                      }
                      return s
                  })
                  return { ...prev, schema: ps }
              })
          } else {
              toast.error(res.message ? res.message : FAILURE_MSG, {
                  position: 'top-center',
                  autoClose: AUTO_CLOSE,
              })
          }
      } catch (error) {
          toast.error(CATCH_ERROR_MSG, {
              position: 'top-center',
              autoClose: AUTO_CLOSE,
          })
          console.error('Failed to fetch schema', error)
      }

      setSchema((prev: any) => Utils.handleLoading(prev, false))
    }

    const handleSelectedRecord=(e:any)=>{
      if (e.name === 'search') {
          setSearchText(e.value)
      }
      if (e.name === 'btn_download') {
          downloadToExcel()
      }
      if (e.name === 'date') {
        if (e.value === '') {
            setDateRange(['', ''])

            setSchema((prevState: any) => {
                const updatedSchema = prevState.schema.map((s: any) => {
                    if (s.element === 'table') {
                        s.value = {
                            name: 'pagination',
                            page: 1,
                            pageSize: 30,
                        }
                    }
                    return s
                })
                return { ...prevState, schema: updatedSchema }
            })
            API_GET_Data({
                startDate: '',
                endDate: '',
                searctext: searchText,
            })
        } else {
            setDateRange(e.value)
            API_GET_Data({
                startDate: e.value[0],
                endDate: e.value[1],
                searctext: searchText,
            })
        }
    }
      if (e.name === 'table') {
        if (e.value?.name === 'pagination') {
            const { page, pageSize } = e.value
            API_GET_Data({
                page: page,
                limit: pageSize,
                startDate: dateRange[0],
                endDate: dateRange[1],
                searctext: searchText,
            })
        }
      }

    }

  return (
    <div className="bg-[#EFF1F9]">
      <LoadingState isLoading={loading} />
      <ElementExecutor
        data={schema}
        selectedRecord={(e: any) => {
          handleSelectedRecord(e);
        }}
      />
    </div>
  )
}

export default ContactusList;
