import React, { useEffect, useState } from 'react'
import { Tooltip,Popover,Select } from "antd";
import moment from 'moment';
import { ElementExecutor } from '@apexcura/core'
import { useNavigate } from 'react-router-dom';
import Utils from '../utils';
import { toast } from 'react-toastify';
import { CONSTANTS } from '../utils/app-constants';
import { useDispatch, useSelector } from 'react-redux';
import { setBranches, setModules } from '../redux/compareUnits/compareUnitsSlice';
import { setLoading } from '../redux/appState/appStateSlice';
import { RootState } from '../redux/rootReducer';
const { FAILURE_MSG, CATCH_ERROR_MSG, AUTO_CLOSE } = CONSTANTS;

const CompareUnits = () => {
    const [schema, setSchema] = useState<any>({
        className:
          'w-full bg-white rounded-lg py-[10px] px-[20px] flex flex-col gap-3',
        schema: [
          {
            name: 'filters',
            fields: [
              {
                name:"date_and_search",
                className:"flex gap-2 justify-center items-center ",
                fields:[
                  {
                      name:"segments",
                      element:"segmented",
                      segmentedOptions:[
                        {
                            label:(<Tooltip title="Today">1D</Tooltip>),
                            value:"TD"
                        },
                        // {
                        //     label:(<Tooltip title="Yesterday">YD</Tooltip>),
                        //     value:"YD"
                        // },
                        {
                            label:(<Tooltip title="This Week">1W</Tooltip>),
                            value:"TW"
                        },
                        {
                            label:(<Tooltip title="This Month">1M</Tooltip>),
                            value:"TM"
                        },
                      ],
                      visible:true,
                      value:"TW"
                  },
                  {
                    name: 'date',
                    label: '',
                    placeholder: 'Select a date',
                    element: 'daterangepicker',
                    value: [moment().subtract(7, 'days').format('DD-MM-YYYY'), moment().format("DD-MM-YYYY")],
                    className: 'flex-1 w-[240px]',
                  },
                  
                ]
              },
              {
                name:"refresh_and_download",
                className:"flex gap-2 justify-center items-center ",
                fields:[
                    {
                        name: "dashboard",
                        label: "Dashboard",
                        element: "button",
                        visible: false,
                        className:
                          "p-2 px-3 text-default-500 bg-[#3341bb] rounded-md text-white font-semibold self-end",
                    },
                ]
              },
            ],
            className: 'flex items-center justify-between gap-2',
          },
          {
              className: 'mt-2',
              name: 'table',
              element: 'table',
              variant: 'plain',
              size: 'small',
              count: 0,
              view: true,
              showSerialNumber: false,
              visible: true,
              loading: false,
              pagination: false,
              expandable: true,
              rowClick:true,
              expandIcon:true,
              rowClickExpandable:true,
              value: { name: 'pagination', page: 1, pageSize: 30 },
              thead:  [               
                {
                  label:"Module",
                  name:"module",
                  key:"module",
                  sortable:false,
                  render:(e:any)=>{
                    return(
                      <span className='capitalize'>{e}</span>
                    )
                  }
                },
              ],
              tbody:[

            ]
          },
      ] 
    });
    const [dateRange, setDateRange] = useState<[string, string]>([
      moment().subtract(7, 'days').format('DD-MM-YYYY'),
      moment().format('DD-MM-YYYY'),
    ])
    const navigate = useNavigate()
    const dispatch=useDispatch();
    const branches:any=useSelector((store:any)=>store.compareUnits.branches)
    // const [branches,setBranches]=useState<any>([]);
    const defaultBranches:any=useSelector((store:RootState)=>store.appState.branches)

    useEffect(()=>{
      change_dropdown_option();
    },[])

    useEffect(() => {
      if(dateRange[0]==="" && dateRange[1]===""){
          change_segments_value(null);
      }else{
        const first = moment(dateRange[0], 'DD-MM-YYYY');
        const second = moment(dateRange[1], 'DD-MM-YYYY');
        const diffInDays = second.diff(first, 'days');
        const today = moment().startOf('day');
  
        if (first.isSame(today, 'day') && second.isSame(today, 'day')) {
            change_segments_value("TD")
        } else if (diffInDays === 7 && second.isSame(today, 'day')) {
            change_segments_value("TW")
        } else if (diffInDays === 30 && second.isSame(today, 'day')) {
            change_segments_value("TM")
        }else{
            change_segments_value(null)
        }
  
      }
      
      const branchess:string[]=branches.map((item:{label:string,value:string})=>item?.value);
      const modules:string[]=[
        "leads",
        "campaigns",
        "patients",
        "users"
    ];
      if(defaultBranches?.length && (branches.length>0 || modules.length>0)){
        API_GET_Table_Data(modules,branchess,dateRange[0],dateRange[1]);
      }
          
    }, [defaultBranches, branches,dateRange]);

    const branch_dropdown=() =>{
      const label:string=branches.length===0 ? "Add a Branch" : "Add another branch to compare "
      return {
        label:<Popover content={<Select labelInValue placeholder="Select branch(es)" className='w-[200px]' mode="multiple" value={[...branches]} options={[...defaultBranches]} onChange={(value:any)=> {handleSelectedRecord({name:"branches",value:value})}} />}> <div className='text-xs cursor-pointer text-gray-600 rounded-full flex gap-1 items-center justify-center'><p className='text-lg'>+</p><p>{label}</p></div></Popover>,
        sortable:false,
    } 
  }

    const change_table_data=(data:any)=>{
      setSchema((prev:any)=>{
        const updatedSchema=prev.schema.map((field:any)=>{
          if(field.name==="table"){
            field.thead=[
              {
                label:"Module",
                name:"module",
                key:"module",
                sortable:false,
                render:(e:any, record:any)=>{
                  return <span className={`capitalize ${record?.children ? "font-semibold ml-2" : ""}`}>{e}</span>
                }
              },
              ...data.thead.map((item:any)=>{
                return {
                  name:item.name,
                  label:<p className='text-center'>{item.label}<span role='presentation' className='text-red-500 pl-2 text-xl cursor-pointer' onClick={()=>removeBranch(item)}>x</span></p>,
                  sortable:false,
                  key:item.key,
                  render:(e:any, record:any)=>{
                    return <p className={`text-center ${record?.children ? "font-semibold" : ""}`}>{e}</p>
                  }
                }
              }),
              
            ]
            if(branches.length!==defaultBranches.length){
              field.thead.push(branch_dropdown())
            }
          
            field.tbody=data.data
          }
          return field;
        })
        return {...prev,schema:updatedSchema}
      })
    }

    const removeBranch=(item:any)=>{
      setSchema((prev:any)=>{
        const updatedSchema=prev.schema.map((field:any)=>{
          if(field.name==="table"){
            field.thead=field.thead.filter((ite:any)=>ite.key!==item.key)
          }
          return field;
        })
        return {...prev,schema:updatedSchema}
      })

      const updatedbranches:any=branches.filter((field:any)=>field.key!==item.key)
      dispatch(setBranches(updatedbranches))
    }

    const change_segments_value=(val:string | null)=>{
      setSchema((prev:any)=>{
          const updatedSchema=prev.schema.map((field:any)=>{
              if(field.name==="filters"){
                field.fields=field.fields.map((ffield:any)=>{
                  if(ffield.name==="date_and_search"){
                    ffield.fields=ffield.fields.map((fffield:any)=>{
                      if(fffield.name==="segments"){
                        fffield.value=val
                      }
                      return fffield;
                    })
                  }
                  return ffield;
                })
              }
              return field;
          })
          return {...prev,schema:updatedSchema}
      })
    }

    const change_dropdown_option=()=>{
      setSchema((prev:any)=>{
        const updatedSchema=prev.schema.map((field:any)=>{
          if(field.name==="table"){
            field.thead=[
              {
                label:"Module",
                name:"module",
                key:"module",
                sortable:false,
                render:(e:any)=>{
                  return(
                    <span className='capitalize'>{e}</span>
                  )
                }
              },
              branch_dropdown()
            ]
          }
          return field;
        })
        return {...prev,schema:updatedSchema}
      })
    }

    // const API_GET_Branches = async () => {
    //   try {
    //       const res: any = await Utils.makeApiCall(`/getUserBranches`, "GET");
    //       if (res.status) {
    //           change_dropdown_option(res?.data);
    //       } else {
    //           toast.error(res.message ? res.message : FAILURE_MSG, { position: 'top-center', autoClose: AUTO_CLOSE })
    //       }
    //   } catch (error) {
    //       toast.error(CATCH_ERROR_MSG, { position: 'top-center', autoClose: AUTO_CLOSE });
    //       console.error('Failed to fetch schema', error);
    //   }
    // }

    const API_GET_Table_Data = async (modules: string[], branches: string[],startDate:string,endDate:string) => {
      dispatch(setLoading(true))
      const payLoad: any = {
          "modules": modules,
          "branches": branches
      };
      try {
          const res: any = await Utils.makeApiCall(`/compareUnits?startDate=${startDate}&endDate=${endDate}`, "POST", payLoad);
          if (res.status) {
              change_table_data(res);
          } else {
              toast.error(res.message ? res.message : FAILURE_MSG, { position: 'top-center', autoClose: AUTO_CLOSE })
          }
      } catch (error) {
          toast.error(CATCH_ERROR_MSG, { position: 'top-center', autoClose: AUTO_CLOSE });
          console.error('Failed to fetch schema', error);
      }
      dispatch(setLoading(false))
    }

    const handleSelectedRecord=(e:any)=>{
        if(e.name==="dashboard"){
            navigate("/dashboard")
        }
        if(e.name==="branches"){
          dispatch(setBranches(e.value))
        }
        if (e.name === 'date') {
          if (e.value === '') {
              setDateRange(['', ''])
          } else {
              setDateRange(e.value)
          }
        }
        if (e.name === "segments") {
          if (e.value === "TD") {
              setDateRange([
                  moment().format('DD-MM-YYYY'),
                  moment().format('DD-MM-YYYY'),
              ]);
              setSchema((prev: any) => {
                  const updatedSchema = prev.schema.map((field: any) => {
                      if (field.name === "filters") {
                          field.fields = field.fields.map((ffield: any) => {
                              if (ffield.name === "date_and_search") {
                                  ffield.fields = ffield.fields.map((ff: any) => {
                                      if (ff.name === "date") {
                                          ff.value = [
                                              moment().format('DD-MM-YYYY'),
                                              moment().format('DD-MM-YYYY'),
                                          ];
                                      }
                                      return ff;
                                  });
                              }
                              return ffield;
                          });
                      }
                      if(field.element==="table"){
                        field.value={
                            name: 'pagination',
                            page: 1,
                            pageSize: 30,
                        }
                    }
                      return field;
                  });
                  return { ...prev, schema: updatedSchema };
              });
          }
          if (e.value === "TM") {
              setDateRange([
                  moment().subtract(30, 'days').format('DD-MM-YYYY'),
                  moment().format('DD-MM-YYYY'),
              ]);
              setSchema((prev: any) => {
                  const updatedSchema = prev.schema.map((field: any) => {
                      if (field.name === "filters") {
                          field.fields = field.fields.map((ffield: any) => {
                              if (ffield.name === "date_and_search") {
                                  ffield.fields = ffield.fields.map((ff: any) => {
                                      if (ff.name === "date") {
                                          ff.value = [
                                              moment().subtract(30, 'days').format('DD-MM-YYYY'),
                                              moment().format('DD-MM-YYYY'),
                                          ];
                                      }
                                      return ff;
                                  });
                              }
                              return ffield;
                          });
                      }
                      if(field.element==="table"){
                        field.value={
                            name: 'pagination',
                            page: 1,
                            pageSize: 30,
                        }
                    }
                      return field;
                  });
                  return { ...prev, schema: updatedSchema };
              });
          }
          if (e.value === "TW") {
              setDateRange([
                  moment().subtract(7, 'days').format('DD-MM-YYYY'),
                  moment().format('DD-MM-YYYY'),
              ]);
              setSchema((prev: any) => {
                  const updatedSchema = prev.schema.map((field: any) => {
                      if (field.name === "filters") {
                          field.fields = field.fields.map((ffield: any) => {
                              if (ffield.name === "date_and_search") {
                                  ffield.fields = ffield.fields.map((ff: any) => {
                                      if (ff.name === "date") {
                                          ff.value = [
                                              moment().subtract(7, 'days').format('DD-MM-YYYY'),
                                              moment().format('DD-MM-YYYY'),
                                          ];
                                      }
                                      return ff;
                                  });
                              }
                              return ffield;
                          });
                      }
                      if(field.element==="table"){
                        field.value={
                            name: 'pagination',
                            page: 1,
                            pageSize: 30,
                        }
                    }
                      return field;
                  });
                  return { ...prev, schema: updatedSchema };
              });
          }
        }
    }


  return (
    defaultBranches?.length > 0 ? <div>
      <ElementExecutor
        data={schema}
        selectedRecord={(e: any) => {
          handleSelectedRecord(e);
        }}
      />
    </div> : <></>
  )
}

export default CompareUnits
