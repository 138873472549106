import { ElementExecutor } from "@apexcura/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { CONSTANTS } from "../../utils/app-constants";
import Storage from "../../utils/local-storage";
import { getPagePermissions } from "../../redux/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip,Popconfirm,Avatar,Switch } from "antd";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { setLoading } from "../../redux/appState/appStateSlice";
const { FAILURE_MSG, CATCH_ERROR_MSG, AUTO_CLOSE } = CONSTANTS;
const { BASE_URL } = CONSTANTS;
const Users = () => {
    const details = Storage.getItem("details");
    const {branch_id,is_multibranch}=details
    const [schema, setSchema] = useState<any>({
        className:
            "w-full bg-white rounded-lg py-[10px] px-[20px] flex flex-col gap-3",
        schema: [
            {
              name: "filters",
              fields: [
                  {
                    name: "search",
                    label: "",
                    placeholder: "Search by Name",
                    isSearch:true,
                    element: "input-text",
                    value: null,
                    icon:"search",
                    containerClassName: 'w-60 flex flex-col rounded-3xl overflow-hidden',
                    className: 'w-full rounded-3xl p-2 px-3 font-medium bg-white overflow-hidden',
                    iconsClassName:"w-[15px] mr-1 flex justify-center items-center",
                  },
                  {
                    name:"segments_and_add",
                    className:"flex gap-2 items-center",
                    fields:[
                      {
                        name:"segments",
                        element:"segmented",
                        segmentedOptions:[
                        ],
                        visible:is_multibranch,
                        value:branch_id,
                      },
                      {
                        name: "btn_add",
                        label: "Add",
                        element: "button",
                        icon: "add",
                        visible: false,
                        className: "add-button ",
                        iconsClassName: "size-4",
                      },
                    ]
                  }
              ],
              className: "flex justify-between items-center gap-2",
            },
            {
              className: "mt-2",
              name: "table",
              element: "table",
              variant: "plain",
              size: "small",
              count: 0,
              visible: true,
              isStatus:false,
              rowClick: true,
              loading: true,
              pagination: true,
              thead: [
                  { name: "fullname", label: "Name", key: "fullname",
                    fixed: "left",
                    render: (e:any, record:any) =>{               
                      if(e){
                        return (
                          <div className="flex gap-1">
                              <Avatar size={"small"} style={{backgroundColor:`${Utils.stringToColour(e)}`}}> 
                                  <p className={`capitalize`}>{Utils.initials(e)}</p>
                              </Avatar> 
                              {<p className={`capitalize`}>{e}</p>}
                          </div>
                  
                        )
                      }
                    }
                  },
                  { name: "phonenumber", label: "Mobile", key: "phonenumber" },
                  {
                  name: "email",
                  label: "Email",
                  key: "email",
                  },
                  {
                    name: "designation_name",
                    label: "Designation",
                    key: "designation_name",
                  },
                  { name: "access_policies", label: "Access Policies", key: "access_policies", render: (e:any, record:any) => <span>{record["access_policies"]?.length || 0}</span> },
                  { name: "status", label: "Status", key: "status",
                    sortable: false,
                    fixed: "right",
                    render: (e:any,record:any) =>{     
                        return (
                           record?.id !== details?.id &&
                            <div className="flex justify-center items-center" role="presentation" onClick={(e)=> e?.stopPropagation()}>
                              <Switch defaultChecked={record?.is_active===1 ? true : false} key={record?.id}  size="small" onChange={(checked:any)=>API_CHANGE_STATUS(record?.id || "", checked)}/>
                            </div>
                        )
                      }
                   },
              ],
              tbody: [
              ],
            },
            {
              className:
                '!w-full',
              name: 'table_modal_container',
              visible: false,
              element:"modal",
              containerClassName:"!w-[70%]",
              fields: [
                {
                  name: 'table_modal',
                  className:
                    'flex w-full border shadow-lg rounded-lg flex-col bg-white overflow-hidden',
                  fields: [
                    {
                      name: 'modal_title',
                      className:
                        'w-full bg-primary/5 p-2 pl-4 flex items-center justify-between',
                      fields: [
                        {
                          name: 'modal_title_key',
                          element: 'div',
                          label: 'User details',
                          className:
                            'font-semibold text-lg text-primary',
                        },
                        {
                          name: 'modal_close',
                          element: 'button',
                          label: 'X',
                          className:
                            'w-[40px] h-[40px] flex justify-center items-center rounded-full bg-primary text-xl text-white',
                        },
                      ],
                    },
                    {
                      name: 'modal_header',
                      className:
                        'grid grid-cols-3 gap-4 shadow-sm p-2 px-4 pb-6',
                        cols: [
                          {
                            name: 'fullname',
                            label: 'Name',
                          },
                          {
                            name: 'phonenumber',
                            label: 'Mobile',
                          },
                          // {
                          //   name: 'access_policies',
                          //   label: 'Access Policies',
                          // },
                          {
                            name: 'email',
                            label: 'Email',
                          },
                        ],
                      fields: [],
                    },
                  ],
                },
              ],
            },
        ],
    });
    const navigate=useNavigate();
    const dispatch = useDispatch();
    const [searchText, setSearchText] = useState('');
    const org_id = Storage.getItem("details")?.["org_id"];
    const [branchid,setBranchId]=useState(branch_id);
    const pagePermissions = useSelector(getPagePermissions)

    useEffect(() => {
      const id = setTimeout(() => {
          API_GET_Data({searctext: searchText,})
      }, 300)

      return () => {
          clearTimeout(id)
      }
    }, [searchText])

    useEffect(()=>{
      API_GET_Branches();
    },[])

    const API_GET_Data = async ({
      searctext = '',
      page = 1,
      limit = 30,
      branchId=branchid
    } = {}) => {
      setSchema((prev: any) => Utils.handleLoading(prev, true))
      try {
          const res = await Utils.makeApiCall(`/user-management?org_id=${org_id}&page=${page}&limit=${limit}&search=${searctext}&branch_id=${branchId}`,'GET')
          if (res.status) 
          {
            const add = pagePermissions.find((r:any) => r.route === location.pathname)?.add || false;
            const view = pagePermissions.find((r:any) => r.route === location.pathname)?.view || false;
            const edit = pagePermissions.find((r:any) => r.route === location.pathname)?.edit || false;
            const pdelete = pagePermissions.find((r:any) => r.route === location.pathname)?.delete || false;
              setSchema((prev: any) => {
                  const ps = prev.schema
                  ps.map((s: any) => {
                      if (s.element === 'table') {
                          s.count = res.total
                          s['tbody'] = res.data
                          const hasactions = s["thead"].some((ef:any)=>ef.name === "actions");
                          if ((view || edit || pdelete) && !hasactions){
                            
                            s['thead'].push({
                              name: "actions",
                              label: "",
                              key: "actions",
                              fixed: "right",
                              sortable:false,
                              render: (e: any, record: any) => {
                                  return (
                                    <div className='flex gap-2 justify-center items-center'>
                                        {/* {view && <div
                                          // onKeyDown={() =>
                                          //   handleSelectedRecord({ name: "view", value: record })
                                          // }
                                          // tabIndex={0}
                                          // role="button"
                                          role= "presentation"
                                          onClick={() => 
                                            handleSelectedRecord({ name: "view", value: record })
                                          }
                                        >
                                          <Tooltip title="View">
                                          <img
                                            src={
                                              `${BASE_URL}/api/public/assets/images/eye.svg`
                                            }
                                            alt="View the record"
                                            className='w-4 h-4'
                                          />
                                          </Tooltip>
                                        </div>} */}
                                        {edit && <div
                                          // onKeyDown={() =>
                                          //   handleSelectedRecord({ name: "edit", value: record })
                                          // }
                                          // tabIndex={0}
                                          // role="button"
                                          role= "presentation"
                                          onClick={(e) => {
                                            e?.stopPropagation();
                                            handleSelectedRecord({ name: "edit", value: record })
                                          }
                                          }
                                        >
                                          <Tooltip title="Edit">
                                          <img
                                            src={
                                              `${BASE_URL}/api/public/assets/images/edit.svg`
                                            }
                                            alt="Comment"
                                            className='w-4 h-4'
                                          />
                                          </Tooltip>
                                        </div>}
                                        
                                 
                                      {pdelete && record?.id !== details?.id && <div
                                        role="presentation"
                                        onClick={(e)=> e?.stopPropagation()}
                                        // onKeyDown={() =>
                                        //   handleSelectedRecord({ name: "delete", value: record })
                                        // }
                                        // tabIndex={0}
                                        // role="button"
                                        // onClick={() =>
                                        //   handleSelectedRecord({ name: "delete", value: record })
                                        // }
                                      >
                                        <Popconfirm 
                                            title="Are you sure you want to delete this user record?" 
                                            onConfirm={()=> handleSelectedRecord({name: 'delete',value: record})}
                                            okButtonProps={{
                                              style: { backgroundColor: '#FF0000' }
                                            }}
                                            okText="Delete"
                                            icon={<ExclamationCircleOutlined style={{ color: '#FF0000' }} />}
                                            >
                                            <img
                                              src={
                                                `${BASE_URL}/api/public/assets/images/discard-red.svg`
                                              }
                                              alt="Comment"
                                              className='w-5 h-5'
                                            />
                                        </Popconfirm>
                                      </div>}
                                      


                              
                                        
                                    </div>
                                  )  
                              },
                            } )
                          }
                          s['loading'] = false
                      } else if (s.name === "filters") {
                        s.fields.map((f:any)=>{
                          if (f.name === "segments_and_add"){
                            f.fields.map((ffield:any)=>{
                              if(ffield.name==="btn_add"){
                                ffield.visible=add;
                              }

                              return ffield;
                            })
                          }
                          return f;
                        })
                      }
                      return s
                  })
                  return { ...prev, schema: ps }
              })
          } 
          else {
              toast.error(res.message ? res.message : FAILURE_MSG, {
                  position: 'top-center',
                  autoClose: AUTO_CLOSE,
              })
          }
      } catch (error) {
          toast.error(CATCH_ERROR_MSG, {
              position: 'top-center',
              autoClose: AUTO_CLOSE,
          })
          console.error('Failed to fetch schema', error)
      }
      setSchema((prev: any) => Utils.handleLoading(prev, false))
    }

    const API_GET_Branches=async ()=>{
      try {
          const res: any = await Utils.makeApiCall(`/getUserBranches`, "GET");
          if (res.status) {
              setSchema((prev:any)=>{
                const updatedSchema=prev.schema.map((field:any)=>{
                  if(field.name==="filters"){
                    field.fields=field.fields.map((ffield:any)=>{
                      if(ffield.name==="segments_and_add"){
                        ffield.fields=ffield.fields.map((fffield:any)=>{
                          if(fffield.name==="segments"){
                            fffield.segmentedOptions=[
                              {
                                label:"All",
                                value:"all"
                            },
                            ...res.data.map((item:any)=>({label:item.branch_name, value:item.branch_id }))
                            ]
                          }
                          return fffield;
                        })
                      }
                      return ffield;
                    })
                  }
                  return field;
                })
                return {...prev,schema:updatedSchema}
              })

          } else {
              toast.error(res.message ? res.message : FAILURE_MSG, { position: 'top-center', autoClose: AUTO_CLOSE })
          }
      } catch (error) {
          toast.error(CATCH_ERROR_MSG, { position: 'top-center', autoClose: AUTO_CLOSE });
          console.error('Failed to fetch schema', error);
      }
  }

    const API_DELETE_User = async (record: any) => {
      // console.log(record)
      setSchema((prev: any) => Utils.handleLoading(prev, true));
      try {
        const response: any = await Utils.makeApiCall(
          `/user-management/${record.value.id}/${org_id}`,
          "DELETE",
        );
        if (response && response.status) {
          toast.success(response.message, {
            position: "top-center",
            autoClose: 2000,
          });
          API_GET_Data();
        } else {
          toast.error(response.message, {
            position: "top-center",
            autoClose: 2000,
          });
        }
      } catch (error) {
        toast.error(CATCH_ERROR_MSG, {
          position: "top-center",
          autoClose: 2000,
        });
        console.error("Failed to delete the data:", error);
      } finally {
        setSchema((prev: any) => Utils.handleLoading(prev, false));
      }
    };

    const API_GET_Details=async (userId:string)=>{
      dispatch(setLoading(true))
        try {
          const res = await Utils.makeApiCall(`/user-management/${userId}/${org_id}`,'GET')
          if (res.status) 
          {
              handleModaldata({data:res.data,visible:true})
          } 
          else {
              toast.error(res.message ? res.message : FAILURE_MSG, {
                  position: 'top-center',
                  autoClose: AUTO_CLOSE,
              })
          }
      } catch (error) {
          toast.error(CATCH_ERROR_MSG, {
              position: 'top-center',
              autoClose: AUTO_CLOSE,
          })
          console.error('Failed to fetch schema', error)
      } finally{
        dispatch(setLoading(false))
      }
    }

    const API_CHANGE_STATUS=async (userid: any,status:any) => {
      // console.log(status)
      const isActive=status===true ? 1 : 0 ;
      try {
        const response: any = await Utils.makeApiCall(`/user-management/status/${userid}`,"PUT",{is_active:isActive});
        if (response && response.data) {
          if(response?.data?.is_active===1){
            toast.success("User status is enabled.", {position: "top-center",autoClose: AUTO_CLOSE});
            return;
          }
          toast.success("User status is disabled.", {position: "top-center",autoClose: AUTO_CLOSE});
        } else {
          toast.error(response.message, {position: "top-center",autoClose: AUTO_CLOSE});
        }
      } catch (error) {

        toast.error(CATCH_ERROR_MSG, {position: "top-center",autoClose: AUTO_CLOSE});
        console.error("Failed to change the status:", error);

      } 
    };

    const handleModaldata = ({
      data = {},
      visible = true,
      
    }: any) => {
      setSchema((prev: any) => {
        const ps = prev.schema
        ps.map((s: any) => {
          if (s.name === 'table_modal_container') {
            s['visible'] = visible
            if (s?.fields?.length) {
              s.fields.map((f: any) => {
                if (f.name === 'table_modal') {
                  if (f?.fields?.length) {
                    f.fields.map((ff: any) => {
                      if (ff.name === 'modal_header') {
                        if (ff?.cols?.length) {
                          ff['fields'] = ff.cols.map(
                            (item: any) => {
                              return {
                                name: item.name,
                                className:
                                  'flex justify-start gap-2',
                                fields: [
                                  {
                                    name: `${item.name}_key`,
                                    element:
                                      'div',
                                    label: item.label,
                                    className:
                                      'font-thin',
                                  },
                                  {
                                    name: `${item.name}_val`,
                                    element:
                                      'div',
                                    label: `${data ? data[item.name] || '--' : '--'}`,
                                    className:
                                      'font-semibold',
                                  },
                                ],
                              }
                            }
                          )
                        }
                      }
                      return ff
                    })
                  }
                  return f
                }
                return f
              })
            }
          }
          return s
        })
        return { ...prev, schema: ps }
      })
    }

    const handleSelectedRecord=async (e:any)=>{
        if(e.name === "btn_add"){
          navigate("/users/add")
        }
        if(e.name==="search"){
          setSchema((prevState: any) => {
            const updatedSchema = prevState.schema.map((s: any) => {
              if (s.element === "table") {
                s.value = { name: 'pagination', page: 1, pageSize: 30 };
              }
              return s;
            });
            return { ...prevState, schema: updatedSchema };
          });
          setSearchText(e.value)
        }
        if(e.name==="delete"){
          API_DELETE_User(e)
        }
        if(e.name==="view"){
          navigate(`/users/view/${e.value.id}`)
        }
        if(e.name==="edit"){
          navigate(`/users/edit/${e.value.id}`)
        }
        if(e.name==="modal_close"){
          handleModaldata({data:{},visible:false})
        }
        if (e.name === "table") {

          if (e.value?.name === "pagination") {
            //console.log(e);
            const { page, pageSize } = e.value;
            API_GET_Data({
              page: page,
              limit: pageSize,
              searctext: searchText
            });
          }
          if (e?.["value"]?.name === "view") {
            const id =  e["value"]?.["value"]?.["id"];
            navigate(`/users/view/${id}`)
          }
        }
        if(e.name==="segments"){
          setSchema((prevState: any) => {
            const updatedSchema = prevState.schema.map((s: any) => {
              if (s.element === "table") {
                s.value = { name: 'pagination', page: 1, pageSize: 30 };
              }
              return s;
            });
            return { ...prevState, schema: updatedSchema };
          });
          setBranchId(e.value)
          API_GET_Data({
            page: 1,
            limit: 30,
            searctext:searchText,
            branchId:e.value
          })
          
        }
    }

  return (
    <div>
      <ElementExecutor
        data={schema}
        selectedRecord={handleSelectedRecord}
      />
    </div>
  )
}

export default Users
