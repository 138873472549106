(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("highcharts"), require("react"));
	else if(typeof define === 'function' && define.amd)
		define(["highcharts", "react"], factory);
	else if(typeof exports === 'object')
		exports["ReactHighchartsPackage"] = factory(require("highcharts"), require("react"));
	else
		root["ReactHighchartsPackage"] = factory(root["highcharts"], root["react"]);
})(this, (__WEBPACK_EXTERNAL_MODULE__713__, __WEBPACK_EXTERNAL_MODULE__155__) => {
return 