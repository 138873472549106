import { ElementExecutor } from '@apexcura/core';
import React, { useEffect, useState } from 'react'
import { unstable_usePrompt, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CONSTANTS } from '../../utils/app-constants';
import Utils from '../../utils';
import LoadingState from '../LoadingState';
import Storage from '../../utils/local-storage';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../redux/store';
import { RootState } from '../../redux/rootReducer';
import useExitPopUp from '../../hooks/useExitPopUp';
const { FAILURE_MSG, CATCH_ERROR_MSG, AUTO_CLOSE } = CONSTANTS;


const AddDepartment = () => {
  const [schema, setSchema] = useState<any>({
    className: "w-full bg-white rounded-lg p-8 flex flex-col gap-3 ",
    schema: []
  });
  const navigate=useNavigate();
  const [loading,setLoading]=useState(false)
  const params = useParams();
  const departmentId = params?.departmentId || "";
  
  const { pathname } = useLocation();
  const slugs = pathname.split("/");
  const isView = slugs.includes("view");

  const org_id = Storage.getItem("details")?.["org_id"];
  const branch_id = Storage.getItem("details")?.["branch_id"]


  useEffect(() => {
    const schemaArr =  [
      {
        name: "heading",
        label: 'Add New Department',
        element: "div",
        className: "text-start items-start font-semibold text-xl"
      },
      {
        name: "data_fields",
        className: "w-full grid grid-cols-12 gap-4",
        fields: [
          {
            name: "dept_code",
            element: "input-text",
            placeholder: "",
            label: "Department Code",
            containerClassName: "w-full flex flex-col col-span-6",
            labelClassName:
              "text-sm mb-1 text-gray-500 font-semibold text-start",
            className:
              "rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none ",
            required: true,
            visible: true,
          },
          {
            name: "dept_name",
            element: "input-text",
            placeholder: "",
            label: "Department Name",
            containerClassName: "w-full flex flex-col col-span-6",
            labelClassName:
              "text-sm mb-1 text-gray-500 font-semibold text-start",
            className:
              "rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none ",
            required: true,
            visible: true,
          },
        //   {
        //     name: "phone_number",
        //     element: "input-number",
        //     placeholder: "",
        //     label: "Mobile",
        //     containerClassName: "w-full flex flex-col col-span-3",
        //     labelClassName:
        //       "text-sm mb-1 text-gray-500 font-semibold text-start",
        //     className:
        //       "rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none ",
          
        //     visible: true,
        //   },
        ],
      },
      {
        name: "cancel_and_submit",
        fields: [
          {
            name: "cancel",
            label: "Cancel",
            element: "button",
            className:
              "p-2 px-3 text-default-500 bg-gray-200 text-black rounded-md font-semibold",
          },
          {
            name: "submit",
            label: "Submit",
            element: "button",
            visible: !isView,
            className:
              "p-2 px-3 text-default-500 bg-[#3341bb] rounded-md text-white font-semibold",
          },
        ],
        className: "flex self-end gap-2 mt-4",
      },
    ];
   
    setSchema((prev: any) => {
      return { ...prev, schema: schemaArr }
    })
  }, [])

  const API_GET_Details=async (departmentId:string)=>{
    setLoading(true);
    try {
      const res = await Utils.makeApiCall(`/department?department_id=${departmentId}`,'GET');
        
      if (res.status) 
      {
        handleGetDetails(res.data || {});
      }
      else {
          toast.error(res.message ? res.message : FAILURE_MSG, {
              position: 'top-center',
              autoClose: AUTO_CLOSE,
          })
      }
    } catch (error) {
        toast.error(CATCH_ERROR_MSG, {
            position: 'top-center',
            autoClose: AUTO_CLOSE,
        })
        console.error('Failed to fetch schema', error)
    }
    setLoading(false);
  }

  useEffect(()=>{
    if(departmentId){
      
      API_GET_Details(departmentId)

      setSchema((prev:any)=>{
        prev.schema.map((field:any)=>{
            if(field.name==="heading"){
                field.label = "Edit Department"
            }
        })

        return {...prev}
      })
    }
  }, [departmentId])
  


  const handleGetDetails=(data:any)=>{
    setSchema((prev:any)=>{
      const updatedSchema=prev.schema.map((field:any)=>{
          if(field.name==="data_fields"){
                field?.fields?.map((fffield:any)=>{
                  if(fffield.name==="dept_code"){
                    fffield.value=data?.dept_code
                  }
                  if(fffield.name==="dept_name"){
                    fffield.value=data?.dept_name
                  }
            })
          }
        return field;
      })
      return {...prev,schema:updatedSchema}
    })
  }

  // console.log(schema)

  const API_POST_Data= async ()=>{
    setLoading(true)
    const payLoad=getPayload(schema);

    try{
      let res:any={};

      if(departmentId){
        res=await Utils.makeApiCall(`/department?department_id=${departmentId}`,"PUT",payLoad);
      }else{
        res=await Utils.makeApiCall("/department","POST",payLoad);
      }
  
      
      if(res.status){
        toast.success(res.message,{position:"top-center",autoClose:AUTO_CLOSE})
        navigate("/departments")
      }else{
        toast.error(res.message ? res.message : FAILURE_MSG,{position:"top-center",autoClose:AUTO_CLOSE})
      }
    }
    catch(error){
      toast.error(CATCH_ERROR_MSG,{position:"top-center",autoClose:AUTO_CLOSE})
      console.log("Error while sending the data.",error)
    }finally{
      setLoading(false)
    }

  }

  const getPayload=(e:any):object =>{
    const payload:any={
      org_id,
      branch_id,
      dept_code:"",
      dept_name:""
    };
    const processFields = (obj: any) => {
      if (obj["fields"] && obj["fields"].length) {
        obj.fields.forEach(processFields);
      } else {
        if (["dept_code","dept_name"].includes(obj.name)){
          payload[obj.name] = obj.value;
        }
      }
    };
    e.schema.forEach(processFields);      
    return payload;
  }

  const [detectChange, setDetectChange] = useState(false)

  // Exit popup's custom hook
  useExitPopUp(detectChange)

  const handleSelectedRecord = (e: any) => {      
    if (e.name === "cancel") {
      navigate("/departments");
    }

    if(e.name==="submit"){
      setDetectChange(false)
      const validationResponse = Utils.handleValidation(schema.schema);
        if (validationResponse) {
            toast.error(`${validationResponse} cannot be empty`, {
              position: "top-center",
              autoClose: AUTO_CLOSE,
            });
        }
        else{
          API_POST_Data();
        }
    }
  };
return (
  <div>
     {loading && <LoadingState/>}
    <ElementExecutor
          data={schema}
          setData={(e:any)=>setSchema({...e})}
          selectedRecord={(e: any)=>{
            handleSelectedRecord(e)
            if(["branch_name", "email", "phone_number", "address", "city", "zipcode", "state", "country"].includes(e?.name) ){
              setDetectChange(true)
            }
          }}
      />
  </div>
)
}

export default AddDepartment
