import React from 'react'
import { isRouteErrorResponse, Outlet, useRouteError } from 'react-router-dom'
import NotFound from './pages/NotFound';

const ErrorBoundary = () => {
    const error = useRouteError()
    console.error(error);

    if (isRouteErrorResponse(error)) {
        const errors: any = {
            401: "You aren't authorized to see this",
            404: <NotFound />,
            503: 'Looks like our API is down',
        }
        const message = errors[error.status] || error?.statusText
        return message
    }
    return <Outlet />
}
export default ErrorBoundary
