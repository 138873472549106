import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import pravas from '../../assets/images/pravaslogo.png'
import lotus from '../../assets/images/lotuslogo.png'
import drvenus from '../../assets/images/drvenuslogo.png'

const ORGS: any = {
    pravasclinic: {
        logo: pravas,
        title: 'Pravas clinic',
    },
    lotushospitals: {
        logo: lotus,
        title: 'Lotus Hospitals',
    },
    drvenushospitals: {
        logo: drvenus,
        title: 'Dr Venus Hosptials',
    },
}

const BotPreview: React.FC = () => {
    const { org_name = '' } = useParams()
    const navigate = useNavigate()

    return (
        <div className="w-full h-screen flex justify-center items-center flex-col gap-6">
            <div className="w-50 h-[120px]">
                <img
                    src={ORGS?.[org_name]?.['logo']}
                    alt={ORGS?.[org_name]?.['title']}
                    className="w-full h-full object-contain"
                />
            </div>
            <p className="text-md font-medium text-gray-400">
                Look at the bottom right corner for the virtual assistant
            </p>
            {/* <button
                onClick={() => {navigate('/bot-preview'), window.location.reload()}}
                className="bg-primary p-2 mt-6 text-white rounded-md px-4 text-sm font-medium hover:shadow-md hover:scale-105 transition-all duration-500"
            >
                Go back
            </button> */}
        </div>
    )
}

export default BotPreview
