import { ElementExecutor } from '@apexcura/core';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CONSTANTS } from '../../utils/app-constants';
import Utils from '../../utils';
import Storage from '../../utils/local-storage';
import useExitPopUp from '../../hooks/useExitPopUp';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../redux/appState/appStateSlice';

const { FAILURE_MSG, CATCH_ERROR_MSG, AUTO_CLOSE } = CONSTANTS;

const addPatients = () => ({
    className: "w-full bg-white rounded-lg p-8 flex flex-col gap-3 items-center",
    schema: [
      {
        name: "data_fields",
        className: "w-full flex flex-col gap-2",
        fields: [
          {
            name: "",
            className: "text-blue-800 font-semibold text-2xl",
            element: "div",
            label: "Add New Patient"
          },
          {
            name: "name_and_type",
            className: "grid grid-cols-3 gap-2 gap-y-4",
            fields: [
              {
                name: "first_name",
                element: "input-text",
                placeholder: "Eg: Anudeep",
                label: "First Name",
                containerClassName: "w-full flex flex-col",
                labelClassName:
                  "text-sm mb-1 text-gray-500 font-semibold text-start",
                className:
                  "rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none",
                required: true,
                visible: true,
              },
              {
                name: "middle_name",
                element: "input-text",
                placeholder: "Eg: Sharma",
                label: "Middle Name",
                containerClassName: "w-full flex flex-col",
                labelClassName:
                  "text-sm mb-1 text-gray-500 font-semibold text-start",
                className:
                  "rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none",
                required: false,
                visible: true,
              },
              {
                name: "last_name",
                element: "input-text",
                placeholder: "Eg: Etukuri",
                label: "Last Name",
                containerClassName: "w-full flex flex-col",
                labelClassName:
                  "text-sm mb-1 text-gray-500 font-semibold text-start",
                className:
                  "rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none",
                required: false,
                visible: true,
              },
              {
                name: "mobile",
                element: "input-number",
                placeholder: "Eg: 8123456790",
                label: "Mobile",
                containerClassName: "w-full flex flex-col",
                labelClassName:
                  "text-sm mb-1 text-gray-500 font-semibold text-start",
                className:
                  "rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none",
                required: true,
                visible: true,
              },
              {
                name: "gender",
                element: "single-select",
                placeholder: "Eg: Male",
                label: "Gender",
                containerClassName: "w-full flex flex-col gap-1",
                labelClassName:
                  "text-sm text-gray-500 font-semibold text-start",
                className:
                  "rounded-md text-gray-500 font-semibold text-sm ",
                required: true,
                visible: true,
                options: [
                    {
                        label: "Male",
                        value: "male"
                    },
                    {
                        label: "Female",
                        value: "female"
                    },
                    {
                        label: "Others",
                        value: "others"
                    }
                ]
              },
              {
                  name: "dob",
                  element: "datepicker",
                  placeholder: "Eg: 01-01-1999",
                  label: "Date of Birth",
                  containerClassName: "w-full flex flex-col gap-1",
                  labelClassName:
                  "text-sm  text-gray-500 font-semibold text-start",
                  className:
                    "rounded-md w-full px-3 py-1 text-gray-500 flex tyext-sm gap-1 font-semibold focus:outline-none",
                  required: true,
                  visible: true,
              },
              {
                name: "umr",
                element: "input-text",
                placeholder: "Eg: PT24000001",
                label: "Reg Id/ Patient Id",
                containerClassName: "w-full flex flex-col",
                labelClassName:
                  "text-sm mb-1 text-gray-500 font-semibold text-start",
                className:
                  "rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none",
                required: false,
                visible: true,
              },
            ],
          },
        ],
      },
      {
        name: "cancel_and_submit",
        fields: [
          {
            name: "cancel",
            label: "Cancel",
            element: "button",
            className:
              "p-2 px-3 text-default-500 bg-gray-200 text-black rounded-md font-semibold",
          },
          {
            name: "submit",
            label: "Submit",
            element: "button",
            className:
              "p-2 px-3 text-default-500 bg-[#3341bb] rounded-md text-white font-semibold",
          },
        ],
        className: "flex self-end gap-2",
      },
    ],
});

const AddPatients = () => {
    const [schema, setSchema] = useState<any>({
      ...addPatients()
    });
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const org_id = Storage.getItem("details")?.["org_id"];

    // useEffect(()=>{
    //   const schema = addPatients();
    //   setSchema((prev:any)=> ({...prev, ...schema}))
    // }, [])

    const validateData = (fields: any[]): string | false => {
        for (const field of fields) {
            if (
                field.required &&
                ((typeof field.value === "string" && field.value.trim() === "") ||
                    (Array.isArray(field.value) && field.value.length === 0) ||
                    field.value === null ||
                    field.value === undefined)
            ) {
                return field.label;
            }
            if (field.fields) {
                const result = validateData(field.fields);
                if (result) {
                    return result;
                }
            }
        }
        return false;
    };

    const API_POST_Data = async () => {
        dispatch(setLoading(true))
        const payLoad = getPayload(schema);
        try {
            const res: any = await Utils.makeApiCall("/patient", "POST", payLoad);
            if (res.status) {
                toast.success(res.message, { position: "top-center", autoClose: AUTO_CLOSE });
                navigate("/patients");
            } else {
                toast.error(res.message ? res.message : FAILURE_MSG, { position: "top-center", autoClose: AUTO_CLOSE });
            }
        } catch (error) {
            toast.error(CATCH_ERROR_MSG, { position: "top-center", autoClose: AUTO_CLOSE });
            console.error("Error while sending the data.", error);
        } finally {
          dispatch(setLoading(false))
        }
    };

    const getPayload = (e: any): object => {
        const payload = {
            first_name: "",
            middle_name: "",
            last_name: "",
            phno: "",
            gender: "",
            date_of_birth: "",
            umr: "",
            org_id: org_id
        };
        e?.schema?.map((f: any)=>{
          if(f.name === "data_fields"){
            f?.fields?.map((ff: any)=>{
              if(ff.name === "name_and_type"){
                ff?.fields?.map((k: any)=>{

                  if(k.name === "first_name"){
                    payload["first_name"] = k.value;
                  }
                  if(k.name === "middle_name"){
                    payload["middle_name"] = k.value || "";
                  }
                  if(k.name === "last_name"){
                    payload["last_name"] = k.value || "";
                  }
                  if(k.name === "mobile"){
                    payload["phno"] = k.value;
                  }
                  if(k.name === "gender"){
                    payload["gender"] = k?.value?.value || "";
                  }
                  if(k.name === "dob"){
                    payload["date_of_birth"] = k.value;
                  }
                  if(k.name === "umr"){
                    payload["umr"] = k.value;
                  }
                })
              }
            })
          }
        })

        return payload;
    };

    const handleSelectedRecord = (e: any) => {
        if (e.name === "cancel") {
            navigate("/patients");
        }
        if (e.name === "submit") {
          setDetectChange(false)
            const validationResponse = validateData(schema.schema);
            if (validationResponse) {
                toast.error(`${validationResponse} cannot be empty`, {
                    position: "top-center",
                    autoClose: AUTO_CLOSE,
                });
            } else {
                API_POST_Data();
            }
        }
    };

    const [detectChange, setDetectChange] = useState(false)

    // Exit popup's custom hook
    useExitPopUp(detectChange)

    return (
        <div>
            <ElementExecutor
                data={schema}
                setData={(e: any) => {
                    setSchema({ ...e });
                }}
                selectedRecord={(e: any) => {
                    handleSelectedRecord(e);
                    if(["name", "mobile", "gender", "dob"].includes(e?.name) ){
                      setDetectChange(true)
                    }
                }}
            />
        </div>
    );
};

export default AddPatients;