import axios, { AxiosResponse, Method } from "axios";
import { CONSTANTS } from "./app-constants";
import * as XLSX from 'xlsx';
import { toast } from "react-toastify";
const { BASE_URL} = CONSTANTS;

interface UtilsType {
  makeApiCall: (
    url: string,
    method?: Method,
    data?: any
  ) => Promise<any>;
  makeFormdataApiCall: (
    url: string,
    method?: Method,
    data?: any
  ) => Promise<any>;
  maskEmail: (
    email: string
  ) => string;
  handleLoading: (prev:any, val: boolean, name?: string) => any,
  handleTabsLoading: (prev:any, val: boolean) => any,
  exportToExcel: (
    thead: Array<{ name: string; label: string; key: string }>,
    tbody: Array<any>,
    filename: string,
  ) => any,
  replacePlaceholders: (text: string, examples: string[]) => string,
  handleValidation: (schema: any) => string | false,
  initials:(text:string)=>string,
  stringToColour:(str:string)=>string,
  updateSchemaRecursively:(schema:any,callback:any)=>any
}

const Utils: UtilsType = {
  makeApiCall: async (url: string, method: Method = "GET", data: any = {}) => {
    // const authToken = JSON.parse(localStorage.getItem('token')||"");
    const HttpClient = axios.create({
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
      withCredentials:true
    }); 

    url = `${BASE_URL}/api${url}`

    try {
      const response: AxiosResponse = await HttpClient({
        method: method,
        url: url,
        data: data,
        headers: {
          // Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
          accept: "application/json",
        },
      });

      return response.data;
    } catch (error: any) {
      if (error.response) {
        return error.response.data;
      } else {
        return { error: "Unknown error occurred" };
      }
    }
  },
  makeFormdataApiCall: async (url: string, method: Method = "GET", data: any = null) => {
    // const authToken = JSON.parse(localStorage.getItem('token')||"");
    const HttpClient = axios.create({
      // headers: {
      //   ...data.getHeaders(), 
      // },
    });

    url = `${BASE_URL}/api${url}`

    try {
      const response: AxiosResponse = await HttpClient({
        method: method,
        url: url,
        data: data,
        headers: {
          // Authorization: `Bearer ${authToken}`,
          //"Content-Type": "application/json",
          'Content-Type': 'multipart/form-data',
          //accept: "application/json",
        },
        withCredentials:true
      });

      return response.data;
    } catch (error: any) {
      if (error.response) {
        return error.response.data;
      } else {
        return { error: "Unknown error occurred" };
      }
    }
  },
  maskEmail: (email: string): string => {
    const [localPart, domain] = email.split('@');
    if (localPart.length <= 2) return email;
  
    const maskedLocalPart = localPart.slice(0, 2) + '*'.repeat(localPart.length - 2);
    return `${maskedLocalPart}@${domain}`;
  },
  handleLoading: (prev:any, val: boolean, name = "") => {
    const ps = prev.schema;
    // ps.map((s: any) => {
    //   if(s.name === name){
    //     s["loading"] = val;
    //   }
      // else if (s.element === "table") {
      //   s["loading"] = val;
      // }
      // return s;
    // });
    const validateData = (fields: any[]): any => {
      for (const field of fields) {
        if (
          field.name === name
        ) {
          field['loading'] = val
        }
        else if (field.element === "table") {
          field["loading"] = val;
        }
        if (field.fields) {
          validateData(field.fields);
        }

      }
      return fields;
    }
    
    return { ...prev, schema: validateData(ps) };
  },
  handleTabsLoading: (prev:any, val: boolean) => {
    const ps = prev.schema;
    ps.map((s: any) => {
      if (s.name === "table_data") {
        s.fields=s.fields.map((field:any)=>{
          if(field.element==="table"){
              field.loading=val;
          }
          return field;
        })
      }
      return s;
    });
    return { ...prev, schema: ps };
  },
  exportToExcel : ( thead:Array<{ name: string; label: string; key: string }>, tbody:Array<any>, filename:string ) => {
    if (tbody.length === 0) {
      toast.error("No data available to download", { position: "top-center", autoClose: 2000 });
      return;
    }
  
    const filteredData = tbody.map((row: any) => {
      const newRow: any = {};
      thead.forEach((column: any) => {
       
          newRow[column.label] = row[column.key];
        
      });
      return newRow;
    });
  
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  
    XLSX.writeFile(workbook, `${filename}.xlsx`);
  },
  replacePlaceholders: (text:string, examples: string[]): string => {
    return text.replace(/\{\{(\d+)\}\}/g, (match, number) => {
      const index = parseInt(number, 10) - 1; // Convert to zero-based index
      return examples[index] !== undefined ? examples[index] : match; // Replace with example or leave as is if not found
    });
  },
  handleValidation: (schema) => {
    const validateData = (fields: any[]): string | false => {
      for (const field of fields) {
        if (
          field.required &&
          ((typeof field.value === "string" && field.value.trim() === "") ||
            (Array.isArray(field.value) && field.value.length === 0) ||
            field.value === null ||
            field.value === undefined)
        ) {
          return field.label;
        }
        if (field.fields) {
          const result = validateData(field.fields);
          if (result) {
            return result;
          }
        }
      }
      return false;
    }
    return validateData(schema);
  },
  initials:(text:any)=>{
    const textArray = text?.trim().split(' ');
    if (textArray && textArray.length === 1) {
        return textArray[0][0];
    } else if (textArray && textArray.length > 1) {
        return textArray[0][0].concat(textArray[textArray.length - 1][0].toUpperCase());
    }
  },
  stringToColour :(str = "")=> {
    let hash = 0;
    for (let i = 0; i < str?.length; i++) {
      hash = str?.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let colour = '#';
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xFF;
      const smoothValue = Math.floor((value + 128) / 2);
      colour += ('00' + smoothValue.toString(16)).substr(-2);
    }
    return colour;
  },
  updateSchemaRecursively : (schema: any, callback: any): any => {
    // Base case: return if schema is not an object
    if (!schema) return schema;
  
    // Apply callback to the current schema object first
    const updatedField = callback(schema);
  
    // If the schema is an array, map through each item
    if (Array.isArray(updatedField)) {
      return updatedField.map((item) => Utils.updateSchemaRecursively(item, callback));
    }
  
    // If the current object has a 'schema' property, recurse into it
    if ('schema' in updatedField) {
      return {
        ...updatedField,
        schema: Utils.updateSchemaRecursively(updatedField.schema, callback),
      };
    }
  
    // If the current object has a 'fields' property, recurse into it
    if ('fields' in updatedField) {
      return {
        ...updatedField,
        fields: Utils.updateSchemaRecursively(updatedField.fields, callback),
      };
    }
  
    // Return the updated field if none of the above conditions apply
    return updatedField;
  }
};

export default Utils;

export const returnSnippetCode = (url:string)=>{return `
  <!-- Apex cura virtual assistant script start -->
    <div id="apexcura-chatbot" style="position:fixed; bottom:10px; right:10px; z-index:10000;"></div>
    <script src="${BASE_URL}/api/public/scripts/apexcura-chatbot.js"></script>
  <!-- Apex cura virtual assistant script end -->
  `}
