"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Notification = void 0;
const react_1 = __importStar(require("react"));
const antd_1 = require("antd");
const io_1 = require("react-icons/io");
const Notification = (props) => {
    const [items, setItems] = (0, react_1.useState)(props.items || []);
    const [visible, setVisible] = (0, react_1.useState)(false);
    const popoverContentStyle = {
        minWidth: '300px',
        maxWidth: '100%',
        maxHeight: '300px',
        overflow: 'auto',
    };
    (0, react_1.useEffect)(() => {
        if (props.items) {
            setItems(props.items);
        }
    }, [props.items]);
    const handleItemClick = (event, item) => {
        event.stopPropagation(); // Prevent the popover from closing immediately
        if (props.onClick) {
            props.onClick(item);
        }
        // Close the popover after clicking an item
        setVisible(false);
    };
    const handleVisibleChange = (newVisible) => {
        setVisible(newVisible);
    };
    const popoverContent = (react_1.default.createElement("div", { style: popoverContentStyle }, items.length > 0 ? (react_1.default.createElement(antd_1.List, { itemLayout: "horizontal", dataSource: items, renderItem: (item, index) => (react_1.default.createElement(antd_1.List.Item, { key: index, onClick: (event) => handleItemClick(event, item), style: { cursor: 'pointer' } },
            react_1.default.createElement(antd_1.List.Item.Meta, { title: item.text, description: item.time }))) })) : (react_1.default.createElement(antd_1.Empty, { image: antd_1.Empty.PRESENTED_IMAGE_SIMPLE }))));
    return (react_1.default.createElement(antd_1.Popover, { className: props.className, content: popoverContent, trigger: "click", visible: visible, onVisibleChange: handleVisibleChange, placement: props.placement },
        react_1.default.createElement(antd_1.Button, { className: props.buttonClassName, onClick: () => setVisible(!visible) },
            react_1.default.createElement(antd_1.Badge, { size: "small", count: items.length >= 100 ? '99+' : items.length },
                react_1.default.createElement("span", { className: props.iconsClassName },
                    react_1.default.createElement(io_1.IoIosNotifications, null))))));
};
exports.Notification = Notification;
