import { ElementExecutor } from '@apexcura/core'
import React, { useEffect, useState } from 'react'
import Utils from '../../utils'
import { CONSTANTS } from "../../utils/app-constants";
import { toast } from 'react-toastify';
import moment from 'moment'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import LoadingState from '../LoadingState';
import {Avatar} from "antd" 
import DoctorEventComponent from '../doctor-events/DoctorEventComponent';
const { BASE_URL} = CONSTANTS;

const VISIBLE_SLOTS=32
const { AUTO_CLOSE } = CONSTANTS

const priorityOptions = {
    VIP_LABEL:"VIP",
    VIP_VALUE:"VIP",
    GENERAL_LABEL:"General",
    GENERAL_VALUE:"GENERAL",
    
}

const sourceOptions = {
    CALLS_LABEL:"Call",
    CALLS_VALUE:"CALL",
    WALKIN_LABEL:"Walk-In",
    WALKIN_VALUE:'WALKIN'
}

const schemaObj = (mobile:string)=>{
    
    return{
        className:'flex flex-col gap-5 max-h- [90vh]',
        schema:[
            {
                name:'filter-section',
                className:'grid grid-cols-4 justify-between gap-4',
                fields:[
                    {
                        name:'date-section',
                        className:'grid grid-cols-10 justify-between',
                        fields:[
                            {
                                name:"prev-date-button",
                                element:"image",
                                visible:false,
                                img:`${BASE_URL}/api/public/assets/images/previous.svg`,
                                alr:"word_cloud_image",
                                imgClassName:'self-center rounded-sm border border-1 border-[#d9d9d9] w-full bg-white mt-6 h-[30px]'
                            }, 
                            {
                                name:'date-select',
                                element:'datepicker',
                                label:"Date",
                                enabled_dates: "from_today",
                                containerClassName:'col-span-8 w-full',
                                className:'w-full'
                            },
                            {
                                name:"next-date-button",
                                element:"image",
                                img:`${BASE_URL}/api/public/assets/images/next.svg`,
                                alr:"word_cloud_image",
                                imgClassName:"self-center rounded-sm border border-1 border-[#d9d9d9] w-full bg-white mt-6 h-[30px]"
                            },
                        ]
                    },
                    {
                        name:'branch-select',
                        element:'single-select',
                        label:"Branch",
                        // containerClassName:'w-[80%]',
                        className:'w-[100%]',
                        options:[],
                    },
                    {
                        name:'doctor-filter',
                        element:'single-select',
                        label:"Doctor",
                        // containerClassName:'w-[80%]',
                        className:'w-[100%]',
                        options:[
                            {
                                label:"Hi",
                                value:'Hi'
                            }
                        ],
                        allowClear:true,
                        defaultValue:{},
                        optionRender:(option:any)=>{
                            if(option.label === 'All') return <>{option.label}</>
                            const items = option.label.split("-");
                            const color = Utils.stringToColour(items[0])
                            const initials = items[0].split(' ')
                            const first = initials.length>1?initials[1][0]:''
                            const last = initials.length>2?initials[2][0]:''
                            return <div className = 'flex gap-2 items-center'>
                              <Avatar style={{ backgroundColor: color, color: '#ffffff', height:'35px', width:'35px' }}>{first}{last}</Avatar>
                              {/* <div className='flex flex-col'>
                                <div>{items[0]}</div>
                                <div>{items[1]}</div>
                              </div> */}
                              <div>{items[0]}</div>
                            </div>
                        }
                    },
                    {
                        name:'department-filter',
                        element:'single-select',
                        label:"Department",
                        allowClear:true,
                        // containerClassName:'w-[80%]',
                        className:'w-[100%]',
                        options:[
                        ]
                    }
                ]
            },
            {
                name:'page-body',
                className:'flex flex-row gap-2',
                fields:[
                    {
                        name:'appointment-section',
                        className:'flex flex-col gap-2 max-h-[570px] bg-white overflow-y-scroll w-full rounded-md shadow-md',
                        fields:[
        
                        ]
                    },
                    {
                        name:'add-patient-section',
                        className:'flex flex-col justify-between gap-2 max-h-[75vh] w-[45%]',
                        visible:false,
                        fields:[
                            {
                                name:'patient-details',
                                className:'flex flex-col rounded-md bg-white rounded-md shadow-lg',
                                fields:[
                                    {
                                        className:'bg-[#D0D5FF] flex items-center justify-center p-2 rounded-md',
                                        element:'div',
                                        label:"Patient Details"
                                    },
                                    {
                                        name:'priority-source-section',
                                        className:'flex flex-row gap-2 p-2 pb-0',
                                        fields:[
                                            {
                                                name:'priority-input',
                                                element:'single-select',
                                                label:"Priority",
                                                containerClassName:'w-[80%]',
                                                className:'w-[100%]',
                                                value:{
                                                    label:priorityOptions.GENERAL_LABEL,
                                                    value:priorityOptions.GENERAL_VALUE
                                                },
                                                options:[
                                                    {
                                                        label:priorityOptions.VIP_LABEL,
                                                        value:priorityOptions.VIP_VALUE
                                                    },
                                                    {
                                                        label:priorityOptions.GENERAL_LABEL,
                                                        value:priorityOptions.GENERAL_VALUE
                                                    }
                                                ],
                                            },
                                            {
                                                name:'source-input',
                                                element:'single-select',
                                                label:"Source",
                                                containerClassName:'w-[80%]',
                                                className:'w-[100%]',
                                                value:{
                                                    label:sourceOptions.CALLS_LABEL,
                                                    value:sourceOptions.CALLS_VALUE
                                                },
                                                options:[
                                                    {
                                                        label:sourceOptions.WALKIN_LABEL,
                                                        value:sourceOptions.WALKIN_VALUE
                                                    },
                                                    {
                                                        label:sourceOptions.CALLS_LABEL,
                                                        value:sourceOptions.CALLS_VALUE
                                                    }
                                                ],
                                            }
                                        ]
                                    },
                                    {
                                        name:'mobile-input',
                                        className:"w-full",
                                        label:"Mobile",
                                        containerClassName:'w-[100%] flex flex-col p-2',
                                        element:"input-text",
                                        value:mobile,
                                        disabled:true
                                    },
                                    {
                                        name:'patients-selection',
                                        className:'flex flex-col gap-2',
                                        fields:[]
                                    },
                                    {
                                        name:'name-input',
                                        containerClassName:'pl-2 w-[100%] pr-2',
                                        element:'input-text',
                                        label:'Name'
                                    },
                                    {
                                        name:'dob-age-gender',
                                        className:'flex flex-row gap-2 p-2 pb-4',
                                        fields:[
                                            {
                                                name:'dob-input',
                                                element:'datepicker',
                                                className:'w-[100%]',
                                                label:"DOB"
                                            },
                                            // {
                                            //     name:'age-input',
                                            //     element:'input-text',
                                            //     label:"Age"
                                            // },
                                            {
                                                name:'gender-input',
                                                element:'single-select',
                                                label:"Gender",
                                                containerClassName:'w-[80%]',
                                                className:'w-[100%]',
                                                options:[
                                                    {
                                                        label:"Male",
                                                        value:"Male"
                                                    },
                                                    {
                                                        label:"Female",
                                                        value:"Female"
                                                    },
                                                    {
                                                        label:"Others",
                                                        value:"Others"
                                                    }
                                                ],
                                            },
                                            {
                                                name:'reset-button',
                                                element:'button',
                                                label:"Reset",
                                                className:'bg-white text-primary font-semibold underline underline-offset-2 flex items-end justify-center p-2'
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                name:'appointment-details',
                                className:'flex flex-col rounded-md bg-white gap-2 rounded-md shadow-lg',
                                fields: [
                                    {
                                        className:'bg-[#D0D5FF] flex items-center justify-center p-2 rounded-md',
                                        element:'div',
                                        label:"Appointment Details"
                                    },
                                    {
                                      name:'appointment-details-grid',
                                      className:'flex flex-col gap-6 p-2',
                                      fields:[
                                          {
                                              name:'slot-details',
                                              className:'grid grid-cols-4',
                                              fields:[
                                                  {
                                                      element:'div',
                                                      label:'Date: ',
                                                      className:'text-[#69757B]'
                                                  },
                                                  {
                                                      element:'div',
                                                      label:'',
                                                      name:'current-slot-date',
                                                      className:'col-span-3 text-[#37474F]'
                                                  },
                                                  {
                                                      element:'div',
                                                      label:'Time: ',
                                                      className:'text-[#69757B]'
                                                  },
                                                  {
                                                      element:'div',
                                                      name:'current-slot-timings',
                                                      label:'',
                                                      className:'col-span-3 text-[#37474F]'
                                                  },
                                              ]
                                          },
                                          {
                                              name:'doctor-details',
                                              className:'grid grid-cols-4',
                                              fields:[
                                                  {
                                                      element:'div',
                                                      label:'Doctor: ',
                                                      className:'text-[#69757B]'
                                                  },
                                                  {
                                                      element:'div',
                                                      label:'',
                                                      name:'doctor-name',
                                                      className:'col-span-3 text-[#37474F]'
                                                  },
                                                  {
                                                      element:'div',
                                                      label:'Dept: ',
                                                      className:'text-[#69757B]'
                                                  },
                                                  {
                                                      element:'div',
                                                      name:'department-name',
                                                      label:'',
                                                      className:'col-span-3 text-[#37474F]'
                                                  },
                                              ]
                                          }
                                      ]
                                    },
                                    {
                                      className:'flex items-center justify-center gap-2 justify-between p-2',
                                      fields:[
                                          {
                                              element:'button',
                                              name:'confirm-appointment',
                                              label:"Confirm Appointment",
                                              className:"px-2 py-2 bg-primary text-white rounded-md w-full",
                                          }
                                      ]
                                    },
                                  ]
                            }
                        ]
                    },
                ]
            },
            {
                name:"additional-appointment-booking-modal",
                element:"modal",
                visible:false,
                containerClassName:'w-[10%]',
                fields: [
                  {
                      name: 'modal_title',
                      className:
                          'w-full bg-primary/5 p-2 pl-4 flex items-center rounded-t-lg justify-between',
                      fields: [
                          {
                              name: 'modal_title_key',
                              element: 'div',
                              label: 'Book Appointment',
                              className:
                                  'font-semibold text-lg text-primary',
                          },
                          {
                              name: 'appointment-booking-modal-close',
                              element: 'button',
                              label: 'X',
                              className:
                                  'w-[40px] h-[40px] flex justify-center items-center rounded-full bg-primary text-xl text-white',
                          },
                      ],
                  },
                  {
                      name: 'modal-body',
                      className:
                          'grid grid-cols-2 max-h-[400px] p-4 overflow-auto gap-1',
                      fields: [
                        {
                            element:'div',
                            label:"Date"
                        },
                        {
                            name:'appt-date',
                            element:'datepicker',
                            className:'w-[50%]',
                            disabled:true
                        },
                        {
                            element:'div',
                            label:"Doctor"
                        },
                        {
                            name:'doctor-name',
                            element:'div',
                            label:'Test Doctor'
                        },
                        {
                            element:'div',
                            label:"Department"
                        },
                        {
                            name:'department-name',
                            element:'div',
                            label:"Test department"
                        },
                        {
                            element:'div',
                            label:"Time"
                        },
                        {
                            name:'appointment-time-picker',
                            element:'timerangepicker',
                            doctorId:'',
                            // label:"Date",
                            value:''
                        },
                      ],
                  },
              ],
            },
            doctorEventModal()
            // {
            //     name:'book-appointment-button',
            //     element:'button',
            //     className:'bg-primary text-white rounded-md self-end p-2',
            //     label:'Book Appointment'
            // }
        ]
    }
}
const doctorEventModal = () => {
    return {
        name:"doctor_event_modal",
        element:"modal",
        visible:false,
        containerClassName:'!w-[80%]',
        className: 'rounded-lg !w-full',
        fields: [
          {
              name: 'modal_title',
              className:
                  'w-full bg-primary/5 p-2 pl-4 flex items-center rounded-t-lg justify-between',
              fields: [
                  {
                      name: 'modal_title_key',
                      element: 'div',
                      label: 'Doctor Schedule',
                      className:
                          'font-semibold text-lg text-primary',
                  },
                  {
                      name: 'doctor_event_modal_close',
                      element: 'button',
                      label: 'X',
                      className:
                          'w-[40px] h-[40px] flex justify-center items-center rounded-full bg-primary text-xl text-white',
                  },
              ],
          },
          {
              name: 'modal-body',
              className:
                  'max-h-[500px] p-4 overflow-auto gap-1',
              fields: [
                {   
                    name : "doctor_event_component",
                    element:'div',
                    label: <DoctorEventComponent/>,
                    className: "w-full"
                }
              ],
          },
      ],
    }
}

export const AddAppointmentComponent = (props:any) => {
    const [loading,setLoading] = useState(false)
    const [schema,setSchema] = useState<any>(schemaObj(props.mobile))
    const [doctorsList,setDoctorsList] = useState<any[]>([])
    const [departmentsList,setDepartmentsList] = useState<any>([])
    const [patientId,setPatientId] = useState<string>('')
    const [source,setSource] = useState<string>(sourceOptions.WALKIN_VALUE)
    const [priority,setPriority] = useState<string>(priorityOptions.GENERAL_VALUE)
    const [mobile,setMobile] = useState<string>('')
    const [name,setName] = useState<string>('')
    const [dob,setDob] = useState<string>('')
    const [gender,setGender] = useState<string>('')
    const [branchId,setBranchId] = useState<string>('')
    const [patientsList,setPaitentsList] = useState<any>([])
    const [currentSlot,setCurrentSlot] = useState<any>()
    const [currentDoctors,setCurrentDoctors] = useState<any[]>([])
    const [currentDate,setCurrentDate] = useState(new Date().toLocaleDateString('en-GB').split('/').join('-'))

    const navigate=useNavigate()

    const today = new Date().toLocaleDateString('en-GB').replace(/\//g, '-')
 
            
    const location = useLocation();
    const params = new URLSearchParams(location.search)

    useEffect(()=>{
        if(!branchId) return
        (async()=>{
            await Get_Departments_List()
            

            if(params.get('source') === 'CALL'){
                console.log('hereeeee')
                setSource(sourceOptions.CALLS_VALUE)
                setSchema((prev:any)=>{
                    return{
                        ...prev,
                        schema:prev.schema.map((e:any)=>{
                            if(e.name === 'page-body'){
                                return{
                                    ...e,
                                    fields:e.fields.map((field:any)=>{
                                        if(field.name === 'add-patient-section'){
                                            return {
                                                ...field,
                                                fields:field.fields.map((f:any)=>{
                                                    if(f.name === 'patient-details'){
                                                        return {
                                                            ...f,
                                                            fields:f.fields.map((ff:any)=>{
                                                                if(ff.name === 'priority-source-section'){
                                                                    return {
                                                                        ...ff,
                                                                        fields:ff.fields.map((fff:any)=>{
                                                                            if(fff.name === 'source-input'){
                                                                    
                                                                                console.log("*****", fff);
                                                                                return{
                                                                                    ...fff,
                                                                                    value:{
                                                                                        label:sourceOptions.CALLS_LABEL,
                                                                                        value:sourceOptions.CALLS_VALUE
                                                                                    }
                                                                                }
                                                                            }
                                                                            return fff
                                                                        })
                                                                    }
                                                                }
                                                                
                                                                return ff
                                                            })
                                                        }
                                                    }
                                                    return f
                                                })
                                            }
                                        }
                                        return field
                                    })
                                }
                            }
                            return e
                        })
                    }
                })
            }
        })()
    },[branchId])

    useEffect(()=>{
        (async()=>{
            const branches = await Get_User_Branches()
            const patients = await Get_Patients_List(props.mobile)
            setPaitentsList(patients)
            setBranchId(branches[0].branch_id)
            setSchema((prev:any)=>{
                return {
                    ...prev,
                    schema:prev.schema.map((field:any)=>{
                        if(field.name === 'filter-section'){
                            return{
                                ...field,
                                fields:field.fields.map((f:any)=>{
                                    if(f.name === 'branch-select'){
                                        return{
                                            ...f,
                                            options:branches.map((branch:any)=>{
                                                return{
                                                    label:branch.branch_name,
                                                    value:branch.branch_id
                                                }
                                            }),
                                            value:{
                                                label:branches[0].branch_name,
                                                value:branches[0].branch_id
                                            }
                                        }
                                    }
                                    return f
                                })
                            }
                        }
                        if(field.name === 'page-body'){
                            return{
                                ...field,
                                fields:field.fields.map((f:any)=>{
                                    if(f.name === 'add-patient-section'){
                                        return{
                                            ...f,
                                            fields:f.fields.map((ff:any)=>{
                                                if(ff.name === 'patient-details'){
                                                    return {
                                                        ...ff,
                                                        fields:ff.fields.map((fff:any)=>{
                                                            if(fff.name === 'patients-selection'){
                                                                return{
                                                                    ...fff,
                                                                    fields:patients.map((patient:any)=>{
                                                                        return{
                                                                            name:'patient-selection-button',
                                                                            element:'button',
                                                                            label:`${patient.name}`,
                                                                            patientId:patient.id,
                                                                            className:'m-2 text-primary border border-primary w-[30%] rounded-md'
                                                                        }
                                                                    })
                                                                }
                                                            }
                                                            return fff
                                                        })
                                                    }
                                                }
                                                return ff
                                            })
                                        }
                                    }
                                    return f
                                })
                            }
                        }
                        return field
                    })
                }
            })
        })()
    },[])

    useEffect(()=>{
        if(!currentSlot){
            setSchema((prev:any)=>{
                return{
                    ...prev,
                    schema:prev.schema.map((e:any)=>{
                        if(e.name === 'page-body'){
                            return{
                                ...e,
                                fields:e.fields.map((field:any)=>{
                                    if(field.name === 'appointment-section'){
                                        return{
                                            ...field,
                                            fields : field.fields.map((ff:any)=>{
                                                if(ff.name === 'slots-container'){
                                                    return{
                                                        ...ff,
                                                        fields:ff.fields.map((f:any)=>{
                                                            if(f.name === 'slot-button-grid'){
                                                                return{
                                                                    ...f,
                                                                    className:'flex flex-row gap-2 flex-wrap bg-white min-h-[30px] justify-items-center',
                                                                    fields:f.fields.map((ff:any)=>{
                                                                        
                                                                        if(ff.name!=='add-extra-appointment-button' && ff.className!=='hidden' && ff.name!=='expand-slots-button'){
                                                                            ff.className = 'slot-button'
                                                                        }
                                                                        
                                                                        return ff
                                                                    })
                                                                }
                                                            }
                                                            return f
                                                        })
                                                    }
                                                }
                                                return ff
                                            })
                                        }
                                    }
                                    return field
                                })
                            }
                        }
                        return e
                    })
                }
            })
            return
        }
        openPatientDetailsPanel(currentSlot.doctorId,currentSlot.date,currentSlot.startTime,currentSlot.endTime,currentSlot.duration)
        setSchema((prev:any)=>{
            return{
                ...prev,
                schema:prev.schema.map((e:any)=>{
                    if(e.name === 'page-body'){
                        return{
                            ...e,
                            fields:e.fields.map((field:any)=>{
                                if(field.name === 'appointment-section'){
                                    return{
                                        ...field,
                                        fields : field.fields.map((ff:any)=>{
                                            if(ff.name === 'slots-container'){
                                                return{
                                                    ...ff,
                                                    fields:ff.fields.map((f:any)=>{
                                                        if(f.name === 'slot-button-grid'){
                                                            return{
                                                                ...f,
                                                                className:'flex flex-row gap-2 flex-wrap bg-white min-h-[30px] justify-items-center',
                                                                fields:f.fields.map((ff:any)=>{
                                                                    if(ff.doctorId === currentSlot.doctorId && 
                                                                        ff.startTime === currentSlot.startTime && 
                                                                        ff.endTime === currentSlot.endTime &&
                                                                        ff.date === currentSlot.date){
                                                                        ff.className = 'slot-button-active'
                                                                    }else{
                                                                        if(ff.name!== 'add-extra-appointment-button' && ff.className!=='hidden' && ff.name!=='expand-slots-button'){
                                                                            ff.className = 'slot-button'
                                                                        }
                                                                    }
                                                                    return ff
                                                                })
                                                            }
                                                        }
                                                        return f
                                                    })
                                                }
                                            }
                                            return ff
                                        })
                                    }
                                }
                                return field
                            })
                        }
                    }
                    return e
                })
            }
        })
    },[currentSlot])

    useEffect(()=>{
        if(!departmentsList) return
        (async()=>{
            if(departmentsList.length == 1){
                const docs = await Get_Doctors_List(departmentsList[0].id)
                setCurrentDoctors(docs)
            }else{
                const docs = await Get_Doctors_List('')
                setCurrentDoctors(docs)
            }
        })()
        setSchema((prev:any)=>{
            return{
                ...prev,
                schema:prev.schema.map((field:any)=>{
                    if(field.name === 'filter-section'){
                        return{
                            ...field,
                            fields:field.fields.map((f:any)=>{
                                if(f.name === 'department-filter'){
                                    if(departmentsList.length == 1){
                                        f.options = departmentsList.map((doctor:any)=>{
                                            return {
                                                label:doctor.name,
                                                value:doctor.id
                                            }
                                        })
                                        f.allowClear = false
                                        f.value = {
                                            label:departmentsList[0].name,
                                            value:departmentsList[0].id
                                        }
                                    }else{
                                        f.options = [{
                                            label:"All",
                                            value:"ALL"
                                        },...departmentsList.map((doctor:any)=>{
                                            return {
                                                label:doctor.name,
                                                value:doctor.id
                                            }
                                        })]
                                        f.value = {
                                            label:'All',
                                            value:"ALL"
                                        }
                                        f.defaultValue = {
                                            label:'All',
                                            value:"ALL"
                                        }
                                    }

                                }
                                if(f.name === 'date-section'){
                                    return{
                                        ...f,
                                        fields:f.fields.map((ff:any)=>{
                                            if(ff.name === 'date-select'){
                                                ff.value = new Date().toLocaleDateString('en-GB').replace(/\//g, '-')
                                            }
                                            return ff
                                        })
                                    }
                                }
                                // if(f.name === 'date-select'){
                                //     f.value = new Date().toLocaleDateString('en-GB').replace(/\//g, '-')
                                // }
                                return f;
                            })
                        }
                    }
                    
                    return field
                })
            }
        })
    },[departmentsList])

    useEffect(()=>{
        if(!doctorsList) return
        setSchema((prev:any)=>{
            return{
                ...prev,
                schema:prev.schema.map((field:any)=>{
                    if(field.name === 'filter-section'){
                        return{
                            ...field,
                            fields:field.fields.map((f:any)=>{
                                if(f.name === 'doctor-filter'){
                                    if(doctorsList.length == 1){
                                        f.options = doctorsList.map((doctor:any)=>{
                                            return {
                                                label:doctor.name,
                                                value:doctor.id
                                            }
                                        })
                                        f.value = {
                                            label:doctorsList[0].name,
                                            value:doctorsList[0].id
                                        }
                                        f.allowClear = false
                                    }else{
                                        f.options = [{
                                            label:'All',
                                            value:"ALL"
                                        },...doctorsList.map((doctor:any)=>{
                                            return {
                                                label:doctor.name,
                                                value:doctor.id
                                            }
                                        })]
                                        f.value = {
                                            label:'All',
                                            value:"ALL"
                                        }
                                        f.defaultValue = {
                                            label:'All',
                                            value:"ALL"
                                        }
                                    }
                                    
                                }
                                return f;
                            })
                        }
                    }
                    return field
                })
            }
        })
    },[doctorsList])

    useEffect(()=>{
        if(mobile.length<3) return
    },[mobile])

    useEffect(()=>{
        (async()=>await updateSlots())()
        console.log(currentDate)
        setSchema((prev:any)=>{
            return{
                ...prev,
                schema:prev.schema.map((field:any)=>{
                    if(field.name === 'filter-section'){
                        return{
                            ...field,
                            fields:field.fields.map((f:any)=>{
                                if(f.name === 'date-section'){
                                    return{
                                        ...f,
                                        fields:f.fields.map((ff:any)=>{
                                            if(ff.name === 'date-select'){
                                                ff.value = currentDate
                                            }
                                            if(ff.name === 'prev-date-button'){
                                                if(moment(currentDate, 'DD-MM-YYYY').isAfter(moment(today, 'DD-MM-YYYY'))){
                                                    ff.visible = true
                                                }else{
                                                    ff.visible = false
                                                }
                                            }
                                            return ff
                                        })
                                    }
                                }
                                // if(f.name === 'date-select'){
                                //     f.value = new Date().toLocaleDateString('en-GB').replace(/\//g, '-')
                                // }
                                return f;
                            })
                        }
                    }
                    
                    return field
                })
            }
        })
    },[currentDoctors,currentDate])

    useEffect(()=>{
        if(patientsList.length == 0) return
        setSchema((prev:any)=>{
            return{
                ...prev,
                schema:prev.schema.map((e:any)=>{
                    if(e.name === 'page-body'){
                        return{
                            ...e,
                            fields:e.fields.map((field:any)=>{
                                if(field.name === 'add-patient-section'){
                                    return {
                                        ...field,
                                        fields:field.fields.map((f:any)=>{
                                            if(f.name === 'patient-details'){
                                                return {
                                                    ...f,
                                                    fields:f.fields.map((ff:any)=>{
                                                        if(ff.name === 'mobile-input'){
                                                            if(!patientsList) return
                                                            console.log(patientsList)
                                                            const optionsList = patientsList.map((option:any)=>{
                                                                return{
                                                                    key:option.id,
                                                                    contact:`${option.phoneNumber}`,
                                                                    label:option.name
                                                                }
                                                            })
                                                            console.log(optionsList)
                                                            return{
                                                                ...ff,
                                                                items:optionsList
                                                            }
                                                            // return ff    
                                                        }
                                                        return ff
                                                    })
                                                }
                                            }
                                            return f
                                        })
                                    }
                                }
                                return field
                            })
                        }
                    }
                    return e
                })
            }
        })
    },[patientsList])

    const updateSlots = async()=>{
        setLoading(true)
        let fields:any[] = await Promise.all(currentDoctors.map(async(doctor:any)=>{
            let num = 0
            const slots = await Get_Available_Slots(doctor.id)
            return{
                className:'flex flex-col p-4 border-b-2 border-gray-200 rounded-sm',
                name:'slots-container',
                doctorId:doctor.id,
                numSlots: slots.availableSlots.length,
                fields:[
                    {
                        className:'grid grid-cols-3 pl-2 pr-4 pb-2 items-center',
                        fields:[
                            {
                                element:'div',
                                label:<span><span className='text-[#00796B]'>{doctor.name}</span><small className='text-[#4B5A61]'> - {doctor.department?.dept_name}</small></span>,
                                className:'text-xl'
                            },
                            {
                                // element:'div',
                                // label:<span><span>Available Slots: </span><span className='font-semibold'>{slots.length}/{slots.totalSlots} </span><img src={`${BASE_URL}/api/public/assets/images/edit.svg`}></img></span>,
                                className:'text-md justify-self-center flex gap-2',
                                fields:[
                                    {
                                        element:'div',
                                        label:'Available Slots: '
                                    },
                                    {
                                        element:'div',
                                        className:'font-semibold',
                                        label:`${slots.length}/${slots.totalSlots} `
                                    },
                                    // {
                                    //     name:"edit-slots-button",
                                    //     element:"image",
                                    //     className:'cursor-pointer p-2',
                                    //     visible:true,
                                    //     doctorId:doctor.id,
                                    //     img:`${BASE_URL}/api/public/assets/images/edit.svg`,
                                    //     alt:"edit icon",
                                    // }, 
                                ]
                            },
                            {
                                // element:'div',
                                // label:<span><span>Available Slots: </span><span className='font-semibold'>{slots.length}/{slots.totalSlots} </span><img src={`${BASE_URL}/api/public/assets/images/edit.svg`}></img></span>,
                                className:'text-md justify-self-center flex gap-2',
                                fields:[
                                    {
                                        element:'div',
                                        label:'Slot Size: '
                                    },
                                    {
                                        element:'div',
                                        className:'font-semibold',
                                        label:`${doctor.slotSize} minutes`
                                    },
                                ]
                            },
                        ]
                    },
                    slots.availableSlots.length!=0?{
                        name:'slot-button-grid',
                        className:'flex flex-row gap-2 flex-wrap bg-white min-h-[30px] justify-items-center',
                        fields:[...slots.availableSlots.map((duration:any)=>{
                            const defaultStyle = 'slot-button'
                            const style = currentDoctors.length==1?defaultStyle:num<VISIBLE_SLOTS?defaultStyle:'hidden'
                            num++
                            return {
                                name:'slot-button',
                                element:'button',
                                label:duration.startTime,
                                doctorId:doctor.id,
                                className:style,
                                date:slots.date,
                                duration:doctor.slotSize,
                                startTime:duration.startTime,
                                endTime:duration.endTime
                            }
                        }),{
                            name:'add-extra-appointment-button',
                            element:'button',
                            doctorId:doctor.id,
                            label:<div className='flex justify-center items-center gap-1'><img src={`${BASE_URL}/api/public/assets/images/add-primary.svg`} alt='add'/> <span>Add</span></div>,
                            className:'text-primary text-xs bg-[#EBECF8] rounded-md w-[80%] mt-2 mb-2 max-w-[70px] min-h-[30px] border border-primary',
                            status:'view',
                            visible:currentDoctors.length===1 || slots.availableSlots.length<=VISIBLE_SLOTS
                        },currentDoctors.length!==1 && slots.availableSlots.length>VISIBLE_SLOTS?{
                            name:'expand-slots-button',
                            element:'button',
                            doctorId:doctor.id,
                            label:'Show More',
                            className:'font-semibold text-primary justify-self-end underline underline-offset-2',
                            status:'view'
                        }:{}]
                    }:{
                        className:'p-4 pl-2 flex items-center gap-2',
                        fields:[
                            {
                                element:'div',
                                label:'No available slots'
                            },
                            {
                                name:'add-extra-appointment-button',
                                element:'button',
                                doctorId:doctor.id,
                                label:<div className='flex justify-center items-center gap-1'><img src={`${BASE_URL}/api/public/assets/images/add.svg`} style={{fill:'#3341bb'}} alt='add'/> <span>Add</span></div>,
                                className:'text-primary text-xs bg-[#EBECF8] rounded-md w-[80%] mt-2 mb-2 max-w-[70px] min-h-[30px] border border-primary',
                                status:'view',
                                visible:currentDoctors.length===1 || slots.availableSlots.length<=VISIBLE_SLOTS
                            }
                        ]
                    }
                ]

            }
            
        }))
        fields = fields.sort((a:any,b:any)=>{
            return a.numSlots>b.numSlots?-1:1
        })
        setLoading(false)
        setSchema((prev:any)=>{
            return{
                ...prev,
                schema:prev.schema.map((e:any)=>{
                    if(e.name === 'page-body'){
                        return{
                            ...e,
                            fields:e.fields.map((field:any)=>{
                                if(field.name === 'appointment-section'){
                                    return{
                                        ...field,
                                        fields:fields
                                    }
                                }
                                return field
                            })
                        }
                    }
                    return e
                })
            }
        })
    }

    const openAdditionalAppointmentModal = (doctorId:any)=>{
        const doctor = currentDoctors.find((doc:any)=>doc.id === doctorId)
        console.log(doctor)
        setSchema((prev:any)=>{
            return{
                ...prev,
                schema:prev.schema.map((field:any)=>{
                    if(field.name === 'additional-appointment-booking-modal'){
                        console.log(field)
                        // field.visible = true
                        return{
                            ...field,
                            visible:true,
                            fields:field.fields.map((e:any)=>{
                                if(e.name === 'modal-body'){
                                    return {
                                        ...e,
                                        fields:e.fields.map((f:any)=>{

                                            if(f.name === 'doctor-name'){
                                                // f.label = doctor.name
                                                return{
                                                    ...f,
                                                    label:doctor.name
                                                }
                                            }
                                            if(f.name === 'department-name'){
                                                // f.label = doctor.department?.name
                                                return{
                                                    ...f,
                                                    label:doctor?.department?.dept_name
                                                }
                                            }
                                            if(f.name === 'appt-date'){
                                                // f.value = currentDate
                                                return{
                                                    ...f,
                                                    value:currentDate
                                                }
                                            }
                                            if(f.name === 'appointment-time-picker'){
                                                return{
                                                    ...f,
                                                    doctorId:doctorId
                                                }
                                            }
                                            return f
                                        })
                                    }
                                }
                                return e
                            })
                        }
                    }
                    return field
                })
            }
        })
    }

    const closeAdditionalAppointmentModal = ()=>{
        setSchema((prev:any)=>{
            return{
                ...prev,
                schema:prev.schema.map((field:any)=>{
                    if(field.name === 'additional-appointment-booking-modal'){
                        field.visible = false
                    }
                    return field
                })
            }
        })
    }

    const openPatientDetailsPanel = (doctorId:any,date:any,startTime:any,endTime:any,duration:any)=>{
        console.log(doctorId,date,startTime,endTime,duration)
        const doctor = currentDoctors.find((doc:any)=>doc.id===doctorId)
        setSchema((prev:any)=>{
            return{
                ...prev,
                schema:prev.schema.map((e:any)=>{
                    if(e.name === 'page-body'){
                        return{
                            ...e,
                            fields:e.fields.map((field:any)=>{
                                if(field.name === 'add-patient-section'){
                                    return {
                                        ...field,
                                        visible:true,
                                        fields:field.fields.map((f:any)=>{
                                            if(f.name === 'appointment-details'){
                                                return{
                                                    ...f,
                                                    fields:f.fields.map((ff:any)=>{
                                                        if(ff.name === 'appointment-details-grid'){
                                                            return{
                                                                ...ff,
                                                                fields:ff.fields.map((fff:any)=>{
                                                                    if(fff.name === 'slot-details'){
                                                                        return{
                                                                            ...fff,
                                                                            fields:fff.fields.map((ffff:any)=>{
                                                                                if(ffff.name === 'current-slot-date'){
                                                                                    ffff.label = date
                                                                                }
                                                                                if(ffff.name === 'current-slot-timings'){
                                                                                    ffff.label = `${startTime} - ${endTime} (${duration} mins)`
                                                                                }
                                                                                return ffff
                                                                            })
                                                                        }
                                                                    }
                                                                    if(fff.name === 'doctor-details'){
                                                                        return{
                                                                            ...fff,
                                                                            fields:fff.fields.map((ffff:any)=>{
                                                                                if(ffff.name === 'doctor-name'){
                                                                                    ffff.label = doctor?.name
                                                                                }
                                                                                if(ffff.name === 'department-name'){
                                                                                    ffff.label = `${doctor?.department?.dept_name}`
                                                                                }
                                                                                return ffff
                                                                            })
                                                                        }
                                                                    }
                                                                    return fff
                                                                })
                                                            }
                                                        }
                                                        return ff
                                                    })
                                                }
                                            }
                                            return f
                                        })
                                    }
                                }
                                return field
                            })
                        }
                    }
                    return e
                })
            }
        })
    }

    const isValidAppointment = ()=>{
        if(!currentSlot){
            toast.error('Please select a valid slot', { position: "top-center", autoClose: AUTO_CLOSE });
            return false
        }
        if(!patientId){
            if(!mobile){
                toast.error('Please enter valid mobile number or select a valid patient', { position: "top-center", autoClose: AUTO_CLOSE });
                return false
            }
            if(!name){
                toast.error('Please enter valid patient name or select a valid patient', { position: "top-center", autoClose: AUTO_CLOSE });
                return false
            }
        }

        return true
    }

    const Get_Departments_List = async()=>{
        const response = await Utils.makeApiCall(`/departmentsDropdown?branch_id=${branchId}`,'GET')
        const list = response.data.map((entry:any)=>{
            return{
                id:entry._id,
                name:entry.dept_name
            }
        })
        setDepartmentsList(list)
        console.log(response)
    }

    const Get_Doctors_List = async(departmentId:string)=>{
        const response = await Utils.makeApiCall(`/doctors?departmentId=${departmentId}&branchId=${branchId}`,'GET')
        const list = response.data.map((entry:any)=>{
            return{
                id:entry._id,
                name:entry.name,
                department:entry.department_id,
                slotSize:entry.slot_size,
            }   
        })
        setDoctorsList(list)
        return list
    }

    const Get_Patients_List = async(searchText:any)=>{
        const response = await Utils.makeApiCall(`/patient/appointments?searchText=${searchText}`,'GET')
        const list = response.data?.map((entry:any)=>{
            return{
                id:entry._id,
                name:entry.name[0]?.text,
                phoneNumber:entry?.telecom[0]?.value,
                gender:entry.gender,
                birthDate:entry.birthDate || entry.date_of_birth
            }
        })
        return list
    }

    const Get_Available_Slots = async(doctorId:string)=>{
        const response = await Utils.makeApiCall(`/appointments/getAvailableSlots?doctorId=${doctorId}&date=${currentDate}`,'GET')
        return response
    }

    const Get_User_Branches = async()=>{
        const response = await Utils.makeApiCall(`/getUserBranches`,'GET')
        return response.data
    }

    const Post_Appointment_Add = async()=>{
        const response = await Utils.makeApiCall(`/appointments`,'POST',{
            doctorId:currentSlot.doctorId,
            startTime:currentSlot.startTime,
            endTime:currentSlot.endTime,
            patientId:patientId,
            name:name,
            mobile:mobile,
            dob:dob,
            gender:gender,
            branchId:branchId,
            date:currentSlot.date,
            source:source,
            priority:priority
        })
        return response
    }

    const handleSelectedRecord = async(e:any)=>{
        console.log(e)
        if(e.name === 'doctor-filter'){
            if(e.value.value === 'ALL'){
                setCurrentDoctors(doctorsList)
            }else{
                setCurrentDoctors(doctorsList.filter((doc)=>{
                    return doc.id === e.value.value
                }))
            }
            setCurrentSlot(null)
        }
        if(e.name === 'branch-select'){
            setBranchId(e.value.value)
            setCurrentSlot(null)
        }
        if(e.name === 'department-filter'){
            if(e.value.value === 'ALL'){ 
                const docs = await Get_Doctors_List('')
                setCurrentDoctors(docs)
            }
            else{
                const docs = await Get_Doctors_List(e.value.value)
                setCurrentDoctors(docs)
                setSchema((prev:any)=>{
                    return{
                        ...prev,
                        schema:prev.schema.map((field:any)=>{
                            if(field.name === 'filter-section'){
                                return{
                                    ...field,
                                    fields:field.fields.map((f:any)=>{
                                        if(f.name === 'doctor-filter'){
                                            return{
                                                ...f,
                                                value:{
                                                    label:'',
                                                    value:''
                                                }
                                            }
                                        }
                                        return f
                                    })
                                }
                            }
                            return field
                        })
                    }
                })
            }
            setCurrentSlot(null)
        }
        if(e.name === 'date-select'){
            setCurrentDate(e.value)
            if(!e.value){
                setCurrentDate(new Date().toLocaleDateString('en-GB').split('/').join('-'))
            }
            setCurrentSlot(null)
        }
        if(e.name === 'next-date-button'){
            setCurrentDate(moment(currentDate, 'DD-MM-YYYY').add(1, 'days').format('DD-MM-YYYY'))
        }
        if(e.name === 'prev-date-button'){
            setCurrentDate(moment(currentDate, 'DD-MM-YYYY').subtract(1, 'days').format('DD-MM-YYYY'))
        }
        if(e.name === 'slot-button'){
            if(patientsList.length==0 && props.name!=='Unknown'){
                setName(props.name)
                setSchema((prev:any)=>{
                    return {
                        ...prev,
                        schema:prev.schema.map((field:any)=>{
                            if(field.name === 'page-body'){
                                return{
                                    ...field,
                                    fields:field.fields.map((f:any)=>{
                                        if(f.name === 'add-patient-section'){
                                            return{
                                                ...f,
                                                fields:f.fields.map((ff:any)=>{
                                                    if(ff.name === 'patient-details'){
                                                        return {
                                                            ...ff,
                                                            fields:ff.fields.map((fff:any)=>{
                                                                if(fff.name === 'name-input'){
                                                                    return{
                                                                        ...fff,
                                                                        value:props.name
                                                                    }
                                                                }
                                                                return fff
                                                            })
                                                        }
                                                    }
                                                    return ff
                                                })
                                            }
                                        }
                                        return f
                                    })
                                }
                            }
                            return field
                        })
                    }
                })
            }
            setMobile(props.mobile)
            setCurrentSlot({
                doctorId:e.doctorId,
                date:e.date,
                startTime:e.startTime,
                endTime:e.endTime,
                duration:e.duration
            })
        }
        if(e.name === 'confirm-appointment'){
            if(!isValidAppointment()) {
                return
            }
            const res = await Post_Appointment_Add()
            if(res?.duplicate) {
                toast.error(res.message, { position: "top-center", autoClose: AUTO_CLOSE });
                updateSlots()
                setCurrentSlot(null)
                setSchema((prev:any)=>{
                    return{
                        ...prev,
                        schema:prev.schema.map((e:any)=>{
                            if(e.name === 'page-body'){
                                return{
                                    ...e,
                                    fields:e.fields.map((field:any)=>{
                                        if(field.name === 'add-patient-section'){
                                            return {
                                                ...field,
                                                fields:field.fields.map((f:any)=>{
                                                    if(f.name === 'appointment-details'){
                                                        return{
                                                            ...f,
                                                            fields:f.fields.map((ff:any)=>{
                                                                if(ff.name === 'appointment-details-grid'){
                                                                    return{
                                                                        ...ff,
                                                                        fields:ff.fields.map((fff:any)=>{
                                                                            if(fff.name === 'slot-details'){
                                                                                return{
                                                                                    ...fff,
                                                                                    fields:fff.fields.map((ffff:any)=>{
                                                                                        if(ffff.name === 'current-slot-date'){
                                                                                            ffff.label = null
                                                                                        }
                                                                                        if(ffff.name === 'current-slot-timings'){
                                                                                            ffff.label = null
                                                                                        }
                                                                                        return ffff
                                                                                    })
                                                                                }
                                                                            }
                                                                            if(fff.name === 'doctor-details'){
                                                                                return{
                                                                                    ...fff,
                                                                                    fields:fff.fields.map((ffff:any)=>{
                                                                                        if(ffff.name === 'doctor-name'){
                                                                                            ffff.label = null
                                                                                        }
                                                                                        if(ffff.name === 'department-name'){
                                                                                            ffff.label = null
                                                                                        }
                                                                                        return ffff
                                                                                    })
                                                                                }
                                                                            }
                                                                            return fff
                                                                        })
                                                                    }
                                                                }
                                                                return ff
                                                            })
                                                        }
                                                    }
                                                    return f
                                                })
                                            }
                                        }
                                        return field
                                    })
                                }
                            }
                            return e
                        })
                    }
                })
            } else if(!res.error){
                // handleClosePopup()
                // updateSlots()
                // resetPatientForm()
                navigate('/appointments')
                toast.success(res.message, { position: "top-center", autoClose: AUTO_CLOSE });
            }
        }
        if(e.name === 'source-input'){
            setSource(e.value.value)
        }
        if(e.name === 'priority-input'){
            setPriority(e.value.value)
        }
        // if(e.name === 'mobile-input'){
        //     if(e.value.text){
        //         const patient = patientsList.find((patient:any)=>patient.id === e.value.key)
        //         setPatientId(e.value.key)
        //         setName('')
        //         setGender('')
        //         setDob('')
        //         setMobile('')
        //         setSchema((prev:any)=>{
        //             return{
        //                 ...prev,
        //                 schema:prev.schema.map((e:any)=>{
        //                     if(e.name === 'page-body'){
        //                         return{
        //                             ...e,
        //                             fields:e.fields.map((field:any)=>{
        //                                 if(field.name === 'add-patient-section'){
        //                                     return {
        //                                         ...field,
        //                                         fields:field.fields.map((f:any)=>{
        //                                             if(f.name === 'patient-details'){
        //                                                 return {
        //                                                     ...f,
        //                                                     fields:f.fields.map((ff:any)=>{
        //                                                         if(ff.name === 'name-input'){
        //                                                             return{
        //                                                                 ...ff,
        //                                                                 disabled:true,
        //                                                                 value:patient.name
        //                                                             }
        //                                                         }
        //                                                         if(ff.name === 'dob-age-gender'){
        //                                                             return{
        //                                                                 ...ff,
        //                                                                 fields:ff.fields.map((fff:any)=>{
        //                                                                     if(fff.name === 'gender-input'){
        //                                                                         return{
        //                                                                             ...fff,
        //                                                                             disabled:true,
        //                                                                             value:patient.gender
        //                                                                         } 
        //                                                                     }
        //                                                                     if(fff.name === 'dob-input'){
        //                                                                         return{
        //                                                                             ...fff,
        //                                                                             disabled:true,
        //                                                                             value:patient.birthDate
        //                                                                         } 
        //                                                                     }
        //                                                                     return fff
        //                                                                 })
        //                                                             }
        //                                                         }
                                                                
        //                                                         return ff
        //                                                     })
        //                                                 }
        //                                             }
        //                                             return f
        //                                         })
        //                                     }
        //                                 }
        //                                 return field
        //                             })
        //                         }
        //                     }
        //                     return e
        //                 })
        //             }
        //         })
        //     }else{
        //         setPatientId('')
        //         setMobile(e.value.key)
        //         const patients = await Get_Patients_List(e.value.key)
        //         setPaitentsList(patients)
        //     }
        // }
        if(e.name === 'name-input'){
            setPatientId('')
            setName(e.value)
        }
        if(e.name === 'dob-input'){
            setDob(e.value)
        }
        if(e.name === 'gender-input'){
            setGender(e.value.value)
        }
        if(e.name === 'reset-button'){
            setPatientId('')
            setName('')
            setGender('')
            setDob('')
            setSchema((prev:any)=>{
                return{
                    ...prev,
                    schema:prev.schema.map((e:any)=>{
                        if(e.name === 'page-body'){
                            return{
                                ...e,
                                fields:e.fields.map((field:any)=>{
                                    if(field.name === 'add-patient-section'){
                                        return {
                                            ...field,
                                            fields:field.fields.map((f:any)=>{
                                                if(f.name === 'patient-details'){
                                                    return {
                                                        ...f,
                                                        fields:f.fields.map((ff:any)=>{
                                                            if(ff.name === 'name-input'){
                                                                return{
                                                                    ...ff,
                                                                    disabled:false,
                                                                    value:''
                                                                }
                                                            }
                                                            if(ff.name === 'dob-age-gender'){
                                                                return{
                                                                    ...ff,
                                                                    fields:ff.fields.map((fff:any)=>{
                                                                        if(fff.name === 'gender-input'){
                                                                            return{
                                                                                ...fff,
                                                                                disabled:false,
                                                                                value:''
                                                                            } 
                                                                        }
                                                                        if(fff.name === 'dob-input'){
                                                                            return{
                                                                                ...fff,
                                                                                disabled:false,
                                                                                value:''
                                                                            } 
                                                                        }
                                                                        return fff
                                                                    })
                                                                }
                                                            }
                                                            
                                                            return ff
                                                        })
                                                    }
                                                }
                                                return f
                                            })
                                        }
                                    }
                                    return field
                                })
                            }
                        }
                        return e
                    })
                }
            })
        }
        if(e.name === 'add-new-patient-button'){
            navigate('/patients/add')
        }
        if(e.name === 'expand-slots-button'){
            const status = e.status
            setSchema((prev:any)=>{
                return{
                    ...prev,
                    schema:prev.schema.map((ee:any)=>{
                        if(ee.name === 'page-body'){
                            return{
                                ...ee,
                                fields:ee.fields.map((field:any)=>{
                                    if(field.name === 'appointment-section'){
                                        return{
                                            ...field,
                                            fields : field.fields.map((ff:any)=>{
                                                if(ff.name === 'slots-container' && ff.doctorId === e.doctorId){
                                                    return{
                                                        ...ff,
                                                        fields:ff.fields.map((f:any)=>{
                                                            if(f.name === 'slot-button-grid' && e.status === 'view'){
                                                                return{
                                                                    ...f,
                                                                    fields:f.fields.map((ff:any)=>{
                                                                        if(ff.className === 'hidden'){
                                                                            ff.className = 'slot-button'
                                                                        }
                                                                        if(ff.name === 'expand-slots-button'){
                                                                            ff.status = 'hide'
                                                                            ff.label = 'Hide'
                                                                        }
                                                                        if(ff.name === 'add-extra-appointment-button'){
                                                                            ff.visible = true
                                                                        }
                                                                        return ff
                                                                    })
                                                                }
                                                            }
                                                            if(f.name === 'slot-button-grid' && e.status === 'hide'){
                                                                let num = 0;
                                                                return{
                                                                    ...f,
                                                                    fields:f.fields.map((ff:any)=>{
                                                                        if(num<VISIBLE_SLOTS) {
                                                                            num++ 
                                                                            return ff
                                                                        }
                                                                        
                                                                        if(ff.name === 'expand-slots-button'){
                                                                            ff.status = 'view'
                                                                            ff.label = 'Show More'
                                                                        }
                                                                        else if(ff.name === 'add-extra-appointment-button'){
                                                                            ff.visible = false
                                                                        }
                                                                        else{
                                                                            ff.className = 'hidden'
                                                                        }
                                                                        num++
                                                                    
                                                                        return ff
                                                                    })
                                                                }
                                                            }
                                                            return f
                                                        })
                                                    }
                                                }
                                                return ff
                                            })
                                        }
                                    }
                                    return field
                                })
                            }
                        }
                        return ee
                    })
                }
            })
        }
        if(e.name === 'add-extra-appointment-button'){
            openAdditionalAppointmentModal(e.doctorId)
        }
        if(e.name === 'appointment-booking-modal-close'){
            closeAdditionalAppointmentModal()
        }
        if(e.name === 'edit-slots-button'){
            // navigate(`/doctor-events/${e.doctorId}`)
            setSchema((prev:any) => {
                return {
                    ...prev,
                    schema: prev.schema.map((sec:any) => {
                        if(sec.name === "doctor_event_modal") {
                            sec.visible = true;
                            sec.fields = sec.fields.map((f:any) => {
                                if(f.name === "modal-body") {
                                    f.fields = f.fields.map((ff:any) => {
                                        if(ff.name === "doctor_event_component") {
                                            ff = {   
                                                name : "doctor_event_component",
                                                element:'div',
                                                label: <DoctorEventComponent selectedDoctor={e.doctorId} selectedDate={moment(currentDate, 'DD-MM-YYYY').toDate()}/>,
                                                className: "w-full"
                                            }
                                        }
                                        return ff;
                                    })
                                }
                                return f;
                            })
                        }
                        return sec;
                    })
                }
            })
        }
        if(e.name === 'doctor_event_modal_close'){
            // navigate(`/doctor-events/${e.doctorId}`)
            setSchema((prev:any) => {
                return {
                    ...prev,
                    schema: prev.schema.map((sec:any) => {
                        if(sec.name === "doctor_event_modal") {
                            sec.visible = false;
                        }
                        return sec;
                    })
                }
            })
            updateSlots();
        }
        if(e.name === 'appointment-time-picker'){
            setCurrentSlot({
                date:currentDate,
                startTime:moment(e.value[0],'h:mm a').format("hh:mm A"),
                endTime:moment(e.value[1],'h:mm a').format("hh:mm A"),
                duration:moment(e.value[1],'h:mm a').diff(moment(e.value[0],'h:mm a'),"minutes"),
                doctorId:e.doctorId
            })
            // openPatientDetailsPanel(e.doctorId,currentDate,
            //     moment(e.value[0],'h:mm a').format("hh:mm A"),
            //     moment(e.value[1],'h:mm a').format("hh:mm A"),
            //     moment(e.value[1],'h:mm a').diff(moment(e.value[0],'h:mm a'),"minutes"))
            closeAdditionalAppointmentModal()
        }
        if(e.name === 'patient-selection-button'){
            const patient = patientsList.find((pat:any)=>pat.id === e.patientId)
            console.log(patient)
            setPatientId(patient.id)
            setSchema((prev:any)=>{
                return{
                    ...prev,
                    schema:prev.schema.map((field:any)=>{
                        if(field.name === 'page-body'){
                            return{
                                ...field,
                                fields:field.fields.map((f:any)=>{
                                    if(f.name === 'add-patient-section'){
                                        return{
                                            ...f,
                                            fields:f.fields.map((ff:any)=>{
                                                if(ff.name === 'patient-details'){
                                                    return {
                                                        ...ff,
                                                        fields:ff.fields.map((fff:any)=>{
                                                            if(fff.name === 'name-input'){
                                                                return{
                                                                    ...fff,
                                                                    value:`${patient?.name}`,
                                                                    disabled:true
                                                                }
                                                            }
                                                            if(fff.name === 'dob-input'){
                                                                return{
                                                                    ...fff,
                                                                    value:`${patient?.dob}`,
                                                                    disabled:true
                                                                }
                                                            }
                                                            if(fff.name === 'gender-input'){
                                                                return{
                                                                    ...fff,
                                                                    value:`${patient?.gender}`,
                                                                    disabled:true
                                                                }
                                                            }
                                                            return fff
                                                        })
                                                    }
                                                }
                                                return ff
                                            })
                                        }
                                    }
                                    return f
                                })
                            }
                        }
                        return field
                    })
                }
            })
        }
    }
    return (
        <div>
            <LoadingState isLoading={loading} />
            <ElementExecutor data={schema} selectedRecord={handleSelectedRecord}/>
        </div>
    )
}