import {createSlice} from "@reduxjs/toolkit"
import moment from "moment"
import { chatsState } from "./types";

const initialState:chatsState={
    pagination:{
        page:1,
        pageSize:30,
    },
    dateRange:[moment().format('DD-MM-YYYY'), moment().format("DD-MM-YYYY")],
    searchText:"",
    selectedRow: {}
}

const chatSlice=createSlice({
    name:"chats",
    initialState,
    reducers:{
        setPagination:(state,action)=>{
            state.pagination=action.payload;
        },
        setDateRange:(state,action)=>{
            state.dateRange=action.payload
        },
        setSearchText:(state,action)=>{
            state.searchText=action.payload;
        },
        setSelectedRow: (state, action) => {
            state.selectedRow=action.payload;
        }
    }

})


export const {setPagination,setDateRange,setSearchText, setSelectedRow}=chatSlice.actions;
export default chatSlice.reducer;