"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OtpElement = void 0;
const react_1 = __importStar(require("react"));
const antd_1 = require("antd");
const OtpElement = (props) => {
    const length = props.length;
    const [otp, setOtp] = (0, react_1.useState)(Array(length).fill(''));
    const inputRefs = (0, react_1.useRef)([]);
    const handleChange = (e, index) => {
        const value = e.target.value;
        if (/^[0-9]$/.test(value) || value === '') {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);
            props.onChange && props.onChange(newOtp.join(''));
            // Move to next input if not the last one
            if (value && index < length - 1) {
                inputRefs.current[index + 1]?.focus();
            }
        }
    };
    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && !otp[index] && index > 0) {
            inputRefs.current[index - 1]?.focus();
        }
    };
    const handlePaste = (e) => {
        e.preventDefault();
        const pasteData = e.clipboardData.getData('text');
        if (/^\d+$/.test(pasteData)) {
            const newOtp = pasteData.split('').slice(0, length);
            setOtp(newOtp.concat(Array(length - newOtp.length).fill('')));
            props.onChange && props.onChange(newOtp.join(''));
            newOtp.forEach((_, idx) => {
                if (inputRefs.current[idx]) {
                    inputRefs.current[idx]?.focus();
                }
            });
        }
    };
    (0, react_1.useEffect)(() => {
        inputRefs.current[0]?.focus();
    }, []);
    return (react_1.default.createElement("div", { className: props.containerClassName },
        Array.from({ length }).map((_, index) => (react_1.default.createElement(antd_1.Input, { disabled: props.disabled, key: index, className: props.className, maxLength: 1, status: props.required && props.errorText ? 'error' : '', value: otp[index], onChange: (e) => handleChange(e, index), onKeyDown: (e) => handleKeyDown(e, index), onPaste: handlePaste, ref: (el) => (inputRefs.current[index] = el) }))),
        props.required ? (react_1.default.createElement("p", { className: props.errorClassName }, props.errorText && props.errorText)) : ('')));
};
exports.OtpElement = OtpElement;
