import React from 'react'
import { CONSTANTS } from '../../utils/app-constants'
import { useNavigate } from 'react-router-dom'
const { BASE_URL } = CONSTANTS
const DemoChatbot = () => {
    const navigate = useNavigate()
    return (
        <div className="w-full h-screen flex justify-center items-center flex-col gap-6">
        <div className="w-50 h-[120px]">
            <img
                src={`${BASE_URL}/api/public/assets/images/apexagentlogo.svg`}
                alt={"Apexcura logo"}
                className="w-full h-full object-contain"
            />
        </div>
        <p className="text-md font-medium text-gray-400">
            Look at the bottom right corner for the virtual assistant
        </p>
        <button
            onClick={() => {navigate('/dashboard'), window.location.reload()}}
            className="bg-primary p-2 mt-6 text-white rounded-md px-4 text-sm font-medium hover:shadow-md hover:scale-105 transition-all duration-500"
        >
            Go to dashboard
        </button>
    </div>
    )
}

export default DemoChatbot
