"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.KanbanBoard = void 0;
const react_1 = __importStar(require("react"));
const antd_1 = require("antd");
const react_beautiful_dnd_1 = require("react-beautiful-dnd");
// Sample data
const initialData = [
    {
        key: '1',
        name: 'John Brown',
        age: 32,
        address: 'New York No. 1 Lake Park',
        tags: ['nice', 'developer'],
    },
    {
        key: '2',
        name: 'Jim Green',
        age: 42,
        address: 'London No. 1 Lake Park',
        tags: ['loser'],
    },
    {
        key: '3',
        name: 'Joe Black',
        age: 32,
        address: 'Sydney No. 1 Lake Park',
        tags: ['cool', 'teacher'],
    },
    {
        key: '3',
        name: 'Joe Black',
        age: 52,
        address: 'Sydney No. 1 Lake Park',
        tags: ['cool', 'teacher'],
    },
    {
        key: '3',
        name: 'Joe Black',
        age: 62,
        address: 'Sydney No. 1 Lake Park',
        tags: ['cool', 'teacher'],
    },
];
// Function to group items by age
const groupByAge = (data) => {
    const groupedData = {};
    data.forEach((item) => {
        if (!groupedData[item.age]) {
            groupedData[item.age] = [];
        }
        groupedData[item.age].push(item);
    });
    return groupedData;
};
const KanbanBoard = () => {
    const [data, setData] = (0, react_1.useState)(initialData);
    const columns = groupByAge(data);
    const handleDragEnd = (result) => {
        const { source, destination } = result;
        // If there's no destination, do nothing
        if (!destination)
            return;
        // Reordering within the same age column
        if (source.droppableId === destination.droppableId) {
            const items = [...columns[parseInt(source.droppableId)]];
            const [reorderedItem] = items.splice(source.index, 1);
            items.splice(destination.index, 0, reorderedItem);
            // Update state with reordered items
            setData((prevData) => prevData.map((item) => item.age === parseInt(source.droppableId)
                ? items.find((i) => i.key === item.key) || item
                : item));
        }
        else {
            // Moving between different age columns
            const sourceItems = [...columns[parseInt(source.droppableId)]];
            const destinationItems = [...columns[parseInt(destination.droppableId)]];
            const [movedItem] = sourceItems.splice(source.index, 1);
            movedItem.age = parseInt(destination.droppableId); // Update the age of the moved item
            destinationItems.splice(destination.index, 0, movedItem);
            // Update state with the moved item
            setData((prevData) => prevData.map((item) => (item.key === movedItem.key ? movedItem : item)));
        }
    };
    return (react_1.default.createElement(react_beautiful_dnd_1.DragDropContext, { onDragEnd: handleDragEnd },
        react_1.default.createElement("div", { style: { display: 'flex' } }, Object.keys(columns).map((age) => (react_1.default.createElement(react_beautiful_dnd_1.Droppable, { droppableId: age, key: age }, (provided) => (react_1.default.createElement("div", { ref: provided.innerRef, ...provided.droppableProps, style: {
                marginBottom: '8px',
                padding: '16px',
                backgroundColor: 'white',
                borderRadius: '4px',
                boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
                // ...provided.draggableProps.style,
            } },
            react_1.default.createElement("h3", null, `Age: ${age}`),
            columns[parseInt(age)].map((item, index) => (react_1.default.createElement(react_beautiful_dnd_1.Draggable, { draggableId: item.key, index: index, key: item.key }, (provided) => (react_1.default.createElement("div", { ref: provided.innerRef, ...provided.draggableProps, ...provided.dragHandleProps, style: {
                    marginBottom: '8px',
                    padding: '16px',
                    backgroundColor: 'white',
                    borderRadius: '4px',
                    boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
                    ...provided.draggableProps.style,
                } },
                react_1.default.createElement(antd_1.Card, { title: item.name },
                    react_1.default.createElement("p", null,
                        "Address: ",
                        item.address),
                    react_1.default.createElement("p", null, "Tags:"),
                    item.tags.map((tag) => (react_1.default.createElement(antd_1.Tag, { color: tag === 'loser'
                            ? 'volcano'
                            : tag.length > 5
                                ? 'geekblue'
                                : 'green', key: tag }, tag.toUpperCase()))))))))),
            provided.placeholder))))))));
};
exports.KanbanBoard = KanbanBoard;
