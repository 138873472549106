import { ElementExecutor } from "@apexcura/core";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { BsPersonBoundingBox } from "react-icons/bs";
import { HiPhoneMissedCall } from "react-icons/hi";
import { IoMagnet } from "react-icons/io5";
import {
  MdCall,
  MdHistory,
  MdOutlineComment,
  MdOutlineCommentsDisabled,
  MdOutlineCompareArrows,
  MdPhoneCallback,
} from "react-icons/md";
import { SiChatbot } from "react-icons/si";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { setLoading } from "../../redux/appState/appStateSlice";
import Utils from "../../utils";
import { CONSTANTS } from "../../utils/app-constants";
import Storage from "../../utils/local-storage";
import LabelTags from "../../utils/tags";

const { FAILURE_MSG, CATCH_ERROR_MSG, AUTO_CLOSE } = CONSTANTS;

const ViewCalls = () => {
  const params = useParams();

  // const [loading, setLoading] = useState<boolean>(false);
  const tagDebounceTime = useRef<ReturnType<typeof setTimeout> | null>(null);

  const dispatch = useDispatch();
  const [commentInput, setCommentInput] = useState("");
  const [commentStatus, setCommmentStatus] = useState<{
    value: string;
    label: string;
  }>({ value: "", label: "" });

  const commentsSchema = () => ({
    name: "comments_modal_container",
    className: "w-full col-span-2 h-full flex flex-col",
    containerClassName: "flex flex-col bg-white shadow-lg rounded-lg ",
    fields: [
      {
        name: "modal_title_key",
        className: "font-semibold text-lg text-primary",
        element: "div",
        label: (
          <span className="flex items-center gap-2">
            <MdOutlineComment /> Comments
          </span>
        ),
      },
      {
        name: "modal_body_container",
        visible: true,
        className: "flex flex-col overflow-auto bg-white h-[42dvh] divide-y",
        fields: [
          {
            name: "empty",
            label: (
              <span className="flex items-center gap-2">
                <MdOutlineCommentsDisabled /> No Comments
              </span>
            ),
            element: "div",
            className: "flex items-center justify-center w-full h-[42dvh]",
          },
        ],
      },
      {
        name: "data_pair",
        className:
          "mt-auto flex gap-2 bg-gray-50 p-4 pb-2 rounded-tl-lg rounded-tr-lg",
        fields: [
          {
            name: "call-tags",
            className: " font-medium text-gray-700 mt-1",
            label: "CallType: ",
            element: "div",
          },
          {
            name: "call-tags-input",
            element: "single-select",
            placeholder: "",
            containerClassName: "w-full flex flex-col col-span-full",
            className:
              "rounded-md text-gray-700 font-semibold focus:outline-none",
            visible: true,
            mode: "tags",
            value: null,
            options: [],
            customColor: true,
          },
        ],
      },
      {
        name: "modal_comment_container",
        className:
          "p-4 pt-2 bg-gray-50 rounded-bl-lg rounded-br-lg flex flex-row gap-2",
        visible: true,
        fields: [
          {
            name: "lead_comment_input",
            element: "input-textarea",
            minRows: 1,
            maxRows: 5,
            className: "p-1 px-2",
            placeholder: "Enter your comment here",
            containerClassName:
              "basis-5/6 bg-white rounded-lg overflow-visible ",
          },
          {
            name: "comment_submit",
            element: "button",
            label: "Comment",
            className:
              "p-1 px-3 bg-primary rounded-md text-sm text-white font-medium",
          },
        ],
      },
    ],
  });

  const [schema, setSchema] = useState<any>({
    className: "w-full rounded-lg grid grid-cols-3 gap-2",
    schema: [
      {
        name: "patient_details",
        className:
          "w-full col-span-full border shadow-sm bg-white rounded-md p-2 px-3",
        visible: false,
        fields: [
          {
            name: "patient_details_heading",
            className: "text-lg font-semibold text-primary mb-1",
            label: (
              <span className="flex items-center gap-2">
                <BsPersonBoundingBox /> Patient Details
              </span>
            ),
            visible: false,
            element: "div",
          },
          {
            name: "other_details",
            className: "flex flex-wrap justify-between gap-2",
            cols: [
              {
                name: "patient_name",
                label: "Name",
              },
              {
                name: "phno",
                label: "Mobile",
              },
              // {
              //     name: 'gender',
              //     label: 'Gender',
              // },
              {
                name: "age",
                label: "Age",
              },
              // {
              //     name: 'pat_reg_id',
              //     label: 'Reg ID',
              // },
              {
                name: "createdAt",
                label: "Registered on",
              },
            ],
            fields: [],
          },
        ],
      },
      {
        name: "main_content",
        className:
          "col-span-2 border shadow-sm bg-white rounded-md p-2 px-3 flex flex-col gap-2 h-[85dvh]",
        fields: [
          {
            name: "view_calls_header_title",
            className: "text-lg font-semibold text-primary",
            label: (
              <span className="flex items-center gap-2">
                <MdCall /> Call Details
              </span>
            ),
            element: "div",
          },
          {
            name: "view_calls_header_data",
            className:
              "flex flex-wrap gap-2 py-2 text-base border rounded-md p-2",

            fields: [
              {
                name: "call_type",
                label: "",
                className: "",
                element: "div",
              },
              {
                name: "call_section",
                className: "flex flex-col gap-1",
                fields: [
                  {
                    name: "data_pair",
                    className: " flex gap-[2px]",
                    fields: [
                      {
                        name: "call_from_value",
                        className: " font-bold text-gray-600 capitalize",
                        label: " N/A",
                        element: "div",
                      },
                      {
                        name: "call_to_value",
                        className: " font-bold text-gray-600 capitalize",
                        label: " N/A",
                        element: "div",
                      },
                    ],
                  },
                  {
                    name: "data_pair",
                    className: "flex gap-1",
                    fields: [
                      {
                        name: "call_date_value",
                        className: " text-xs text-gray-400 capitalize",
                        label: " N/A",
                        element: "div",
                      },
                      {
                        name: "call_duration_value",
                        className: " text-xs text-gray-400 capitalize",
                        label: " N/A",
                        element: "div",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            name: "comments_and_recent_interactions",
            className: "h-full",
            fields: [{ ...commentsSchema() }],
          },
        ],
      },
      {
        name: "recent_interactions",
        className:
          "w-full bg-white border-[1px] col-span-1 p-1 flex flex-col h-[85dvh] rounded-md",
        fields: [
          {
            name: "recent_interactions_text",
            label: (
              <span className="flex items-center gap-1">
                <MdHistory className="text-xl" /> Recent Interactions
              </span>
            ),
            className: "text-lg font-semibold pt-1 pl-1 text-primary",
            element: "div",
          },
          {
            name: "recent_interactions_cards",
            className: " overflow-y-auto gap-1 h-full",
            fields: [],
          },
        ],
      },
    ],
  });

  const [selectedRecord, setSelectedRecord] = useState({
    _id: params?.id || "",
    interaction_type: "Call",
  });
  const [phno, setPhno] = useState();

  useEffect(() => {
    if (selectedRecord?._id) {
      API_GET_GetAllComments();
      API_GET_CALL_DATA();
      handleSelectedInteraction(selectedRecord?._id);
    }
  }, [selectedRecord]);

  useEffect(() => {
    handleTagOptions();
  }, []);
  const handleTagOptions = async () => {
    const res = await Utils.makeApiCall("/getCallTags", "GET");
    if (res.status) {
      setSchema((prev: any) => {
        const updateField = (fields: any[]) =>
          fields.map((field: any) => {
            if (field.name === "call-tags-input") {
              field.options = res?.data?.map((option: any) => ({
                label: option?.name,
                value: option?._id,
                color: option?.colour,
              }));
            } else if (field.fields) {
              field.fields = updateField(field.fields);
            }
            return field;
          });

        return {
          ...prev,
          schema: prev?.schema?.map((sec: any) => {
            if (sec.fields) {
              sec.fields = updateField(sec.fields);
            }
            return sec;
          }),
        };
      });
    }
  };

  const API_GET_CALL_DATA = async () => {
    dispatch(setLoading(true));
    // return
    const id = selectedRecord?._id;
    try {
      const res = await Utils.makeApiCall(`/ivrCallDetails/${id}`, "GET");
      if (res.status && res.data) {
        if (!phno) {
          API_GET_RecentInteractions(res?.data?.From);
          setPhno(res?.data?.From);
          API_GET_Patient_details(res?.data?.patient_id);
        }
        setSchema((prev: any) => {
          const updateField = (fields: any[]) =>
            fields.map((field: any) => {
              if (field.name === "call_type") {
                const { icon, iconClassName } = getCallIconData(
                  res?.data?.status
                );
                field.label = icon;
                field.className = iconClassName;
              } else if (field.name === "call_from_value") {
                field.label =
                  `${res?.data?.Name} (${res?.data?.From})` || "N/A";
                // field.label = res?.data?.From || "N/A"
              } else if (field.name === "call_to_value") {
                field.label = res?.data?.To ? (
                  <span className="flex items-center gap-1">
                    <MdOutlineCompareArrows className="text-gray-400 text-xl" />{" "}
                    {res?.data?.To}
                  </span>
                ) : (
                  ""
                );
              } else if (field.name === "direction_value") {
                field.label = res?.data?.Direction || "N/A";
              } else if (field.name === "calls_status_value") {
                field.label = res?.data?.status || "N/A";
              } else if (field.name === "call_date_value") {
                let date = res?.data?.StartTime || "N/A";
                date = moment(date).format("DD-MM-YYYY HH:mm");
                field.label = date || "N/A";
              } else if (field.name === "call_duration_value") {
                field.label = res?.data?.DialCallDuration
                  ? `(${res?.data?.DialCallDuration}s)`
                  : "";
              } else if (field.name === "call-tags-input") {
                field.value = res?.data?.call_tags;
              } else if (field.fields) {
                field.fields = updateField(field.fields);
              }
              return field;
            });

          return {
            ...prev,
            schema: prev?.schema?.map((sec: any) => {
              if (sec.fields) {
                sec.fields = updateField(sec.fields);
              }
              return sec;
            }),
          };
        });
      } else {
        toast.error(res.message ? res.message : FAILURE_MSG, {
          position: "top-center",
          autoClose: 2000,
        });
      }
    } catch (error) {
      toast.error(CATCH_ERROR_MSG, {
        position: "top-center",
        autoClose: AUTO_CLOSE,
      });
      console.error("Failed to fetch schema", error);
    }
    dispatch(setLoading(false));
  };

  const API_GET_Patient_details = async (id: any) => {
    dispatch(setLoading(true));
    if (!id) {
      setSchema((prev: any) => {
        const updateField = (fields: any[]) =>
          fields.map((field: any) => {
            if (field?.cols?.length) {
              field["fields"] = [];
            }
            return field;
          });

        return {
          ...prev,
          schema: prev?.schema?.map((sec: any) => {
            if (sec.fields) {
              sec.fields = updateField(sec.fields);
            }
            return sec;
          }),
        };
      });
      return;
    }
    try {
      const res = await Utils.makeApiCall(`/patient/${id}`, "GET");
      if (res.status && res.data) {
        const headerData: any = {
          ...res.data,
          patient_name: res?.data?.name?.[0]?.text || null,
          phno: res?.data?.telecom?.[0]?.value,
          createdAt: res?.data?.createdAt
            ? moment(res?.data?.createdAt).format("DD-MM-YYYY HH:mm")
            : null,
        };
        setSchema((prev: any) => {
          const updateField = (fields: any[]) =>
            fields.map((field: any) => {
              if (field.name === "patient_details_heading") {
                field.visible = true;
              }
              if (field?.cols?.length) {
                field["fields"] = field.cols.map((item: any) => {
                  return {
                    name: item.name,
                    className: "flex gap-2 items-baseline",
                    fields: [
                      {
                        name: `${item.name}_key`,
                        element: "div",
                        label: item.label,
                        className: "font-medium text-gray-700 text-xs",
                      },
                      {
                        name: `${item.name}_val`,
                        element: "div",
                        label: `${headerData ? (item.name === "age" ? `${headerData[item.name]} ${headerData?.["gender"] ? `/ ${headerData?.["gender"]}` : ""}` : headerData[item.name] || "--") : "--"}`,
                        className: "text-sm font-bold text-gray-600 capitalize",
                      },
                    ],
                  };
                });
                // console.log(field)
              }
              return field;
            });

          return {
            ...prev,
            schema: prev?.schema?.map((sec: any) => {
              if (sec.name === "patient_details") {
                sec.visible = true;
                sec.fields = updateField(sec.fields);
              }
              return sec;
            }),
          };
        });
      } else {
        toast.error(res.message ? res.message : FAILURE_MSG, {
          position: "top-center",
          autoClose: 2000,
        });
      }
    } catch (error) {
      toast.error(CATCH_ERROR_MSG, {
        position: "top-center",
        autoClose: AUTO_CLOSE,
      });
      console.error("Failed to fetch schema", error);
    }
    dispatch(setLoading(false));
  };

  const API_GET_RecentInteractions = async (mobile: string) => {
    setLoading(true);
    setSchema((prev: any) => {
      const updateField = (fields: any[]) =>
        fields.map((field: any) => {
          if (field.name === "recent_interactions_cards") {
            field.fields = [];
          } else if (field.fields) {
            field.fields = updateField(field.fields);
          }
          return field;
        });

      return {
        ...prev,
        schema: prev?.schema?.map((sec: any) => {
          if (sec.fields) {
            sec.fields = updateField(sec.fields);
          }
          return sec;
        }),
      };
    });
    try {
      const res = await Utils.makeApiCall(
        `/getRecentInteractions?mobile=${mobile}`,
        "GET"
      );

      if (!res.status) {
        toast.error(res.message || FAILURE_MSG, {
          position: "top-center",
          autoClose: 2000,
        });
        return;
      }
      handleInteractions(res);
    } catch (error) {
      toast.error(CATCH_ERROR_MSG, {
        position: "top-center",
        autoClose: AUTO_CLOSE,
      });
      console.error("Failed to fetch schema", error);
    } finally {
      setLoading(false);
    }
  };

  const getInteractionIconData = (item: any) => {
    let icon;
    let iconClassName = "flex items-center rounded-md justify-center p-2";
    if (item?.interaction_type === "Lead") {
      icon = <IoMagnet className="size-5 rotate-180" />;
      iconClassName += ` ${LabelTags.get("Lead")}`;
    } else if (item?.interaction_type === "Call") {
      const icons: any = {
        missed: <HiPhoneMissedCall className="size-5" />,
        answered: <MdCall className="size-5" />,
        call_attempt: <MdPhoneCallback className="size-5" />,
      };
      icon = icons[item.type?.toLowerCase()] || (
        <MdPhoneCallback className="size-5" />
      );
      iconClassName += ` ${LabelTags.get(item.type)}`;
    } else {
      icon = <SiChatbot className="size-5" />;
      iconClassName += ` ${LabelTags.get("Chatbot")}`;
    }
    return { icon, iconClassName };
  };

  const getCallIconData = (status: any) => {
    let icon = "";
    let iconClassName =
      "flex items-center rounded-full w-12 h-12 justify-center p-2";
    const icons: any = {
      missed: <HiPhoneMissedCall className="size-5" />,
      answered: <MdCall className="size-5" />,
      call_attempt: <MdPhoneCallback className="size-5" />,
    };
    icon = icons[status?.toLowerCase()] || (
      <MdPhoneCallback className="size-5" />
    );
    iconClassName += ` ${LabelTags.get(status)}`;
    return { icon, iconClassName };
  };

  const handleInteractions = (res: any) => {
    setSchema((prev: any) => {
      const updateField = (fields: any[]) =>
        fields.map((field: any) => {
          if (field.name === "recent_interactions_cards") {
            if (res.data?.length) {
              field.fields = res.data.map((item: any) => {
                const { icon, iconClassName } = getInteractionIconData(item);
                const isSelected = item?._id === selectedRecord._id;
                return {
                  record: item,
                  name: `interaction_${item._id}`,
                  element: "clickable-widget",
                  className: `m-1 p-2 flex items-center gap-2 border-[1px] rounded-md drop-shadow-sm bg-white scale-[1] hover:scale-[1.01] hover:bg-[#F2F2F2]/10 transition-all duration-600 ease-in-out cursor-pointer ${isSelected ? "border-primary shadow-md scale-[1.01] border-[2px]" : ""}`,
                  fields: [
                    {
                      name: `icon_${item._id}`,
                      label: icon,
                      className: iconClassName,
                      element: "div",
                    },
                    {
                      name: `content_${item._id}`,
                      className: "flex flex-col gap-[2px] w-full",
                      fields: [
                        {
                          name: "interaction_header",
                          className: "flex justify-between items-center",
                          fields: [
                            {
                              name: "interaction_name",
                              element: "div",
                              className: `text-sm font-medium text-black`,
                              label: item.interaction_type,
                            },
                            {
                              name: "interaction_status",
                              element: "div",
                              className: `p-1 px-2 text-xs text-gray-600`,
                              label: item.createdAt,
                            },
                          ],
                        },
                        {
                          name: "interaction_type",
                          element: "div",
                          className: `text-[10px] w-fit capitalize font-medium ${iconClassName} !p-0 !bg-transparent`,
                          label: item.type || "--",
                        },
                      ],
                    },
                  ],
                };
              });
            } else {
              field.fields = [
                {
                  name: "no_recent_interactions",
                  element: "div",
                  className:
                    "text-sm text-gray-600 text-center flex justify-center items-center h-full",
                  label: "No recent interactions found",
                },
              ];
            }
          } else if (field.fields) {
            field.fields = updateField(field.fields);
          }
          return field;
        });

      return {
        ...prev,
        schema: prev?.schema?.map((sec: any) => {
          if (sec.fields) {
            sec.fields = updateField(sec.fields);
          }
          return sec;
        }),
      };
    });
  };

  const handleSelectedInteraction = (id: any) => {
    setSchema((prev: any) => {
      const updateField = (fields: any[]) =>
        fields.map((field: any) => {
          if (field.name.includes("interaction_")) {
            const isSelected = field?.record?._id === id;
            field.className = `m-1 p-2 flex items-center gap-2 border-[1px] rounded-md drop-shadow-sm bg-white scale-[1] hover:scale-[1.01] hover:bg-[#F2F2F2]/10 transition-all duration-600 ease-in-out cursor-pointer ${isSelected ? "border-primary shadow-md scale-[1.01] border-[2px]" : ""}`;
          } else if (field.fields) {
            field.fields = updateField(field.fields);
          }
          return field;
        });

      return {
        ...prev,
        schema: prev?.schema?.map((sec: any) => {
          if (sec.fields) {
            sec.fields = updateField(sec.fields);
          }
          return sec;
        }),
      };
    });
  };

  const API_GET_GetAllComments = async () => {
    dispatch(setLoading(true));
    const id = selectedRecord?._id;
    resetForm(false);
    try {
      const res = await Utils.makeApiCall(
        `/calls/getComments?callId=${id}`,
        "GET"
      );
      if (res.status) {
        if (res?.data?.length !== 0) {
          setSchema((prev: any) => {
            const updateField = (fields: any[]) =>
              fields.map((field: any) => {
                if (field.name === "modal_body_container") {
                  field.fields = res.data.map((comment: any) => {
                    const name = comment?.fullname || "--";
                    const allInitials =
                      name
                        ?.split(" ")
                        .map((e: string) => e[0])
                        .join("")
                        .toUpperCase() || "--";
                    const iconInitials =
                      allInitials.length >= 2
                        ? allInitials.substring(0, 2)
                        : allInitials;

                    return {
                      name: comment["_id"],
                      className: "flex flex-col",
                      fields: [
                        {
                          name: "modal_content",
                          className: "flex w-full my-2 px-0 gap-2",
                          fields: [
                            {
                              name: "modal_icon",
                              className: "flex justify-center",
                              fields: [
                                {
                                  name: "modal_element_icon",
                                  className: `bg-primary/5 text-primary rounded-full w-[40px] h-[40px] flex items-center justify-center text-md font-bold`,
                                  element: "div",
                                  label: iconInitials,
                                },
                              ],
                            },
                            {
                              name: "modal_body",
                              className: " w-full flex flex-col",
                              fields: [
                                {
                                  name: "modal_element_title",
                                  className:
                                    "flex flex-row justify-between w-full",
                                  fields: [
                                    {
                                      name: "modal_element_name",
                                      className:
                                        "flex gap-2 items-center justify-center",
                                      fields: [
                                        {
                                          name: "user_name",
                                          className: "font-semibold",
                                          element: "div",
                                          label: name || "--",
                                        },
                                        {
                                          name: "time",
                                          className: "text-xs",
                                          element: "div",
                                          // label: `${moment(comment?.createdAt).format('DD-MM-YYYY hh:mm a')} (${moment(comment?.createdAt).fromNow()})`
                                          label: `${moment(comment?.createdAt).fromNow()}`,
                                        },
                                      ],
                                    },
                                  ],
                                },
                                {
                                  name: "modal_element_text",
                                  element: "div",
                                  className: "text-sm",
                                  label: comment?.text || "--",
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    };
                  });
                } else if (field.name === "call-tags-input") {
                  field.value = res?.data?.call_tags;
                } else if (field.fields) {
                  field.fields = updateField(field.fields);
                }
                return field;
              });

            return {
              ...prev,
              schema: prev?.schema?.map((sec: any) => {
                if (sec.fields) {
                  sec.fields = updateField(sec.fields);
                }
                return sec;
              }),
            };
          });
        }
      } else {
        toast.error(res.message ? res.message : FAILURE_MSG, {
          position: "top-center",
          autoClose: 2000,
        });
      }
    } catch (error) {
      toast.error(CATCH_ERROR_MSG, {
        position: "top-center",
        autoClose: AUTO_CLOSE,
      });
      console.error("Failed to fetch schema", error);
    }
    dispatch(setLoading(false));
  };

  const API_POST_AddComent = async () => {
    dispatch(setLoading(true));

    if (!commentInput) {
      toast.error("Please enter your comment.", {
        position: "top-center",
        autoClose: AUTO_CLOSE,
      });
      dispatch(setLoading(false));
      return;
    }
    // if (commentStatus['value'].length === 0) {
    //     toast.error("Please select your status.", { position: "top-center", autoClose: AUTO_CLOSE })
    //     dispatch(setLoading(false))
    //     return;
    // }
    const id = selectedRecord?._id;
    const payload = {
      text: commentInput,
      userId: Storage.getItem("details")["_id"],
      callId: id,
      status: commentStatus["value"],
    };

    try {
      const res = await Utils.makeApiCall(`/calls/addComment`, "POST", payload);

      if (res.status) {
        toast.success(res.message, {
          position: "top-center",
          autoClose: 2000,
        });
        API_GET_GetAllComments();
        resetForm(true);
      } else {
        toast.error(res.message ? res.message : FAILURE_MSG, {
          position: "top-center",
          autoClose: 2000,
        });
      }
    } catch (error) {
      toast.error(CATCH_ERROR_MSG, {
        position: "top-center",
        autoClose: AUTO_CLOSE,
      });
      console.error("Failed to fetch schema", error);
    }
    dispatch(setLoading(false));
  };

  const resetForm = (visible: boolean) => {
    setSchema((prev: any) => {
      const updateField = (fields: any[]) =>
        fields.map((field: any) => {
          if (field.name === "comments_and_recent_interactions") {
            field.fields = field.fields.map((item: any) => {
              if (item.name === "comments_modal_container") {
                // item.visible = visible;
                item.fields.map((f: any) => {
                  if (f.name === "modal_body_container") {
                    if (!visible) {
                      f.fields = [
                        {
                          name: "empty",
                          label: (
                            <span className="flex items-center gap-2">
                              <MdOutlineCommentsDisabled /> No Comments
                            </span>
                          ),
                          element: "div",
                          className:
                            "flex items-center justify-center w-full h-[200px]",
                        },
                      ];
                    }
                  } else if (f.name === "modal_comment_container") {
                    f.fields = f.fields.map((ff: any) => {
                      ff.value = null;
                      return ff;
                    });
                  }
                });
              }
              return item;
            });
          } else if (field.fields) {
            field.fields = updateField(field.fields);
          }
          return field;
        });

      return {
        ...prev,
        schema: prev?.schema?.map((sec: any) => {
          if (sec.fields) {
            sec.fields = updateField(sec.fields);
          }
          return sec;
        }),
      };
    });
  };
  const handleCallTags = async (e: any) => {
    if (tagDebounceTime.current) {
      clearTimeout(tagDebounceTime.current);
    }
    tagDebounceTime.current = setTimeout(async () => {
      try {
        const payload: any[] = [];
        let flag = false;
        for (const val of e?.value || []) {
          if (val?.label === val?.value) {
            flag = true;
            const res = await Utils.makeApiCall("/call-tags", "POST", {
              tag_name: val?.label,
            });
            if (res.status) {
              payload.push(res?.data?._id);
              await handleTagOptions();
            } else {
              continue;
            }
          } else {
            payload.push(val?.value);
          }
        }
        const id = selectedRecord?._id;
        const res = await Utils.makeApiCall(
          `/updateCallTag?call_id=${id}`,
          "PUT",
          payload
        );
        if (!res.status) {
          toast.error(CATCH_ERROR_MSG, {
            position: "top-center",
            autoClose: AUTO_CLOSE,
          });
          console.log("Error while sending the data.");
        } else {
          if (flag) {
            // API_GET_GetAllComments()
            API_GET_CALL_DATA();
            handleTagOptions();
          }
        }
      } catch (err) {
        toast.error(CATCH_ERROR_MSG, {
          position: "top-center",
          autoClose: AUTO_CLOSE,
        });
        console.log("Error while sending the data.", err);
      }
    }, 0);
  };

  const handleSelectedRecord = (e: any) => {
    if (e.name === "call-tags-input") {
      handleCallTags(e);
    }

    if (e.name === "comment_submit") {
      API_POST_AddComent();
    }
    if (e.name === "lead_status_dropdown") {
      setCommmentStatus(e?.value || { value: "", label: "" });
    }
    if (e.name === "lead_comment_input") {
      setCommentInput(e?.value || "");
    }

    if (e.name.includes("interaction_")) {
      // console.log(e);
      if (e?.record?.interaction_type === "Call") {
        setSelectedRecord(e?.record);
      }
    }
  };

  return (
    <ElementExecutor data={schema} selectedRecord={handleSelectedRecord} />
  );
};

export default ViewCalls;
