import { ElementExecutor } from '@apexcura/core';
import React, { useEffect, useState } from 'react'
import { unstable_usePrompt, useNavigate, useParams } from 'react-router-dom';
import useExitPopUp from '../hooks/useExitPopUp';

const editPatients = {
    className: "w-full bg-white rounded-lg p-8 flex flex-col gap-3 items-center",
    schema: [ 
      {
        name: "data_fields",
        className: "w-full flex flex-col gap-2",
        fields: [
          {
            name: "name_and_type",
            className: "grid grid-cols-2 flex gap-2",
            fields: [
              {
                name: "name",
                element: "input-text",
                placeholder: "Eg: Jon doe",
                label: "Name",
                containerClassName: "w-full flex flex-col",
                labelClassName:
                  "text-sm mb-1 text-gray-500 font-semibold text-start",
                className:
                  "rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none",
                required: true,
                visible: true,
              },
              {
                name: "mobile",
                element: "input-text",
                placeholder: "Eg: 8123456790",
                label: "Mobile",
                containerClassName: "w-full flex flex-col",
                labelClassName:
                  "text-sm mb-1 text-gray-500 font-semibold text-start",
                className:
                  "rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none",
                required: true,
                visible: true,
              },
              {
                name: "gender",
                element: "single-select",
                placeholder: "Male",
                label: "Gender",
                containerClassName: "w-full flex flex-col gap-1",
                labelClassName:
                  "text-sm mb-1 text-gray-500 font-semibold text-start",
                className:
                  "rounded-md text-gray-500 font-semibold text-sm ",
                required: true,
                visible: true,
                options:[
                    {
                        label:"Male",
                        value:"male"
                    },
                    {
                        label:"Female",
                        value:"female"
                    },
                    {
                        label:"Others",
                        value:"others"
                    }
                ]
              },
              {
                name:"dob",
                className:"w-full flex flex-col gap-2",
                fields:[
                    {
                        element:"div",
                        name:"dob_text",
                        label:"Date of Birth",
                        className:"text-gray-500 font-semibold text-sm "
                    },
                    {
                        name: "dob",
                        element: "datepicker",
                        placeholder: "Eg: 01-01-1999",
                        label: "",
                        className:
                          "rounded-md w-full px-3 py-1 text-gray-700 font-semibold focus:outline-none",
                        required: true,
                        visible: true,
                      },
                ]
              }
            ],
          },
        ],
      },
      {
        name: "cancel_and_submit",
        fields: [
          {
            name: "cancel",
            label: "Cancel",
            element: "button",
            className:
              "p-2 px-3  text-default-500 bg-gray-200 text-black rounded-md font-semibold",
          },
          {
            name: "submit",
            label: "Submit",
            element: "button",
            className:
              "p-2 px-3  text-default-500 bg-primary  rounded-md text-white font-semibold",
          },
        ],
        className: "flex self-end gap-2",
      },
    ],
};

const EditPatients = () => {

    const [schema, setSchema] = useState<any>(editPatients);
    const navigate=useNavigate();
    const {patientId}=useParams();
    console.log(patientId)
    
    const [detectChange, setDetectChange] = useState(false)

    // Exit popup's custom hook
    useExitPopUp(detectChange)

    
    const handleSelectedRecord=(e:any)=>{
        
        if(e.name === "cancel"){
          navigate("/patients")
        }
        if(e.name === "submit"){
          setDetectChange(false)
        }
    }


  return (
    <div>
      <ElementExecutor
        data={schema}
        setData={(e: any) => {
          setSchema({ ...e });
        }}
        selectedRecord={(e: any) => {
          handleSelectedRecord(e);
          if(["name", "mobile", "gender", "dob"].includes(e?.name) ){
            setDetectChange(true)
          }
        }}
      />
    </div>
  )
}

export default EditPatients
