import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../rootReducer'
import { IAuthState } from './types'

const initialState: IAuthState = {
    pagePermissions: [],
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setPagePermissions: (
            state,
            action: PayloadAction<Array<Record<string, any>>>
        ) => {
            state.pagePermissions = action.payload
        },
    },
})

export const getPagePermissions = (state: RootState) =>
    state.auth.pagePermissions
export const { setPagePermissions } = authSlice.actions

export default authSlice.reducer
