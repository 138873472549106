import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ILeadInitState } from "./types";

const initialState: ILeadInitState = {
    activeTab: "comments",
    selectedRow: {}
} 
const leadSlice = createSlice({
    name: "lead",
    initialState,
    reducers: {
        setActiveTab: (state, action: PayloadAction<string>) =>{
            state.activeTab = action.payload;
        },
        setSelectedRow: (state, action) => {
            state.selectedRow=action.payload;
        }
    }
})
export const { setActiveTab, setSelectedRow } = leadSlice.actions;
export default leadSlice.reducer;