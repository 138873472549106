import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { IPatientsState } from "./types";


const initialState: IPatientsState = {
    pagination:{
        page:1,
        pageSize:30,
    },
    dateRange:["",""],
    searchText:"",

} 

const patientSlice=createSlice({
    name:"patient",
    initialState,
    reducers:{
        setPagination:(state,action)=>{
            state.pagination=action.payload;
        },
        setDateRange:(state,action)=>{
            state.dateRange=action.payload
        },
        setSearchText:(state,action)=>{
            state.searchText=action.payload;
        }
    }
})

export const {setPagination,setDateRange,setSearchText}=patientSlice.actions;
export default patientSlice.reducer;