"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AadharComponent = void 0;
const react_1 = __importStar(require("react"));
const antd_1 = require("antd");
const AadharComponent = (props) => {
    const [values, setValues] = (0, react_1.useState)(Array(3).fill(''));
    // const [error, setError] = useState(false);
    const inputRefs = (0, react_1.useRef)([]);
    (0, react_1.useEffect)(() => {
        if (props.onChange) {
            const finalValue = values.join('');
            props.onChange(finalValue);
            // console.log(finalValue);
        }
        // const hasEmptyField = values.some((value) => value === '');
        // setError(hasEmptyField);
    }, [values]);
    const handleChange = (index) => (e) => {
        const newValue = e.target.value.replace(/[^0-9]/g, '');
        const maxLength = 4;
        const isBackspace = e.nativeEvent instanceof InputEvent &&
            e.nativeEvent.inputType === 'deleteContentBackward';
        setValues((prevValues) => {
            const newValues = [...prevValues];
            newValues[index] = newValue;
            return newValues;
        });
        if (newValue.length === maxLength &&
            index < inputRefs.current.length - 1) {
            inputRefs.current[index + 1]?.focus();
        }
        else if (isBackspace && newValue.length === 0 && index > 0) {
            inputRefs.current[index - 1]?.focus();
        }
    };
    const getInput = (index) => {
        return (react_1.default.createElement("div", { className: props.containerClassName, key: index },
            react_1.default.createElement(antd_1.Input, { ref: (el) => (inputRefs.current[index] = el), placeholder: props.placeholder, addonBefore: props.addonBefore, value: values[index], disabled: props.disabled, id: `${props.name}-${index}`, prefix: props.prefix, type: props.type, 
                // status={props.required && error ? 'error' : ''}
                className: props.className, variant: props.variant, name: `${props.name}-${index}`, onChange: handleChange(index), maxLength: 4 })));
    };
    return (react_1.default.createElement("div", null,
        props.label && (react_1.default.createElement("p", { className: props.labelClassName },
            props.label,
            ' ',
            props.required ? react_1.default.createElement("span", { style: { color: 'red' } }, "*") : '')),
        react_1.default.createElement("div", null,
            react_1.default.createElement("div", { className: "flex flex-row" }, Array.from({ length: 3 }, (_, index) => getInput(index))))));
};
exports.AadharComponent = AadharComponent;
