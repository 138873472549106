import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IAppState } from './types'

const initialState: IAppState = {
    loading: false,
    branches:[]
}

const appStateSlice = createSlice({
    name: 'appState',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        },
        setBranches:(state, action: PayloadAction<any>) => {
            state.branches = action.payload
        },
    },
})


export const { setLoading,setBranches } = appStateSlice.actions

export default appStateSlice.reducer
