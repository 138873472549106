import React, { useEffect, useState } from 'react'
import {Avatar,Tooltip} from "antd";
import moment from 'moment';
import { ElementExecutor } from '@apexcura/core';
import { CONSTANTS } from '../../utils/app-constants';
import { toast } from 'react-toastify';
import Utils from '../../utils';
import Storage from '../../utils/local-storage';
import { setLoading } from '../../redux/appState/appStateSlice';
import { useDispatch } from 'react-redux';
const { BASE_URL } = CONSTANTS;
const { FAILURE_MSG, CATCH_ERROR_MSG, AUTO_CLOSE } = CONSTANTS

const LeadInsights = () => {
    const [schema, setSchema] = useState<any>({
        className:
          'w-full bg-white rounded-lg py-[10px] px-[20px] flex flex-col gap-3',
        schema: [
          {
            name: 'filters',
            fields: [
              {
                name:"date_and_search",
                className:"flex gap-2 justify-center items-center ",
                fields:[
                  {
                      name:"segments",
                      element:"segmented",
                      segmentedOptions:[
                        {
                            label:(<Tooltip title="Today">1D</Tooltip>),
                            value:"TD"
                        },
                        {
                            label:(<Tooltip title="This Week">1W</Tooltip>),
                            value:"TW"
                        },
                        {
                            label:(<Tooltip title="This Month">1M</Tooltip>),
                            value:"TM"
                        },
                      ],
                      visible:true,
                      value:"TM"
                  },
                  {
                    name: 'date',
                    label: '',
                    placeholder: 'Select a date',
                    allowClear:false,
                    element: 'daterangepicker',
                    value: [moment().subtract(30, 'days').format('DD-MM-YYYY'), moment().format("DD-MM-YYYY")],
                    className: 'flex-1 w-[240px]',
                  },
                ]
              },
            ],
            className: 'flex items-center justify-between gap-2',
          },
          {
            name:"anomolies_and_insights",
            className:"grid grid-cols-2 gap-2 items-start h-[calc(100vh-150px)]",
            fields:[
                {
                    name:"anomalies_table",
                    className:"flex gap-2 flex-col col-span-1 rounded-md p-2 h-fit overflow-hidden",
                    fields:[
                        {
                            name:"anomalies",
                            label:"Anomalies",
                            element:"div",
                            className:"text-primary font-semibold text-xl w-full bg-primary/5 rounded-lg p-2"
                        },
                        {
                            name:"anomalies_cards",
                            className:"grid grid-cols-2 gap-2 h-full overflow-y-auto [&::-webkit-scrollbar]:hidden",
                            fields:[
                                {
                                    name: `unanswered_questions_card`,
                                    className:
                                        'rounded-md cursor-pointer border-2 border-black hover:shadow-lg flex gap-3 items-center bg-white px-[16px] py-[14px]',
                                    element: 'clickable-widget',
                                    fields: [
                                        {
                                            name: 'unanswered_questions_card_icon',
                                            className: 'cursor-default ',
                                            element: 'image',
                                            imgClassName:"size-8",
                                            img:`${BASE_URL}/api/public/assets/images/dashboard-bot.svg`,
                                            visible: true,
                                        },
                                        {
                                            name:"anomalies_value_and_status",
                                            className:"flex flex-col gap-1 ml-1",
                                            fields:[
                                                {
                                                    name: 'bot_effeciency_card_value',
                                                    className:'text-xl text-black font-semibold',
                                                    element: 'div',
                                                    label: '12 %',
                                                    visible: true,
                                                },
                                                {
                                                    name: 'bot_effeciency_card_text',
                                                    className: 'text-sm text-gray-4 font-normal',
                                                    element: 'div',
                                                    label: 'Lead Drop-off Rate',
                                                    visible: true,
                                                },
                                            ]
                                        }
                                    ],
                                },
                                {
                                    name: `chat_dropoff_card`,
                                    className:
                                        'rounded-md cursor-pointer border-2 border-black hover:shadow-lg flex gap-3 items-center bg-white px-[16px] py-[14px]',
                                    element: 'clickable-widget',
                                    fields: [
                                        {
                                            name: 'chat_dropoff_card_icon',
                                            className: 'cursor-default ',
                                            element: 'image',
                                            imgClassName:"size-8",
                                            img:`${BASE_URL}/api/public/assets/images/dashboard-hangup.svg`,
                                            visible: true,
                                        },
                                        {
                                            name:"anomalies_value_and_status",
                                            className:"flex flex-col gap-1 ml-1",
                                            fields:[
                                                {
                                                    name: 'negative_feedback_card_value',
                                                    className:'text-xl text-black font-semibold',
                                                    element: 'div',
                                                    label: '35 %',
                                                    visible: true,
                                                },
                                                {
                                                    name: 'negative_feedback_card_text',
                                                    className: 'text-sm text-gray-4 font-normal',
                                                    element: 'div',
                                                    label: 'High Bounce Rate',
                                                    visible: true,
                                                },
                                            ]
                                        }
                                    ],
                                },
                                {
                                    name: `chat_dropoff_card`,
                                    className:
                                        'rounded-md cursor-pointer border-2 border-black hover:shadow-lg flex gap-3 items-center bg-white px-[16px] py-[14px]',
                                    element: 'clickable-widget',
                                    fields: [
                                        {
                                            name: 'chat_dropoff_card_icon',
                                            className: 'cursor-default ',
                                            element: 'image',
                                            imgClassName:"size-8",
                                            img:`${BASE_URL}/api/public/assets/images/dashboard-hangup.svg`,
                                            visible: true,
                                        },
                                        {
                                            name:"anomalies_value_and_status",
                                            className:"flex flex-col gap-1 ml-1",
                                            fields:[
                                                {
                                                    name: 'negative_feedback_card_value',
                                                    className:'text-xl text-black font-semibold',
                                                    element: 'div',
                                                    label: '14 mins',
                                                    visible: true,
                                                },
                                                {
                                                    name: 'negative_feedback_card_text',
                                                    className: 'text-sm text-gray-4 font-normal',
                                                    element: 'div',
                                                    label: 'Lead Response Delay',
                                                    visible: true,
                                                },
                                            ]
                                        }
                                    ],
                                },
                            ]
                        }
                    ]
                },
                {
                name:"actionable_insights_table",
                className:"flex gap-2 flex-col col-span-1 rounded-md p-2 h-fit overflow-hidden",
                fields:[
                    {
                        name:"actionable_insights",
                        label:"Actionable Insights",
                        element:"div",
                        className:"text-primary font-semibold text-xl w-full bg-primary/5 rounded-lg p-2"
                    },
                    {
                        name:"anomalies_cards",
                        className:"grid grid-cols-2 gap-2 h-full overflow-y-auto [&::-webkit-scrollbar]:hidden",
                        fields:[
                            {
                                name: `unanswered_questions_card`,
                                className:
                                    'rounded-md cursor-pointer border-2 border-black hover:shadow-lg flex gap-3 items-center bg-white px-[16px] py-[14px]',
                                element: 'clickable-widget',
                                fields: [
                                    {
                                        name: 'unanswered_questions_card_icon',
                                        className: 'cursor-default ',
                                        element: 'image',
                                        imgClassName:"size-8",
                                        img:`${BASE_URL}/api/public/assets/images/dashboard-bot.svg`,
                                        visible: true,
                                    },
                                    {
                                        name:"anomalies_value_and_status",
                                        className:"flex flex-col gap-1 ml-1",
                                        fields:[
                                            {
                                                name: 'bot_effeciency_card_value',
                                                className:'text-xl text-black font-semibold',
                                                element: 'div',
                                                label: '43 %',
                                                visible: true,
                                            },
                                            {
                                                name: 'bot_effeciency_card_text',
                                                className: 'text-sm text-gray-4 font-normal',
                                                element: 'div',
                                                label: 'Lead Conversion Rate',
                                                visible: true,
                                            },
                                        ]
                                    }
                                ],
                            },
                            {
                                name: `chat_dropoff_card`,
                                className:
                                    'rounded-md cursor-pointer border-2 border-black hover:shadow-lg flex gap-3 items-center bg-white px-[16px] py-[14px]',
                                element: 'clickable-widget',
                                fields: [
                                    {
                                        name: 'chat_dropoff_card_icon',
                                        className: 'cursor-default ',
                                        element: 'image',
                                        imgClassName:"size-8",
                                        img:`${BASE_URL}/api/public/assets/images/dashboard-hangup.svg`,
                                        visible: true,
                                    },
                                    {
                                        name:"anomalies_value_and_status",
                                        className:"flex flex-col gap-1 ml-1",
                                        fields:[
                                            {
                                                name: 'negative_feedback_card_value',
                                                className:'text-xl text-black font-semibold',
                                                element: 'div',
                                                label: 'Calls',
                                                visible: true,
                                            },
                                            {
                                                name: 'negative_feedback_card_text',
                                                className: 'text-sm text-gray-4 font-normal',
                                                element: 'div',
                                                label: 'High-Interest Segments',
                                                visible: true,
                                            },
                                        ]
                                    }
                                ],
                            },
                            {
                                name: `chat_dropoff_card`,
                                className:
                                    'rounded-md cursor-pointer border-2 border-black hover:shadow-lg flex gap-3 items-center bg-white px-[16px] py-[14px]',
                                element: 'clickable-widget',
                                fields: [
                                    {
                                        name: 'chat_dropoff_card_icon',
                                        className: 'cursor-default ',
                                        element: 'image',
                                        imgClassName:"size-8",
                                        img:`${BASE_URL}/api/public/assets/images/dashboard-hangup.svg`,
                                        visible: true,
                                    },
                                    {
                                        name:"anomalies_value_and_status",
                                        className:"flex flex-col gap-1 ml-1",
                                        fields:[
                                            {
                                                name: 'negative_feedback_card_value',
                                                className:'text-xl text-black font-semibold',
                                                element: 'div',
                                                label: '10 - 11 A.M',
                                                visible: true,
                                            },
                                            {
                                                name: 'negative_feedback_card_text',
                                                className: 'text-sm text-gray-4 font-normal',
                                                element: 'div',
                                                label: 'Optimal Lead Engagement Time',
                                                visible: true,
                                            },
                                        ]
                                    }
                                ],
                            },
                        ]
                    }
                ]
                },
            ]
          },
          {
            name: 'table_modal',
            containerClassName:"!w-[90%]  ",
            className: 'rounded-lg !w-full !h-[90vh]',
            visible: false,
            element: "modal",
            fields: [
                {
                    name: 'modal_title',
                    className: 'w-full bg-primary/5 p-2 pl-4 flex items-center rounded-t-lg justify-between',
                    fields: [
                        {
                            name: 'modal_title_key',
                            element: 'div',
                            label: '',
                            className: 'font-semibold text-lg text-primary',
                        },
                        {
                            name: 'modal_close',
                            element: 'button',
                            label: 'X',
                            className: 'w-[40px] h-[40px] flex justify-center items-center rounded-full bg-primary text-xl text-white',
                        },
                    ],
                },
                {
                    name:"cards_and_chats",
                    className:"flex gap-2 items-center  w-full",
                    fields:[
                        {
                            name:"cards",
                            className:"grid grid-cols-3 w-[100%] gap-3 h-[70%] max-h-[80vh] overflow-y-scroll p-2",
                            fields:[
        
                            ]
                        },
                        {
                            name:"chats",
                            visible:false,
                            className:" col-span-1 w-[40%] h-[70%] flex flex-col items-stretch ",
                            fields:[
                                {
                                    name: 'chat_modal_title',
                                    className: 'w-full p-2 pl-4 flex items-center rounded-t-lg justify-between',
                                    fields: [
                                        {
                                            name: 'chat_modal_title_key',
                                            element: 'div',
                                            label: 'Interaction',
                                            className: 'font-semibold text-lg text-primary',
                                        },
                                        {
                                            name: 'chat_modal_close',
                                            element: 'button',
                                            label: 'X',
                                            className: 'w-[40px] h-[40px] flex justify-center items-center hover:border-[2px] rounded-full text-xl text-black',
                                        },
                                    ],
                                },
                                {
                                    name: 'chat_modal_texts',
                                    className: 'bot-conversations w-full flex flex-col gap-2 pb-2 p-2 h-[90%] max-h-[70vh] overflow-y-scroll',
                                    fields: [
                                        
                                    ],
                                },
                            ]
                        }
                    ]
                }
            ],
          },
        ],
    });
    const dispatch = useDispatch();
    const [dateRange, setDateRange] = useState<[string, string]>([
        moment().subtract(30, 'days').format('DD-MM-YYYY'),
        moment().format('DD-MM-YYYY'),
    ])
    const details = Storage.getItem("details");

    useEffect(()=>{
        API_GET_BOT_EFFECIENCY_INSIGHTS({ startDate: dateRange[0], endDate: dateRange[1],name:"not_bot_effeciency_modal" })
        API_GET_NEGATIVE_FEEDBACK_INSIGHTS({ startDate: dateRange[0], endDate: dateRange[1],name:"not_negative_feedback_modal" })

        const first = moment(dateRange[0], 'DD-MM-YYYY');
        const second = moment(dateRange[1], 'DD-MM-YYYY');
        const diffInDays = second.diff(first, 'days');
        const today = moment().startOf('day');
    
        if (first.isSame(today, 'day') && second.isSame(today, 'day')) {
            change_segments_value("TD")
        } else if (diffInDays === 7 && second.isSame(today, 'day')) {
            change_segments_value("TW")
        } else if (diffInDays === 30 && second.isSame(today, 'day')) {
            change_segments_value("TM")
        }else{
            change_segments_value(null)
        }
    },[dateRange])

    const API_GET_BOT_EFFECIENCY_INSIGHTS=async ({  startDate = "", endDate = "",name="not_bot_effeciency_modal" })=>{
        dispatch(setLoading(true))
        try {
            const res: any = await Utils.makeApiCall(`/getBotActionableInsights?start_date=${moment(startDate, "DD-MM-YYYY").format("YYYY-MM-DD")}&end_date=${moment(endDate, "DD-MM-YYYY").format("YYYY-MM-DD")}`,"GET");
            if (res?.status) {
                if(name==="not_bot_effeciency_modal"){
                    change_percentage_of_bot_efficiency(res?.data)
                }
                else{
                    change_modal_data(res.data,"Bot Efficiency is at")
                }

            } else {
                toast.error(res.message ? res.message : FAILURE_MSG, { position: 'top-center', autoClose: AUTO_CLOSE })
            }
        } catch (error) {
            toast.error(CATCH_ERROR_MSG, { position: 'top-center', autoClose: AUTO_CLOSE });
            console.error('Failed to fetch schema', error);
        }
        dispatch(setLoading(false))
    }

    const API_GET_NEGATIVE_FEEDBACK_INSIGHTS=async ({  startDate = "", endDate = "",name="not_negative_feedback_modal" })=>{
        dispatch(setLoading(true))
        try {
            const res: any = await Utils.makeApiCall(`/getBotNegativeFeedbacks?start_date=${moment(startDate, "DD-MM-YYYY").format("YYYY-MM-DD")}&end_date=${moment(endDate, "DD-MM-YYYY").format("YYYY-MM-DD")}`,"GET");
            if (res?.status) {
                if(name==="not_negative_feedback_modal"){
                    change_percentage_of_negative_feedback(res?.data)
                } else {
                    change_modal_data(res?.data,"Negative FeedBack Rate is at")
                }
                
            } else {
                toast.error(res.message ? res.message : FAILURE_MSG, { position: 'top-center', autoClose: AUTO_CLOSE })
            }
        } catch (error) {
            toast.error(CATCH_ERROR_MSG, { position: 'top-center', autoClose: AUTO_CLOSE });
            console.error('Failed to fetch schema', error);
        }
        dispatch(setLoading(false))
    }

    const API_GET_texts=async (id:any,name:any)=>{
        dispatch(setLoading(true))
        try {
            const res: any = await Utils.makeApiCall(`/conversation?id=${id}&org_id=${details?.org_id}`, 'GET')
            if (res) {
                change_texts_data(res?.data,name);

            } else {
                toast.error(res.message ? res.message : FAILURE_MSG, { position: 'top-center', autoClose: AUTO_CLOSE })
            }
        } catch (error) {
            toast.error(CATCH_ERROR_MSG, { position: 'top-center', autoClose: AUTO_CLOSE });
            console.error('Failed to fetch schema', error);
        }
        dispatch(setLoading(false))
    }

    const change_texts_data = (data: any,name:any) => {
        setSchema((prev: any) => {
            const updatedSchema = prev.schema.map((field: any) => {
                if (field?.name === "table_modal") {
                    field.fields = field.fields?.map((ffield: any) => {
                        if (ffield?.name === "cards_and_chats") {
                            ffield.fields = ffield.fields?.map((fffield: any) => {
                                if (fffield?.name === "chats") {
                                    fffield.visible = true;
                                    fffield.className = "col-span-1 w-[40%] h-[70%] flex flex-col items-stretch transition-all duration-300 ease-in-out"
                                    fffield.fields = fffield.fields?.map((ffffield: any) => {
                                        if (ffffield?.name === "chat_modal_texts") {
                                            ffffield.fields = data[0]?.messages;
                                        }
                                        return ffffield;
                                    }) || [];
                                }
                                if (fffield?.name === "cards") {
                                    fffield.className = "grid grid-cols-2 w-[60%] gap-3 h-[70%] max-h-[80vh] overflow-y-scroll p-2 transition-all duration-300 ease-in-out";
                                    fffield.fields=fffield.fields.map((card:any)=>{
                                        if(card.name===name){
                                            card.className= " cursor-pointer border border-[2px] border-primary gap-2  rounded-lg p-[5px] flex flex-col items-stretch"
                                        }else{
                                            card.className="hover:shadow-md cursor-pointer border border-[1px] gap-2  rounded-lg p-[5px] flex flex-col items-stretch"
                                        }
                                        return card;
                                    })
                                }
                                return fffield;
                            }) || [];
                        }
                        return ffield;
                    }) || [];
                }
                return field;
            });
            return {...prev, schema: updatedSchema}
        });
    }

    const change_modal_data = (data: any,label:any) => {
        if(data?.kpis.length==0){
            toast.error("No insights in the given dates.",{autoClose:800,position:"top-center"})
            return;
        }
        setSchema((prev: any) => {
            const utcToIndianTime = (utcTime: moment.MomentInput) => {
                // Parse the UTC time
                const utcMoment = moment.utc(utcTime, 'HH:mm:ss');
                
                // Add 5 hours and 30 minutes for IST
                const istMoment = utcMoment.add(5, 'hours').add(30, 'minutes');
                
                // Format the result
                return istMoment.format('hh:mm A');
              };
          const updatedSchema = prev.schema.map((item: any) => {
            if (item.name === 'table_modal') {
                item.visible=true;
              item.fields = item.fields.map((field: any) => {
                if (field.name === "cards_and_chats") {
                  field.fields=field.fields.map((ffield:any)=>{
                    if(ffield.name==="cards"){
                        ffield.fields=data?.kpis.map((chat: any, index: any) =>{
                            return {
                                name: `chat-item-${index}`,
                                className: "hover:shadow-md cursor-pointer border border-[2px] gap-2  rounded-lg p-[5px] flex flex-col items-stretch",
                                element: "clickable-widget",
                                id:chat?._id,
                                fields: [
                                    {
                                        name:"name_and_date",
                                        className: "flex justify-between items-center gap-2 border-b border-b-[1px] border-gray-300 pb-2",
                                        fields:[
                                            {
                                                name: `chat-name-${index}`,
                                                className: 'text-sm font-semibold text-black',
                                                element: "div",
                                                // label: chat?.patient_name || "Anonymous",
                                                label:<div className='flex items-center gap-2'>
                                                    <Avatar size={"default"} style={{backgroundColor:`${Utils.stringToColour(chat?.patient_name || "Anonymous")}`}}>
                                                         <p className={`capitalize`}>{Utils.initials(chat?.patient_name || "Anonymous")}</p>
                                                    </Avatar>
                                                    <p className='flex flex-col '>
                                                        <p>{chat?.patient_name || "Anonymous"} </p>
                                                        <p className='text-xs text-gray-500 font-medium'>{chat?.patient_mobile || "--"}</p>
                                                    </p>
                                                    
                                                </div>
                                            },
                                            {
                                                name: `chat-date-${index}`,
                                                className: 'text-[10px] text-gray-500',
                                                element: "div",
                                                label: `${moment(chat?.date,"YYYY-MM-DD").format("DD-MM-YYYY")} ${utcToIndianTime(chat?.time)}`,
                                            },
                                        ]
                                    },
                                    {
                                        name: "value_and_status",
                                        className: "flex flex-col items-center gap-2 w-full bot-conversations",
                                        fields: [
                                            {
                                                name: `user-message-${index}`,
                                                className: 'user-message-card',
                                                element: "div",
                                                label: `${chat.user}`,
                                            },
                                            {
                                                name: `bot-message-${index}`,
                                                className: 'bot-message line-clamp-3',
                                                element: "div",
                                                label: <Tooltip title={chat.bot}>{chat.bot}</Tooltip>,
                                                style: {
                                                    display: '-webkit-box',
                                                    WebkitBoxOrient: 'vertical',
                                                    WebkitLineClamp: 3,
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    maxHeight: '5.2em', // Adjust this value based on your font size and line height
                                                    lineHeight: '1.5em', // Adjust this value to match your desired line height
                                                    position: 'relative',
                                                    paddingBottom: '1em', // Add some padding to ensure the ellipsis doesn't overlap with text
                                                }
                                            },
                                        ]
                                    }
                                ]
                            }
                          })
                    }
                    return ffield;
                  })
                }
                if(field.name==="modal_title"){
                    field.fields=field.fields.map((ffield:any)=>{
                        if(ffield.name==="modal_title_key"){
                            ffield.label=`${label} ${data?.bot_efficiency || data?.negative_feedback_rate}%`
                        }
                        return ffield;
                    })
                }
                return field;
              })
            }
            return item;
          });
          return { ...prev, schema: updatedSchema };
        });
    };

    const change_percentage_of_bot_efficiency = (data: any) => {
        
        setSchema((prev: any) => {
            const updatedSchema = prev.schema.map((field: any) => {
                if (field.name === "anomolies_and_insights") {  // Keep the original spelling
                    return {
                        ...field,
                        fields: field.fields.map((ffield: any) => {
                            if (ffield.name === "actionable_insights_table") {
                                return {
                                    ...ffield,
                                    fields: ffield.fields.map((fffield: any) => {
                                        if (fffield.name === "actionable_insights_cards") {
                                            return {
                                                ...fffield,
                                                fields: fffield.fields.map((ffffield: any) => {
                                                    if (ffffield.name === "bot_effeciency_card") {  // Keep the original spelling
                                                        return {
                                                            ...ffffield,
                                                            fields: ffffield.fields.map((fffffield: any) => {
                                                                if (fffffield.name === "value_and_status") {
                                                                    return {
                                                                        ...fffffield,
                                                                        fields: fffffield.fields.map((ffffffield: any) => {
                                                                            if (ffffffield.name === "bot_effeciency_card_value") {  // Keep the original spelling
                                                                                // console.log("Updating bot_effeciency_card_value");
                                                                                return {
                                                                                    ...ffffffield,
                                                                                    label: `${data?.bot_efficiency || "0"}%`
                                                                                };
                                                                            }
                                                                            return ffffffield;
                                                                        })
                                                                    };
                                                                }
                                                                return fffffield;
                                                            })
                                                        };
                                                    }
                                                    return ffffield;
                                                })
                                            };
                                        }
                                        return fffield;
                                    })
                                };
                            }
                            return ffield;
                        })
                    };
                }
                return field;
            });
            
            // console.log("Updated schema:", updatedSchema);
            return { ...prev, schema: updatedSchema };
        });
    };

    const change_percentage_of_negative_feedback = (data: any) => {
        
        setSchema((prev: any) => {
            const updatedSchema = prev.schema.map((field: any) => {
                if (field.name === "anomolies_and_insights") {  // Keep the original spelling
                    return {
                        ...field,
                        fields: field.fields.map((ffield: any) => {
                            if (ffield.name === "actionable_insights_table") {
                                return {
                                    ...ffield,
                                    fields: ffield.fields.map((fffield: any) => {
                                        if (fffield.name === "actionable_insights_cards") {
                                            return {
                                                ...fffield,
                                                fields: fffield.fields.map((ffffield: any) => {
                                                    if (ffffield.name === "negative_feedback_card") {  // Keep the original spelling
                                                        return {
                                                            ...ffffield,
                                                            fields: ffffield.fields.map((fffffield: any) => {
                                                                if (fffffield.name === "value_and_status") {
                                                                    return {
                                                                        ...fffffield,
                                                                        fields: fffffield.fields.map((ffffffield: any) => {
                                                                            if (ffffffield.name === "negative_feedback_card_value") {  // Keep the original spelling
                                                                                // console.log("Updating bot_effeciency_card_value");
                                                                                return {
                                                                                    ...ffffffield,
                                                                                    label: `${data?.negative_feedback_rate || "0"}%`
                                                                                };
                                                                            }
                                                                            return ffffffield;
                                                                        })
                                                                    };
                                                                }
                                                                return fffffield;
                                                            })
                                                        };
                                                    }
                                                    return ffffield;
                                                })
                                            };
                                        }
                                        return fffield;
                                    })
                                };
                            }
                            return ffield;
                        })
                    };
                }
                return field;
            });
            
            // console.log("Updated schema:", updatedSchema);
            return { ...prev, schema: updatedSchema };
        });
    };

    const handleModalClose = () => {
        setSchema((prev: any) => {
            const updatedSchema = prev.schema.map((item: any) => {
                if (item.name === 'table_modal') {
                    item.visible = false;
                    item.fields=item.fields.map((field:any)=>{
                        if(field.name==="cards_and_chats"){
                            field.fields=field.fields.map((ffield:any)=>{
                                if(ffield.name==="chats"){
                                    ffield.visible=false;
                                }
                                if(ffield.name==="cards"){
                                    ffield.className="grid grid-cols-3 w-[100%] gap-3 h-[70%] max-h-[80vh] overflow-y-scroll p-2"
                                }
                                return ffield;
                            })
                        }
                        return field;
                    })
                }
                return item;
            });
            return { ...prev, schema: updatedSchema };
        });
    }

    const handle_close_chat_model = () => {
        setSchema((prev: any) => {
            const updatedSchema = prev.schema.map((field: any) => {
                if (field?.name === "table_modal") {
                    field.fields = field.fields?.map((ffield: any) => {
                        if (ffield?.name === "cards_and_chats") {
                            ffield.fields = ffield.fields?.map((fffield: any) => {
                                if (fffield?.name === "chats") {
                                    fffield.visible = false;
                                    fffield.className = "col-span-1 w-[40%] h-[70%] flex flex-col items-stretch transition-all duration-300 ease-in-out opacity-0 transform translate-x-full";
                                }
                                if (fffield?.name === "cards") {
                                    fffield.className = "grid grid-cols-3 w-[100%] gap-3 h-[70%] max-h-[80vh] overflow-y-scroll p-2 transition-all duration-300 ease-in-out";
                                    fffield.fields = fffield.fields.map((card: any) => {
                                        card.className = "hover:shadow-md cursor-pointer border border-[1px] gap-2 rounded-lg p-[5px] flex flex-col items-stretch transition-all duration-300 ease-in-out transform scale-100 opacity-100";
                                        return card;
                                    });
                                }
                                return fffield;
                            }) || [];
                        }
                        return ffield;
                    }) || [];
                }
                return field;
            });
            return {...prev, schema: updatedSchema}
        });
    }

    const change_segments_value=(val:string | null)=>{
        setSchema((prev:any)=>{
            const updatedSchema=prev.schema.map((field:any)=>{
                if(field.name==="filters"){
                   field.fields=field.fields.map((ffield:any)=>{
                    if(ffield.name==="date_and_search"){
                        ffield.fields=ffield.fields.map((fffield:any)=>{
                            if(fffield.name==="segments"){
                                fffield.value=val;
                            }
                            return fffield;
                        })
                    }
                    return ffield;
                   })
                }
                return field;
            })
            return {...prev,schema:updatedSchema}
        })
    }

    const handleSelectedRecord = (e: any) => {

        if(e.name==="modal_close"){
            handleModalClose();
        }
        if(e.name==="bot_effeciency_card"){
            API_GET_BOT_EFFECIENCY_INSIGHTS({startDate:dateRange[0],endDate:dateRange[1],name:"bot_effeciency_modal"})
        }
        if(e.name==="negative_feedback_card"){
            API_GET_NEGATIVE_FEEDBACK_INSIGHTS({startDate:dateRange[0],endDate:dateRange[1],name:"negative_feedback_modal"})
        }
        if(e.name.includes("chat-item-")){
            API_GET_texts(e.id,e.name);
        }
        if(e.name==="chat_modal_close"){
            handle_close_chat_model()
        }
        if (e.name === "segments") {
            // console.log(e)
            if (e.value === "TD") {
                setDateRange([
                    moment().format('DD-MM-YYYY'),
                    moment().format('DD-MM-YYYY'),
                ]);
                setSchema((prev: any) => {
                    const updatedSchema = prev.schema.map((field: any) => {
                        if (field.name === "filters") {
                            field.fields = field.fields.map((ffield: any) => {
                                if (ffield.name === "date_and_search") {
                                    ffield.fields = ffield.fields.map((ff: any) => {
                                        if (ff.name === "date") {
                                            ff.value = [
                                                moment().format('DD-MM-YYYY'),
                                                moment().format('DD-MM-YYYY'),
                                            ];
                                        }
                                        return ff;
                                    });
                                }
                                return ffield;
                            });
                        }
                        return field;
                    });
                    return { ...prev, schema: updatedSchema };
                });
            }
            if (e.value === "TM") {
                setDateRange([
                    moment().subtract(30, 'days').format('DD-MM-YYYY'),
                    moment().format('DD-MM-YYYY'),
                ]);
                setSchema((prev: any) => {
                    const updatedSchema = prev.schema.map((field: any) => {
                        if (field.name === "filters") {
                            field.fields = field.fields.map((ffield: any) => {
                                if (ffield.name === "date_and_search") {
                                    ffield.fields = ffield.fields.map((ff: any) => {
                                        if (ff.name === "date") {
                                            ff.value = [
                                                moment().subtract(30, 'days').format('DD-MM-YYYY'),
                                                moment().format('DD-MM-YYYY'),
                                            ];
                                        }
                                        return ff;
                                    });
                                }
                                return ffield;
                            });
                        }
                        return field;
                    });
                    return { ...prev, schema: updatedSchema };
                });
            }
            if (e.value === "TW") {
                setDateRange([
                    moment().subtract(7, 'days').format('DD-MM-YYYY'),
                    moment().format('DD-MM-YYYY'),
                ]);
                setSchema((prev: any) => {
                    const updatedSchema = prev.schema.map((field: any) => {
                        if (field.name === "filters") {
                            field.fields = field.fields.map((ffield: any) => {
                                if (ffield.name === "date_and_search") {
                                    ffield.fields = ffield.fields.map((ff: any) => {
                                        if (ff.name === "date") {
                                            ff.value = [
                                                moment().subtract(7, 'days').format('DD-MM-YYYY'),
                                                moment().format('DD-MM-YYYY'),
                                            ];
                                        }
                                        return ff;
                                    });
                                }
                                return ffield;
                            });
                        }
                        return field;
                    });
                    return { ...prev, schema: updatedSchema };
                });
            }
        }
        if(e.name=== "date"){
            setDateRange(e.value)
        }
    }
    
  return (
    <div className=''>
      <ElementExecutor
        data={schema}
        selectedRecord={(e: any) => {
          handleSelectedRecord(e);
        }}/>
    </div>
  )
}

export default LeadInsights

// import React, { useEffect, useState } from 'react'
// import {Tooltip} from "antd";
// import moment from 'moment';
// import { ElementExecutor } from '@apexcura/core';
// import { CONSTANTS } from '../../utils/app-constants';
// import { toast } from 'react-toastify';
// import Utils from '../../utils';
// import Storage from '../../utils/local-storage';
// import { setLoading } from '../../redux/appState/appStateSlice';
// import { useDispatch } from 'react-redux';
// const { BASE_URL } = CONSTANTS;
// const { FAILURE_MSG, CATCH_ERROR_MSG, AUTO_CLOSE } = CONSTANTS

// const LeadsInsights = () => {

//   const [schema, setSchema] = useState<any>({
//         className:
//           'w-full bg-white rounded-lg py-[10px] px-[20px] flex flex-col gap-3',
//         schema: [
//           {
//             name: 'filters',
//             fields: [
//               {
//                 name:"date_and_search",
//                 className:"flex gap-2 justify-center items-center ",
//                 fields:[
//                   {
//                       name:"segments",
//                       element:"segmented",
//                       segmentedOptions:[
//                         {
//                             label:(<Tooltip title="Today">1D</Tooltip>),
//                             value:"TD"
//                         },
//                         {
//                             label:(<Tooltip title="This Week">1W</Tooltip>),
//                             value:"TW"
//                         },
//                         {
//                             label:(<Tooltip title="This Month">1M</Tooltip>),
//                             value:"TM"
//                         },
//                       ],
//                       visible:true,
//                       value:"TM"
//                   },
//                   {
//                     name: 'date',
//                     label: '',
//                     placeholder: 'Select a date',
//                     element: 'daterangepicker',
//                     value: [moment().subtract(30, 'days').format('DD-MM-YYYY'), moment().format("DD-MM-YYYY")],
//                     className: 'flex-1 w-[240px]',
//                   },
//                 ]
//               },
//             ],
//             className: 'flex items-center justify-between gap-2',
//           },
//           {
//             name:"anomolies_and_insights",
//             className:"grid grid-cols-2 gap-2 items-start h-[calc(100vh-150px)]",
//             fields:[
//                 {
//                     name:"anomalies_table",
//                     className:"flex gap-2 flex-col col-span-1 rounded-md p-2 h-full overflow-hidden",
//                     fields:[
//                         {
//                             name:"anomalies",
//                             label:"Anomalies",
//                             element:"div",
//                             className:"text-primary text-lg font-semibold rounded-md w-full bg-primary/5 p-2"
//                         },
//                         {
//                             name:"anomalies_cards",
//                             className:"grid grid-cols-2 gap-2 h-full overflow-y-auto",
//                             fields:[]
//                         }
//                     ]
//                   },
//                   {
//                     name:"actionable_insights_table",
//                     className:"flex gap-2 flex-col col-span-1 rounded-md p-2 h-full overflow-hidden",
//                     fields:[
//                         {
//                             name:"actionable_insights",
//                             label:"Actionable Insights",
//                             element:"div",
//                             className:"text-primary text-lg font-semibold rounded-md w-full bg-primary/5 p-2"
//                         },
//                         {
//                             name:"actionable_insights_cards",
//                             className:"grid grid-cols-2 gap-2 h-full overflow-y-auto",
//                             fields:[]
//                         }
//                     ]
//                   },
//             ]
//           },
//           {
//             name: 'table_modal',
//             containerClassName:"!w-[90%]  ",
//             className: 'rounded-lg !w-full !h-[90vh]',
//             visible: false,
//             element: "modal",
//             fields: [
//                 {
//                     name: 'modal_title',
//                     className: 'w-full bg-primary/5 p-2 pl-4 flex items-center rounded-t-lg justify-between',
//                     fields: [
//                         {
//                             name: 'modal_title_key',
//                             element: 'div',
//                             label: 'Details',
//                             className: 'font-semibold text-lg text-primary',
//                         },
//                         {
//                             name: 'modal_close',
//                             element: 'button',
//                             label: 'X',
//                             className: 'w-[40px] h-[40px] flex justify-center items-center rounded-full bg-primary text-xl text-white',
//                             onClick: () => handleModalClose(),
//                         },
//                     ],
//                 },
//                 {
//                     name: 'modal_header',
//                     className: 'grid grid-cols-4 gap-2 shadow-sm p-2 px-4',
//                     fields: [
//                         {
//                             name: "card1",
//                             className: 'flex justify-start gap-2',
//                             fields: [
//                                 {
//                                     name: `key`,
//                                     element: 'div',
//                                     label: "Name:",
//                                     className: 'font-medium ',
//                                 },
//                                 {
//                                     name: `_val`,
//                                     element: 'div',
//                                     label: `John Doe`,
//                                     className: 'font-semibold',
//                                 },
//                             ],
//                         },
//                         {
//                             name: "card2",
//                             className: 'flex justify-start gap-2',
//                             fields: [
//                                 {
//                                     name: `phno_key`,
//                                     element: 'div',
//                                     label: "Phone Number:",
//                                     className: 'font-medium',
//                                 },
//                                 {
//                                     name: `phno_val`,
//                                     element: 'div',
//                                     label: '1234567890',
//                                     className: 'font-semibold',
//                                 },
//                             ],
//                         },
//                         {
//                             name: "card3",
//                             className: 'flex justify-start gap-2',
//                             fields: [
//                                 {
//                                     name: `gender_key`,
//                                     element: 'div',
//                                     label: "Gender:",
//                                     className: 'font-medium',
//                                 },
//                                 {
//                                     name: `gender_val`,
//                                     element: 'div',
//                                     label: 'Male',
//                                     className: 'font-semibold',
//                                 },
//                             ],
//                         },
//                         {
//                             name: "card4",
//                             className: 'flex justify-start gap-2',
//                             fields: [
//                                 {
//                                     name: `age_key`,
//                                     element: 'div',
//                                     label: "Age:",
//                                     className: 'font-medium',
//                                 },
//                                 {
//                                     name: `age_val`,
//                                     element: 'div',
//                                     label: '35',
//                                     className: 'font-semibold',
//                                 },
//                             ],
//                         },
//                         {
//                             name: "card5",
//                             className: 'flex justify-start gap-2',
//                             fields: [
//                                 {
//                                     name: `chat_start_key`,
//                                     element: 'div',
//                                     label: "Call Start:",
//                                     className: 'font-medium',
//                                 },
//                                 {
//                                     name: `chat_start_val`,
//                                     element: 'div',
//                                     label: '2023-10-08 10:00 AM',
//                                     className: 'font-semibold',
//                                 },
//                             ],
//                         },
//                         {
//                             name: "card6",
//                             className: 'flex justify-start gap-2',
//                             fields: [
//                                 {
//                                     name: `chat_end_key`,
//                                     element: 'div',
//                                     label: "Call End:",
//                                     className: 'font-medium',
//                                 },
//                                 {
//                                     name: `chat_end_val`,
//                                     element: 'div',
//                                     label: '2023-10-08 10:30 AM',
//                                     className: 'font-semibold',
//                                 },
//                             ],
//                         },
//                         {
//                             name: "card7",
//                             className: 'flex justify-start gap-2',
//                             fields: [
//                                 {
//                                     name: `chat_duration_key`,
//                                     element: 'div',
//                                     label: "Call Duration:",
//                                     className: 'font-medium',
//                                 },
//                                 {
//                                     name: `chat_duration_val`,
//                                     element: 'div',
//                                     label: '30 minutes',
//                                     className: 'font-semibold',
//                                 },
//                             ],
//                         },
//                     ]
                    
//                 },

//             ],
//           },
//         ],
//     });
//     const [dateRange, setDateRange] = useState<[string, string]>([
//         moment().subtract(30, 'days').format('DD-MM-YYYY'),
//         moment().format('DD-MM-YYYY'),
//     ])
//     const details = Storage.getItem("details");
//     const dispatch = useDispatch();

//     const API_GET_Insights_Data=async ({  startDate = "", endDate = "" })=>{
//         dispatch(setLoading(true))
//         try {
//             const res: any = await Utils.makeApiCall(`/getInsightsByDateAndOrgId?org_id=${details?.["org_id"]}&start_date=${startDate}&end_date=${endDate}`, "GET");
//             if (res.status) {
//                 if(res.data.length===0){
//                     change_insights_with_no_data();
//                 } else{
//                     change_insights_data(res.data);
//                 }

//             } else {
//                 toast.error(res.message ? res.message : FAILURE_MSG, { position: 'top-center', autoClose: AUTO_CLOSE })
//             }
//         } catch (error) {
//             toast.error(CATCH_ERROR_MSG, { position: 'top-center', autoClose: AUTO_CLOSE });
//             console.error('Failed to fetch schema', error);
//         }
//         dispatch(setLoading(false))
//     }

//     const change_insights_data = (data: any) => {
//         setSchema((prev: any) => {
//             const updatedSchema = prev.schema.map((item: any) => {
//                 if (item.name === "anomolies_and_insights") {
//                     item.fields = item.fields.map((field: any) => {
//                         if (field.name === "anomalies_table" || field.name === "actionable_insights_table") {
//                             const isAnomalies = field.name === "anomalies_table";
//                             field.fields = field.fields.map((subfield: any) => {
//                                 if (subfield.name === "anomalies_cards" || subfield.name === "actionable_insights_cards") {
//                                     subfield.fields = data.map((insight: any, index: number) => ({
//                                         name: `${isAnomalies ? 'anomaly' : 'insight'}-item-${index}`,
//                                         className: `hover:shadow-md cursor-pointer border ${isAnomalies ? 'border-[#E41B47] bg-[#FFF4F7]' : 'border-[#34D399] bg-[#ECFDF5]'} rounded-lg p-[5px] flex flex-col items-center justify-center gap-2`,
//                                         element: "clickable-widget",
//                                         fields: [
//                                             {
//                                                 name: 'icon',
//                                                 className: 'size-7',
//                                                 element: 'image',
//                                                 img: `${BASE_URL}/api/public/assets/images/${isAnomalies?"smiley-sad.svg":"smiley.svg"}`,
//                                                 visible: true,
//                                             },
//                                             {
//                                                 name: "value_and_status",
//                                                 className: "flex justify-between items-center gap-2",
//                                                 fields: [
//                                                     {
//                                                         name: `${isAnomalies ? 'anomaly' : 'insight'}-status-${index}`,
//                                                         className: `text-base font-semibold font-semibold ${isAnomalies ? 'text-[#E41B47]' : 'text-[#059669]'}`,
//                                                         element: "div",
//                                                         label: `${insight.status}`,
//                                                         visible: false
//                                                     },
//                                                     {
//                                                         name: `${isAnomalies ? 'anomaly' : 'insight'}-title-${index}`,
//                                                         className: 'text-xs cursor-pointer text-black',
//                                                         element: 'div',
//                                                         label: `${insight.title}`
//                                                     },
//                                                 ]
//                                             }
//                                         ]
//                                     }));
//                                 }
//                                 return subfield;
//                             });
//                         }
//                         return field;
//                     });
//                 }
//                 return item;
//             });
//             return { ...prev, schema: updatedSchema };
//         });
//     }

//     const change_insights_with_no_data = () => {
//         setSchema((prev: any) => {
//             const updatedSchema = prev.schema.map((item: any) => {
//                 if (item.name === "anomolies_and_insights") {
//                     item.fields = item.fields.map((field: any) => {
//                         if (field.name === "anomalies_table" || field.name === "actionable_insights_table") {
//                             const isAnomalies = field.name === "anomalies_table";
//                             field.fields = field.fields.map((subfield: any) => {
//                                 if (subfield.name === "anomalies_cards" || subfield.name === "actionable_insights_cards") {
//                                     subfield.fields = [{
//                                         name: `${isAnomalies ? 'anomaly' : 'insight'}-indicator`,
//                                         className: `border rounded-lg p-[5px] flex flex-col items-center justify-center gap-2 h-full`,
//                                         fields: [
//                                             {
//                                                 name: "value_indicator",
//                                                 className: "flex justify-between items-center gap-2",
//                                                 fields: [
//                                                     {
//                                                         name: `${isAnomalies ? 'anomaly' : 'insight'}-title_indicator`,
//                                                         className: 'text-base text-gray-500',
//                                                         element: 'div',
//                                                         label: `No ${isAnomalies ? 'Anomalies' : 'Insights'} Found`
//                                                     },
//                                                 ]
//                                             }
//                                         ]
//                                     }];
//                                 }
//                                 return subfield;
//                             });
//                         }
//                         return field;
//                     });
//                 }
//                 return item;
//             });
//             return { ...prev, schema: updatedSchema };
//         });
//     }

//     const handleModalClose = () => {
//         setSchema((prev: any) => {
//             const updatedSchema = prev.schema.map((item: any) => {
//                 if (item.name === 'table_modal') {
//                     item.visible = false;
//                 }
//                 return item;
//             });
//             return { ...prev, schema: updatedSchema };
//         });
//     }

//     const handleModalOpen = () => {
//         setSchema((prev: any) => {
//             const updatedSchema = prev.schema.map((item: any) => {
//                 if (item.name === 'table_modal') {
//                     item.visible = true;
//                 }
//                 return item;
//             });
//             return { ...prev, schema: updatedSchema };
//         });
//     }
    
//     useEffect(()=>{
//         API_GET_Insights_Data({ startDate: dateRange[0], endDate: dateRange[1] })
//     },[dateRange])

//     const handleSelectedRecord = (e: any) => {
//         if( e.name.includes("insight-item") || e.name.includes("anomaly-item") ){
//             handleModalOpen();
//         }
//         if(e.name==="modal_close"){
//             handleModalClose();
//         }
//     }
    
//   return (
//     <div>
//       <ElementExecutor
//         data={schema}
//         selectedRecord={(e: any) => {
//           handleSelectedRecord(e);
//         }}/>
//     </div>
//   )
// }

// export default LeadsInsights
