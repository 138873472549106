import { ElementExecutor } from '@apexcura/core';
import React, { useEffect, useState } from 'react'
import { unstable_usePrompt, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CONSTANTS } from '../../utils/app-constants';
import Utils from '../../utils';
import LoadingState from '../LoadingState';
import Storage from '../../utils/local-storage';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../redux/store';
import { RootState } from '../../redux/rootReducer';
import useExitPopUp from '../../hooks/useExitPopUp';
const { FAILURE_MSG, CATCH_ERROR_MSG, AUTO_CLOSE } = CONSTANTS;


const AddBranch = () => {
  const [schema, setSchema] = useState<any>({
    className: "w-full bg-white rounded-lg p-8 flex flex-col gap-3 ",
    schema: []
  });
  const navigate=useNavigate();
  const [loading,setLoading]=useState(false)
  const params = useParams();
  const branchId = params?.branchId || "";
  const orgId = params?.orgId || "";
  const { pathname } = useLocation();
  const slugs = pathname.split("/");
  const isView = slugs.includes("view");

  const org_id = Storage.getItem("details")?.["org_id"];
  const [branchOrgId, setBranchOrgId] = useState("");


  useEffect(() => {
    const schemaArr =  [
      {
        name: "heading",
        label: 'Add New Branch',
        element: "div",
        className: "text-start items-start font-semibold text-xl"
      },
      {
        name: "data_fields",
        className: "w-full grid grid-cols-12 gap-4",
        fields: [
          {
            name: "branch_name",
            element: "input-text",
            placeholder: "",
            label: "Branch Name",
            containerClassName: "w-full flex flex-col col-span-6",
            labelClassName:
              "text-sm mb-1 text-gray-500 font-semibold text-start",
            className:
              "rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none ",
            required: true,
            visible: true,
          },
          {
            name: "email",
            element: "input-text",
            placeholder: "",
            label: "Email",
            containerClassName: "w-full flex flex-col col-span-3",
            labelClassName:
              "text-sm mb-1 text-gray-500 font-semibold text-start",
            className:
              "rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none ",
            required: true,
            visible: true,
          },
          {
            name: "phone_number",
            element: "input-number",
            placeholder: "",
            label: "Mobile",
            containerClassName: "w-full flex flex-col col-span-3",
            labelClassName:
              "text-sm mb-1 text-gray-500 font-semibold text-start",
            className:
              "rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none ",
          
            visible: true,
          },
          {
            name: "address",
            element: "input-text",
            placeholder: "",
            label: "Address",
            containerClassName: "w-full flex flex-col col-span-9",
            labelClassName:
              "text-sm mb-1 text-gray-500 font-semibold text-start",
            className:
              "rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none ",
       
            visible: true,
          },
          {
            name: "city",
            element: "input-text",
            placeholder: "",
            label: "City",
            containerClassName: "w-full flex flex-col col-span-3",
            labelClassName:
              "text-sm mb-1 text-gray-500 font-semibold text-start",
            className:
              "rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none ",
         
            visible: true,
          },
          {
            name: "zipcode",
            element: "input-number",
            placeholder: "",
            label: "Zip Code",
            containerClassName: "w-full flex flex-col col-span-4",
            labelClassName:
              "text-sm mb-1 text-gray-500 font-semibold text-start",
            className:
              "rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none ",
 
            visible: true,
          },
          {
            name: "state",
            element: "single-select",
            placeholder: "",
            label: "State",
            containerClassName: "w-full flex flex-col col-span-4",
            labelClassName:
              "text-sm mb-1 text-gray-500 font-semibold text-start",
            className:
              "rounded-md  text-gray-700 font-semibold focus:outline-none ",
  
            visible: true,
            options : [
              {
                label: "Andhra Pradesh",
                value: "Andhra Pradesh"
              },
              {
                label: "Arunachal Pradesh",
                value: "Arunachal Pradesh"
              },
              {
                label: "Assam",
                value: "Assam"
              },
              {
                label: "Bihar",
                value: "Bihar"
              },
              {
                label: "Chhattisgarh",
                value: "Chhattisgarh"
              },
              {
                label: "Goa",
                value: "Goa"
              },
              {
                label: "Gujarat",
                value: "Gujarat"
              },
              {
                label: "Haryana",
                value: "Haryana"
              },
              {
                label: "Himachal Pradesh",
                value: "Himachal Pradesh"
              },
              {
                label: "Jharkhand",
                value: "Jharkhand"
              },
              {
                label: "Karnataka",
                value: "Karnataka"
              },
              {
                label: "Kerala",
                value: "Kerala"
              },
              {
                label: "Madhya Pradesh",
                value: "Madhya Pradesh"
              },
              {
                label: "Maharashtra",
                value: "Maharashtra"
              },
              {
                label: "Manipur",
                value: "Manipur"
              },
              {
                label: "Meghalaya",
                value: "Meghalaya"
              },
              {
                label: "Mizoram",
                value: "Mizoram"
              },
              {
                label: "Nagaland",
                value: "Nagaland"
              },
              {
                label: "Odisha",
                value: "Odisha"
              },
              {
                label: "Punjab",
                value: "Punjab"
              },
              {
                label: "Rajasthan",
                value: "Rajasthan"
              },
              {
                label: "Sikkim",
                value: "Sikkim"
              },
              {
                label: "Tamil Nadu",
                value: "Tamil Nadu"
              },
              {
                label: "Telangana",
                value: "Telangana"
              },
              {
                label: "Tripura",
                value: "Tripura"
              },
              {
                label: "Uttar Pradesh",
                value: "Uttar Pradesh"
              },
              {
                label: "Uttarakhand",
                value: "Uttarakhand"
              },
              {
                label: "West Bengal",
                value: "West Bengal"
              }
            ],
            value: {
              label: "Telangana",
              value: "Telangana"
            },
          },
          {
            name: "country",
            element: "single-select",
            placeholder: "",
            label: "Country",
            containerClassName: "w-full flex flex-col col-span-4",
            labelClassName:
              "text-sm mb-1 text-gray-500 font-semibold text-start",
            className:
              "rounded-md text-gray-700 font-semibold focus:outline-none ",
      
            visible: true,
            options: [
              {
                label:"India",
                value:"India"
              }
            ],
            value:{
              label:"India",
              value:"India"
            }
          },
        ],
      },
      {
        name: "cancel_and_submit",
        fields: [
          {
            name: "cancel",
            label: "Cancel",
            element: "button",
            className:
              "p-2 px-3 text-default-500 bg-gray-200 text-black rounded-md font-semibold",
          },
          {
            name: "submit",
            label: "Submit",
            element: "button",
            visible: !isView,
            className:
              "p-2 px-3 text-default-500 bg-[#3341bb] rounded-md text-white font-semibold",
          },
        ],
        className: "flex self-end gap-2 mt-4",
      },
    ];
   
    setSchema((prev: any) => {
      return { ...prev, schema: schemaArr }
    })
  }, [])

  const API_GET_Details=async (userId:string)=>{
    setLoading(true);
    try {
      const res = await Utils.makeApiCall(`/branches?org_id=${orgId}&branch_id=${branchId}`,'GET')
      if (res.status) 
      {
        handleGetDetails(res.data?.[0] || {});
      }
      else {
          toast.error(res.message ? res.message : FAILURE_MSG, {
              position: 'top-center',
              autoClose: AUTO_CLOSE,
          })
      }
    } catch (error) {
        toast.error(CATCH_ERROR_MSG, {
            position: 'top-center',
            autoClose: AUTO_CLOSE,
        })
        console.error('Failed to fetch schema', error)
    }
    setLoading(false);
  }

  useEffect(()=>{
    if(branchId){
      
      API_GET_Details(branchId)

      setSchema((prev:any)=>{
        prev.schema.map((field:any)=>{
            if(field.name==="heading"){
                field.label = "Edit Branch"
            }
        })

        return {...prev}
      })
    }
  }, [branchId])
  


  const handleGetDetails=(data:any)=>{
    setSchema((prev:any)=>{
      setBranchOrgId(data?.org_id)
      const updatedSchema=prev.schema.map((field:any)=>{
          if(field.name==="data_fields"){
                field?.fields?.map((fffield:any)=>{
                  if(fffield.name==="branch_name"){
                    fffield.value=data.branch_name
                  }
                  if(fffield.name==="email"){
                    fffield.value=data.email
                  }
                  if(fffield.name==="phone_number"){
                    fffield.value=data.phone_number
                  }
                  if(fffield.name==="address"){
                    fffield.value=data.address
                  }
                  if(fffield.name==="city"){
                    fffield.value=data.city
                  }
                  if(fffield.name==="zipcode"){
                    fffield.value=data.zipcode
                  }
                  if(fffield.name==="state"){
                    fffield.value={
                      label: data.state,
                      value: data.state,
                    }
                  }
                  if(fffield.name==="country"){
                    fffield.value={
                      label: data.country,
                      value: data.country,
                    }
                    console.log({
                      label: data.country,
                      value: data.country,
                    });
                    
                  }
            })
          }
        return field;
      })
      return {...prev,schema:updatedSchema}
    })
  }

  // console.log(schema)

  const API_POST_Data= async ()=>{
    setLoading(true)
    const payLoad=getPayload(schema);

    console.log(payLoad);

    try{
      let res:any={};

      if(branchId){
        res=await Utils.makeApiCall(`/branch/${branchId}`,"PUT",payLoad);
      }else{

        res=await Utils.makeApiCall("/branch","POST",payLoad);

      }
  
  
      if(res.status){
        toast.success(res.message,{position:"top-center",autoClose:AUTO_CLOSE})
        navigate("/branches")
      }else{
        toast.error(res.message ? res.message : FAILURE_MSG,{position:"top-center",autoClose:AUTO_CLOSE})
      }
    }
    catch(error){
      toast.error(CATCH_ERROR_MSG,{position:"top-center",autoClose:AUTO_CLOSE})
      console.log("Error while sending the data.",error)
    }finally{
      setLoading(false)
    }

  }

  const getPayload=(e:any):object =>{
    const payload:any={
      org_id,
      branch_name:"",
      address:"",
      city:"",
      state:"",
      zipcode:"",
      country:"",
      phone_number:"",
      email:""
    };
    const processFields = (obj: any) => {
      if (obj["fields"] && obj["fields"].length) {
        obj.fields.forEach(processFields);
      } else {
        if (["branch_name", "phone_number", "email", "address", "city", "zipcode"].includes(obj.name)){
          payload[obj.name] = obj.value;
        } else if (["state", "country"].includes(obj.name)){
    
            payload[obj.name]=obj?.value?.value;
            
        }
      }
    };
    e.schema.forEach(processFields);      
    return payload;
  }

  const [detectChange, setDetectChange] = useState(false)

  // Exit popup's custom hook
  useExitPopUp(detectChange)

  const handleSelectedRecord = (e: any) => {      
    if (e.name === "cancel") {
      navigate("/branches");
    }

    if(e.name==="submit"){
      setDetectChange(false)
      const validationResponse = Utils.handleValidation(schema.schema);
        if (validationResponse) {
            toast.error(`${validationResponse} cannot be empty`, {
              position: "top-center",
              autoClose: AUTO_CLOSE,
            });
        }
        else{
          API_POST_Data();
        }
    }
  };
return (
  <div>
     <LoadingState isLoading={loading} />
    <ElementExecutor
          data={schema}
          setData={(e:any)=>setSchema({...e})}
          selectedRecord={(e: any)=>{
            handleSelectedRecord(e)
            if(["branch_name", "email", "phone_number", "address", "city", "zipcode", "state", "country"].includes(e?.name) ){
              setDetectChange(true)
            }
          }}
      />
  </div>
)
}

export default AddBranch
