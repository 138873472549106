// ProtectedRoute.tsx
import React, { useEffect, useState } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import Loader from '../components/loader'
import Utils from '../utils'
import { useDispatch, useSelector } from 'react-redux'
import { setPagePermissions } from '../redux/auth/authSlice'
import UnAuthorized from './UnAuthorized'
import useActivityTimeout from '../hooks/useActivityTimeout'
import ScrollToTop from '../components/ScrollToTop'
import LoadingState from './LoadingState'
import { RootState } from '../redux/rootReducer'

const ProtectedRoute: React.FC = () => {
    const [isAuthorized, setIsAuthorized] = useState<boolean | null>(null)
    const { pathname } = useLocation()
    const dispatch = useDispatch()
    const isAuthenticated = !!localStorage.getItem('details')
    const userDetails = JSON.parse(localStorage.getItem('details') || '{}');
    const loading = useSelector((state: RootState) => state.appState.loading)

    useActivityTimeout();
    useEffect(() => {
        // setIsAuthorized(true);
        //     return
        if(["/reports" ,"/campaigns/add", "/appointments","/contacts-list","/contacts-list/add"].includes(location.pathname) || location.pathname.includes("/contacts-list") || location.pathname==="/org-config" || location.pathname==="/compareUnits" || location.pathname==="/widgets" || location.pathname==="/branches/edit"){
            setIsAuthorized(true);
            return
        }
        const checkPermission = async () => {
            try {
                const res = await Utils.makeApiCall(
                    `/getAccountUsrPagePermissions`,
                    'POST',
                    {}
                )
                if (res.status) {
                    if (res.data){
                        dispatch(setPagePermissions(res.data))
                        const view = res.data.find(
                            (r: any) => r.route === pathname
                        )?.readonly
                        setIsAuthorized(view)
                        if(pathname.split('/').includes("add")){
                            const add = res.data.find(
                                (r: any) => pathname.includes(r.route)
                            )?.add                        
                            setIsAuthorized(add)
                        } else if(pathname.split('/').includes("view")){
                            const view = res.data.find(
                                (r: any) => pathname.includes(r.route)
                            )?.view
                            setIsAuthorized(view)
                        } else if(pathname.split('/').includes("upload-file")){
                            const view = res.data.find(
                                (r: any) => r.route.includes("patients")
                            )?.add
                            setIsAuthorized(view)
                        }
                        else if(pathname.split('/').includes("edit")){
                            const edit = res.data.find(
                                (r: any) => pathname.includes(r.route)
                            )?.edit
                            setIsAuthorized(edit)
                        }
                    } else{
                        setIsAuthorized(false);
                    }
                } else{
                    setIsAuthorized(false);
                }
            } catch (error) {
                console.error('Error checking permission:', error)
                setIsAuthorized(false)
            }
        }

        if (isAuthenticated) {
            checkPermission()
        } else {
            setIsAuthorized(false)
        }
    }, [location.pathname, isAuthenticated])
    if (isAuthenticated && (!userDetails.org_id || userDetails.org_id === '')) {
       
        return <Navigate to="/registerOrganization" replace />;
    }
    if (!isAuthenticated) {
        return <Navigate to="/" replace />
    }

    if (isAuthorized === null) {
        return <Loader /> // Show a loader while checking permissions
    }
    return isAuthorized ? <><ScrollToTop /><LoadingState isLoading={loading} /><Outlet /></> : <UnAuthorized />
}

export default ProtectedRoute
