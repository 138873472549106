import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PersistGate } from 'redux-persist/integration/react';
import './input.css';
import Layout from './layout';
import ProtectedRoute from './pages/ProtectedRoute';
import AddCampaign from './pages/campaigns/AddCampaign';
import Campaigns from './pages/campaigns/Campaigns';
import { persistor, store } from './redux/store';
import reportWebVitals from './reportWebVitals';
import { CONSTANTS } from './utils/app-constants';
import  Profile  from './pages/Profile';
//import { GoogleOAuthProvider } from '@react-oauth/google';

const Organizations = lazy(() => import ('./pages/Organizations'));
const Botactivity = lazy(() => import ('./pages/Botactivity'));
const Bottraining = lazy(() => import ('./pages/bot-training/Bottraining'));
// const Campaigns = lazy(() => import ('./pages/campaigns/Campaigns'));
const Leads = lazy(() => import ('./pages/Leads'));
const Moduleintro = lazy(() => import ('./pages/Moduleintro'));
const Patients = lazy(() => import ('./pages/patients/Patients'));
const Scheduler = lazy(() => import ('./pages/Scheduler'));
const Login = lazy(() => import ('./pages/Login'));
// const AddCampaign = lazy(() => import ('./pages/campaigns/AddCampaign'));
// const Add = lazy(() => import ('./pages/bot-training/Add'));
import Add from "./pages/bot-training/Add";
const ContactsList = lazy(() => import ('./pages/contactslist/ContactsList'));
const AddContact = lazy(() => import ('./pages/contactslist/AddContact'));
const ChangePassword = lazy(() => import ('./pages/auth/ChangePassword'));
const ForgotPassword = lazy(() => import ('./pages/auth/ForgotPassword'));
const BotactivityDashboard = lazy(() => import ('./pages/dashboards/BotactivityDashboard'));
const ResetPassword = lazy(() => import ('./pages/auth/ResetPassword'));
const Templates = lazy(() => import ('./pages/templates/Templates'));
const AddTemplate = lazy(() => import ('./pages/templates/AddTemplate'));
const SignUp = lazy(() => import ('./pages/SignUp'));
const RegisterOrganization = lazy(() => import ('./pages/RegisterOrganization'));
const ViewLead = lazy(()=> import ('./pages/leads/ViewLead'))

import AddPatients from './pages/patients/AddPatients';
import EditPatients from './pages/EditPatients';
import AddLeads from './pages/AddLeads';
import ContactusList from './pages/ContactusList';
import Calls from './pages/Calls';
import ViewCampaign from './pages/campaigns/View';
import EditBottraining from './pages/bot-training/EditBottraining';
import Users from './pages/users/Users';
import Loader from './components/loader';
import ErrorBoundary from './ErrorBoundary';
import TableSkeleton from './components/Skeletons/table-skeleton';
import AddUsers from './pages/users/AddUsers';
import EditTemplate from './pages/templates/EditTemplate';
import Branches from './pages/branches/Branches';
import AddBranch from './pages/branches/AddBranch';
import BotPreview from './pages/bot-preview/Preview';
import ViewCalls from './pages/calls/ViewCalls';
import ReleaseNotes from './pages/releaseNotes/ReleaseNotes';
import AddReleaseNotes from './pages/releaseNotes/AddReleaseNotes';
import DemoChatbot from './pages/bot-preview/DemoChatbot';
import CompareUnits from './pages/CompareUnits';
import Departments from './pages/departments/Departments';
import AddDepartment from './pages/departments/AddDepartment';
import UploadDepartments from './pages/departments/UploadDepartments';
import Widget from './pages/widgets/Widget';
import ComingSoon from './pages/comingsoon';
import Reports from './pages/Reports';
import CallsReport from './pages/calls/CallsReport';
import CallsInsights from './pages/calls/CallsInsights';
import LeadsReport from './pages/leads/LeadsReport';
import LeadsInsights from './pages/leads/LeadsInsights';
import ChatsReport from './pages/Chats/ChatsReport';
import ChatsInsights from './pages/Chats/ChatsInsights';
import { AddAppointments } from './pages/appointments/AddAppointments';
import { ViewAppointments } from './pages/appointments/ViewAppointments';
import EditAppointment from './pages/appointments/EditAppointment';
import DoctorEvent from './pages/doctor-events/DoctorEvent'
import { SecreatryDashboard } from './pages/secretary/SecretaryDashboard';
// import ViewLead from './pages/leads/ViewLead';
const UploadPatients = lazy(()=> import ('./pages/patients/UploadPatients'))
const BotPreviewsList = lazy(() => import ('./pages/bot-preview/index'));
const OrgDashboard = lazy(() => import ('./pages/campaigns/Campaigns'));
const {clientId}=CONSTANTS;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const appRouter = createBrowserRouter([
  {
    path: "/",
    errorElement: <ErrorBoundary />,
    children: [
  {
    index: true,
    element: <Login />,
  },
  {
    path: "/moduleintro",
    element: <Moduleintro />,
  },
  {
    path: "/change-password",
    element: <ChangePassword />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
  },
  {
    path: "/signup",
    element: <SignUp />,
  },
  {
    path:"/registerOrganization",
    element: <RegisterOrganization/>
  },
  {
    element: <ProtectedRoute />,
    children: [
      {
        element: <Layout />,
        children: [
      {
        path: "/dashboard",
        element: <BotactivityDashboard />
      },
      {
        path: "/compareUnits",
        element: <CompareUnits />
      },
      {
        path: "/widgets",
        element: <Widget />
      },
      {
        path: "/organization",
        // element: <Layout />,
        children: [
          {
            path: "",
            element: <Organizations />,
          },
          {
            path:'view',
            element:<div>404 Not found</div>
          },
          {
            path:'view/:id',
            element:<Profile/>
          }
        ],
      },
      {
        path: "/org-dashboard",
        // element: <Layout />,
        children: [
          {
            path: "",
            element: <OrgDashboard />,
          },
        ],
      },
      {
        path: "/scheduler",
        // element: <Layout />,
        children: [
          {
            path: "",
            element: <Scheduler />,
          },
        ],
      },
      {
        path:"/users",
        //element:<Layout/>,
        children:[
          {
            path: "",
            element: <Users />,
          },
          {
            path: "add",
            element: <AddUsers />,
          },
          {
            path: "edit/:userId",
            element: <AddUsers />,
          },
          {
            path: "view/:userId",
            element: <AddUsers />,
          },
        ]
      },
      {
        path: "/bot-training",
        // element: <Layout />,
        children: [
          {
            path: "",
            element: <Bottraining />,
          },
          {
            path: "add",
            element: <Add />,
          },
          {
            path: "edit/:botId",
            element: <EditBottraining />,
          },
        ],
      },
      {
        path: "/contactus-list",
        // element: <Layout />,
        children:[
          {
            path:"",
            element:<ContactusList/>,
          }
        ]
      },
      {
        path: "/org-config",
        // element: <Layout />,
        children: [
          {
            path: "",
            element: <Profile />,
          },
        ],
      },
      {
        path: "/templates",
        // element: <Layout />,
        children: [
          {
            path: "",
            element: <Templates />,
          },
          {
            path: "add",
            element: <AddTemplate />,
          },
          {
            path: "edit/:templateId",
            element: <EditTemplate />,
          }
        ],
      },
      {
        path:"/calls",
        // element:<Layout/>,
        children:[
          {
            path:"",
            element:<Calls/>
          },
          {
            path:"view/:id",
            element:<ViewCalls/>
          },
        ]
      },
      {
        path:"calls-report",
        element:<CallsReport/>
      },
      {
        path:"calls-ai-insights",
        element:<CallsInsights/>
      },
      {
        path: "/bot-activity-log",
        // element: <Layout />,
        children: [
          {
            path: "",
            element: <Botactivity />,
          },
        ],
      },
      {
        path:"bot-activity-report",
        element:<ChatsReport/>
      },
      {
        path:"bot-activity-ai-insights",
        element:<ChatsInsights/>
      },
      {
        path: "/patients",
        // element: <Layout />,
        children: [
          {
            path: "",
            element: <Patients />,
          },
          {
            path: "add",
            element: <AddPatients />,
          },
          {
            path: "upload-file",
            element: <UploadPatients />,
          },
          {
            path: "edit/:patientId",
            element: <EditPatients />,
          }
        ],
      },
      {
        path: "/leads",
        // element: <Layout />,
        children: [
          {
            path: "",
            element: <Leads />,
          },
          {
            path:"view/:id",
            element:<ViewLead/>
          },
          {
            path:"add",
            element:<AddLeads/>
          }
        ],
      },
      {
        path:"bot-activity-report",
        element:<ComingSoon/>
      },
      {
        path:"bot-activity-ai-insights",
        element:<ComingSoon/>
      },
      {
        path:"leads-report",
        element:<LeadsReport/>
      },
      {
        path:"leads-ai-insights",
        element:<LeadsInsights/>
      },
      {
        path: "/campaigns",
        // element: <Layout />,
        children: [
          {
            path: "",
            element: <Campaigns />,
          },
          {
            path: "add",
            element: <AddCampaign />,
          },
          {
            path: "view/:id",
            element: <ViewCampaign />,
          }
        ],
      },
      {
        path: "/contacts-list",
        // element: <Layout />,
        children: [
          {
            path: "",
            element: <ContactsList />,
          },
          {
            path: "add",
            element: <AddContact />,
          },
          {
            path: "edit/:id",
            element: <AddContact />,
          },
        ],
      },
      {
        path: "/branches",
        children: [
          {
            path: "",
            element: <Branches />,
          },
          {
            path: "add",
            element: <AddBranch />,
          },
          {
            path: "edit/:orgId/:branchId",
            element: <AddBranch />,
          },
        ],
      },
      {
        path: "/departments",
        children: [
          {
            path: "",
            element: <Departments/>
          },
          {
            path: "add",
            element: <AddDepartment/>
          },
          {
            path:"edit/:departmentId",
            element: <AddDepartment/>
          },
          {
            path: "upload-file",
            element: <UploadDepartments/>
          }
        ]
      },
      {
        path: "/release-notes",
        children: [
          {
            path: "",
            element: <ReleaseNotes />,
          },
          {
            path: "add",
            element: <AddReleaseNotes />,
          },
        ],
      },
      {
        path: "/reports",
        element: <Reports />
      },
      {
        path:'/appointments',
        children:[
          {
            path:'',
            element:<ViewAppointments/>
          },
          {
            path:'add',
            element:<AddAppointments/>
          },
          {
            path:'reschedule/:appointmentId',
            element: <EditAppointment/>
          }
        ]
      },
      {
        path: '/doctor-events',
        element: <DoctorEvent/>
      },
      {
        path:'/secretary',
        element:<SecreatryDashboard/>
      }
        ]
      },
    ],
  },
  {
    path: "/chatbot",
    element: <DemoChatbot />,
  },
  // {
  //   path: "/bot-preview",
  //   element: <BotPreviewsList />,
  // },
  {
    path: "/bot-preview/:org_name",
    element: <BotPreview />,
  },
    
]
}
]);

root.render(
  <React.StrictMode>
  {/* <GoogleOAuthProvider clientId={clientId}> */}
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Suspense fallback={<Loader />}>
          <ToastContainer />
          <RouterProvider
            router={appRouter}
            fallbackElement={<div>Loading...</div>}
          />
        </Suspense>
      </PersistGate>
    </Provider>
    {/* </GoogleOAuthProvider> */}
  </React.StrictMode>
);

reportWebVitals();