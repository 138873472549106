"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AvatarUpload = void 0;
const react_1 = __importStar(require("react"));
const antd_1 = require("antd");
const icons_1 = require("../constants/icons");
const AvatarUpload = (props) => {
    const [imageUrl, setImageUrl] = (0, react_1.useState)(props.value);
    (0, react_1.useEffect)(() => {
        if (props.value) {
            setImageUrl(props.value);
        }
    }, [props.value]);
    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };
    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        getBase64(file, (imageUrl) => {
            const newFile = {
                uid: file.uid,
                name: file.name,
                status: 'done',
                url: imageUrl,
                thumbUrl: imageUrl,
                originFileObj: file,
            };
            if (props.onChange) {
                props.onChange(newFile);
            }
            setImageUrl(newFile);
        });
        if (!isJpgOrPng) {
            antd_1.message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            antd_1.message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    };
    const customRequest = (options) => {
        const { onSuccess } = options;
        setTimeout(() => {
            if (onSuccess) {
                onSuccess('ok');
            }
        }, 0);
    };
    const uploadButton = (react_1.default.createElement("button", { className: props.iconsClassName }, icons_1.icons[props.icon || '']));
    return (react_1.default.createElement("div", { className: props.containerClassName },
        imageUrl.url ? (react_1.default.createElement("img", { src: imageUrl.url ? imageUrl.url : '', alt: "avatar", className: "w-full h-full object-cover self-center" })) : (react_1.default.createElement("div", null)),
        react_1.default.createElement(antd_1.Upload, { className: "avatar-uploader", showUploadList: false, accept: props.accept, beforeUpload: beforeUpload, customRequest: customRequest },
            react_1.default.createElement("div", null, uploadButton))));
};
exports.AvatarUpload = AvatarUpload;
