import { createSlice } from "@reduxjs/toolkit";
import { ICallDetailsState } from "./types";
import { detailsSchema } from "./schema";

const initialState: ICallDetailsState = {
    schema: detailsSchema()
}
const callDetailsSlice = createSlice({
    name: "callDetails",
    initialState,
    reducers: {
        setSchema: (state, action) => {
            state.schema = action.payload;
        }
    }
})

export const { setSchema } = callDetailsSlice.actions;
export default callDetailsSlice.reducer;