import { ElementExecutor } from '@apexcura/core'
import React from 'react'
import Schema from '../utils/schema'
import { useNavigate } from 'react-router-dom'

const NotFound = () => {
    const schema: any = Schema.get('not_found')
    const navigate = useNavigate()
    const handleSelect = (e: any) => {
        if (e.name === 'action') {
            navigate('/dashboard')
        }
    }
    return <ElementExecutor data={schema} selectedRecord={handleSelect} />
}

export default NotFound
