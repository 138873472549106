import { CONSTANTS } from './app-constants';
import { ISchema } from './types'
const { BASE_URL } = CONSTANTS;
// Note: Kindly Add schemas in Alphabetical order for better readability
class Schema {
    private static schemas: { [key: string]: ISchema } = {
        branch_switch: {
            className: "",
            schema: [
                {
                    name: 'branch_switch_modal',
                    containerClassName: "!w-[350px]",
                    element: "modal",
                    className: "flex flex-col gap-2 p-4", 
                    visible: true,
                    fields: [
                        {
                            element: 'div',
                            name: 'primary_text',
                            label: 'Branch switching...',
                            className: 'text-lg font-semibold text-primary',
                        },
                        {
                            element: 'div',
                            name: 'seondary_text',
                            label: 'Please wait till the branch switch',
                            className: '',
                        },
                    ],
                }
            ]
        },
        forgot_password: {
            className:
                'grid grid-cols-3 gap-2 items-center p-8 w-full h-fit bg-custom-gradient',
            schema: [
                {
                    name: 'form',
                    className:
                        'bg-white p-8 px-12 flex flex-col gap-4 items-center rounded-2xl shadow-lg w-full',
                    fields: [
                        
                        {
                            name: 'back_button',
                            icon: `${BASE_URL}/api/public/assets/images/back.svg`,
                            label: 'Back',
                            element: 'button',
                            iconsClassName:"size-4",
                            isSVGStylesOverride: false,
                            className:
                                'text-sm text-[#505050] flex justify-center gap-1 items-center self-start',
                            visible: true,
                        },
                        {
                            name: 'apex_image',
                            img: `${BASE_URL}/api/public/assets/images/apexcura.png`,
                            alt: 'Apex_cura_image',
                            element: 'image',
                            visible: true,
                            imgClassName: 'w-30 self-center',
                        },
                        {
                            name: 'welcome_text',
                            label: 'Forgot your password?',
                            element: 'div',
                            className:
                                'text-lg text-gray-700 font-semibold self-start w-full',
                            visible: true,
                        },
                        {
                            name: 'email',
                            element: 'input-text',
                            placeholder: 'Enter your email',
                            label: 'Email',
                            containerClassName: 'w-full flex flex-col',
                            labelClassName:
                                'text-sm text-gray-500 font-semibold',
                            className:
                                'w-full rounded-md mt-2 px-3 py-2 bg-gray-200 text-gray-700 font-semibold focus:outline-none',
                            required: true,
                            visible: true,
                        },
                        {
                            name: 'forgot_pass_captcha',
                            element: 'capcha',
                            value: '',
                            className:"",
                            visible: false,
                        },
                        {
                            name: 'send_email',
                            element: 'button',
                            input: 'button',
                            value: 'Send Email',
                            label: 'Send Reset Link',
                            className:
                                'w-full font-medium rounded-md bg-primary transition duration-500 ease-in-out shadow-md hover:shadow-lg text-white px-3 py-2 text-center ',
                            visible: true,
                        },
                    ],
                },
                {
                    name: "card2",
                    className:
                      "col-span-2 bg-transparent flex flex-col items-center justify-center ",
                    fields: [
                      {
                        name: "banner_comp",
                        className: "flex flex-col justify-center items-center w-fit ",
                        fields: [
                          {
                            name: "login_image",
                            img: `${BASE_URL}/api/public/assets/images/login-banner.svg`,
                            alt: "login_image",
                            element: "image",
                            visible: true,
                            imgClassName: "w-[60%] self-center",
                          },
                          {
                            name: "login_page_text_body",
                            className: "",
                            fields: [
                              {
                                name: "login_page_text",
                                element: "div",
                                label: "Seamless",
                                className: "text-4xl font-thin text-white",
                                visible: true,
                              },
                              {
                                name: "login_page_text1",
                                element: "div",
                                label: "Healthcare Management",
                                className: "text-4xl text-white font-semibold",
                                visible: true,
                              },
                              {
                                name: "login_page_text2",
                                element: "div",
                                label: "at your fingertips",
                                className: "text-4xl font-thin text-white",
                                visible: true,
                              },
                            ]
                          },
                        ],
                      },
                    ],
                },
            ],
        },
        login: {
            className:
                'grid grid-cols-3 gap-2 items-center p-8 w-full min-h-screen h-fit bg-custom-gradient',
            schema: [
                {
                    name: 'form_login',
                    className:
                        'bg-white p-8 px-12 flex flex-col gap-4 items-center rounded-2xl shadow-lg w-full',
                    fields: [
                        {
                            name: 'apex_image',
                            img: `${BASE_URL}/api/public/assets/images/apexcura.png`,
                            alt: 'Apex_cura_image',
                            element: 'image',
                            visible: true,
                            imgClassName: 'w-30 self-center',
                        },
                        {
                            name: 'welcome_text',
                            label: 'Welcome to Apex Cura',
                            element: 'div',
                            className:
                                'text-lg text-gray-700 font-semibold self-start w-full',
                            visible: true,
                        },
                        {
                            name: 'email',
                            element: 'input-text',
                            placeholder: 'Eg: example@gmail.com',
                            label: 'Email',
                            containerClassName: 'w-full flex flex-col',
                            labelClassName:
                                'text-sm text-gray-500 font-semibold',
                            className:
                                'w-full rounded-md mt-2 px-3 py-2 bg-gray-200 text-gray-700 font-semibold focus:outline-none',
                            required: true,
                            visible: true,
                        },
                        {
                            name: 'password',
                            element: 'input-password',
                            placeholder: 'Enter your password',
                            label: 'Password',
                            className:
                                'w-full rounded-md mt-2 px-3 py-2 bg-gray-200 text-gray-700 font-semibold focus:outline-none',
                            required: true,
                            containerClassName: 'w-full flex flex-col',
                            labelClassName:
                                'text-sm text-gray-500 font-semibold',
                            visibility: false,
                            visible: true,
                            type: 'password',
                        },
                        {
                            name: 'remember_and_forget',
                            element: '',
                            className:
                                'w-full flex  items-center text-xs',
                            fields: [
                                {
                                    name: 'remember_password',
                                    element: 'single-checkbox',
                                    label: 'Remember Me',
                                    className: '',
                                    value: '',
                                    visible:false
                                },
                                {
                                    name: 'forget_password',
                                    element: 'button',
                                    label: 'Forgot password?',
                                    className:
                                        'bg-transparent font-medium text-blue-700',
                                    visible: true,
                                },
                            ],
                        },
                        {
                            name: 'login_captcha',
                            element: 'capcha',
                            className:"",
                            visible: false,
                        },
                        {
                            name: 'sign_in',
                            element: 'button',
                            input: 'button',
                            value: 'Sign in',
                            label: 'Sign in',
                            className:
                                'w-full font-medium rounded-md bg-primary transition duration-500 ease-in-out  shadow-md hover:shadow-lg text-white px-3 py-2 text-center ',
                            visible: true,
                        },
                        {
                            name: 'divider',
                            element: 'div',
                            className: 'w-full h-[1px] bg-gray-100',
                        },
                        // {
                        //     name: 'google_login',
                        //     icon: `${BASE_URL}/api/public/assets/images/google.svg`,
                        //     label: 'Sign in with Google',
                        //     isSVGStylesOverride: false,
                        //     element: 'button',
                        //     className:
                        //         'w-full gap-2 font-medium rounded-md transition duration-500 ease-in-out hover:shadow-md bg-gray-100 text-gray-700 text-sm py-2 px-3 flex justify-center items-center',
                        //     visible: true,
                        // },
                        {
                            name: 'not_having_account',
                            className:
                                'w-full flex gap-2 font-medium text-sm text-gray-500 justify-center items-center',
                            fields: [
                                {
                                    name: 'not_having_account_text',
                                    element: 'div',
                                    label: "Don't have an account? ",
                                    className: 'text-black',
                                },
                                {
                                    name: 'sign_up_now',
                                    element: 'button',
                                    label: ' Sign up now.',
                                    className: 'text-blue-700 cursor-pointer bg-transparent',
                                },
                            ],
                        },
                    ],
                },
                {
                    name: 'form_otp',
                    className:
                        'bg-white p-8 px-12 flex flex-col gap-4 items-center rounded-2xl shadow-lg w-full',
                    visible: false,
                    fields: [
                        {
                            name: 'back_button',
                            icon: `${BASE_URL}/api/public/assets/images/back.svg`,
                            label: 'Back',
                            element: 'button',
                            iconsClassName:"size-4",
                            isSVGStylesOverride: false,
                            className:
                                'text-sm text-[#505050] flex justify-center gap-1 items-center self-start',
                            visible: true,
                        },
                        {
                            name: 'apex_image',
                            img: `${BASE_URL}/api/public/assets/images/apexcura.png`,
                            alt: 'Apex_cura_image',
                            element: 'image',
                            visible: true,
                            imgClassName: 'w-30 self-center',
                        },
                        {
                            name: 'welcome_text',
                            label: 'We have sent an otp to your email',
                            element: 'div',
                            className:
                                'text-lg text-default-500 font-semibold self-start w-full',
                            visible: true,
                        },
                        {
                            name: "email_handler",
                            className:"flex flex-row justify-between w-full",
                            fields:[
                                {
                                  name: "logged_in_email",
                                  label: "",
                                  element: "div",
                                  className: "text-sm text-primary font-medium self-start",
                                  visible: true,
                                },
                                {
                                  name: 'resend_otp',
                                  label: 'Resend OTP',
                                  element: 'div',
                                  visible: true,
                                  className: "text-sm text-indigo-500 cursor-pointer font-medium self-start",
                                }
                            ]
                          },
                        {
                            name: 'otp',
                            element: 'otp',
                            length: 6,
                            label: '',
                            className: 'text-center',
                            containerClassName: 'flex gap-2',
                            required: true,
                            visible: true,
                        },
                        {
                            name: 'verify',
                            element: 'button',
                            input: 'button',
                            value: 'Verify',
                            label: 'Verify',
                            className:
                                'w-full font-medium rounded-md bg-primary transition duration-500 ease-in-out  shadow-md hover:shadow-lg text-white px-3 py-2 text-center ',
                            visible: true,
                        },
                    ],
                },
                {
                    name: "card2",
                    className:
                      "col-span-2 bg-transparent flex flex-col items-center justify-center ",
                    fields: [
                      {
                        name: "banner_comp",
                        className: "flex flex-col justify-center items-center w-fit ",
                        fields: [
                          {
                            name: "login_image",
                            img: `${BASE_URL}/api/public/assets/images/login-banner.svg`,
                            alt: "login_image",
                            element: "image",
                            visible: true,
                            imgClassName: "w-[60%] self-center",
                          },
                          {
                            name: "login_page_text_body",
                            className: "",
                            fields: [
                              {
                                name: "login_page_text",
                                element: "div",
                                label: "Seamless",
                                className: "text-4xl font-thin text-white",
                                visible: true,
                              },
                              {
                                name: "login_page_text1",
                                element: "div",
                                label: "Healthcare Management",
                                className: "text-4xl text-white font-semibold",
                                visible: true,
                              },
                              {
                                name: "login_page_text2",
                                element: "div",
                                label: "at your fingertips",
                                className: "text-4xl font-thin text-white",
                                visible: true,
                              },
                            ]
                          },
                        ],
                      },
                    ],
                  },
            ],
        },
        logout: {
            className: "",
            schema: [
                {
                    name: 'logout_modal',
                    containerClassName: "!w-[350px]",
                    element: "modal",
                    className: "flex flex-col gap-6 p-4", 
                    visible: true,
                    fields: [
                        {
                            element: 'div',
                            name: 'logout_text',
                            label: 'Are you sure you want to logout?',
                            className: 'text-lg font-semibold',
                        },
                        {
                            name: 'logout_btns',
                            className: 'flex justify-end items-center gap-2',
                            fields: [
                                {
                                    element: 'button',
                                    name: 'cancel_button',
                                    label: 'Cancel',
                                    className: 'text-base font-semibold font-sans cursor-pointer rounded-md p-2 px-3 text-default-500 bg-gray-200 text-black',
                                },
                                {
                                    element: 'button',
                                    name: 'logout_button',
                                    label: 'Logout',
                                    className:
                                        'text-base font-sans font-semibold  cursor-pointer text-white bg-primary rounded-md p-2 px-3',
                                },
                            ],
                        },
                    ],
                }
            ]
        },
        not_found: {
            className: 'w-full h-screen bg-[#EFF1F9]',
            schema: [
                {
                    name: 'container',
                    className:
                        'flex flex-col gap-2 h-screen items-center justify-center self-center',
                    fields: [
                        {
                            name: 'image',
                            img: `${BASE_URL}/api/public/assets/images/not-found.svg`,
                            alt: 'Not found image',
                            element: 'image',
                            imgClassName: 'w-[25%] self-center',
                        },
                        {
                            name: 'primary_text',
                            element: 'div',
                            label: "OOPS! THE PAGE YOU WERE LOOKING FOR, COULDN'T BE FOUND.",
                            className: 'text-lg font-medium text-[#37474F]',
                        },
                        {
                            name: 'action',
                            element: 'button',
                            label: 'Go back',
                            className: 'bg-primary text-white p-2 mt-4 text-sm font-semibold rounded-md'
                        },
                    ],
                },
            ],
        },
        patients: {
            className:
                'w-full bg-white rounded-lg py-[10px] px-[20px] flex flex-col gap-3',
            schema: [
                {
                    name: 'filters',
                    fields: [
                        {
                            name: 'search',
                            label: '',
                            placeholder: 'Search...',
                            element: 'input-text',
                            value: null,
                            className: '',
                        },
                        {
                            name: 'btn_download',
                            label: 'Download',
                            element: 'button',
                            icon: 'download',
                            className: 'secondary-button',
                            action: 'download_list',
                            iconsClassName: 'size-4',
                        },
                        {
                            name: "btn_add",
                            label: "Add",
                            element: "button",
                            icon: "add",
                            className: "add-button ",
                            iconsClassName: "size-4",
                        },
                    ],
                    className: 'flex self-end gap-2',
                },
                {
                    className: 'mt-2',
                    name: 'table',
                    element: 'table',
                    variant: 'plain',
                    size: 'small',
                    count: 0,
                    view: true,
                    visible: true,
                    loading: true,
                    pagination: true,
                    thead: [
                        {
                            name: 'name',
                            label: 'Name',
                            key: 'name',
                        },
                        {
                            name: 'phno',
                            label: 'Mobile',
                            key: 'phno',
                        },
                        {
                            name: 'age',
                            label: 'Age',
                            key: 'age',
                        },
                        {
                            name: 'gender',
                            label: 'Gender',
                            key: 'gender',
                        },
                        {
                            name: 'createdAt',
                            label: 'Registered On',
                            key: 'createdAt',
                        },
                    ],
                    tbody: [],
                },
            ],
        },
        unauthorized: {
            className: 'w-full h-screen bg-[#EFF1F9]',
            schema: [
                {
                    name: 'container',
                    className:
                        'flex flex-col gap-2 h-screen items-center justify-center self-center',
                    fields: [
                        {
                            name: 'primary_text',
                            element: 'div',
                            label: 'You are not authorized to access this page.',
                            className: 'text-xl font-semibold text-primary',
                        },
                        {
                            name: 'secondary_text',
                            element: 'div',
                            label: 'Please contact your administrator',
                            className: 'text-sm font-normal text-[#1E272B]'
                        },
                        {
                            name: 'image',
                            img: `${BASE_URL}/api/public/assets/images/unauthorized.svg`,
                            alt: 'Not found image',
                            element: 'image',
                            imgClassName: 'w-[35%] self-center',
                        },
                        // {
                        //     name: 'action',
                        //     element: 'button',
                        //     label: 'Go back to dashboard',
                        //     className: 'bg-primary text-white p-2 mt-4 text-sm font-semibold rounded-md'
                        // },
                    ],
                },
            ],
        },
    }

    static get(schemaName: SchemaName): ISchema {
        const schema = this.schemas[schemaName]
        if (!schema) {
            throw new Error(`Schema with name ${schemaName} does not exist`)
        }
        return JSON.parse(JSON.stringify(schema)) // Return a deep copy
    }
}

type SchemaName = 'branch_switch' | 'forgot_password' | 'login' | 'logout' | 'not_found' | 'patients' | 'unauthorized'

export default Schema
