import { ElementExecutor } from "@apexcura/core";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import {
  MdOutlineCommentsDisabled,
  MdOutlineCompareArrows,
} from "react-icons/md";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { LeadStatusStyles } from "../pages/Leads";
import { setLoading } from "../redux/appState/appStateSlice";
import {
  callDetailsFields,
  chatDetailsFields,
  detailsSchema,
  leadDetailsFields,
} from "../redux/calls/schema";
import { DetailsServices } from "../redux/calls/services/details";
import Utils from "../utils";
import { CONSTANTS } from "../utils/app-constants";
import Storage from "../utils/local-storage";
import RecentInteractionSkeleton from "./Skeletons/RecentInteractions";
import { AddAppointmentComponent } from "../pages/appointments/AddAppointmentCompontent";

const { FAILURE_MSG, CATCH_ERROR_MSG, AUTO_CLOSE } = CONSTANTS;

const DetailsModal = (props: any) => {
  const dispatch = useDispatch();
  const tagDebounceTime = useRef<ReturnType<typeof setTimeout> | null>(null);

  const [phno, setPhno] = useState("");
  const [type, setType] = useState("all");
  const [Lead, setLead] = useState<any>();
  const [isModified, setIsModified] = useState<boolean>(false);
  const [commentInput, setCommentInput] = useState("");
  const [commentStatus, setCommmentStatus] = useState<{
    value: string;
    label: string;
  }>({ value: "", label: "" });

  const [schema, setSchema] = useState<any>({});

  const [selectedRecord, setSelectedRecord] = useState({
    _id: props?.selectedRecord?._id || "",
    interaction_type: props?.selectedRecord?.interaction_type,
  });

  useEffect(() => {
    loadSchema(props?.selectedRecord?.interaction_type);
  }, []);

  useEffect(() => {
    if (selectedRecord?._id) {
      loadSchema(selectedRecord?.interaction_type, true);
      if (selectedRecord?.interaction_type === "Call") {
        API_GET_GetAllComments(selectedRecord?.interaction_type);
        API_GET_CALL_DATA();
      } else if (selectedRecord?.interaction_type === "Chat") {
        API_GET_CHAT_DATA();
      } else if (selectedRecord?.interaction_type === "Lead") {
        API_GET_GetAllComments(selectedRecord?.interaction_type);
        API_GET_LEAD_DATA();
      }
      handleSelectedInteraction(selectedRecord?._id);
    }
  }, [selectedRecord]);

  useEffect(() => {
    if (phno && type) {
      API_GET_RecentInteractions(phno, type);
    }
  }, [phno, type]);

  const loadSchema = (type = "", replace = false) => {
    const details = detailsSchema();
    const s = {
      Call: callDetailsFields(),
      Chat: chatDetailsFields(),
      Lead: leadDetailsFields(),
    }[type];
    setSchema((prev: any) => {
      const updatedSchema = Utils.updateSchemaRecursively(
        replace ? prev : details,
        (field: any) => {
          if (field.name === "main_content") {
            return { ...field, fields: [...s] };
          } else if (field.name === "modal_title_key") {
            const title: any = {
              Call: "Call Details",
              Chat: "Chat Details",
              Lead: "Lead Details",
            }[type];
            field.label = title;
          }
          return field;
        }
      );
      return updatedSchema;
    });
    if (type === "Call") {
      handleTagOptions();
    }
  };
  const handleTagOptions = async () => {
    const res = await Utils.makeApiCall("/getCallTags", "GET");
    if (res.status) {
      setSchema((prev: any) => {
        const updateField = (fields: any[]) =>
          fields.map((field: any) => {
            if (field.name === "call-tags-input") {
              field.options = res?.data?.map((option: any) => ({
                label: option?.name,
                value: option?._id,
                color: option?.colour,
              }));
            } else if (field.fields) {
              field.fields = updateField(field.fields);
            }
            return field;
          });

        return {
          ...prev,
          schema: prev?.schema?.map((sec: any) => {
            if (sec.fields) {
              sec.fields = updateField(sec.fields);
            }
            return sec;
          }),
        };
      });
    }
  };

  const appointmentBookingModal = (mobile:string, name:string)=>{
    // console.log('here')
    return {
            element:'div',
            label:<div><AddAppointmentComponent mobile={mobile} name={name}/></div>
          }
  }

  const API_GET_CALL_DATA = async () => {
    dispatch(setLoading(true));
    // return
    const id = selectedRecord?._id;
    try {
      const res = await Utils.makeApiCall(`/ivrCallDetails/${id}`, "GET");
      if (res.status && res.data) {
        if (!phno) {
          setPhno(res?.data?.From);
          API_GET_Patient_details(res?.data?.patient_id);
        }
        setSchema((prev: any) => {
          const updateField = (fields: any[]) =>
            fields.map((field: any) => {
              if (field.name === "call_type") {
                const { icon, iconClassName } = DetailsServices.getCallIconData(
                  res?.data?.status
                );
                field.label = icon;
                field.className = iconClassName;
              } else if (field.name === "call_from_value") {
                field.label =
                  `${res?.data?.Name} (${res?.data?.Direction.toLowerCase()==="outgoing"? res?.data?.CallTo :res?.data?.CallFrom})` || "N/A";
                // field.label = res?.data?.From || "N/A"
              } else if (field.name === "call_to_value") {
                field.label = res?.data?.DialWhomNumber ? (
                  <span className="flex items-center gap-1">
                    <MdOutlineCompareArrows className="text-gray-400 text-xl" />{" "}
                    {res?.data?.Direction.toLowerCase()==="outgoing"? res?.data?.CallFrom :res?.data?.DialWhomNumber}
                  </span>
                ) : (
                  ""
                );
              } else if (field.name === "direction_value") {
                field.label = res?.data?.Direction || "N/A";
              } else if (field.name === "calls_status_value") {
                field.label = res?.data?.status || "N/A";
              } else if (field.name === "call_date_value") {
                let date = res?.data?.StartTime || "N/A";
                date = moment(date).format("DD-MM-YYYY HH:mm");
                field.label = date || "N/A";
              } else if (field.name === "call_duration_value") {
                field.label = res?.data?.DialCallDuration
                  ? `(${res?.data?.DialCallDuration}s)`
                  : "";
              } else if (field.name === "call-tags-input") {
                field.value = res?.data?.call_tags;
              } else if (field.fields) {
                field.fields = updateField(field.fields);
              }
              return field;
            });

          return {
            ...prev,
            schema: prev?.schema?.map((sec: any) => {
              if (sec.fields) {
                sec.fields = updateField(sec.fields);
              }
              return sec;
            }),
          };
        });
      } else {
        toast.error(res.message ? res.message : FAILURE_MSG, {
          position: "top-center",
          autoClose: 2000,
        });
      }
    } catch (error) {
      toast.error(CATCH_ERROR_MSG, {
        position: "top-center",
        autoClose: AUTO_CLOSE,
      });
      console.error("Failed to fetch schema", error);
    }
    dispatch(setLoading(false));
  };

  const API_GET_CHAT_DATA = async () => {
    dispatch(setLoading(true));
    // return
    const id = selectedRecord?._id;
    try {
      const res = await Utils.makeApiCall(`/conversation?id=${id}`, "GET");
      if (res.status && res?.data?.length) {
        const conversation = res?.data[0];
        if (!phno) {
          setPhno(conversation?.phno);
          API_GET_Patient_details(conversation?.patient_id);
        }
        setSchema((prev: any) => {
          const updateField = (fields: any[]) =>
            fields.map((field: any) => {
              if (field?.name === "medium_icon") {
                const { icon, iconClassName } = DetailsServices.getChatIconData(
                  conversation?.medium
                );
                field.label = icon;
                field.className = iconClassName;
              } else if (field?.name === "medium") {
                field.label = conversation?.medium || "";
              } else if (["chat_start", "chat_end"].includes(field.name)) {
                let date = conversation?.chat_start;
                date = moment(date).format("DD-MM-YYYY HH:mm");
                field.label = date || "N/A";
              } else if (field.name === "chat_duration") {
                field.label = conversation?.chat_duration
                  ? ` (${conversation?.chat_duration})`
                  : "";
              } else if (field.name === "conversations") {
                field.className = `col-span-9 flex flex-col p-4 overflow-auto gap-1 bot-conversations ${conversation?.medium?.toLowerCase()}`;
                field["fields"] = conversation?.messages || [];
              } else if (field.fields) {
                field.fields = updateField(field.fields);
              }
              return field;
            });

          return {
            ...prev,
            schema: prev?.schema?.map((sec: any) => {
              if (sec.fields) {
                sec.fields = updateField(sec.fields);
              }
              return sec;
            }),
          };
        });
      } else {
        toast.error(res.message ? res.message : FAILURE_MSG, {
          position: "top-center",
          autoClose: 2000,
        });
      }
    } catch (error) {
      toast.error(CATCH_ERROR_MSG, {
        position: "top-center",
        autoClose: AUTO_CLOSE,
      });
      console.error("Failed to fetch schema", error);
    }
    dispatch(setLoading(false));
  };

  const API_GET_LEAD_DATA = async () => {
    dispatch(setLoading(true));
    // return
    const id = selectedRecord?._id;
    try {
      const res = await Utils.makeApiCall(`/leads/${id}`, "GET");
      if (res.status && res?.data) {
        const lead = res?.data;
        setLead(lead);
        if (!phno) {
          setPhno(lead?.phone_number);
          API_GET_Patient_details(lead?.patient_id);
        }
        setSchema((prev: any) => {
          const updateField = (fields: any[]) =>
            fields.map((field: any) => {
              if (field?.name === "source_icon") {
                const { icon, iconClassName } =
                  DetailsServices.getLeadIconData(lead);
                field.label = icon;
                field.className = iconClassName;
              } else if (["source", "reason"].includes(field?.name)) {
                field.label = lead?.[field.name] || "";
              } else if (field.name === "leads_modal_tabs") {
                const arr: { key: string; label: string }[] = [
                  {
                    key: "comments",
                    label: "Comments",
                  },
                ];
                if (
                  ["contact form", "website"].includes(
                    lead?.source?.toLowerCase()
                  )
                ) {
                  arr.push({
                    key: "contact_details",
                    label: "Contact Details",
                  });
                }
                if (
                  ["chatbot", "whatsapp","instagram","facebook"].includes(lead?.source?.toLowerCase())
                ) {
                  arr.push({
                    key: "conversations",
                    label: "Conversations",
                  });
                }
                field.options = arr;
                field.value = { key: "comments", label: "Comments" };
              } else if (field.fields) {
                field.fields = updateField(field.fields);
              }
              return field;
            });

          return {
            ...prev,
            schema: prev?.schema?.map((sec: any) => {
              if (sec.fields) {
                sec.fields = updateField(sec.fields);
              }
              return sec;
            }),
          };
        });
        if (lead?.conversation) {
          API_GET_Conversations(lead?.conversation || "");
        }
      } else {
        toast.error(res.message ? res.message : FAILURE_MSG, {
          position: "top-center",
          autoClose: 2000,
        });
      }
    } catch (error) {
      toast.error(CATCH_ERROR_MSG, {
        position: "top-center",
        autoClose: AUTO_CLOSE,
      });
      console.error("Failed to fetch schema", error);
    }
    dispatch(setLoading(false));
  };

  const API_GET_Conversations = async (cid: any) => {
    // setLoading(true);

    if (cid) {
      try {
        const res = await Utils.makeApiCall(`/conversation?id=${cid}`, "GET");

        if (res.status) {
          const conversation = res?.data?.[0];
          const messages = res.data?.[0]?.messages || [];
          setSchema((prev: any) => {
            const updateField = (fields: any[]) =>
              fields.map((field: any) => {
                if (field?.name === "conversations_body") {
                  field.className = `flex flex-col p-4 overflow-auto gap-1 bot-conversations ${conversation?.medium?.toLowerCase()}`;
                  field.fields = messages;
                } else if (field.fields) {
                  field.fields = updateField(field.fields);
                }
                return field;
              });

            return {
              ...prev,
              schema: prev?.schema?.map((sec: any) => {
                if (sec.fields) {
                  sec.fields = updateField(sec.fields);
                }
                return sec;
              }),
            };
          });
        } else {
          toast.error(res.message, {
            position: "top-center",
            autoClose: 2000,
          });
        }
      } catch (error) {
        console.error("Failed to fetch schema", error);
      }
    }
    // dispatch(setLoading(false))
  };

  const API_GET_Patient_details = async (id: any) => {
    // console.log(id)
    dispatch(setLoading(true));
    if (!id) {
      setSchema((prev: any) => {
        const updateField = (fields: any[]) =>
          fields.map((field: any) => {
            if (field?.cols?.length) {
              field.visible = false;
              field["fields"] = [];
            }
            return field;
          });

        return {
          ...prev,
          schema: prev?.schema?.map((sec: any) => {
            if (sec.fields) {
              sec.fields = updateField(sec.fields);
            }
            return sec;
          }),
        };
      });
      return;
    }
    try {
      const res = await Utils.makeApiCall(`/patient/${id}`, "GET");
      if (res.status && res.data) {
        const headerData: any = {
          ...res.data,
          patient_name: res?.data?.name?.[0]?.text || null,
          phno: res?.data?.telecom?.[0]?.value,
          createdAt: res?.data?.createdAt
            ? moment(res?.data?.createdAt).format("DD-MM-YYYY HH:mm")
            : null,
        };
        setSchema((prev: any) => {
          const updateField = (fields: any[]) =>
            fields.map((field: any) => {
              if (field?.cols?.length) {
                field.visible = true;
                field["fields"] = field.cols.map((item: any) => {
                  return {
                    name: item.name,
                    className: "flex gap-2 items-baseline",
                    fields: [
                      {
                        name: `${item.name}_key`,
                        element: "div",
                        label: item.label,
                        className: "font-medium text-gray-700 text-xs",
                      },
                      {
                        name: `${item.name}_val`,
                        element: "div",
                        label: `${headerData ? (item.name === "age" ? `${headerData[item.name]} ${headerData?.["gender"] ? `/ ${headerData?.["gender"]}` : ""}` : headerData[item.name] || "--") : "--"}`,
                        className: "text-sm font-bold text-gray-600 capitalize",
                      },
                    ],
                  };
                });
                // console.log(field)
              }
              return field;
            });

          return {
            ...prev,
            schema: prev?.schema?.map((sec: any) => {
              if (sec?.fields) {
                sec.fields = updateField(sec.fields);
              }
              return sec;
            }),
          };
        });
      } else {
        toast.error(res.message ? res.message : FAILURE_MSG, {
          position: "top-center",
          autoClose: 2000,
        });
      }
    } catch (error) {
      toast.error(CATCH_ERROR_MSG, {
        position: "top-center",
        autoClose: AUTO_CLOSE,
      });
      console.error("Failed to fetch schema", error);
    }
    dispatch(setLoading(false));
  };

  const API_GET_RecentInteractions = async (mobile: string, type: string) => {
    setLoading(true);
    setSchema((prev: any) => {
      const updateField = (fields: any[]) =>
        fields.map((field: any) => {
          if (field.name === "recent_interactions_cards") {
            field.fields = [
              {
                name: "skeleton",
                element: "div",
                label: <RecentInteractionSkeleton />,
              },
            ];
          } else if (field.fields) {
            field.fields = updateField(field.fields);
          }
          return field;
        });

      return {
        ...prev,
        schema: prev?.schema?.map((sec: any) => {
          if (sec.fields) {
            sec.fields = updateField(sec.fields);
          }
          return sec;
        }),
      };
    });
    try {
      const res = await Utils.makeApiCall(
        `/getRecentInteractions?mobile=${mobile}&type=${type || ""}`,
        "GET"
      );

      if (!res.status) {
        toast.error(res.message || FAILURE_MSG, {
          position: "top-center",
          autoClose: 2000,
        });
        return;
      }
      handleInteractions(res);
    } catch (error) {
      toast.error(CATCH_ERROR_MSG, {
        position: "top-center",
        autoClose: AUTO_CLOSE,
      });
      console.error("Failed to fetch schema", error);
    } finally {
      setLoading(false);
    }
  };

  const handleInteractions = (res: any) => {
    setSchema((prev: any) => {
      const updateField = (fields: any[]) =>
        fields.map((field: any) => {
          if (field.name === "recent_interactions_cards") {
            if (res.data?.length) {
              field.fields = res.data.map((item: any) => {
                const { icon, iconClassName } =
                  DetailsServices.getInteractionIconData(item);
                const isSelected = item?._id === selectedRecord._id;
                return {
                  record: item,
                  name: `interaction_${item._id}`,
                  element: "clickable-widget",
                  className: `m-1 p-2 flex items-center gap-2 border-[1px] rounded-md drop-shadow-sm bg-white scale-[1] hover:scale-[1.01] hover:bg-[#F2F2F2]/10 transition-all duration-600 ease-in-out cursor-pointer ${isSelected ? "border-primary shadow-md scale-[1.01] border-[2px]" : ""}`,
                  fields: [
                    {
                      name: `icon_${item._id}`,
                      label: icon,
                      className: iconClassName,
                      element: "div",
                    },
                    {
                      name: `content_${item._id}`,
                      className: "flex flex-col gap-[2px] w-full",
                      fields: [
                        {
                          name: "interaction_header",
                          className: "flex justify-between items-center",
                          fields: [
                            {
                              name: "interaction_name",
                              element: "div",
                              className: `text-sm font-medium text-black`,
                              label: item.interaction_type,
                            },
                            {
                              name: "interaction_status",
                              element: "div",
                              className: `p-1 px-2 text-xs text-gray-600`,
                              label: item.createdAt,
                            },
                          ],
                        },
                        {
                          name: "interaction_type",
                          element: "div",
                          className: `text-[10px] w-fit capitalize font-medium ${iconClassName} !p-0 !bg-transparent`,
                          label: item.type || "--",
                        },
                      ],
                    },
                  ],
                };
              });
            } else {
              field.fields = [
                {
                  name: "no_recent_interactions",
                  element: "div",
                  className:
                    "text-sm text-gray-600 text-center flex justify-center items-center h-full",
                  label: "No recent interactions found",
                },
              ];
            }
          } else if (field.fields) {
            field.fields = updateField(field.fields);
          }
          return field;
        });

      return {
        ...prev,
        schema: prev?.schema?.map((sec: any) => {
          if (sec.fields) {
            sec.fields = updateField(sec.fields);
          }
          return sec;
        }),
      };
    });
  };

  const handleSelectedInteraction = (id: any) => {
    setSchema((prev: any) => {
      const updateField = (fields: any[]) =>
        fields.map((field: any) => {
          if (field.name.includes("interaction_")) {
            const isSelected = field?.record?._id === id;
            field.className = `m-1 p-2 flex items-center gap-2 border-[1px] rounded-md drop-shadow-sm bg-white scale-[1] hover:scale-[1.01] hover:bg-[#F2F2F2]/10 transition-all duration-600 ease-in-out cursor-pointer ${isSelected ? "border-primary shadow-md scale-[1.01] border-[2px]" : ""}`;
          } else if (field.fields) {
            field.fields = updateField(field.fields);
          }
          return field;
        });

      return {
        ...prev,
        schema: prev?.schema?.map((sec: any) => {
          if (sec.fields) {
            sec.fields = updateField(sec.fields);
          }
          return sec;
        }),
      };
    });
  };

  const API_GET_GetAllComments = async (type = "") => {
    dispatch(setLoading(true));
    const id = selectedRecord?._id;
    resetForm(false);
    const url: any = {
      Call: `/calls/getComments?callId=${id}`,
      Lead: `/leads/getComments?leadId=${id}`,
    }[type];
    if (!url) {
      return;
    }
    try {
      const res = await Utils.makeApiCall(url, "GET");
      if (res.status) {
        if (res?.data?.length !== 0) {
          setSchema((prev: any) => {
            const updateField = (fields: any[]) =>
              fields.map((field: any) => {
                if (field.name === "comments_body") {
                  field.fields = res.data.map((comment: any) => {
                    const name = comment?.fullname || "--";
                    const allInitials =
                      name
                        ?.split(" ")
                        .map((e: string) => e[0])
                        .join("")
                        .toUpperCase() || "--";
                    const iconInitials =
                      allInitials.length >= 2
                        ? allInitials.substring(0, 2)
                        : allInitials;

                    return {
                      name: comment["_id"],
                      className: "flex flex-col",
                      fields: [
                        {
                          name: "modal_content",
                          className: "flex w-full my-2 px-0 gap-2",
                          fields: [
                            {
                              name: "modal_icon",
                              className: "flex justify-center",
                              fields: [
                                {
                                  name: "modal_element_icon",
                                  className: `bg-primary/5 text-primary rounded-full w-[40px] h-[40px] flex items-center justify-center text-md font-bold`,
                                  element: "div",
                                  label: iconInitials,
                                },
                              ],
                            },
                            {
                              name: "modal_body",
                              className: " w-full flex flex-col",
                              fields: [
                                {
                                  name: "modal_element_title",
                                  className:
                                    "flex flex-row justify-between w-full",
                                  fields: [
                                    {
                                      name: "modal_element_name",
                                      className:
                                        "flex gap-2 items-center justify-center",
                                      fields: [
                                        {
                                          name: "user_name",
                                          className: "font-semibold",
                                          element: "div",
                                          label: name || "--",
                                        },
                                        {
                                          name: "time",
                                          className: "text-xs",
                                          element: "div",
                                          // label: `${moment(comment?.createdAt).format('DD-MM-YYYY hh:mm a')} (${moment(comment?.createdAt).fromNow()})`
                                          label: `${moment(comment?.createdAt).fromNow()}`,
                                        },
                                      ],
                                    },
                                    {
                                      name: "modal_element_group",
                                      className:
                                        "flex flex-row gap-3 items-center",
                                      visible: type === "Lead",
                                      fields: [
                                        {
                                          name: "modal_element_status",
                                          className: `p-1 px-2 text-xs rounded-md  ${LeadStatusStyles[comment?.status]}`,
                                          element: "div",
                                          label: comment?.status || "--",
                                          visible: comment?.status
                                            ? true
                                            : false,
                                        },
                                      ],
                                    },
                                  ],
                                },
                                {
                                  name: "modal_element_text",
                                  element: "div",
                                  className: "text-sm",
                                  label: comment?.text || "--",
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    };
                  });
                } else if (field.fields) {
                  field.fields = updateField(field.fields);
                }
                return field;
              });

            return {
              ...prev,
              schema: prev?.schema?.map((sec: any) => {
                if (sec.fields) {
                  sec.fields = updateField(sec.fields);
                }
                return sec;
              }),
            };
          });
        }
      } else {
        toast.error(res.message ? res.message : FAILURE_MSG, {
          position: "top-center",
          autoClose: 2000,
        });
      }
    } catch (error) {
      toast.error(CATCH_ERROR_MSG, {
        position: "top-center",
        autoClose: AUTO_CLOSE,
      });
      console.error("Failed to fetch schema", error);
    }
    dispatch(setLoading(false));
  };

  const API_POST_AddComent = async () => {
    dispatch(setLoading(true));

    if (!commentInput) {
      toast.error("Please enter your comment.", {
        position: "top-center",
        autoClose: AUTO_CLOSE,
      });
      dispatch(setLoading(false));
      return;
    }
    if (
      selectedRecord?.interaction_type === "Lead" &&
      commentStatus["value"].length === 0
    ) {
      toast.error("Please select the status.", {
        position: "top-center",
        autoClose: AUTO_CLOSE,
      });
      dispatch(setLoading(false));
      return;
    }
    const id = selectedRecord?._id;
    const payload: any = {
      text: commentInput,
      userId: Storage.getItem("details")["_id"],
      status: commentStatus?.["value"],
    };
    let url = "";
    if (selectedRecord?.interaction_type === "Call") {
      payload["callId"] = id;
      url = "/calls/addComment";
    } else if (selectedRecord?.interaction_type === "Lead") {
      payload["leadId"] = id;
      url = "/leads/addComment";
    }
    if (!url) {
      dispatch(setLoading(false));
      return;
    }
    try {
      const res = await Utils.makeApiCall(url, "POST", payload);

      if (res.status) {
        toast.success(res.message, {
          position: "top-center",
          autoClose: 2000,
        });
        API_GET_GetAllComments(selectedRecord?.interaction_type);
        resetForm(true);
        setIsModified(true);
      } else {
        toast.error(res.message ? res.message : FAILURE_MSG, {
          position: "top-center",
          autoClose: 2000,
        });
      }
    } catch (error) {
      toast.error(CATCH_ERROR_MSG, {
        position: "top-center",
        autoClose: AUTO_CLOSE,
      });
      console.error("Failed to fetch schema", error);
    }
    dispatch(setLoading(false));
  };

  const resetForm = (visible: boolean) => {
    setSchema((prev: any) => {
      const updateField = (fields: any[]) =>
        fields.map((field: any) => {
          if (field.name === "comments_body") {
            if (!visible) {
              field.fields = [
                {
                  name: "empty",
                  label: (
                    <span className="flex items-center gap-2">
                      <MdOutlineCommentsDisabled /> No Comments
                    </span>
                  ),
                  element: "div",
                  className: "flex items-center justify-center w-full h-full",
                },
              ];
            }
          } else if (field.name === "comments_input_container") {
            field.fields = field.fields.map((ff: any) => {
              if (ff.name === "lead_status_dropdown") {
                ff.value = {};
                setCommmentStatus({ value: "", label: "" });
              }
              if (ff.name === "comment_input") {
                ff.value = "";
                setCommentInput("");
              }
              return ff;
            });
          } else if (field.fields) {
            field.fields = updateField(field.fields);
          }
          return field;
        });

      return {
        ...prev,
        schema: prev?.schema?.map((sec: any) => {
          if (sec.fields) {
            sec.fields = updateField(sec.fields);
          }
          return sec;
        }),
      };
    });
  };
  const handleCallTags = async (e: any) => {
    if (tagDebounceTime.current) {
      clearTimeout(tagDebounceTime.current);
    }
    tagDebounceTime.current = setTimeout(async () => {
      try {
        const payload: any[] = [];
        let flag = false;
        for (const val of e?.value || []) {
          if (val?.label === val?.value) {
            flag = true;
            const res = await Utils.makeApiCall("/call-tags", "POST", {
              tag_name: val?.label,
            });
            if (res.status) {
              payload.push(res?.data?._id);
              await handleTagOptions();
              setIsModified(true);
            } else {
              continue;
            }
          } else {
            payload.push(val?.value);
          }
        }
        const id = selectedRecord?._id;
        const res = await Utils.makeApiCall(
          `/updateCallTag?call_id=${id}`,
          "PUT",
          payload
        );
        if (!res.status) {
          toast.error(CATCH_ERROR_MSG, {
            position: "top-center",
            autoClose: AUTO_CLOSE,
          });
          console.log("Error while sending the data.");
        } else {
          setIsModified(true);
          if (flag) {
            // API_GET_GetAllComments()
            API_GET_CALL_DATA();
            handleTagOptions();
          }
        }
      } catch (err) {
        toast.error(CATCH_ERROR_MSG, {
          position: "top-center",
          autoClose: AUTO_CLOSE,
        });
        console.log("Error while sending the data.", err);
      }
    }, 0);
  };

  const updateInteractionType = (option: any) => {
    setSchema((prevSchema: any) => {
      const updatedSchema = Utils.updateSchemaRecursively(
        prevSchema,
        (field: any) => {
          if (field.name === "interaction_types") {
            return {
              ...field,
              value: option,
            };
          }
          return field;
        }
      );

      return updatedSchema;
    });
    setType(option.value);
  };

  const handleTabs = (tab: any) => {
    setSchema((prev: any) => {
      const updateField = (fields: any[]) =>
        fields.map((field: any) => {
          if (["comments_modal_container"].includes(field.name)) {
            field.visible = tab === "comments";
          } else if (["conversations_body"].includes(field.name)) {
            field.visible = tab === "conversations";
          } else if (["messages_body"].includes(field.name)) {
            field.visible = tab === "contact_details";
            if (tab === "contact_details") {
              field.fields = [
                {
                  name: `name`,
                  className: "flex items-center w-full gap-2 ",
                  fields: [
                    {
                      name: `name_label`,
                      className:
                        "font-medium text-lg w-[30%] text-right text-gray-4 capitalize",
                      element: "div",
                      label: `Name: `,
                    },
                    {
                      name: `item_value`,
                      className:
                        "font-semibold text-lg w-[50%] text-left text-black",
                      element: "div",
                      label: Lead?.name || "--",
                    },
                  ],
                },
                {
                  name: `email`,
                  className: "flex items-center w-full gap-2 ",
                  fields: [
                    {
                      name: `email_name`,
                      className:
                        "font-medium w-[30%] text-right text-lg text-gray-4 capitalize",
                      element: "div",
                      label: `Email: `,
                    },
                    {
                      name: `item_value`,
                      className:
                        "font-semibold text-lg w-[50%] text-left text-black",
                      element: "div",
                      label: Lead?.email || "--",
                    },
                  ],
                },
                {
                  name: `mobile`,
                  className: "flex items-center w-full gap-2 ",
                  fields: [
                    {
                      name: `mobile_label`,
                      className:
                        "font-medium w-[30%] text-right text-lg text-gray-4 capitalize",
                      element: "div",
                      label: `Mobile: `,
                    },
                    {
                      name: `mobile_value`,
                      className:
                        "font-semibold text-lg w-[50%] text-left text-black",
                      element: "div",
                      label: Lead?.phone_number || "--",
                    },
                  ],
                },
                {
                  name: `index`,
                  className: "flex items-center w-full gap-2 ",
                  visible: Lead?.message ? true : false,
                  fields: [
                    {
                      name: `item_name`,
                      className:
                        "font-medium w-[30%] text-right text-lg text-gray-4 capitalize",
                      element: "div",
                      label: `Message: `,
                    },
                    {
                      name: `item_value`,
                      className:
                        "font-semibold text-lg w-[50%] text-left text-black",
                      element: "div",
                      label: Lead?.message || "--",
                    },
                  ],
                },
                ...Lead.additional_fields.map((field: any, index: any) => {
                  if (
                    field.element === "multiple_select" ||
                    field.element === "select"
                  ) {
                    return {
                      name: `index_${index}`,
                      className: "flex items-center w-full gap-2 ",
                      fields: [
                        {
                          name: `item_name_${index}`,
                          className:
                            "font-medium w-[30%] text-right text-lg text-gray-4 capitalize",
                          element: "div",
                          label: `${field?.label}: `,
                        },
                        {
                          name: `item_value_${index}`,
                          className:
                            "font-semibold text-lg w-[50%] text-left text-black",
                          element: "div",
                          label: field?.value
                            ?.map((item: any) => item?.name)
                            ?.join(", "),
                        },
                      ],
                    };
                  }
                  if (
                    field.element === "textarea" ||
                    field.element === "input"
                  ) {
                    return {
                      name: `index_${index}`,
                      className: "flex items-center w-full gap-2 ",
                      fields: [
                        {
                          name: `item_name_${index}`,
                          className:
                            "font-medium w-[30%] text-right text-lg text-gray-4 capitalize",
                          element: "div",
                          label: `${field?.label}: `,
                        },
                        {
                          name: `item_value_${index}`,
                          className:
                            "font-semibold text-lg w-[50%] text-left text-black",
                          element: "div",
                          label: field?.value,
                        },
                      ],
                    };
                  }
                  // if (field.element === "hyperlink") {
                  //   return {
                  //     name: `index_${index}`,
                  //     className: "flex items-center w-full gap-2 ",
                  //     fields: [
                  //       {
                  //         name: `item_name_${index}`,
                  //         className:
                  //           "font-medium w-[30%] text-right text-lg text-gray-4 capitalize",
                  //         element: "div",
                  //         label: `${field?.label}: `,
                  //       },
                  //       {
                  //         name: `item_value_${index}`,
                  //         className:
                  //           "font-semibold text-lg w-[50%] text-left text-blue-400",
                  //         element: "div",
                  //         label: (
                  //           <a href={field?.value} target={field?.target}>
                  //             {field?.value}
                  //           </a>
                  //         ),
                  //       },
                  //     ],
                  //   };
                  // }
                  return { name: `index_${index}` };
                }),
              ];
              // console.log(field, tab);
            }
          } else if (field.fields) {
            field.fields = updateField(field.fields);
          }
          return field;
        });

      return {
        ...prev,
        schema: prev?.schema?.map((sec: any) => {
          if (sec.fields) {
            sec.fields = updateField(sec.fields);
          }
          return sec;
        }),
      };
    });
  };

  const handleSelectedRecord = (e: any) => {
    if (e.name === "call-tags-input") {
      handleCallTags(e);
    }

    if (e.name === "comment_submit") {
      API_POST_AddComent();
    }
    if (e.name === "lead_status_dropdown") {
      setCommmentStatus(e?.value || { value: "", label: "" });
    }
    if (e.name === "comment_input") {
      setCommentInput(e?.value || "");
    }

    if (e.name === "interaction_types") {
      updateInteractionType(e?.value);
    } else if (e.name.includes("interaction_")) {
      // console.log(e);
      if (["Call", "Chat", "Lead"].includes(e?.record?.interaction_type)) {
        setSelectedRecord(e?.record);
      }
    }
    if (e.name === "modal_close") {
      props.onClose(isModified);
    }

    if (e.name === "leads_modal_tabs") {
      handleTabs(e?.value?.key);
    }
    if(e.name === "appointment_button"){
      let ph_no, name;
      if(props?.selectedRecord?.interaction_type === "Lead"){
        ph_no = props?.selectedRecord?.phone_number?.slice(-10);
        name = props?.selectedRecord?.name
      } else if(props?.selectedRecord?.interaction_type === "Call"){
        ph_no = props?.selectedRecord?.From?.slice(-10);
        name = props?.selectedRecord?.Name
      } 
      const apptBooking = appointmentBookingModal(ph_no, name)
      setSchema((prev: any) => {
        const updateFieldRecursively = (fields: any[]) => {
          return fields.map((field: any) => {
            if(field.name === 'appointment-booking-modal'){
              field.visible = true;
            } 
            if(field.name === 'modal-body') {
              field.fields = [apptBooking];
            }
            if (field.fields) {
              field.fields = updateFieldRecursively(field.fields); 
            }
            return field;
          });
        };
      
        return {
          ...prev,
          schema: prev?.schema?.map((section: any) => {
            if (section.fields) {
              section.fields = updateFieldRecursively(section.fields);
            }
            return section;
          }),
        };
      });
    }
    if(e.name === 'appointment-booking-modal-close'){
      // console.log(schema);
      setSchema((prev: any) => {
        const updateFieldRecursively = (fields: any[]) => {
          return fields.map((field: any) => {
            if (field.name === "appointment-booking-modal") {
              field.visible = false;
            }
            if (field.fields) {
              field.fields = updateFieldRecursively(field.fields); 
            }
            return field;
          });
        };
      
        return {
          ...prev,
          schema: prev?.schema?.map((section: any) => {
            if (section.fields) {
              section.fields = updateFieldRecursively(section.fields);
            }
            return section;
          }),
        };
      });
    }
  };

  return (
    <ElementExecutor data={schema} selectedRecord={handleSelectedRecord} />
  );
};

export default DetailsModal;
