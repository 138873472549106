import { Layout, Skeleton } from "antd"
import React from "react"
const { Content } = Layout

const SidebarSkeleton = () => {
  return (
    <Content className={`bg-white p-2 mt-2 flex flex-col gap-2 w-[200px] max-w-[200px] items-center`}>
      <Skeleton.Button
        active={true}
        size="default"
        shape="circle"
        style={{ width: 70, height: 70 }}
        className="w-full mb-2"
      />
      {[...Array(8)].map((_, index) => (
        <Skeleton.Button
          key={index}
          active={true}
          size="default"
          shape="square"
          style={{ height: 35 }}
          block={true}
        />
      ))}
    </Content>
  )
}

export default SidebarSkeleton
