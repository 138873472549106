import { combineReducers } from '@reduxjs/toolkit';
import appStateReducer from "./appState/appStateSlice";
import authReducer from './auth/authSlice';
import callsReducer from "./calls/callSlice";
import campaignReducer from './campaigns/campaignSlice';
import compareUnitsReducer from './compareUnits/compareUnitsSlice';
import leadFiltersReducer from "./leads/leadFilterSlice";
import leadReducer from './leads/leadSlice';
import chatReducer from "./patientChats/chatSlice";
import patientReducer from "./patients/patientSlice";
import uiSchemaReducer from './uiSchema/uiSchemaSlice';
import callDeatilsReducer from "./calls/detailsSlice";

const rootReducer = combineReducers({
  appState: appStateReducer,
  auth: authReducer,
  calls:callsReducer,
  callDetails: callDeatilsReducer,
  campaign: campaignReducer,
  compareUnits:compareUnitsReducer,
  lead: leadReducer,
  leadFilters:leadFiltersReducer,
  patient:patientReducer,
  patientChats:chatReducer,
  uiSchema: uiSchemaReducer,
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
