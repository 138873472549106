"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileUpload = void 0;
const antd_1 = require("antd");
const icons_1 = require("@ant-design/icons");
const react_1 = __importStar(require("react"));
const FileUpload = (props) => {
    const { Dragger } = antd_1.Upload;
    const initialFiles = Array.isArray(props.value) ? props.value : [];
    const [files, setFiles] = (0, react_1.useState)(initialFiles);
    const [loading, setLoading] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        if (props.value) {
            const initialFiles = Array.isArray(props.value) ? props.value : [];
            setFiles([...initialFiles]);
        }
    }, [props.value]);
    function getBase64(file, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(file);
    }
    const beforeUpload = (file) => {
        if (props.fileType?.length) {
            const isFileType = file.type && props.fileType.includes(file.type);
            if (!isFileType) {
                antd_1.message.error(`You can only upload ${props.fileType} files!`);
                return false;
            }
        }
        if (props.fileSize) {
            // console.log(props.fileSize);
            const isFileSize = file.size && file.size / 1024 / 1024 < props.fileSize;
            // console.log(file.size);
            if (!isFileSize) {
                antd_1.message.error(`File must be smaller than ${props.fileSize}MB!`);
                return false;
            }
        }
        setLoading(true);
        getBase64(file, (imageUrl) => {
            const newFile = {
                uid: file.uid,
                name: file.name,
                status: 'done',
                url: imageUrl,
                thumbUrl: imageUrl,
                originFileObj: file,
            };
            const updatedList = props.allowOne ? [newFile] : [...files, newFile];
            setFiles(updatedList);
            if (props.onChange) {
                {
                    props.allowClear
                        ? props.onChange(newFile)
                        : props.onChange(updatedList);
                }
            }
            setLoading(false);
        });
        return false;
    };
    const handleRemove = async (file) => {
        setLoading(true);
        try {
            const updatedFiles = files.filter((f) => f.uid !== file.uid);
            {
                props.allowOne ? setFiles([]) : setFiles([...updatedFiles]);
            }
            if (props.onChange) {
                {
                    props.allowOne ? setFiles([]) : props.onChange(updatedFiles);
                }
            }
        }
        finally {
            setLoading(false);
        }
    };
    const customRequest = (options) => {
        const { onSuccess } = options;
        setTimeout(() => {
            if (onSuccess) {
                onSuccess('ok');
            }
        }, 0);
    };
    const dragger = (react_1.default.createElement("div", null,
        react_1.default.createElement(Dragger, { action: "", accept: props.accept, listType: "picture", maxCount: props.max_count, multiple: props.multiple, fileList: files, beforeUpload: beforeUpload, onRemove: handleRemove, showUploadList: true, customRequest: customRequest },
            react_1.default.createElement("p", null,
                react_1.default.createElement(icons_1.InboxOutlined, null)),
            react_1.default.createElement("p", null, "Click or drag file to upload"))));
    return react_1.default.createElement(react_1.default.Fragment, null, loading ? react_1.default.createElement(antd_1.Spin, null, dragger) : dragger);
};
exports.FileUpload = FileUpload;
