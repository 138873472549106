export interface IField {
    name: string
    label?: string
    placeholder?: string
    element?: string
    value?: any
    input?: string
    isSVGStylesOverride?: boolean
    icon?: string
    required?: boolean
    action?: string
    type?: string
    img?: string
    alt?: string
    length?: number
    className?: string
    containerClassName?: string
    labelClassName?: string
    iconsClassName?: string
    imgClassName?: string
    fields?: IField[]
    count?: number
    visible?: boolean
    visibility?: boolean
    render?: (e: string, record: Record<string, string>) => JSX.Element
}

export interface ITableHeader {
    name: string
    label: string
    key: string
    render?: (e: string, record: Record<string, string>) => JSX.Element
}

export interface ISchema {
    name?: string
    className: string
    visible?: boolean
    schema: {
        name: string
        element?: string
        loading?: boolean
        variant?: string
        size?: string
        count?: number
        pagination?: boolean
        view?: boolean
        visible?: boolean
        thead?: ITableHeader[]
        tbody?: any[]
        fields?: IField[]
        className?: string
        containerClassName?: string
    }[]
}
export interface HandleModalDataParams {
    data: IField[]
    visible: boolean
    headerData?: Record<string, string>
}
export interface Item {
    name: string
    label: string
}

export interface IOrganizationData {
    name:string,
    website:string,
    gstin:string,
    address:string,
    zipcode:string,
    city:string,
    phone_number:string,
    virtual_number:string,
    meta_phone_number:string,
    logo:object,
    primary_btn_text_color:string,
    primary_btn_color:string,
    primary_text_color:string,
    secondary_btn_color:string,
    secondary_btn_text_color:string,
    chatbot_icon:object,
    chatbot_name:string
}


export type iconName =
    | 'customer-service'
    | 'home'
    | 'info'
    | 'location'
    | 'policy-document'
    | 'whatsapp'

export const LeadStatus ={
    new: 'New',
    open: 'Open',
    inprogress: 'In-Progress',
    qualified: 'Qualified',
    callback: 'Callback',
    closed: 'Closed',
    converted: "Converted",
    discarded: "Discarded"
}

export const LeadStatusColors = {
    [LeadStatus.new]:'#8AC1BB',
    [LeadStatus.open]:'#CADBBF',
    [LeadStatus.inprogress]:'#FFCB8A',
    [LeadStatus.qualified]:'#AEA29F',
    [LeadStatus.callback]:'#CEB0FA',
    [LeadStatus.closed]:'#FCB0CB',
    [LeadStatus.converted]:'#8BC34A',
    [LeadStatus.discarded]:'#FCB0CB',
}

export const CallStatus ={
    none: 'None',
    open: 'Open',
    pending: 'Pending',
    closecallback: 'Closed',
}

export const CallStatusColors = {
    [CallStatus.none]:'#8AC1BB',
    [CallStatus.open]:'#CADBBF',
    [CallStatus.pending]:'#FFCB8A',
    [CallStatus.closecallback]:'#AEA29F',
}
