import { CgWebsite } from "react-icons/cg";
import { FaFacebook, FaInstagram, FaUser } from "react-icons/fa";
import { HiPhoneMissedCall } from "react-icons/hi";
import { IoMagnet } from "react-icons/io5";
import { MdCall, MdPhoneCallback } from "react-icons/md";
import { SiChatbot } from "react-icons/si";

import LabelTags from "../../../utils/tags";
const getInteractionIconData = (item: any) => {
  let icon;
  let iconClassName = "flex items-center rounded-md justify-center p-2";
  if (item?.interaction_type === "Lead") {
    icon = <IoMagnet className="size-5 rotate-180" />;
    iconClassName += ` ${LabelTags.get("Lead")}`;
  } else if (item?.interaction_type === "Call") {
    const icons: any = {
      missed: <HiPhoneMissedCall className="size-5" />,
      answered: <MdCall className="size-5" />,
      call_attempt: <MdPhoneCallback className="size-5" />,
    };
    icon = icons[item.type?.toLowerCase()] || (
      <MdPhoneCallback className="size-5" />
    );
    iconClassName += ` ${LabelTags.get(item.type)}`;
  } else {
    icon = <SiChatbot className="size-5" />;
    iconClassName += ` ${LabelTags.get("Chatbot")}`;
  }
  return { icon, iconClassName };
};

const getChatIconData = (status: any) => {
  let iconClassName =
    "flex items-center rounded-full w-10 h-10 justify-center p-2";
  const icon = <SiChatbot className="size-5" />;
  iconClassName += ` ${LabelTags.get(status)}`;
  return { icon, iconClassName };
};
const getCallIconData = (status: any) => {
  let icon = "";
  let iconClassName =
    "flex items-center rounded-full w-10 h-10 justify-center p-2";
  const icons: any = {
    missed: <HiPhoneMissedCall className="size-5" />,
    answered: <MdCall className="size-5" />,
    call_attempt: <MdPhoneCallback className="size-5" />,
  };
  icon = icons[status?.toLowerCase()] || <MdPhoneCallback className="size-5" />;
  iconClassName += ` ${LabelTags.get(status)}`;
  return { icon, iconClassName };
};

const getLeadIconData = (item: any) => {
  let icon;
  let iconClassName =
    "flex items-center rounded-full w-10 h-10 justify-center p-2";
  const source = item?.source?.toLowerCase();
  if (source === "website") {
    icon = <CgWebsite className="size-5" />;
  } else if (source === "chatbot") {
    icon = <SiChatbot className="size-5" />;
  } else if (source === "operator") {
    icon = <FaUser className="size-5" />;
  } else if (source === "facebook") {
    icon = <FaFacebook className="size-10" />;
  } else if (source === "instagram") {
    icon = <FaInstagram className="size-8 text-white" />;
  } else {
    icon = <IoMagnet className="size-5 rotate-180" />;
  }
  iconClassName += ` ${LabelTags.get(source)}`;
  return { icon, iconClassName };
};

export const DetailsServices = {
  getInteractionIconData,
  getCallIconData,
  getChatIconData,
  getLeadIconData,
};
