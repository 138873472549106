"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PasswordElement = void 0;
const react_1 = __importStar(require("react"));
const icons_1 = require("@ant-design/icons");
const antd_1 = require("antd");
const PasswordElement = (props) => {
    const [passwordVisible, setPasswordVisible] = (0, react_1.useState)(false);
    const handleVisibilityToggle = () => {
        setPasswordVisible((prev) => !prev);
    };
    const handleChange = (e) => {
        if (props.onChange) {
            props.onChange(e.target.value);
        }
    };
    const renderVisibilityIcon = (visible) => visible ? react_1.default.createElement(icons_1.EyeTwoTone, null) : react_1.default.createElement(icons_1.EyeInvisibleOutlined, null);
    return (react_1.default.createElement("div", { className: props.containerClassName },
        props.label && (react_1.default.createElement("p", { className: props.labelClassName },
            props.label,
            ' ',
            props.required ? react_1.default.createElement("span", { style: { color: 'red' } }, "*") : '')),
        react_1.default.createElement(antd_1.Input.Password, { placeholder: props.placeholder, className: props.className, type: props.type, status: props.required && props.errorText ? 'error' : '', onChange: (e) => {
                handleChange(e);
            }, iconRender: renderVisibilityIcon, visibilityToggle: {
                visible: passwordVisible && passwordVisible,
                onVisibleChange: handleVisibilityToggle,
            } }),
        props.required ? (react_1.default.createElement("p", { className: props.errorClassName }, props.errorText && props.errorText)) : ('')));
};
exports.PasswordElement = PasswordElement;
