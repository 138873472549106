import React, { useEffect } from 'react'
import { unstable_usePrompt } from 'react-router-dom';

const useExitPopUp = (detectChange: any) => {

  unstable_usePrompt({
    message: "Are you sure? Changes may not be saved!",
    when: ({ currentLocation, nextLocation }) => {
      const shouldPrompt = detectChange && (currentLocation.pathname !== nextLocation.pathname);
      return shouldPrompt;
    },

  });


  useEffect(() => {
    if (!detectChange) {
      return
    }
    function beforeUnloadHandler(event: BeforeUnloadEvent) {
      if (location.pathname.includes("add") || location.pathname.includes("edit")) {

        event.preventDefault();
      }
      return (event.returnValue = '');
    }
    window.addEventListener("beforeunload", beforeUnloadHandler, { capture: true })
    return () => {
      window.removeEventListener("beforeunload", beforeUnloadHandler)
    }

  }, [detectChange])
}

export default useExitPopUp
