"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardElement = void 0;
const react_1 = __importStar(require("react"));
const antd_1 = require("antd");
const icons_1 = require("../constants/icons");
// import { icons } from '../constants/icons';
const { Meta } = antd_1.Card;
const CardElement = (props) => {
    const cardRef = (0, react_1.useRef)(null);
    const [disabled, setDisabled] = (0, react_1.useState)();
    (0, react_1.useEffect)(() => {
        setDisabled(props.disabled);
    }, [props.disabled]);
    const handleClick = () => {
        if (disabled)
            return;
        if (cardRef.current) {
            cardRef.current.focus();
        }
        if (props.onClick && !props.disabled) {
            props.onClick(props.name);
        }
    };
    return (react_1.default.createElement(antd_1.Card, { ref: cardRef, hoverable: !disabled, onClick: handleClick, className: props.cardClassname, bordered: false, tabIndex: disabled ? -1 : 0, style: disabled ? { cursor: 'not-allowed', opacity: 0.6 } : {} },
        react_1.default.createElement("div", { className: `absolute -left-2 -top-2 w-5 ${disabled ? 'hidden' : ''}`, style: { visibility: 'hidden' }, onFocus: () => {
                cardRef.current
                    ?.querySelector('.icon-container')
                    ?.setAttribute('style', 'visibility: visible');
            }, onBlur: () => {
                cardRef.current
                    ?.querySelector('.icon-container')
                    ?.setAttribute('style', 'visibility: hidden');
            } },
            react_1.default.createElement("div", { className: "icon-container" }, icons_1.icons[props.icon])),
        react_1.default.createElement(Meta, { avatar: react_1.default.createElement("div", { className: props.imgClassName },
                react_1.default.createElement("img", { alt: props.name, src: props.img })), title: react_1.default.createElement("div", { className: props.titleClassName }, props.label), description: props.items &&
                props.items.map((item, index) => (react_1.default.createElement("div", { className: props.containerClassName, key: index },
                    react_1.default.createElement("p", { className: props.className }, item.name),
                    react_1.default.createElement("p", { className: props.labelClassName }, item.label)))) })));
};
exports.CardElement = CardElement;
