import React, { useEffect, useRef, useState } from 'react'
import { ElementExecutor } from '@apexcura/core'
import moment from 'moment'
import { toast } from 'react-toastify'
import Utils from '../utils';
import { CONSTANTS } from "../utils/app-constants";
import { LeadStatus } from '../utils/types'
import Storage from '../utils/local-storage'
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveMenu, setTopbar } from '../redux/uiSchema/uiSchemaSlice';
import { getPagePermissions } from '../redux/auth/authSlice';
import { Tooltip,Avatar, Popover,Popconfirm } from "antd";
import { setLoading } from '../redux/appState/appStateSlice';
import { setDateRange, setFilters, setSearchText, setPagination,setLeadsType } from '../redux/leads/leadFilterSlice';
import { RootState } from '../redux/rootReducer';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import DetailsModal from '../components/DetailsModal';
import { setSelectedRow } from '../redux/leads/leadSlice';
import { AddAppointmentComponent } from './appointments/AddAppointmentCompontent';

const { BASE_URL, FAILURE_MSG, CATCH_ERROR_MSG, AUTO_CLOSE } = CONSTANTS;

export const colors:any = {
  "Call Back Request": "bg-[#29AAE1]/5 text-[#29AAE1]",
  "Ambulance Request": "bg-[#BF0E0E]/5 text-[#BF0E0E]",
  "Call Was Successful": "bg-[#4DA723]/5 text-[#4DA723]",
  "Answered": "bg-[#4DA723]/5 text-[#4DA723]",
  "Client Hungup": "bg-[#F59F0D]/5 text-[#F59F0D]",
  "Missed Call": "bg-[#F9A07A]/5 text-[#F37748]",
  "Missed": "bg-[#F9A07A]/5 text-[#F37748]"
};



export const LeadStatusStyles:any = {
  "New": "bg-[#29AAE1]/10 text-[#29AAE1]",
  "Open": "bg-cyan-500/5 text-cyan-500",
  "In-Progress": "bg-yellow-500/10 text-yellow-500",
  "Qualified": "bg-fuchsia-500/5 text-fuchsia-500",
  "Callback": "bg-fuchsia-500/5 text-fuchsia-500",
  "Closed": "bg-red-500/5 text-red-500",
  "Converted": "bg-green-500/5 text-green-500",
    "Discarded": "bg-red-500/5 text-red-500",
};

const LeadSourceStyles:any = {
  "Chatbot": "bg-[#29AAE1]/5 text-[#29AAE1]",
  "Operator1": "bg-[#BF0E0E]/5 text-[#BF0E0E]",
  "Operator": "bg-fuchsia-500/5 text-fuchsia-500",
  "Phone": "bg-[#4DA723]/5 text-[#4DA723]",
  "Website": "bg-[#F59F0D]/5 text-[#F59F0D]",
  "Phone3": "bg-[#F9A07A]/5 text-[#F37748]"
};

const commentsSchema = () => (
  {
    name: 'comments_modal_container',
    className:'',
    containerClassName:' flex flex-col h-[60vh] bg-white shadow-lg rounded-lg',
    element: "div",
    fields:[
      // {
      //   name:'modal_title',
      //   className:'bg-[#F2F2F2] rounded-lg flex justify-between items-center p-2 pl-4 w-full',
      //   fields:[
      //     {
      //       name:'modal_title_key',
      //       className:'font-semibold text-lg',
      //       element:'div',
      //       label:'Comments'
      //     },
      //   ]
      // },
      {
        name:'modal_body_container',
        visible: true,
        className:'flex flex-col overflow-auto bg-white h-[50vh] divide-y',
        fields:[
          {
            name: "empty",
            label: "No comments",
            element: "div",
            className: "flex items-center justify-center w-full  min-h-[40vh]"
          }
        ]
      },
      {
        name:'modal_comment_container',
        className:'mt-auto p-4 bg-gray-50 rounded-lg flex flex-row gap-2',
        visible: true,
        fields:[
          {
            name:'lead_status_dropdown',
            className: "rounded-md text-gray-700 font-semibold",
            element: "single-select",
            containerClassName: "flex flex-col basis-1/6",
            labelClassName:
              "text-sm mb-1 text-gray-500 font-semibold text-start",
            required: true,
            placeholder: "Status",
            options: [
              {
                label: "Qualified",
                value: LeadStatus.qualified,
              },
              {
                label: "Converted",
                value: LeadStatus.converted,
              },
              {
                label: "Discarded",
                value: LeadStatus.discarded,
              }
            ],
          },
          {
              name:'lead_comment_input',
              element:'input-textarea',
              minRows:1,
              maxRows:5,
              placeholder:"Enter your comment here",
              containerClassName:'basis-4/6 bg-white rounded-lg overflow-visible '
          },
          {
              name:'comment_submit',
              element:'button',
              label:'Comment',
              className:'basis-1/6 mt-auto h-[35px] bg-primary rounded-lg text-white'
          }
        ]
      }
    ]
  }
)

const conversationSchema = () => (
  {
    name: 'conversations_body',
    className:
        'flex flex-col h-[60vh]  p-4 overflow-auto gap-1 bot-conversations',
    fields: [
      {
        name: 'conversation_blank',
        element: 'div',
        className: 'basis-1/6',
        label: "No conversations..."
      }
    ],
  }
)

const messageSchema = () => (
  {
    name: 'conversations_body',
    className:
        ' !h-[60vh] flex gap-2 flex-col p-4 overflow-y-auto ',
    fields: [
      {
        name: 'message_blank',
        element: 'div',
        className: 'basis-1/6',
        label: "No message..."
      }
    ],
  }
)


const Leads = () => {
  // const [leadsType, setLeadsType] = useState("all")
  // const [dateRange, setDateRange] = useState<[string, string]>([moment().subtract(7, 'days').format('DD-MM-YYYY'), moment().format("DD-MM-YYYY")]);
  // const [searchText, setSearchText] = useState("");
  const [commentAdded, setCommentAdded] = useState<boolean>(false);
  const [commentInput,setCommentInput] = useState('')
  const [leadId,setLeadId] = useState('') 
  const [commentStatus,setCommmentStatus] = useState<{value:string,label:string}>({value:'',label:''})
  // const [filters,setFilters]=useState({status:[],source:[]})
  const [message,setmessage]=useState("")
  const [addFields,setAddFields]=useState([]);
  const [data,setData]=useState<any>({});
  
  
  const {dateRange,searchText,filters,pagination,leadsType} = useSelector((store:any) => store.leadFilters);
  const { selectedRow } = useSelector((store:RootState) => store.lead);
  const {page,pageSize}=pagination
  
  const branches = useSelector((state: RootState) => state.uiSchema.branches)
  const [schema, setSchema] = useState<any>({
    className:
      'w-full ',
    schema: [
      {
        name: "lead_details_modal",
        element: "modal",
        containerClassName:"!w-[90%]  ",
        className: 'rounded-lg !w-full',
        visible: false,
        // width: 1100,
        fields: [
              {
                name: "lead_details_modal_head",
                className: "flex justify-between items-center p-4 bg-primary/5",
                fields: [
                    {
                        name:'modal_title_key',
                        className:'font-semibold text-lg  text-[#3341BB]',
                        element:'div',
                        label:'Lead Details'
                    },
                    {
                        name: "leads_details_modal_close",
                        element: "button",
                        label: "X",
                        className:
                          "!w-[35px] !h-[35px] rounded-full bg-primary text-xl text-white flex justify-center items-center",
                    },
                ]
              },
              {
                name: 'lead_details_header_data',
                className:
                  'grid grid-cols-3 gap-2 py-2 px-6',
                
                fields: [
                  {
                    name: "lead_details_data_pair",
                    className: "flex gap-1",
                    fields: [
                      {
                        name: "lead_name_label",
                        className: " font-medium text-gray-700",
                        label: "Name: ",
                        element: "div",
                      },
                      {
                        name: "lead_name_value",
                        className: " font-bold text-gray-600",
                        label: "N/A",
                        element: "div",
                      },
                    ]
                  },
                  {
                    name: "lead_details_data_pair",
                    className: "flex gap-1",
                    fields: [
                      {
                        name: "lead_status_label",
                        className: " font-medium text-gray-700",
                        label: "Status: ",
                        element: "div",
                      },
                      {
                        name: "lead_status_value",
                        className: " font-bold text-gray-600",
                        label: "N/A",
                        element: "div",
                      },
                    ]
                  },
                  {
                    name: "lead_details_data_pair",
                    className: "flex gap-1",
                    fields: [
                      {
                        name: "lead_phone_label",
                        className: " font-medium text-gray-700",
                        label: "Phone Number: ",
                        element: "div",
                      },
                      {
                        name: "lead_phone_value",
                        className: " font-bold text-gray-600",
                        label: "N/A",
                        element: "div",
                      },
                    ]
                  },
                  {
                    name: "lead_details_data_pair",
                    className: "flex gap-1",
                    fields: [
                      {
                        name: "lead_source_label",
                        className: " font-medium text-gray-700",
                        label: "Source: ",
                        element: "div",
                      },
                      {
                        name: "lead_source_value",
                        className: " font-bold text-gray-600",
                        label: "N/A",
                        element: "div",
                      },
                    ]
                  },
                  {
                    name: "lead_details_data_pair",
                    className: "flex gap-1",
                    fields: [
                      {
                        name: "lead_arrivedon_label",
                        className: " font-medium text-gray-700",
                        label: "Arrived On: ",
                        element: "div",
                      },
                      {
                        name: "lead_arrivedon_value",
                        className: " font-bold text-gray-600",
                        label: "N/A",
                        element: "div",
                      },
                    ]
                  },
                ],
              },
              {
                name: "lead_details_modal_body",
                className: "grid grid-cols-12 items-end pb-4 pr-4",
                fields: [
                  {
                    name: "lead_details_tabs_container",
                    className: "col-span-9",
                    fields: [
                      {
                        name: "leads_modal_tabs",
                        element: "tabs",
                        containerClassName: "p-0 m-0 px-4",
                        className: "flex items-center gap-1",
                        options: [
                          {
                            key: "comments",
                            label: "Comments",
                          },
                          
                        ],
                        value: {key: "comments",
                          label: "Comments",}
                      },
                      {
                        name: "tabs_body",
                        className: "px-4 pt-2",
                        fields: [
                          {...commentsSchema()}
                        ],
                      },
                    ]
                  },
                  {
                    name:"recent_interactions",
                    className:"w-full bg-gray-50 border-[1px] col-span-3 p-1  pb-2 flex flex-col gap-0 h-[60vh] rounded-md",
                    fields:[
                        {
                            name:"recent_interactions_text",
                            label:"Recent Interactions",
                            className:"text-lg font-semibold pt-1 pl-1 pb-1 text-primary",
                            element:"div"
                        },
                        {
                            name:"recent_interactions_cards",
                            className:" overflow-y-scroll gap-1 !h-[60vh]",
                            fields:[]
                        }
                    ]
                    
                }
                ]
              },
        ]
      },
      {
        name: "leads_cards_container",
        className: "grid grid-cols-4 gap-2",
        fields: [
          {
            name: 'total_leads_card',
            visible: true,
            leadType:"",
            key: "all",
            element: 'clickable-widget',
            className:"calls-top-card active",
            fields: [
                {
                  name:"total_leads_icon_and_value",
                  className:"flex gap-4 justify-center items-center",
                  fields:[
                    {
                      name: 'total_leads_icon',
                      className: '',
                      iconsClassName:"w-10 h-10 cursor-pointer",
                      element: 'button',
                      icon:`${BASE_URL}/api/public/assets/images/total_leads.svg`,
                      isSVGStylesOverride: false,
                      visible: true,
                    },
                    {
                      name: 'total_leads_text',
                      className: 'text-sm font-semibold text-[#505050]',
                      element: 'div',
                      label: 'Total Leads',
                      visible: true,
                    },
                  ]
                },
                {
                  name: 'total_leads_value',
                  className:'text-lg font-semibold text-[#254AAA]',
                  element: 'div',
                  label: '0',
                  visible: true,
              },
            ],
          },
          {
            name: 'qualified_leads_card',
            visible: true,
            key: "qualified",
            leadType:"Qualified",
            element: 'clickable-widget',
            className:"calls-top-card",
            fields: [
                {
                  name:"qualified_leads_icon_and_value",
                  className:"flex gap-4 justify-center items-center",
                  fields:[
                    {
                      name: 'qualified_leads_icon',
                      className: '',
                      iconsClassName:"w-10 h-10  cursor-pointer",
                      element: 'button',
                      icon:`${BASE_URL}/api/public/assets/images/qualified_leads.svg`,
                      isSVGStylesOverride: false,
                      visible: true,
                    },
                    {
                      name: 'qualified_leads_text',
                      className: 'text-sm font-semibold text-[#505050]',
                      element: 'div',
                      label: 'Qualified Leads',
                      visible: true,
                    },
                  ]
                },
                {
                  name: 'qualified_leads_value',
                  className:'text-lg font-semibold text-[#254AAA]',
                  element: 'div',
                  label: '0',
                  visible: true,
              },
            ],
          },
          {
            name: 'converted_leads_card',
            visible: true,
            key: "converted",
            leadType:"Converted",
            className:"calls-top-card",
            element: 'clickable-widget',
            fields: [
                {
                  name:"converted_leads_icon_and_value",
                  className:"flex gap-4 justify-center items-center",
                  fields:[
                    {
                      name: 'converted_leads_icon',
                      className: '',
                      iconsClassName:"w-10 h-10  cursor-pointer",
                      element: 'button',
                      icon:`${BASE_URL}/api/public/assets/images/converted_leads.svg`,
                      isSVGStylesOverride: false,
                      visible: true,
                    },
                    {
                      name: 'converted_leads_text',
                      className: 'text-sm font-semibold text-[#505050]',
                      element: 'div',
                      label: 'Converted',
                      visible: true,
                    },
                  ]
                },
                {
                  name: 'converted_leads_value',
                  className:'text-lg font-semibold text-[#254AAA]',
                  element: 'div',
                  label: '0',
                  visible: true,
              },
            ],
          },
          {
            name: 'discarded_leads_card',
            visible: true,
            key: "discarded",
            leadType:"Discarded",
            className:"calls-top-card",
            element: 'clickable-widget',
            fields: [
                {
                  name:"discarded_leads_icon_and_value",
                  className:"flex gap-4 justify-center items-center",
                  fields:[
                    {
                      name: 'discarded_leads_icon',
                      className: '',
                      iconsClassName:"w-10 h-10  cursor-pointer",
                      element: 'button',
                      icon:`${BASE_URL}/api/public/assets/images/discarded_leads.svg`,
                      isSVGStylesOverride: false,
                      visible: true,
                    },
                    {
                      name: 'discarded_leads_text',
                      className: 'text-sm font-semibold text-[#505050]',
                      element: 'div',
                      label: 'Discarded',
                      visible: true,
                    },
                  ]
                },
                {
                  name: 'discarded_leads_value',
                  className:'text-lg font-semibold text-[#254AAA]',
                  element: 'div',
                  label: '0',
                  visible: true,
              },
            ],
          },
        ]
      },
      {
        name: 'filters',
        fields: [
          {
            name:"date_and_search",
            className:"flex gap-2 justify-center items-center flex-wrap ",
            fields:[
              {
                name: "search",
                label: "",
                placeholder: "By Customer Name, Phone Number",
                element: "input-text",
                isSearch:true,
                value: searchText,
                containerClassName: 'w-60 flex flex-col rounded-3xl overflow-hidden',
                className: 'w-full rounded-3xl p-2 px-3 font-medium bg-white overflow-hidden',
                icon:"search",
                iconsClassName:"w-[15px] mr-1 flex justify-center items-center",
              },
              {
                  name:"segments",
                  element:"segmented",
                  segmentedOptions:[
                    {
                        label:(<Tooltip title="One Day">1D</Tooltip>),
                        value:"TD"
                    },
                    // {
                    //     label:(<Tooltip title="Yesterday">YD</Tooltip>),
                    //     value:"YD"
                    // },
                    {
                        label:(<Tooltip title="One Week">1W</Tooltip>),
                        value:"TW"
                    },
                    {
                        label:(<Tooltip title="One Month">1M</Tooltip>),
                        value:"TM"
                    },
                  ],
                  visible:true,
                  value:"TW"
              },
              {
                name:"left_button",
                label: '',
                element: 'button',
                icon: 'left',
                action: '',
                iconsClassName: 'size-2',
                className:"pb-1"
                // isSVGStylesOverride: false,
              },
              {
                name: 'date',
                label: '',
                placeholder: 'Select a date',
                element: 'daterangepicker',
                value: dateRange,
                className: 'flex-1 w-[240px]',
              },
              {
                name:"right_button",
                label: '',
                element: 'button',
                icon: 'right',
                action: '',
                iconsClassName: 'size-2',
                className:"pb-1"
                // isSVGStylesOverride: false,
              },
            ]
          },
          {
            name:"refresh_and_download",
            className:"flex gap-2 justify-center items-center ",
            fields:[
              {
                name: 'btn_refresh',
                label: 'Refresh',
                element: 'button',
                icon: 'refresh',
                className: 'secondary-button',
                action: 'download_list',
                iconsClassName: 'size-4',
              },
              {
                name: 'btn_download',
                label: 'Download',
                element: 'button',
                icon: 'download',
                visible: false,
                className: 'secondary-button',
                action: 'download_list',
                iconsClassName: 'size-4',
              },
              {
                name: "btn_add",
                label: "Add",
                element: "button",
                icon: "add",
                visible: false,
                className: "add-button hover:shadow-lg ",
                iconsClassName: "size-4",
              },
            ]
          },
          
        ],
        className: 'flex justify-between items-center gap-2 bg-white p-4 rounded-none rounded-t-lg mt-2',
      },
      {
        className: 'bg-white p-4 rounded-none rounded-b-lg pt-0',
        name: "leads_table",
        element: 'table-element',
        loading: true,
        variant: 'plain',
        count:0,
        size: 'small',
        pagination: true, 
        value:[{name:"pagination",page,pageSize},{name:"filters",value:{status:filters.status,source:filters.source}}],
        rowClick:true,
        view: true,
        visible: true,
        thead: [
          { name: 'name', label: 'Customer Name', key: 'name',
            fixed: "left",
            render: (e:any) =>{      
              const name = e?.trim();         
              // if(e){
                  return (
                    <div className="flex gap-1">
                        <Avatar size={"small"} style={{backgroundColor:`${Utils.stringToColour(name || "Unknown")}`}}> 
                            <p className={`capitalize`}>{Utils.initials(name || "Unknown")}</p>
                        </Avatar> 
                        {<p className={`capitalize`}>{name || "Unknown"}</p>}
                    </div>
            
                  )
              // }
            }
          },
          {
            name: 'phone_number',
            label: 'Phone Number',
            key: 'phone_number',
          },
          {
            name: 'createdAt',
            label: 'Date & Time',
            key: 'createdAt',
          },
          {
            name: "branch",
            label: "Branch",
            key: "branch",
            render: (e: any) => <p>{e || "--"}</p>
          },
          // {name: 'type', label: 'Type', key: 'type', render: (e:any) => <p className={`capitalize`}>{e}</p>},
          { name: 'source', label: 'Source', key: 'source',

            filters:[
              { text: 'Chatbot', value: 'Chatbot' },
              // { text: 'Phone', value: 'Phone' },
              { text: 'Website', value: 'Website' },
              { text: 'Whatsapp', value: 'Whatsapp' },
              { text: 'Facebook', value: 'Facebook' },
              { text: 'Instagram', value: 'Instagram' },
              { text: 'Operator', value: 'Operator' },], 
              render: (e:any, record:any) => {
                const bgColor:string = LeadSourceStyles[record?.source] || "bg-[#F2F2F2]/50 text-default-300"
              return <span className={`w-full p-1 px-2 rounded-md font-medium ${bgColor}`}>{e}</span>
              }},
          { name: 'status', label: 'Status', key: 'status',
            // filters:[
            //   { text: 'New', value: 'New' },
            //   { text: 'Open', value: 'Open' },
            //   { text: 'In-Progress', value: 'In_Progress' },
            //   { text: 'Qualified', value: 'Qualified' },
            //   { text: 'Callback', value: 'Callback' },
            //   { text: 'Closed', value: 'Closed' },
            //   { text: 'Converted', value: 'Converted' },
            //   { text: 'Discarded', value: 'Discarded' },],
            render:(e:any,record:any)=>{
            
            const bgColor:string = LeadStatusStyles[record?.status] || "bg-[#F2F2F2]/50 text-default-300"
            return <span className={`w-full p-1 px-2 rounded-md font-medium ${bgColor}`}>{e}</span>
          }  },
          {
            name: 'reason',
            label: 'Reason',
            key: 'reason',
            render:(e:any,record:any)=>{
              if(record?.source.toLowerCase()!=="operator"){
                return(
                  <Popover content={e}  overlayStyle={{width:'350px' }}><div>{e?.length > 10 ? `${e.slice(0, 50)}...` : e || '--'} </div></Popover>
                )
              } else{
                return(<div>{ e || '--'} </div>)
              }
              
            }
          },
          { name: 'commentsCount', label: "#Comments", key: 'commentsCount', render: (e:any) => <p className='text-center'>{e||0}</p> }
          
        ],
        tbody: [],
        modalContent: {
          className: "flex flex-col gap-2 overflow-auto max-h-[400px] p-[10px]",
          fields: [],
        },
      },
      {
        name:'appointment-booking-modal',
        element:'modal',
        visible:false,
        containerClassName:'!w-[96%]',
        className:"!w-full",
        fields: [
          {
              name: 'modal-title',
              className:
                  'w-full bg-primary/5 p-2 pl-4 flex items-center rounded-t-lg justify-between',
              fields: [
                  {
                      name: 'modal-title-key',
                      element: 'div',
                      label: 'Book Appointment',
                      className:
                          'font-semibold text-lg text-primary',
                  },
                  {
                      name: 'appointment-booking-modal-close',
                      element: 'button',
                      label: 'X',
                      className:
                          'w-[40px] h-[40px] flex justify-center items-center rounded-full bg-primary text-xl text-white',
                  },
              ],
          },
          {
              name: 'modal-body',
              className: 'flex flex-col max-h-[500px] p-4 overflow-auto gap-1',
              fields: [
                  
              ],
          },
        ],
      },
      {
        name: "outbound_calls_modal",
        containerClassName:"!w-[20%] z-[1002] fixed mt-[30px] right-5",
        style:{ zIndex: 1001 },
        className:
            'rounded-lg flex flex-col items-center pb-4',
        visible:false,
        mask: false, 
        maskClosable: false,
        draggable:true,
        element:"modal",
        fields:[
          {
            name:"end_button",
            className:"self-end cursor-pointer rounded-full mt-3 mr-3 ",
            element:"div",
            label:<Popconfirm 
            title="Are you sure you want to close the modal?" 
            onConfirm={()=> handleSelectedRecord({name: 'close_call_modal'})}
            okButtonProps={{
              style: { backgroundColor: 'gray' }
            }}
            okText="Close"
            icon={<ExclamationCircleOutlined style={{ color: 'red' }} />}
            >
            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18 6L6 18" stroke="#000000" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M6 6L18 18" stroke="#000000" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>


          </Popconfirm>
          },
          {
            name:"avatar",
            className:"mt-2",
            label:<Avatar size={70} style={{backgroundColor:`${Utils.stringToColour("")}`,fontSize: '30px'}}> 
                          <p className={`capitalize`}>{Utils.initials("")}</p>
                  </Avatar>,
            element:"div"
          },
          {
            name:"name",
            label:"",
            className:"text-lg mt-2 font-semibold",
            element:"div"
          },
          {
            name:"mobile_number",
            label:"",
            className:"text-md font-semibold text-gray-4",
            element:"div"
          },
          {
            name:"status",
            label:"",
            className:"text-sm font-light mt-2",
            element:"div"
          },
          {
            name:"red_and_green_buttons",
            className:"flex  items-center gap-2 mt-1",
            fields:[
              {
                name:"green_button",
                className:"rounded-full bg-gray-400 p-4 cursor-pointer",
                element:"button",
                label:<svg width="22" height="22" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M0 2.57143C0 1.15127 1.15127 0 2.57143 0H3.74711C4.48457 0 5.1274 0.501906 5.30627 1.21735L6.25409 5.00865C6.41092 5.63597 6.17652 6.29618 5.65922 6.68416L4.5504 7.51577C4.43526 7.60213 4.41023 7.72858 4.44257 7.81687C5.41587 10.4738 7.52615 12.5841 10.1831 13.5574C10.2714 13.5898 10.3979 13.5647 10.4842 13.4496L11.3158 12.3408C11.7038 11.8235 12.364 11.5891 12.9913 11.7459L16.7826 12.6937C17.4981 12.8726 18 13.5154 18 14.2529V15.4286C18 16.8487 16.8487 18 15.4286 18H13.5C6.04416 18 0 11.9558 0 4.5V2.57143Z" fill="#000000"/>
                </svg>,
                loading:true,
                visible:false,
              },
              {
                name:"end_button",
                className:"bg-gray-400 p-4  cursor-pointer rounded-full ",
                element:"div",
                visible:false,
                label:<Popconfirm 
                title="Are you sure you want to close the modal?" 
                onConfirm={()=> handleSelectedRecord({name: 'close_call_modal'})}
                okButtonProps={{
                  style: { backgroundColor: 'gray' }
                }}
                okText="Close"
                icon={<ExclamationCircleOutlined style={{ color: 'red' }} />}
                >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18 6L6 18" stroke="#ffffff" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M6 6L18 18" stroke="#ffffff" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>


              </Popconfirm>
              }
            ]
          }
        ]
      }
    ],
  });

  
  const location=useLocation();
  const dispatch=useDispatch();
  const navigate=useNavigate()
  // const [pagination,setPagination]=useState({pagenum:1,limit:10});

  const org_id = Storage.getItem("details")?.["org_id"];

  const pagePermissions = useSelector(getPagePermissions)
  const ws = useRef<WebSocket | null>(null);
    const heartbeatInterval = useRef<NodeJS.Timeout | null>(null);
    const reconnectInterval = useRef<NodeJS.Timeout | null>(null);
    const [callsData, setCallsData] = useState([]);
    const serverUrl = CONSTANTS.SOCKET_URL;

  useEffect(() => {
    if (location.state?.source === "Leads") {
      dispatch(setTopbar(location.state?.source))
      dispatch(setActiveMenu("/leads"))
    }
  }, [location.state?.source, dispatch]);


  useEffect(() => {

    const id = setTimeout(() => {
      API_GET_Leads({ startDate: dateRange[0], endDate: dateRange[1], searctext: searchText, source:filters.source,status:filters.status });
    }, 500);

    return () => {
      clearTimeout(id);
    }
  }, [searchText])

  // useEffect(()=>{
  //   if(leadId){
  //     API_GET_GetAllComments()
  //   }
  // },[leadId])

  useEffect(()=>{

    if(dateRange[0]==="" && dateRange[1]===""){
        change_segments_value(null)
    }
    const first = moment(dateRange[0], 'DD-MM-YYYY');
    const second = moment(dateRange[1], 'DD-MM-YYYY');
    const diffInDays = second.diff(first, 'days');

    if (diffInDays === 0) {
        change_segments_value("TD")

    } else if (diffInDays === 6) {
        change_segments_value("TW")
    } else if (diffInDays === 29) {
        change_segments_value("TM")
    }else{
        change_segments_value(null)
    }

    setSchema((prevSchema: any) => {
      const updatedSchema = Utils.updateSchemaRecursively(prevSchema, (field: any) => {
       if(field.name==="date"){
        return {...field,value:dateRange}
       }
       if(field.name==="left_button"){
        return {...field,loading:!dateRange[0]}
       }
       if(field.name === "right_button") {
        const isStartDateMissing = dateRange[0].length===0;
        const isEndDateToday = dateRange[1] === moment().format("DD-MM-YYYY");
    
        return {
            ...field,
            loading: isStartDateMissing || isEndDateToday
        };
    }
    
        return field;
      });
    
      return updatedSchema;
    });
},[dateRange,dispatch])

  const change_segments_value=(val:string | null)=>{
    setSchema((prev:any)=>{
        const updatedSchema=prev.schema.map((field:any)=>{
            if(field.name==="filters"){
              field.fields=field.fields.map((ffield:any)=>{
                if(ffield.name==="date_and_search"){
                    ffield.fields=ffield.fields.map((fffield:any)=>{
                        if(fffield.name==="segments"){
                            fffield.value=val;
                        }
                        return fffield;
                    })
                }
                return ffield;
              })
            }
            return field;
        })
        return {...prev,schema:updatedSchema}
    })
  }

  

  const API_GET_Leads = async ({
    page = pagination.page,
    limit = pageSize,
    startDate = dateRange[0],
    endDate = dateRange[1],
    searctext = searchText,
    type = leadsType,
    source=[],
    status=[]
  }:any = {}) => {
    // setSchema((prev: any) => Utils.handleLoading(prev, true));
    dispatch(setLoading(true))
    try {
      
      // let branches = await Utils.makeApiCall("/getUserBranches", "GET")

      // branches = branches?.data || [

      const branchData: any = {}

      branches.map((b: any)=>{
        branchData[b.value] = b.label
      })


      const sourceString = source&&source ? source.join(",") : "";
      const statusString = status&&status.length ? status.join(",") : "";
      // console.log(statusString);
      
      const res = await Utils.makeApiCall(`/leads?org_id=${org_id}&page=${page}&limit=${limit}&startDate=${startDate}&endDate=${endDate}&search=${searctext}&source=${sourceString}&status=${statusString}`, "GET");
      if (res.status) {
        const add = pagePermissions.find((r:any) => r.route === location.pathname)?.add || false;
        const view = pagePermissions.find((r:any) => r.route === location.pathname)?.view || false;
        const download = pagePermissions.find((r:any) => r.route === location.pathname)?.download || false;
        const totalCounts = res?.totalCounts || 0
        const qualifiedCounts = res?.qualifiedCounts || 0
        const convertedCounts = res?.convertedCounts || 0
        const discardedCounts = res?.discardedCounts || 0

        setSchema((prev: any)=>{
          prev?.schema?.map((p: any)=>{
            if (p.name === 'leads_cards_container'){
              p?.fields?.map((pp: any)=>{
                if(pp.name === "total_leads_card"){
                  pp?.fields?.map((k: any)=>{
                    if(k.name === "total_leads_value"){
                      k.label = totalCounts
                    }
                  })
                }

                if(pp.name === "qualified_leads_card"){
                  pp?.fields?.map((k: any)=>{
                    if(k.name === "qualified_leads_value"){
                      k.label = qualifiedCounts
                    }
                  })
                }

                if(pp.name === "converted_leads_card"){
                  pp?.fields?.map((k: any)=>{
                    if(k.name === "converted_leads_value"){
                      k.label = convertedCounts
                    }
                  })
                }

                if(pp.name === "discarded_leads_card"){
                  pp?.fields?.map((k: any)=>{
                    if(k.name === "discarded_leads_value"){
                      k.label = discardedCounts
                    }
                  })
                }
              })
            }
          })
          return {...prev}
        })

        setSchema((prev: any) => {
          const ps = prev.schema;
          ps.map((s: any) => {
            if (s.element === "table-element") {
              s.count = res.total;
              s["tbody"] = res.data.map((acc: any) => {
                const branch = branchData[acc['branch_id']]
                const createdAt = acc["createdAt"]
                  ? moment(acc["createdAt"]).format("DD-MM-YYYY HH:mm")
                  : "";
                return { ...acc, createdAt, branch };
              });
              const hasactions = s["thead"].some((ef:any)=>ef.name === "actions");
              if ((view || add) && !hasactions){
                s['thead'].push({
                  name: 'actions',
                  label: '',
                  key: 'actions',
                  sortable:false,
                  fixed: "right",
                  render: (e: any, record: any) => {
                    return (
                      <div className='flex gap-4 justify-end items-center self-end'>
                          {/* {view && <div
                            onKeyDown={() =>
                              handleSelectedRecord({ name: "view", value: record })
                            }
                            tabIndex={0}
                            role="button"
                            onClick={() =>
                              handleSelectedRecord({ name: "view", value: record })
                            }
                          >
                            <Tooltip title="View">
                            <img
                              src={
                                `${BASE_URL}/api/public/assets/images/eye.svg`
                              }
                              alt="View the record"
                            />
                            </Tooltip>
                          </div>} */}
                          {record?.phone_number && <div
                            role="button"
                            tabIndex={0}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleSelectedRecord({ name: "call", value: record });
                            }}
                            onKeyDown={(e) => {
                              if (e.key === "Enter" || e.key === " ") {
                                e.stopPropagation();
                                handleSelectedRecord({ name: "call", value: record });
                              }
                            }}
                          >
                            <Tooltip title={`Call ${record?.name || "Unknown"}`}>
                              <img
                                src={`${BASE_URL}/api/public/assets/images/call.svg`}
                                alt="call the record"
                                className="w-[20px] h-[15px] mt-1 cursor-pointer"
                              />
                            </Tooltip>
                          </div>}
                          {/* {add && <div
                            className='w-[20px] h-[20px] p-1 cursor-pointer  flex items-center justify-center bg-white rounded-md'
                            role='presentation'
                            onClick={ (e) => {
                              e.stopPropagation(); 
                            }
                            }
                          >
                            <Tooltip title="Quick actions">
                              <Popover
                                content={
                                  <div className='flex flex-col gap-2'>
                                    {[
                                      {"name": "comments", "label": `${"  "}Add a Comment`, icon:"comments.svg"}, 
                                      {name: "book_appointment", label: "Book an appointment", icon: "appointments.svg"}
                                    ].map((item:any)=>{
                                      if(!record?.phone_number && item?.name=== "book_appointment"){
                                        return null
                                      }
                                      return <button key={item.name} onClick={()=>{handleSelectedRecord({...record, ...item, "customerName" : record?.name})}} className='text-xs font-medium hover:shadow-sm transition-all duration-600 text-left text-gray-900 rounded-md p-1 flex items-center gap-1'>
                                        <img src={`${BASE_URL}/api/public/assets/images/${item.icon}`} alt={item.icon} className='w-[20px] h-[20px]'/>{item.label}
                                      </button>;
                                    })}
                                  </div>
                                }
                                placement="bottomRight"
                                title=""
                                trigger="hover"
                              >
                                <p className='text-2xl font-bold' >+</p>
                              </Popover>
                            </Tooltip>
                          </div>} */}
                      </div>
                    );
                  },
                })
              }
              s["loading"] = false;
            } else if (s.name === "filters") {
              s.fields.map((f:any)=>{
                if (f.name === "refresh_and_download"){
                  f.fields.map((sff:any)=>{
                    if (sff.name === "btn_add"){
                      sff.visible = add
                    } else if (sff.name === "btn_download"){
                      sff.visible = download
                    }
                  })
                }
              })
            }
            return s;
          });
          return { ...prev, schema: ps };
        });
      } else {
        toast.error(res.message ? res.message : FAILURE_MSG, { position: "top-center", autoClose: AUTO_CLOSE });
      }
    } catch (error) {
      toast.error(CATCH_ERROR_MSG, { position: "top-center", autoClose: AUTO_CLOSE });
    }
    dispatch(setLoading(false))
    // setSchema((prev: any) => Utils.handleLoading(prev, false));
  };

  const setupWebSocket = () => {
    ws.current = new WebSocket(serverUrl);

    ws.current.onopen = () => {
      // console.log("Connected to WebSocket server.");
      startHeartbeat();
    };

    ws.current.onmessage = (event) => {
      const message = JSON.parse(event.data);
      // console.log("Received message:", message);
      console.log(message.status)
      // Handle different WebSocket message types
      if (message?.status === "Ongoing") {
        change_outbound_modal(message?.status);
      }
      if (["Missed", "Answered"].includes(message?.status)) {
        close_callback_modal();
      }
    };

    ws.current.onclose = () => {
      console.log("WebSocket connection closed. Attempting to reconnect...");
      stopHeartbeat();
      attemptReconnect();
    };

    ws.current.onerror = (error) => {
      console.error("WebSocket error:", error);
      ws.current?.close();
    };
  };

  // Heartbeat mechanism to keep WebSocket connection alive
  const startHeartbeat = () => {
    heartbeatInterval.current = setInterval(() => {
      if (ws.current?.readyState === WebSocket.OPEN) {
        ws.current.send(JSON.stringify({ type: "ping" }));
        console.log("Sent heartbeat (ping)");
      }
    }, 30000); // Every 30 seconds
  };

  // Stop heartbeat interval
  const stopHeartbeat = () => {
    if (heartbeatInterval.current) {
      clearInterval(heartbeatInterval.current);
      heartbeatInterval.current = null;
    }
  };
  

  // Attempt to reconnect WebSocket if connection is lost
  const attemptReconnect = () => {
    reconnectInterval.current = setTimeout(() => {
      console.log("Reconnecting to WebSocket...");
      setupWebSocket();
    }, 5000); // Reconnect after 5 seconds
  };

  // Clean up WebSocket and intervals on component unmount
  useEffect(() => {
    setupWebSocket();

    return () => {
      ws.current?.close();
      stopHeartbeat();
      if (reconnectInterval.current) {
        clearTimeout(reconnectInterval.current);
      }
    };
  }, []);

  
  const API_GET_LEAD_DATA = async(leadId: any) =>{
    return;
    try{
      const res = await Utils.makeApiCall(
        `/leads/${leadId}`,
        'GET'
      )
      if(res.status && res.data){
        await API_GET_RecentInteractions(res?.data?.phone_number)
        API_GET_Conversations(res?.data?.conversation || "")
        setData(res?.data)
        // }
        
        setSchema((prev:any)=>{
          prev?.schema?.map((p:any)=>{
              if(p.name === "lead_details_modal"){
                p?.fields?.map((sec: any)=>{
                  if(sec?.name === "lead_details_header_data"){
                    sec?.fields?.map((f: any)=>{
                      if(f.name === "lead_details_data_pair"){
                        f?.fields?.map((ff: any)=>{
                          if(ff.name === "lead_name_value"){
                            ff.label = res?.data?.name || "N/A"
                          }
                          if(ff.name === "lead_status_value"){
                            ff.label = res?.data?.status || "N/A"
                          }
                          if(ff.name === "lead_phone_value"){
                            ff.label = res?.data?.phone_number || "N/A"
                            
                          }
                          if(ff.name === "lead_source_value"){
                            ff.label = res?.data?.source || "N/A"
                          }
                          if(ff.name === "lead_arrivedon_value"){
                            const date = res?.data?.createdAt
                            ff.label = date ? moment(date).format("DD-MM-YYYY HH:mm") : "N/A"
                          }
                        })
                      }
                    })
                  }
                  if(sec.name==="lead_details_modal_body" ){
                    sec?.fields.map((field:any)=>{
                      if(field?.name==="lead_details_tabs_container"){
                        field.fields.map((ffield:any)=>{
                          if(ffield.name==="leads_modal_tabs"){
                            const arr:{key:string,label:string}[]=[
                              {
                                key: "comments",
                                label: "Comments",
                              },
                            ];
                            if(res?.data?.source==="Contact form" || res?.data?.source==="Website"){
                                arr.push({
                                  key: "contact_details",
                                  label: "Contact Details",
                                },)
                            }
                            if(res?.data?.source==="Chatbot" || res?.data?.source==="Whatsapp"){
                              arr.push({
                                key: "conversations",
                                label: "Conversations",
                              },)
                          }
                            ffield.options= arr
                            ffield.value= {key: "comments",
                              label: "Comments",}
                          }
                          return ffield;
                        })
                      }
                      return field;
                    })
                  }
                })
              }
  
              // if(sec?.name === "tabs_body"){
              //   const add = pagePermissions.find((r:any) => r.route === "/leads")?.add || false;
              //   sec?.fields?.map((f: any)=>{
              //     if(f?.name === "comments_modal_container"){
              //       f?.fields?.map((ff: any)=>{
              //         if(ff?.name === "modal_comment_container"){
              //           ff.visible = add
              //         }
              //       })
              //     }
              //   })
              // }
          })
  
          return {...prev}
        })
  
      }else{
        toast.error(res.message, {
          position: 'top-center',
          autoClose: 2000,
        })
      }
  
    } catch(error){
      console.error('Failed to fetch schema', error)
    }
  }

  const API_GET_RecentInteractions = async (mobile: string) => {
    dispatch(setLoading(true))
    setSchema((prev: any) => {
        const updateField = (fields: any[]) =>
            fields.map((field: any) => {
                if (field.name === 'recent_interactions_cards') {
                    field.fields = []
                } else if (field.fields) {
                    field.fields = updateField(field.fields)
                }
                return field
            })

        return {
            ...prev,
            schema: prev?.schema?.map((sec: any) => {
                if (sec.fields) {
                    sec.fields = updateField(sec.fields)
                }
                return sec
            }),
        }
    })
    try {
        const res = await Utils.makeApiCall(`/getRecentInteractions?mobile=${mobile}`, 'GET');

        if (!res.status) {
            toast.error(res.message || FAILURE_MSG, {
                position: 'top-center',
                autoClose: 2000,
            });
            return;
        }

        setSchema((prev: any) => {
            const updateField = (fields: any[]) =>
                fields.map((field: any) => {
                    if (field.name === "recent_interactions_cards") {
                        if (res.data?.length){
                            field.fields = res.data.map((item: any) => ({
                                name: `interaction_${item.id}`,
                                className: 'm-1 p-2 flex flex-col gap-[2px] border-[1px] border-gray-200 rounded-md drop-shadow-sm bg-white',
                                fields: [
                                    {
                                        name: "interaction_header",
                                        className: 'flex justify-between items-center',
                                        fields: [
                                            {
                                                name: "interaction_name",
                                                element: "div",
                                                className: 'text-sm font-medium text-black',
                                                label: item.interaction_type,
                                            },
                                            {
                                                name: "interaction_status",
                                                element: "div",
                                                className: `p-1 px-2 text-xs rounded-md text-gray-600`,
                                                label: item.createdAt,
                                            },
                                        ],
                                    },
                                    {
                                        name: "interaction_type",
                                        element: "div",
                                        className: `text-[10px] p-1 px-2 w-fit capitalize font-medium ${item.interaction_type === 'Call' ? colors[item.type] : item.interaction_type === 'Lead' ? 'bg-[#F59F0D]/5 text-[#F59F0D]' : 'bg-[#29AAE1]/5 text-[#29AAE1]'}`,
                                        label: item.type || "--",
                                    },
                                ],
                            }));
                        } else {
                            field.fields = [
                                {
                                    name: 'no_recent_interactions',
                                    element: 'div',
                                    className: 'text-sm text-gray-600 text-center flex justify-center items-center h-full',
                                    label: 'No recent interactions found',
                                },
                            ];
                        }
                    } else if (field.fields) {
                        field.fields = updateField(field.fields);
                    }
                    return field;
                });

            return {
                ...prev,
                schema: prev?.schema?.map((sec: any) => {
                    if (sec.fields) {
                        sec.fields = updateField(sec.fields);
                    }
                    return sec;
                }),
            };
        });
    } catch (error) {
        toast.error(CATCH_ERROR_MSG, { position: 'top-center', autoClose: AUTO_CLOSE });
        console.error('Failed to fetch schema', error);
    } finally {
      dispatch(setLoading(false))
    }
  };
  
  
  const API_GET_GetAllComments = async(leadId: any, org_id: any)=>{
    
    try {
        const res = await Utils.makeApiCall(
            `/leads/getComments?leadId=${leadId}&org_id=${org_id}`,
            'GET'
        )
        if (res.status && res.data.length > 0) {
          setSchema((prev:any)=>{
            prev?.schema?.map((p:any)=>{
              if(p.name === "lead_details_modal"){
                p.fields?.map((pp:any)=>{
                  if(pp.name === "lead_details_modal_body"){
                    pp?.fields?.map((a: any)=>{
                      if(a.name === "lead_details_tabs_container"){
                        a?.fields?.map((ss: any)=>{
                          if (ss?.name === "tabs_body"){
                            ss?.fields?.map((sec: any)=>{
                              if (sec.name === "comments_modal_container"){
                                  sec.fields.map((ff:any)=>{
                                      if(ff.name === "modal_body_container"){
                                          ff.fields = res.data.map((comment:any)=>{
                                              const name = comment?.fullname || '--'
                                              const allInitials = name?.split(' ').map((e:string)=>e[0]).join('').toUpperCase() || '--'
                                              const iconInitials = allInitials.length>=2?allInitials.substring(0,2):allInitials;
                       
                                              return {
                                                  name:comment['_id'],
                                                  className:'flex flex-col',
                                                  fields:[
                                                      {
                                                          name:'modal_content',
                                                          className:'flex w-full p-2 gap-1',
                                                          fields:[
                                                              {
                                                                  name:'modal_icon',
                                                                  className:'basis-[80px] flex justify-center',
                                                                  fields:[
                                                                      {
                                                                          name:'modal_element_icon',
                                                                          className:`bg-primary/10 text-primary rounded-full w-[50px] h-[50px] flex items-center justify-center text-2xl font-bold`,
                                                                          element:'div',
                                                                          label:iconInitials
                                                                      }
                                                                  ]
                                                              },
                                                              {
                                                                  name:'modal_body',
                                                                  className:' w-full flex flex-col',
                                                                  fields:[
                                                                      {
                                                                          name:'modal_element_title',
                                                                          className:'flex flex-row justify-between w-full',
                                                                          fields:[
                                                                              {
                                                                                  name:'modal_element_name',
                                                                                  className:'flex gap-2 items-center justify-center',
                                                                                  fields: [
                                                                                    {
                                                                                      name: 'user_name',
                                                                                      className:'font-bold text-lg',
                                                                                      element:'div',
                                                                                      label: name || '--'
                                                                                    },
                                                                                    {
                                                                                      name: 'time',
                                                                                      className:'text-xs',
                                                                                      element:'div',
                                                                                      label: `${moment(comment?.createdAt).fromNow()}`
                                                                                    },
                                                                                  ]
                                                                              },
                                                                              {
                                                                                  name:'modal_element_group',
                                                                                  className:'flex flex-row gap-3 items-center',
                                                                                  fields:[
                                                                                    {
                                                                                        name:'modal_element_status',
                                                                                        className:`p-1 px-2 text-xs rounded-md  ${LeadStatusStyles[comment.status]}`,
                                                                                        element:'div',
                                                                                        label:comment?.status || '--',
                                                                                        visible: comment?.status?true:false
                                                                                    }
                                                                                  ]
                                                                              }
                                                                          ]
                                                                      },
                                                                      {
                                                                          name:'modal_element_text',
                                                                          element:'div',
                                                                          className:'',
                                                                          label:comment?.text || '--'
                                                                      }
                                                                  ]
                                                              }
                                                              
                                                          ]
                                                      }
                                                  ]
                                                  
                                              }
                                          });
                                      }
                                      return ff;
                                  })
                              }
                              
                            })
                          }
                        })
                      }
                    })
                  }
                })
              }
            })
            return {...prev};
        })
            
        }
      } catch (error) {
      toast.error("Network Error!!", {
          position: 'top-center',
          autoClose: 2000,
      })
        console.error('Failed to fetch schema', error)
    }
  }
  
  const API_GET_Conversations = async (cid: any) => {
    
    // setLoading(true);
  
    if(cid){
      try {
        const res = await Utils.makeApiCall(
          `/conversation?id=${cid}&org_id=${org_id}`,
          'GET'
        )
    
        if (res.status) {
          const messages = res.data?.[0]?.messages || [];
    
          setSchema((prev:any)=>{
            prev?.schema?.map((p:any)=>{
              if(p.name === "lead_details_modal"){
                p.fields?.map((pp:any)=>{
                  if(pp.name === "lead_details_modal_body"){
                    pp?.fields?.map((a: any)=>{
                      if(a.name === "lead_details_tabs_container"){
                        a?.fields?.map((ss: any)=>{
                          if (ss?.name === "tabs_body"){
                            ss?.fields?.map((ff: any)=>{
                              if(ff?.name === "conversations_body"){
                                ff.fields = messages
                              }
                            })
                          }
                        })
                      }
                    })
                  }
                })
              }
            })
            return {...prev};
        })
          
        } else {
          toast.error(res.message, {
            position: 'top-center',
            autoClose: 2000,
          })
        }
      } catch (error) {
        console.error('Failed to fetch schema', error)
      }
    }
    // dispatch(setLoading(false))
  }

  const appointmentBookingModal = (mobile:string, name:string)=>{
    console.log('here')
    return {
            element:'div',
            label:<div><AddAppointmentComponent mobile={mobile} name={name}/></div>
          }
  }
  
    
  const API_POST_AddComent = async()=>{
    
    if(!commentInput){
      toast.error("Please add a Comment to change the status", {
        position: 'top-center',
        autoClose: 2000,
      })
      return;
    }
    dispatch(setLoading(true))
  
    const payload = {
        text:commentInput,
        userId:Storage.getItem('details')['_id'],
        leadId:leadId,
        org_id,
        status:commentStatus['value']
    }
  
  
    try {
        const res = await Utils.makeApiCall(
            `/leads/addComment`,
            'POST',
            payload
        )
  
        if (res.status) {
              toast.success("Comment added!!", {
                position: 'top-center',
                autoClose: 2000,
            })
            API_GET_LEAD_DATA(leadId)
            API_GET_GetAllComments(leadId, org_id);
            resetForm();
        } else {
            toast.error(res.message, {
                position: 'top-center',
                autoClose: 2000,
            })
        }
    } catch (error) {
        console.error('Failed to fetch schema', error)
    }
    dispatch(setLoading(false))
  }

  const change_outbound_modal=(status:any)=>{
    setSchema((prevSchema: any) => {
      const updatedSchema = Utils.updateSchemaRecursively(prevSchema, (field: any) => {
        if(field.name==="status"){
          field.label=status
        }
        return field;
      });
    
      return updatedSchema;
    });
  }


  const refresh_leads = async () => {
    dispatch(setLoading(true))
    try{
      const res = await Utils.makeApiCall("/leads", "POST", {});
      if (res.status) {
        dispatch(setLoading(false))
        API_GET_Leads({
          page: page,
          limit: pageSize,
          startDate: dateRange[0],
          endDate: dateRange[1],
          source:filters.source,
          status:filters.status
        });
        toast.success(res.message, { position: "top-center", autoClose: AUTO_CLOSE });
      } else {
        toast.error(res.message ? res.message : FAILURE_MSG, { position: "top-center", autoClose: AUTO_CLOSE });
      }
    }catch (error) {
      toast.error(CATCH_ERROR_MSG, { position: "top-center", autoClose: AUTO_CLOSE });
    }
    finally{
      dispatch(setLoading(false))
    }
  };

  const downloadToExcel = async () => {
    try {
      const tableSchema = schema.schema.find((s: any) => s.element === "table-element");
      const { thead } = tableSchema;
      dispatch(setLoading(true))

      const response = await Utils.makeApiCall(`/leads?org_id=${org_id}&page=1&limit=10&startDate=${dateRange[0]}&endDate=${dateRange[1]}&download=true&search=${searchText}`, "GET");
      const data = response.data;
      dispatch(setLoading(false))

      if (!data || data.length === 0) {
        toast.error("No data available to download", { position: "top-center", autoClose: AUTO_CLOSE });
        return;
      }

      Utils.exportToExcel(thead, data, "leads");
    } catch (error) {
      toast.error("Failed to download data as Excel", { position: "top-center", autoClose: AUTO_CLOSE });
    }
  };


  const handleLeadsModal = (e: any, visible: boolean) =>{
    // console.log(e);
    dispatch(setLoading(true))
    setSchema((prev:any)=>{
      prev?.schema?.map(async (f: any)=>{
        if(f.name === "lead_details_modal"){
          if(visible){
            await API_GET_LEAD_DATA(e._id)
            API_GET_GetAllComments(e._id, e.organization)
            f.visible = visible
          }else{
            f.visible = visible
          }
        }
      })
      return {...prev}
    })
    dispatch(setLoading(false))
  }


  const API_MAKE_Voice_call=async (record:any)=>{

    const body:any={  
        From: "",
        To: record?.phone_number,
        CallerId: "",
        callId: "",
        org_id,
        callMadeFrom:"leads"
    }
    dispatch(setLoading(true))
    try {
      // change_outbound_modal("Calling...",record);
        const res: any = await Utils.makeApiCall(`/make-voice-call`, "POST",body);
        if (res?.status) {
          dispatch(setLoading(false))
          const name=record?.Name || "Unknown"
          setSchema((prevSchema: any) => {
            const updatedSchema = Utils.updateSchemaRecursively(prevSchema, (field: any) => {
              if(field.name==="outbound_calls_modal"){
                field.visible=true
              }
              if(field.name==="name"){
                field.label=name
              }
              if(field.name==="mobile_number"){
                field.label=record?.phone_number
              }
              if(field.name==="status"){
                field.label=res?.message
              }
              if(field.name==="avatar"){
                field.label=<Avatar size={70} style={{backgroundColor:`${Utils.stringToColour(name)}`,fontSize: '30px'}}> 
                              <p className={`capitalize`}>{Utils.initials(name)}</p>
                            </Avatar>
              }
              return field;
            });
          
            return updatedSchema;
          });

        } else {
            toast.error(res.message ? res.message : FAILURE_MSG, { position: 'top-center', autoClose: AUTO_CLOSE })
        }
    } catch (error) {
        toast.error(CATCH_ERROR_MSG, { position: 'top-center', autoClose: AUTO_CLOSE });
        console.error('Failed to fetch schema', error);
    }
    dispatch(setLoading(false))
  }
  const close_callback_modal=()=>{
    setSchema((prevSchema: any) => {
      const updatedSchema = Utils.updateSchemaRecursively(prevSchema, (field: any) => {
        if(field.name==="outbound_calls_modal"){
          field.visible=false
        }
        // if(field.name==="green_button"){
        //   field.loading=false,
        //   field.className="bg-green-400 p-4 rounded-full cursor-pointer"
        // }
        return field;
      });
    
      return updatedSchema;
    });
  }

  const handleSelectedRecord = async (e: any) => {
    // console.log(e.value)

    if(e.name==="call"){
      setSchema((prev:any)=>{
        prev.schema.map((item:any)=>{
          if(item.name==="outbound_calls_modal"){
            if(item.visible){
              toast.warning("Cannot make call while in another call",{position:"top-center",autoClose:AUTO_CLOSE})
            } else{
              API_MAKE_Voice_call(e?.value);
            }
          }
          return item;
        })
        return prev;
      })
    }
    if (e.name === "left_button") {
      const today = moment().format('DD-MM-YYYY');

      let dateDifference = moment(dateRange[1], 'DD-MM-YYYY').diff(moment(dateRange[0], 'DD-MM-YYYY'), 'days');
    
      dateDifference = dateDifference === 0 ? 1 : dateDifference;
  
      if (dateDifference === 1) {
          const newStartDate = moment(dateRange[0], 'DD-MM-YYYY').subtract(1, 'days').format('DD-MM-YYYY');
          const newEndDate = moment(dateRange[1], 'DD-MM-YYYY').subtract(1, 'days').format('DD-MM-YYYY');
       
          // const validatedStartDate = moment(newStartDate, 'DD-MM-YYYY').isAfter(today) ? today : newStartDate;
          // const validatedEndDate = moment(newEndDate, 'DD-MM-YYYY').isAfter(today) ? today : newEndDate;
  
          dispatch(setDateRange([newStartDate, newEndDate]));
  
          API_GET_Leads({
              startDate: newStartDate,
              endDate: newEndDate,
              searctext: searchText,
              source: filters.source,
              status: filters.status,
          });
      } else {
    
          const newEndDate = moment(dateRange[0], 'DD-MM-YYYY').subtract(1, 'days').format('DD-MM-YYYY');
          

          const newStartDate = moment(newEndDate, 'DD-MM-YYYY').subtract(dateDifference, 'days').format('DD-MM-YYYY');

          // const validatedStartDate = moment(newStartDate, 'DD-MM-YYYY').isAfter(today) ? today : newStartDate;
          // const validatedEndDate = moment(newEndDate, 'DD-MM-YYYY').isAfter(today) ? today : newEndDate;
      
          dispatch(setDateRange([newStartDate, newEndDate]));

          API_GET_Leads({
              startDate: newStartDate,
              endDate: newEndDate,
              searctext: searchText,
              source: filters.source,
              status: filters.status,
          });
      }
    }
    if (e.name === "right_button") {
        const today = moment().format('DD-MM-YYYY');
        
        let dateDifference = moment(dateRange[1], 'DD-MM-YYYY').diff(moment(dateRange[0], 'DD-MM-YYYY'), 'days');
        
        dateDifference = dateDifference === 0 ? 1 : dateDifference;
   
        if (dateDifference === 1) {
            const newStartDate = moment(dateRange[1], 'DD-MM-YYYY').add(1, 'days').format('DD-MM-YYYY');
            const newEndDate = moment(dateRange[1], 'DD-MM-YYYY').add(1, 'days').format('DD-MM-YYYY');
            
            const validatedStartDate = moment(newStartDate, 'DD-MM-YYYY').isAfter(moment(today, 'DD-MM-YYYY')) ? today : newStartDate;
            const validatedEndDate = moment(newEndDate, 'DD-MM-YYYY').isAfter(moment(today, 'DD-MM-YYYY')) ? today : newEndDate;
    
            dispatch(setDateRange([validatedStartDate, validatedEndDate]));

            API_GET_Leads({
                startDate: validatedStartDate,
                endDate: validatedEndDate,
                searctext: searchText,
                source: filters.source,
                status: filters.status,
            });
        } else {

            const newStartDate = moment(dateRange[1], 'DD-MM-YYYY').add(1, 'days').format('DD-MM-YYYY');

            const newEndDate = moment(newStartDate, 'DD-MM-YYYY').add(dateDifference, 'days').format('DD-MM-YYYY');
        
            const validatedStartDate = moment(newStartDate, 'DD-MM-YYYY').isAfter(moment(today, 'DD-MM-YYYY')) ? today : newStartDate;
            const validatedEndDate = moment(newEndDate, 'DD-MM-YYYY').isAfter(moment(today, 'DD-MM-YYYY')) ? today : newEndDate;
        
            dispatch(setDateRange([validatedStartDate, validatedEndDate]));
        
            API_GET_Leads({
                startDate: validatedStartDate,
                endDate: validatedEndDate,
                searctext: searchText,
                source: filters.source,
                status: filters.status,
            });
        }
    }
    if (e.name === "btn_download") {
      downloadToExcel();
    }
    if(e.name==="btn_add"){
      navigate("/leads/add")
    }
    if (e.name === "search") {
      dispatch(setSearchText(e.value))  ;
      setSchema((prevState: any) => {
        const updatedSchema = prevState.schema.map((s: any) => {
          if (s.element === "table-element") {
            dispatch(setPagination({ name: 'pagination', page: 1, pageSize }))
            s.value = [{ name: 'pagination', page: 1, pageSize }];
          }
          return s;
        });
        return { ...prevState, schema: updatedSchema };
      });
    }
    if (e.name === "date") {
      if (e.value === "") {

        dispatch(setDateRange(["", ""]));

        setSchema((prevState: any) => {
          const updatedSchema = prevState.schema.map((s: any) => {
            if (s.element === "table-element") {
              dispatch(setPagination({ name: 'pagination', page:1, pageSize }))
              s.value = [{ name: 'pagination', page:1, pageSize }];
            }
            return s;
          });
          return { ...prevState, schema: updatedSchema };
        });
        API_GET_Leads({ startDate: "", endDate: "" ,source:filters.source,status:filters.status});
      } else {
        setSchema((prevState: any) => {
          const updatedSchema = prevState.schema.map((s: any) => {
            if (s.element === "table-element") {
              dispatch(setPagination({ name: 'pagination', page: 1, pageSize }))
              s.value = [{ name: 'pagination', page:1, pageSize }];
            }
            return s;
          });
          return { ...prevState, schema: updatedSchema };
        });
        dispatch(setDateRange(e.value));
        API_GET_Leads({ startDate: e.value[0], endDate: e.value[1] ,source:filters.source,status:filters.status});
      }
    }
    if(e?.name==="close_call_modal"){
      close_callback_modal();
    }
    if (e.name == "btn_refresh") {
      refresh_leads();
    }

    if (e.name === "segments") {
      if (e.value === "TD") {
          dispatch(setDateRange([
              moment().format('DD-MM-YYYY'),
              moment().format('DD-MM-YYYY'),
          ]));
          API_GET_Leads({
              startDate: moment().format('DD-MM-YYYY'),
              endDate: moment().format('DD-MM-YYYY'),
              searctext: searchText,
              source:filters.source,status:filters.status
          })
          setSchema((prev: any) => {
              const updatedSchema = prev.schema.map((field: any) => {
                  if (field.name === "filters") {
                      field.fields = field.fields.map((ffield: any) => {
                          if (ffield.name === "date_and_search") {
                              ffield.fields = ffield.fields.map((ff: any) => {
                                  if (ff.name === "date") {
                                      ff.value = [
                                          moment().format('DD-MM-YYYY'),
                                          moment().format('DD-MM-YYYY'),
                                      ];
                                  }
                                  return ff;
                              });
                          }
                          return ffield;
                      });
                  }
                  if(field.element==="table-element"){
                    dispatch(setPagination({ name: 'pagination', page:1, pageSize }))
                    field.value = [{ name: 'pagination', page:1, pageSize }];
                }
                  return field;
              });
              return { ...prev, schema: updatedSchema };
          });
      }
      if (e.value === "TM") {
          dispatch(setDateRange([
              moment().subtract(29, 'days').format('DD-MM-YYYY'),
              moment().format('DD-MM-YYYY'),
          ]));
          API_GET_Leads({
              startDate: moment().subtract(29, 'days').format('DD-MM-YYYY'),
              endDate:moment().format('DD-MM-YYYY'),
              searctext: searchText,
              source:filters.source,status:filters.status
          })
          setSchema((prev: any) => {
              const updatedSchema = prev.schema.map((field: any) => {
                  if (field.name === "filters") {
                      field.fields = field.fields.map((ffield: any) => {
                          if (ffield.name === "date_and_search") {
                              ffield.fields = ffield.fields.map((ff: any) => {
                                  if (ff.name === "date") {
                                      ff.value = [
                                          moment().subtract(29, 'days').format('DD-MM-YYYY'),
                                          moment().format('DD-MM-YYYY'),
                                      ];
                                  }
                                  return ff;
                              });
                          }
                          return ffield;
                      });
                  }
                  if(field.element==="table-element"){
                    dispatch(setPagination({ name: 'pagination', page:1, pageSize }))
                    field.value = [{ name: 'pagination', page:1, pageSize }];
                }
                  return field;
              });
              return { ...prev, schema: updatedSchema };
          });
      }
      if (e.value === "TW") {
          dispatch(setDateRange([
              moment().subtract(6, 'days').format('DD-MM-YYYY'),
              moment().format('DD-MM-YYYY'),
          ]));
          API_GET_Leads({
              startDate: moment().subtract(6, 'days').format('DD-MM-YYYY'),
              endDate: moment().format('DD-MM-YYYY'),
              searctext: searchText,
              source:filters.source,status:filters.status
          })
          setSchema((prev: any) => {
              const updatedSchema = prev.schema.map((field: any) => {
                  if (field.name === "filters") {
                      field.fields = field.fields.map((ffield: any) => {
                          if (ffield.name === "date_and_search") {
                              ffield.fields = ffield.fields.map((ff: any) => {
                                  if (ff.name === "date") {
                                      ff.value = [
                                          moment().subtract(6, 'days').format('DD-MM-YYYY'),
                                          moment().format('DD-MM-YYYY'),
                                      ];
                                  }
                                  return ff;
                              });
                          }
                          return ffield;
                      });
                  }
                  if(field.element==="table-element"){
                    dispatch(setPagination({ name: 'pagination', page:1, pageSize }))
                    field.value = [{ name: 'pagination', page:1, pageSize }];
                }
                  return field;
              });
              return { ...prev, schema: updatedSchema };
          });
      }
    }

    if (e.name === "leads_table") {
      const item=e.value[0]
          if (item&&item.name === "pagination" &&(pagination.page !== item.page || pagination.pageSize !== item.pageSize)) 
          {
            dispatch(setPagination(item));
            API_GET_Leads({
              page: item.page,
              limit: item.pageSize,
              startDate: dateRange[0],
              endDate: dateRange[1],
              searctext: searchText,
              source: filters.source,
              status: filters.status,
            });
          }
          else if (item&&item.name === "filters" && (filters.status !== item.value.status || filters.source !== item.value.source)) 
          {
            const { status, source } = item.value;
            console.log("filters")
            dispatch(setFilters({ status, source }));
            API_GET_Leads({
              startDate: dateRange[0],
              endDate: dateRange[1],
              searctext: searchText,
              source: source,
              status: status,
              type:"all",
              page:1,
            });
            setSchema((prevState: any) => {
              const updatedSchema = prevState.schema.map((s: any) => {
                if (s.element === "table-element") {
                  s.value = [{ name: 'pagination', page: 1, pageSize }];
                }
                // if(s.name === "leads_cards_container"){
                //   s?.fields?.map((ff: any)=>{
                //     ff.className ="border-[1px] cursor-pointer rounded-md cursor-default flex border-[#93B8E2] gap-2 items-center justify-between   p-[10px]";
                //   })
                // }
                return s;
              });
              return { ...prevState, schema: updatedSchema };
            });
          
      }
      if (e?.["value"]?.name === "view") {
        const id = e["value"]?.["value"]?.["_id"] || e["value"]?.["_id"];
        const value = e["value"]?.["value"]?.["_id"] ? e["value"]?.["value"] : e["value"]
        dispatch(setSelectedRow(value));
        // handleLeadsModal(value, true)
        // setLeadId(id)
        // dispatch(setLoading(true));
        // API_GET_GetAllComments(id, org_id)
        // dispatch(setLoading(false));
      }
    }
    if (e.name === "view") {
      // navigate(`/leads/view/${e?.value?._id}`)
      dispatch(setSelectedRow(e?.value));
      // handleLeadsModal(e.value, true)
      // setLeadId(e?.value?._id)
      // dispatch(setLoading(true))
      // API_GET_GetAllComments(leadId, org_id)
      // dispatch(setLoading(false))

    }

    if(e.name === "leads_modal_tabs"){

      setSchema((prev:any)=>{
        prev?.schema?.map((f: any)=>{
          if(f.name === "lead_details_modal"){
            f?.fields?.map((ff: any)=>{
              if(ff.name === "lead_details_modal_body"){
                ff?.fields?.map((s: any)=>{
                  if(s.name === "lead_details_tabs_container"){
                    s?.fields?.map((ss: any)=>{
                      if(ss.name === "tabs_body"){
                        if(e?.value?.key === "comments"){
                          ss.fields = [
                            {...commentsSchema()}
                          ]
                        }else if(e?.value?.key === "conversations"){
                          ss.fields = [
                            {...conversationSchema()}
                          ]
                        }else if(e?.value?.key === "contact_details"){
                          ss.fields = [
                            {...messageSchema(),fields:[
                              {
                                  name:`name`,
                                  className:"flex items-center w-full gap-2 ",
                                  fields:[
                                    {
                                      name:`name_label`,
                                      className:"font-medium text-lg w-[30%] text-right text-gray-4 capitalize",
                                      element:"div",
                                      label:`Name: `
                                    },
                                    {
                                      name:`item_value`,
                                      className:"font-semibold text-lg w-[50%] text-left text-black",
                                      element:"div",
                                      label:data?.name || "--"
                                    }
                                  ]
                              } ,
                              {
                                  name:`email`,
                                  className:"flex items-center w-full gap-2 ",
                                  fields:[
                                    {
                                      name:`email_name`,
                                      className:"font-medium w-[30%] text-right text-lg text-gray-4 capitalize",
                                      element:"div",
                                      label:`Email: `
                                    },
                                    {
                                      name:`item_value`,
                                      className:"font-semibold text-lg w-[50%] text-left text-black",
                                      element:"div",
                                      label:data?.email || "--"
                                    }
                                  ]
                              } ,
                              {
                                  name:`mobile`,
                                  className:"flex items-center w-full gap-2 ",
                                  fields:[
                                    {
                                      name:`mobile_label`,
                                      className:"font-medium w-[30%] text-right text-lg text-gray-4 capitalize",
                                      element:"div",
                                      label:`Mobile: `
                                    },
                                    {
                                      name:`mobile_value`,
                                      className:"font-semibold text-lg w-[50%] text-left text-black",
                                      element:"div",
                                      label:data?.phone_number || "--"
                                    }
                                  ]
                                } ,
                                {
                                  name:`index`,
                                        className:"flex items-center w-full gap-2 ",
                                        visible: data?.message ? true:false,
                                        fields:[
                                          {
                                            name:`item_name`,
                                            className:"font-medium w-[30%] text-right text-lg text-gray-4 capitalize",
                                            element:"div",
                                            label:`Message: `
                                          },
                                          {
                                            name:`item_value`,
                                            className:"font-semibold text-lg w-[50%] text-left text-black",
                                            element:"div",
                                            label:data?.message || "--"
                                          }
                                        ]
                                    } ,
                              ...data.additional_fields.map((field:any,index:any)=>{
                                
                              if(field.element==="multiple_select" || field.element==="select" ){
                                return {
                                  name:`index_${index}`,
                                  className:"flex items-center w-full gap-2 ",
                                  fields:[
                                    {
                                      name:`item_name_${index}`,
                                      className:"font-medium w-[30%] text-right text-lg text-gray-4 capitalize",
                                      element:"div",
                                      label:`${field?.label}: `
                                    },
                                    {
                                      name:`item_value_${index}`,
                                      className:"font-semibold text-lg w-[50%] text-left text-black",
                                      element:"div",
                                      label:field?.value?.map((item: any) => item?.name)?.join(', ')
                                    }
                                  ]
                                }
                              }
                              if(field.element==="textarea" || field.element==="input"){
                                return {
                                    name:`index_${index}`,
                                    className:"flex items-center w-full gap-2 ",
                                    fields:[
                                      {
                                        name:`item_name_${index}`,
                                        className:"font-medium w-[30%] text-right text-lg text-gray-4 capitalize",
                                        element:"div",
                                        label:`${field?.label}: `
                                      },
                                      {
                                        name:`item_value_${index}`,
                                        className:"font-semibold text-lg w-[50%] text-left text-black",
                                        element:"div",
                                        label:field?.value
                                      }
                                    ]
                                }
                              }

                              //commented to remove form path from UI 

                              // if(field.element==="hyperlink"){
                              //   return {
                              //       name:`index_${index}`,
                              //       className:"flex items-center w-full gap-2 ",
                              //       fields:[
                              //         {
                              //           name:`item_name_${index}`,
                              //           className:"font-medium w-[30%] text-right text-lg text-gray-4 capitalize",
                              //           element:"div",
                              //           label:`${field?.label}: `
                              //         },
                              //         {
                              //           name:`item_value_${index}`,
                              //           className:"font-semibold text-lg w-[50%] text-left text-blue-400",
                              //           element:"div",
                              //           label:<a href={field?.value} target={field?.target}>{field?.value}</a>
                              //         }
                              //       ]
                              //   }
                              // }
                              return {name:`index_${index}`}

                            }),
                              
                            ]}
                          ]
                        }
                      }
                    })
                  }
                })
              }
            })
          }
        })
        return {...prev}
      })
      dispatch(setLoading(true))
      API_GET_LEAD_DATA(leadId)
      API_GET_GetAllComments(leadId, org_id)
      dispatch(setLoading(false))
    }

    if(e.name === "leads_details_modal_close"){
      await resetForm()
      handleLeadsModal("", false)
      setLeadId("")
      setSchema((prev:any)=>{
        prev?.schema?.map((f: any)=>{
          if(f.name === "lead_details_modal"){
            f?.fields?.map((ff: any)=>{
              if(ff.name === "lead_details_modal_body"){
                ff?.fields?.map((s: any)=>{
                  if(s.name === "lead_details_tabs_container"){
                    s?.fields?.map((ss: any)=>{
                      if(ss.name === "leads_modal_tabs"){
                        ss.options=[
                          {
                            key: "comments",
                            label: "Comments",
                          },
                          {
                            key: "conversations",
                            label: "Conversations",
                          },
                        ]
                        ss.value = {key: "comments",
                          label: "Comments"}
                      }
                      if(ss.name === "tabs_body"){
                        ss.fields = [
                          {...commentsSchema()}
                        ]
                      }
                    })
                  }
                })
              }
            })
          }
        })
        return {...prev}
      })
    }

    if(e.name === "comment_submit"){
      API_POST_AddComent() 
    }
    if (e.name === "lead_status_dropdown"){
      
      setCommmentStatus(e?.value || {value:'',label:''})
      
    }
    if (e.name === "lead_comment_input"){
      setCommentInput(e?.value || "")
    }
    if(e.name === 'book_appointment'){
      const apptBooking = appointmentBookingModal(e?.phone_number?.slice(-10),e?.customerName)
      setSchema((prev:any)=>{
        return{
          ...prev,
          schema:prev.schema.map((field:any)=>{
            if(field.name === 'appointment-booking-modal'){
              return{
                ...field,
                visible:true,
                fields:field.fields.map((f:any)=>{
                  if(f.name === 'modal-body'){
                    return{
                      ...f,
                      fields:[apptBooking]
                    }
                  }
                  return f
                })
              }
            }
            return field
          })
        }
      })
    }

    if(e.name === 'appointment-booking-modal-close'){
      setSchema((prev:any)=>{
        return{
          ...prev,
          schema:prev.schema.map((field:any)=>{
            if(field.name === 'appointment-booking-modal'){
              return{
                ...field,
                visible:false
              }
            }
            return field
          })
        }
      })
    }

    if(e.name === "total_leads_card" || e.name === "qualified_leads_card" || e.name === "converted_leads_card" || e.name === "discarded_leads_card"){

      

      // dispatch(setLeadsType(e.leadType))
      dispatch(setPagination({page:1,pageSize}))
      dispatch(setFilters({source:filters.source,status:[e.leadType]}))
      API_GET_Leads({ 
        type: e.leadType,
        page : 1,
        limit : pageSize,
        startDate : dateRange[0],
        endDate : dateRange[1],
        searctext : searchText,
        source:filters.source,
        status:[e.leadType]
      });

      setSchema((prev:any)=>{
        prev?.schema?.map((f: any)=>{
          if(f.name === "leads_cards_container"){
            f?.fields?.map((ff: any)=>{
              ff.className =`calls-top-card ${ff.name === e.name ? "active":""}`
            })
          }
          if(f.name==="leads_table"){
            f.value=[{name:"pagination",page:1,pageSize},{name:"filters",value:{status:[],source:[]}}]
          }
        })
        return {...prev}
      })
    }

  };
  // console.log(schema)

  function resetForm() {
    setSchema((prev:any)=>{
      prev?.schema?.map((f: any)=>{
        if(f.name === "lead_details_modal"){
          f?.fields?.map((ff: any)=>{
            if(ff.name === "lead_details_modal_body"){
              ff?.fields?.map((s: any)=>{
                if(s.name === "lead_details_tabs_container"){
                  s?.fields?.map((ss: any)=>{
                    if(ss.name === "tabs_body"){
                     ss?.fields?.map((d: any)=>{
                      if(d.name === "comments_modal_container"){
                        d?.fields?.map((dd: any)=>{
                          if(dd.name === "modal_comment_container"){
                            dd?.fields?.map((k: any)=>{
                              if(k.name === "lead_status_dropdown"){
                                k.value = {}
                                setCommmentStatus({value:'',label:''})
                              } 
                              if(k.name === "lead_comment_input"){
                                k.value = ''
                                setCommentInput("")
                              }
                            })
                          }
                        })
                      }
                     })
                    }
                  })
                }
              })
            }
          })
        }
      })
      return {...prev}
    })
  }

  return (
    <div className="bg-[#EFF1F9]">
      <ElementExecutor
        data={schema}
        selectedRecord={(e: any) => {
          handleSelectedRecord(e);
        }}
      />
      {selectedRow?.["_id"] && <DetailsModal selectedRecord={{...selectedRow, interaction_type: "Lead" }} onClose={(e:boolean)=> {
        dispatch(setSelectedRow({}));
        if (e) {
          API_GET_Leads({
            page: page,
            limit: pageSize,
            startDate: dateRange[0],
            endDate: dateRange[1],
            source:filters.source,
            status:filters.status
          });
        }
      }}/>}
    </div>
  );
};


export default Leads;
// const refresh_leads = async () => {
//   const response = await Utils.makeApiCall('/leads', 'POST', {})
//   if (response.status) {
//     get_leads_details()
//   }
// }

// const handleSelectedRecord = (e: any) => {
//   if (e.name == 'btn_refresh') {
//     refresh_leads()
//   } else if (e.name == 'modal_close') {
//     handleModaldata({ data: [], visible: false })
//   } else if (e.name === 'table') {
//     const val = e['value'] ? e['value']?.['conversation'] : ''
//     if (val) {
//       API_GET_Conversations(val, e['value'])
//     }
//   }
// }



// return (
//   <div className="bg-[#EFF1F9]">
//     <ElementExecutor
//       data={schema}
//       setData={() => { }}
//       selectedRecord={(e: any) => {
//         handleSelectedRecord(e)
//       }}
//     />
//   </div>
// )


