interface StorageType {
  getItem: (key: string) => any;
  setItem: (key: string, value: any) => void;
  clearAll: () => void;
}

const Storage: StorageType = {
  getItem: (key: string) => {
    const data = localStorage.getItem(key);
    if (data) {
      return JSON.parse(data);
    }
    return null;
  },
  setItem: (key: string, value: any) => {
    localStorage.setItem(key, JSON.stringify(value));
  },
  clearAll: () => localStorage.clear()
};

export default Storage;
