import React, { useEffect, useState } from 'react'
import {Tooltip} from "antd";
import moment from 'moment';
import { ElementExecutor } from '@apexcura/core';
import { CONSTANTS } from '../../utils/app-constants';
import { toast } from 'react-toastify';
import Utils from '../../utils';
import Storage from '../../utils/local-storage';
import { setLoading } from '../../redux/appState/appStateSlice';
import { useDispatch } from 'react-redux';
const { BASE_URL } = CONSTANTS;
const { FAILURE_MSG, CATCH_ERROR_MSG, AUTO_CLOSE } = CONSTANTS

const CallsInsights = () => {
    const [schema, setSchema] = useState<any>({
        className:
          'w-full bg-white rounded-lg py-[10px] px-[20px] flex flex-col gap-3',
        schema: [
          {
            name: 'filters',
            fields: [
              {
                name:"date_and_search",
                className:"flex gap-2 justify-center items-center ",
                fields:[
                  {
                      name:"segments",
                      element:"segmented",
                      segmentedOptions:[
                        {
                            label:(<Tooltip title="Today">1D</Tooltip>),
                            value:"TD"
                        },
                        {
                            label:(<Tooltip title="This Week">1W</Tooltip>),
                            value:"TW"
                        },
                        {
                            label:(<Tooltip title="This Month">1M</Tooltip>),
                            value:"TM"
                        },
                      ],
                      visible:true,
                      value:"TM"
                  },
                  {
                    name: 'date',
                    label: '',
                    placeholder: 'Select a date',
                    allowClear:false,
                    element: 'daterangepicker',
                    value: [moment().subtract(30, 'days').format('DD-MM-YYYY'), moment().format("DD-MM-YYYY")],
                    className: 'flex-1 w-[240px]',
                  },
                ]
              },
            ],
            className: 'flex items-center justify-between gap-2',
          },
          {
            name:"anomolies_and_insights",
            className:"grid grid-cols-2 gap-2 items-start h-[calc(100vh-150px)]",
            fields:[
                {
                    name:"anomalies_table",
                    className:"flex gap-2 flex-col col-span-1 rounded-md p-2 h-fit overflow-hidden",
                    fields:[
                        {
                            name:"anomalies",
                            label:"Anomalies",
                            element:"div",
                            className:"text-primary font-semibold text-xl w-full bg-primary/5 rounded-lg p-2"
                        },
                        {
                            name:"anomalies_cards",
                            className:"grid grid-cols-2 gap-2 h-full overflow-y-auto [&::-webkit-scrollbar]:hidden",
                            fields:[
                                {
                                    name: `missed_calls_card`,
                                    className:
                                        'rounded-md cursor-pointer border-2 border-black hover:shadow-lg flex flex-col  gap-2 items-center bg-white px-[16px] py-[12px] justify-center',
                                    element: 'clickable-widget',
                                    fields: [
                                        {
                                            name: 'missed_calls_card_icon',
                                            className: 'cursor-default ',
                                            element: 'image',
                                            imgClassName:"size-10",
                                            img:`${BASE_URL}/api/public/assets/images/missed-calls.svg`,
                                            visible: true,
                                        },
                                        {
                                            name: 'missed_calls_card_text',
                                            className: 'text-md text-black font-semibold',
                                            element: 'div',
                                            label: 'Missed Calls',
                                            visible: true,
                                        },
                                        {
                                            name: 'missed_sub_text',
                                            className: '',
                                            element: 'div',
                                            label: '',
                                            visible: false,
                                        }
                                    ],
                                },
                                {
                                    name: `call_duration_card`,
                                    className:
                                        'rounded-md cursor-pointer border-2 border-black hover:shadow-lg flex flex-col  gap-2 items-center bg-white px-[16px] py-[12px] justify-center',
                                    element: 'clickable-widget',
                                    fields: [
                                        {
                                            name: 'call_duration_card_icon',
                                            className: 'cursor-default ',
                                            element: 'image',
                                            imgClassName:"size-10",
                                            img:`${BASE_URL}/api/public/assets/images/call-duration.svg`,
                                            visible: true,
                                        },
                                        {
                                            name: 'call_duration_card_text',
                                            className: 'text-md text-black font-semibold',
                                            element: 'div',
                                            label: 'Call Duration',
                                            visible: true,
                                        },
                                        {
                                            name: 'duration_sub_text',
                                            className: '',
                                            element: 'div',
                                            label: "",
                                            visible: false,
                                        }
                                    ],
                                },
                                {
                                    name: `agent_performance_card`,
                                    className:
                                        'rounded-md cursor-pointer border-2 border-black hover:shadow-lg flex flex-col  gap-2 items-center bg-white px-[16px] py-[12px] justify-center',
                                    element: 'clickable-widget',
                                    fields: [
                                        {
                                            name: 'agent_performance_icon',
                                            className: 'cursor-default ',
                                            element: 'image',
                                            imgClassName:"size-10",
                                            img:`${BASE_URL}/api/public/assets/images/agent-performance.svg`,
                                            visible: true,
                                        },
                                        {
                                            name: 'agent_performance_text',
                                            className: 'text-md text-black font-semibold',
                                            element: 'div',
                                            label: 'Agent Performance',
                                            visible: true,
                                        },
                                        {
                                            name: 'agent_sub_text',
                                            className: '',
                                            element: 'div',
                                            label: "",
                                            visible: false,
                                        }
                                    ],
                                },
                            ]
                        }
                    ]
                },
                {
                    name:"actionable_insights_table",
                    className:"flex gap-2 flex-col col-span-1 rounded-md p-2 h-fit overflow-hidden",
                    fields:[
                        {
                            name:"actionable_insights",
                            label:"Actionable Insights",
                            element:"div",
                            className:"text-primary font-semibold text-xl w-full bg-primary/5 rounded-lg p-2"
                        },
                        {
                            name:"actionable_insights_cards",
                            className:"grid grid-cols-2 gap-2 h-full overflow-y-auto [&::-webkit-scrollbar]:hidden",
                            fields:[
                                {
                                    name: `call_completion_card`,
                                    className:
                                        'rounded-md cursor-pointer border-2 border-black hover:shadow-lg flex flex-col  gap-2 items-center bg-white px-[16px] py-[12px] justify-center',
                                    element: 'clickable-widget',
                                    fields: [
                                        {
                                            name: 'bot_effeciency_card_icon',
                                            className: 'cursor-default ',
                                            element: 'image',
                                            imgClassName:"size-10",
                                            img:`${BASE_URL}/api/public/assets/images/call-completion-rate.svg`,
                                            visible: true,
                                        },
                                        {
                                            name: 'bot_effeciency_card_text',
                                            className: 'text-md text-black font-semibold',
                                            element: 'div',
                                            label: 'Call Completion Rate',
                                            visible: true,
                                        },
                                        {
                                            name: 'completion_sub_text',
                                            className: '',
                                            element: 'div',
                                            label: "",
                                            visible: false,
                                        }
                                    ],
                                },
                                {
                                    name: `call_frequency_card`,
                                    className:
                                        'rounded-md cursor-pointer border-2 border-black hover:shadow-lg flex flex-col  gap-2 items-center bg-white px-[16px] py-[12px] justify-center',
                                    element: 'clickable-widget',
                                    fields: [
                                        {
                                            name: 'call_frequency_card_icon',
                                            className: 'cursor-default ',
                                            element: 'image',
                                            imgClassName:"size-10",
                                            img:`${BASE_URL}/api/public/assets/images/caller-frequency.svg`,
                                            visible: true,
                                        },
                                        {
                                            name: 'call_frequency_card_text',
                                            className: 'text-md text-black font-semibold',
                                            element: 'div',
                                            label: 'Caller Frequency',
                                            visible: true,
                                        },
                                        {
                                            name: 'frequency_sub_text',
                                            className: '',
                                            element: 'div',
                                            label: "",
                                            visible: false,
                                        }
                                    ],
                                },
                                {
                                    name: `peak_hour_card`,
                                    className:
                                        'rounded-md cursor-pointer border-2 border-black hover:shadow-lg flex flex-col  gap-2 items-center bg-white px-[16px] py-[12px] justify-center',
                                    element: 'clickable-widget',
                                    fields: [
                                        {
                                            name: 'peak_hour_icon',
                                            className: 'cursor-default ',
                                            element: 'image',
                                            imgClassName:"size-10",
                                            img:`${BASE_URL}/api/public/assets/images/peak-hour.svg`,
                                            visible: true,
                                        },
                                        {
                                            name: 'peak_hour_text',
                                            className: 'text-md text-black font-semibold',
                                            element: 'div',
                                            label: 'Peak Hour',
                                            visible: true,
                                        },
                                        {
                                            name: 'peak_sub_text',
                                            className: '',
                                            element: 'div',
                                            label: "",
                                            visible: false,
                                        }
                                    ],
                                },
                            ]
                        }
                    ]
                },
            ]
          },
          {
            name: 'table_modal',
            containerClassName:"!w-[90%]  ",
            className: 'rounded-lg !w-full !h-[90vh]',
            visible: false,
            element: "modal",
            fields: [
                {
                    name: 'modal_title',
                    className: 'w-full bg-primary/5 p-2 pl-4 flex items-center rounded-t-lg justify-between',
                    fields: [
                        {
                            name: 'modal_title_key',
                            element: 'div',
                            label: '',
                            className: 'font-semibold text-lg text-primary',
                        },
                        {
                            name: 'modal_close',
                            element: 'button',
                            label: 'X',
                            className: 'w-[40px] h-[40px] flex justify-center items-center rounded-full bg-primary text-xl text-white',
                        },
                    ],
                },
                {
                    name:"cards_and_chats",
                    className:"p-4",
                    fields:[
                        // {
                        //     name:"cards",
                        //     className:"p-4",
                        //     fields:[
        
                        //     ]
                        // },

                    ]
                }
            ],
          },
        ],
    });
    const [dateRange, setDateRange] = useState<[string, string]>([
        moment().subtract(30, 'days').format('DD-MM-YYYY'),
        moment().format('DD-MM-YYYY'),
    ])
    const [insightsData,setInsightsData]=useState<any>({});
    const [anomaliesData,setAnomaliesData]=useState<any>({});
    const details = Storage.getItem("details");
    const dispatch = useDispatch();

    useEffect(()=>{

        const first = moment(dateRange[0], 'DD-MM-YYYY');
        const second = moment(dateRange[1], 'DD-MM-YYYY');
        const diffInDays = second.diff(first, 'days');
        const today = moment().startOf('day');
    
        if (first.isSame(today, 'day') && second.isSame(today, 'day')) {
            change_segments_value("TD")
        } else if (diffInDays === 7 && second.isSame(today, 'day')) {
            change_segments_value("TW")
        } else if (diffInDays === 30 && second.isSame(today, 'day')) {
            change_segments_value("TM")
        }else{
            change_segments_value(null)
        }
        API_GET_CALL_INSIGHTS({startDate:dateRange[0],endDate:dateRange[1]})
        API_GET_CALL_Anomalies({startDate:dateRange[0],endDate:dateRange[1]})
    },[dateRange])

    const API_GET_CALL_INSIGHTS=async ({  startDate = "", endDate = "", })=>{
        dispatch(setLoading(true))
        try {
            const res: any = await Utils.makeApiCall(`/getCallsActionableInsights?start_date=${moment(startDate, "DD-MM-YYYY").format("YYYY-MM-DD")}&end_date=${moment(endDate, "DD-MM-YYYY").format("YYYY-MM-DD")}`,"GET");
            if (res?.status) {
                setInsightsData(res)
                countsDisplay(res?.counts,"insights");   
            } else {
                toast.error(res.message ? res.message : FAILURE_MSG, { position: 'top-center', autoClose: AUTO_CLOSE })
            }
        } catch (error) {
            toast.error(CATCH_ERROR_MSG, { position: 'top-center', autoClose: AUTO_CLOSE });
            console.error('Failed to fetch schema', error);
        }
        dispatch(setLoading(false))
    }

    const API_GET_CALL_Anomalies=async ({  startDate = "", endDate = "",label="" })=>{
        dispatch(setLoading(true))
        try {
            const res: any = await Utils.makeApiCall(`/getCallsAnomalies?start_date=${moment(startDate, "DD-MM-YYYY").format("YYYY-MM-DD")}&end_date=${moment(endDate, "DD-MM-YYYY").format("YYYY-MM-DD")}`,"GET");
            if (res?.status) {
                setAnomaliesData(res)
                countsDisplay(res?.counts, "anomalies")
            } else {
                toast.error(res.message ? res.message : FAILURE_MSG, { position: 'top-center', autoClose: AUTO_CLOSE })
            }
        } catch (error) {
            toast.error(CATCH_ERROR_MSG, { position: 'top-center', autoClose: AUTO_CLOSE });
            console.error('Failed to fetch schema', error);
        }
        dispatch(setLoading(false))
    }

    const change_anomalies_modal_data=(data: any,name:any,label:any)=>{
        console.log(data)
        setSchema((prev:any)=>{
            const updatedSchema=prev.schema.map((field:any)=>{
                if(field.name==="table_modal"){
                    field.visible=true;
                    field.fields=field.fields.map((ffield:any)=>{
                        if(ffield.name==="cards_and_chats"){
                            ffield.fields=[{
                                className: '',
                                name: 'patients_table',
                                element: 'table',
                                variant: 'plain',
                                size: 'small',
                                count: 0,
                                view: true,
                                visible: true,
                                loading: false,
                                pagination: false,
                                // value: { name: 'pagination', page: 1, pageSize: 10 },
                                tbody:data
                            }]
                            if(name==="call_duration_anomalies"){
                                ffield.fields[0].thead=[
                                    {
                                        name:"date",
                                        label:"Date",
                                        key:"date",
                                        sortable:false,
                                    },
                                    {
                                        name:"text",
                                        label:"",
                                        key:"text",
                                        sortable:false,
                                    },
                                    {
                                        name:"avg_duration",
                                        label:"Average Duration",
                                        key:"avg_duration",
                                        sortable:false,
                                        render:(e:any)=>{
                                            return <p ><span className='font-bold'>{e?.toFixed(0) || 0}</span> secs</p>
                                        }
                                    },
                                    {
                                        name:"call_count",
                                        label:"Call Count",
                                        key:"call_count",
                                        sortable:false,
                                        render:(e:any)=>{
                                            return <p>with <span className='text-primary font-bold'>{e}</span> calls</p>
                                        }
                                    }
                                ]
                            }
                            if(name==="missed_call_anomalies"){
                                ffield.fields[0].thead=[
                                    {
                                        name:"date",
                                        label:"Date",
                                        key:"date",
                                        sortable:false,
                                    },
                                    {
                                        name:"missed_calls",
                                        label:"Missed Calls",
                                        key:"missed_calls",
                                        sortable:false,
                                        render:(e:any)=>{
                                            return <p>there were <span className='font-bold text-primary'>{e}</span> missed calls</p>
                                        }
                                    },
                                    {
                                        name:"text",
                                        label:"",
                                        key:"text",
                                        sortable:false,
                                    },
                                ]
                            }
                            if(name==="agent_performance_anomalies"){
                                ffield.fields[0].thead=[
                                    {
                                        name:"agent_name",
                                        label:"Agent Name",
                                        key:"agent_name",
                                        sortable:false,
                                    },
                                    {
                                        name:"agent_number",
                                        label:"Agent Number",
                                        key:"agent_number",
                                        sortable:false,
                                        render:(e:any)=>{
                                            return <p>{e || "--"}</p>
                                        }
                                    },
                                    {
                                        name:"avg_call_duration",
                                        label:"Average Call Duration",
                                        key:"avg_call_duration",
                                        sortable:false,
                                        render:(e:any)=>{
                                            return <p>{e? "with avg call duration":""} <span className='font-bold'>{e?.toFixed(0) || "0"}</span> secs</p>
                                        }
                                    },
                                    {
                                        name:"missed_call_rate",
                                        label:"Missed Call Rate",
                                        key:"missed_call_rate",
                                        sortable:false,
                                        render:(e:any)=>{
                                            return <p>{e? "has missed call rate":""} <span className='font-bold text-primary'>{(e*100).toFixed(2) || "0"} %</span></p>
                                        }
                                    },
                                    {
                                        name:"total_calls",
                                        label:"Total Calls",
                                        key:"total_calls",
                                        sortable:false,
                                        render:(e:any)=>{
                                            return <p>{e? "for ":""} <span className='font-bold text-primary'>{e || "0"} calls.</span></p>
                                        }
                                    },
                                ]
                            }
                        }
                        if(ffield.name==="modal_title"){
                            ffield.fields=ffield.fields.map((fffield:any)=>{
                                if(fffield.name==="modal_title_key"){
                                    fffield.label=label;
                                }
                                return fffield;
                            })
                        }
                        return ffield;
                    })
                }
                return field;
            })
            return {...prev,schema:updatedSchema}
        })
    }

    const countsDisplay=(data: any, api: string)=>{
        setSchema((prev: any) => {
            const updateField = (fields: any[]) =>
                fields.map((field: any) => {  
                    if (api === "insights") {
                        if (field.name === "completion_sub_text") {
                            field.visible = Boolean(data?.maxCallcompletionrate?.rate);
                            field.label = <p className='text-xs bg-green-100 p-1 px-2 rounded-lg text-green-700'>Highest: <span className='font-semibold'>{data?.maxCallcompletionrate?.rate}</span> on {data?.maxCallcompletionrate.date ? moment(data?.maxCallcompletionrate.date).format("DD-MM-YYYY") : ""}</p>
                        } 
                        else if (field.name === "frequency_sub_text") {
                            field.visible = Boolean(data?.maxCallFrequency?.frequency);
                            field.label = <p className='text-xs bg-green-100 p-1 px-2 rounded-lg text-green-700'>Highest: <span className='font-semibold'>{data?.maxCallFrequency?.frequency}</span> calls on {data?.maxCallFrequency.date ? moment(data?.maxCallFrequency.date).format("DD-MM-YYYY") : ""}</p>
                        } 
                        else if (field.name === "peak_sub_text") {
                            field.visible = Boolean(data?.peakHour?.time_interval);
                            field.label = <p className='text-xs bg-green-100 p-1 px-2 rounded-lg text-green-700'>Highest: <span className='font-semibold'>{data?.peakHour?.time_interval}</span> on {data?.peakHour.date ? moment(data?.peakHour.date).format("DD-MM-YYYY") : ""}</p>
                        } 
                        else if (field.fields) {
                            field.fields = updateField(field.fields)
                        }
                    } else {
                        if (field.name === "missed_sub_text" && data?.missedCallsRecord) {
                            field.visible = Boolean(data?.missedCallsRecord?.metrics?.missed_calls);
                            field.label = <p className='text-xs bg-red-50 p-1 px-2 rounded-lg text-red-700'><span className='font-semibold'>{data?.missedCallsRecord?.metrics?.missed_calls} missed calls </span> on {data?.missedCallsRecord?.date ? moment(data?.missedCallsRecord?.date).format("DD-MM-YYYY"): ""}</p>
                        }
                        else if (field.name === "duration_sub_text") {
                            field.visible = Boolean(data?.maxdurationRecord?.metrics?.avg_duration);
                            field.label = <p className='text-xs bg-green-50 p-1 px-2 rounded-lg text-green-700'>Highest avg duration <span className='font-semibold'>{data?.maxdurationRecord?.metrics?.avg_duration?.toFixed(1)}</span> secs</p>
                        }
                        else if (field.name === "agent_sub_text") {
                            field.visible = Boolean(data?.maxMissedCallsrateRecord?.metrics?.missed_call_rate);
                            field.label = <p className='text-xs bg-orange-100 p-1 px-2 rounded-lg text-orange-700'>An Agent has <span className='font-semibold'>{(data?.maxMissedCallsrateRecord?.metrics?.missed_call_rate*100).toFixed(2)} %</span> missed call rate</p>
                        } else if (field.fields) {
                            field.fields = updateField(field.fields)
                        }
                    } 
                    return field;
                });

            return {
                ...prev,
                schema: prev?.schema?.map((sec: any) => {
                    if (sec.fields) {
                        sec.fields = updateField(sec.fields);
                    }
                    return sec;
                }),
            };
        });
    }

    const change_modal_data = (data: any,label:string) => {
        if(label==="call completion rate"){
            const cardData=data?.categories || [];
            if (!cardData || cardData.length === 0) {
                toast.error("No insights in the given dates.", { autoClose: 800, position: "top-center" });
                return;
            }
            setSchema((prev: any) => {
                const updatedSchema = prev.schema.map((item: any) => {
                    if (item?.name === 'table_modal') {
                        // console.log(item?.name)
                        item.visible = true;
                        item.fields = (item.fields || []).map((field: any) => {
                            if (field?.name === "cards_and_chats") {
                                // field.fields = field.fields.map((ffield:any)=>{
                                //     if(ffield.name==="cards"){
                                        field.className="w-full "
                                        field.fields=[{
                                            name: 'leads_generated',
                                            className: 'rounded-md mb-5 bg-white px-2 h-full',
                                            element: 'linechart',
                                            showLegend:false,
                                            xAxisTitle:"Days",
                                            yAxisTitle:"Call Completion Rate",
                                            showTooltip:true,
                                            fontSize:"12px",
                                            labelsfontSize:"12px",
                                            axisLabelClassName:"text-xs font-medium text-black",
                                            color:"#669df6",
                                            label: '',
                                            labelClassName:
                                                'w-[80%] font-semibold text-base text-primary my-5 ',
                                            height: "36%",
                                            pointWidth: 20,
                                            categories: data?.categories ,
                                            data:data?.kpis,
                                        }]
                                //     }
                                //     return ffield;
                                // })
                            }
                            if (field?.name === "modal_title") {
                                field.fields = (field.fields || []).map((ffield: any) => {
                                    if (ffield?.name === "modal_title_key") {
                                        ffield.label = `Insights for ${label} between ${dateRange[0]} and ${dateRange[1]}`;
                                    }
                                    return ffield;
                                });
                            }
                            return field;
                        });
                    }
                    return item;
                });
                return { ...prev, schema: updatedSchema };
            });
            
        } 
        else if(label==="caller frequency"){
            const cardData=data;
            if (!cardData || cardData.length === 0) {
                toast.error("No insights in the given dates.", { autoClose: 800, position: "top-center" });
                return;
            }

            setSchema((prev: any) => {
                const updatedSchema = prev.schema.map((item: any) => {
                    if (item?.name === 'table_modal') {
                        item.visible = true;
                        item.fields = (item.fields || []).map((field: any) => {
                            if (field?.name === "cards_and_chats") {
                                field.className = "w-full p-4 pb-16 h-full overflow-y-scroll"
                                field.fields = [
                                    {
                                        name :'',
                                        className: 'h-fit',
                                        element: 'table',
                                        pagination: false,
                                        variant: 'plain',
                                        size: 'small',
                                        count: 0,
                                        view: true,
                                        visible: true,
                                        loading: false,
                                        // value: { name: 'pagination', page: 1, pageSize: 10 },
                                        thead : [
                                            { name:'', label: "", key: "", sortable: false, render: (e: any)=> {return <p>On date</p>} },
                                            { name:'date', label: "", key: "date", sortable: false, render: (e: any)=> {return <p>{e? moment(e, "YYYY-MM-DD").format("DD-MM-YYYY"): ""}</p>} },
                                            { name:'frequency', label: "Frequency", key: "frequency", sortable: false, render: (e: any)=> {return <span>there were <b className='text-primary' >{e}</b> calls from </span>} },
                                            { name:'number', label: "Phone Number", key: "number", sortable: false,  render: (e: any)=> {return <span><b>{e}</b></span> } },
                                            // { name:'', label: "", key: "", sortable: false, render: (e: any)=> {return <p>times called</p>} },
                                        ],
                                        tbody : cardData

                                    }
                                ]
                                
                            }
                            if (field?.name === "modal_title") {
                                field.fields = (field.fields || []).map((ffield: any) => {
                                    if (ffield?.name === "modal_title_key") {
                                        ffield.label = `Insights for ${label} between ${dateRange[0]} and ${dateRange[1]}`;
                                    }
                                    return ffield;
                                });
                            }
                            return field;
                        });
                    }
                    return item;
                });
                return { ...prev, schema: updatedSchema };
            });
        }
        
        else if(label==="peak hour"){
            const cardData=data;
            if (!cardData || cardData.length === 0) {
                toast.error("No insights in the given dates.", { autoClose: 800, position: "top-center" });
                return;
            }

            setSchema((prev: any) => {
                const updatedSchema = prev.schema.map((item: any) => {
                    if (item?.name === 'table_modal') {
                        item.visible = true;
                        item.fields = (item.fields || []).map((field: any) => {
                            if (field?.name === "cards_and_chats") {
                                field.className = "w-full p-4 pb-16 h-full overflow-y-scroll"
                                field.fields = [
                                    {
                                        name :'',
                                        className: 'h-fit',
                                        element: 'table',
                                        pagination: false,
                                        variant: 'plain',
                                        size: 'small',
                                        count: 0,
                                        view: true,
                                        visible: true,
                                        loading: false,
                                        // value: { name: 'pagination', page: 1, pageSize: 10 },
                                        thead : [
                                            { name:'', label: "", key: "", sortable: false, render: (e: any)=> {return <span>The busiest hour on</span>} },
                                            { name:'date', label: "", key: "date", sortable: false, render: (e: any)=> {return <span><span className='font-medium'>{e? moment(e, "YYYY-MM-DD").format("DD-MM-YYYY"): ""}</span> was</span>} },
                                            { name:'time_interval', label: "Peak Hour", sortable: false, key: "time_interval",  render: (e: any)=> {return <p className='font-semibold'>{e}</p>} },
                                            { name:'total_calls', label: "Frequency", sortable: false, key: "caller_frequency",  render: (e: any)=> {return <span>with <b className='text-primary text-center' >{e}</b> calls</span>} },
                                            // { name:'', label: "", key: "", sortable: false, render: (e: any)=> {return <p>total calls</p>} },
                                        ],
                                        tbody : cardData
                                    }
                                ]
                            }
                            if (field?.name === "modal_title") {
                                field.fields = (field.fields || []).map((ffield: any) => {
                                    if (ffield?.name === "modal_title_key") {
                                        ffield.label = `Insights for ${label} between ${dateRange[0]} and ${dateRange[1]}`;
                                    }
                                    return ffield;
                                });
                            }
                            return field;
                        });
                    }
                    return item;
                });
                return { ...prev, schema: updatedSchema };
            });
        }
        
        
    };


    const handleModalClose = () => {
        setSchema((prev: any) => {
            const updatedSchema = prev.schema.map((item: any) => {
                if (item.name === 'table_modal') {
                    item.visible = false;
                }
                return item;
            });
            return { ...prev, schema: updatedSchema };
        });
    }

    const change_segments_value=(val:string | null)=>{
        setSchema((prev:any)=>{
            const updatedSchema=prev.schema.map((field:any)=>{
                if(field.name==="filters"){
                   field.fields=field.fields.map((ffield:any)=>{
                    if(ffield.name==="date_and_search"){
                        ffield.fields=ffield.fields.map((fffield:any)=>{
                            if(fffield.name==="segments"){
                                fffield.value=val;
                            }
                            return fffield;
                        })
                    }
                    return ffield;
                   })
                }
                return field;
            })
            return {...prev,schema:updatedSchema}
        })
    }

    const handleSelectedRecord = (e: any) => {

        if(e.name==="modal_close"){
            handleModalClose();
        }
        if (e.name === "segments") {
            // console.log(e)
            if (e.value === "TD") {
                setDateRange([
                    moment().format('DD-MM-YYYY'),
                    moment().format('DD-MM-YYYY'),
                ]);
                setSchema((prev: any) => {
                    const updatedSchema = prev.schema.map((field: any) => {
                        if (field.name === "filters") {
                            field.fields = field.fields.map((ffield: any) => {
                                if (ffield.name === "date_and_search") {
                                    ffield.fields = ffield.fields.map((ff: any) => {
                                        if (ff.name === "date") {
                                            ff.value = [
                                                moment().format('DD-MM-YYYY'),
                                                moment().format('DD-MM-YYYY'),
                                            ];
                                        }
                                        return ff;
                                    });
                                }
                                return ffield;
                            });
                        }
                        return field;
                    });
                    return { ...prev, schema: updatedSchema };
                });
            }
            if (e.value === "TM") {
                setDateRange([
                    moment().subtract(30, 'days').format('DD-MM-YYYY'),
                    moment().format('DD-MM-YYYY'),
                ]);
                setSchema((prev: any) => {
                    const updatedSchema = prev.schema.map((field: any) => {
                        if (field.name === "filters") {
                            field.fields = field.fields.map((ffield: any) => {
                                if (ffield.name === "date_and_search") {
                                    ffield.fields = ffield.fields.map((ff: any) => {
                                        if (ff.name === "date") {
                                            ff.value = [
                                                moment().subtract(30, 'days').format('DD-MM-YYYY'),
                                                moment().format('DD-MM-YYYY'),
                                            ];
                                        }
                                        return ff;
                                    });
                                }
                                return ffield;
                            });
                        }
                        return field;
                    });
                    return { ...prev, schema: updatedSchema };
                });
            }
            if (e.value === "TW") {
                setDateRange([
                    moment().subtract(7, 'days').format('DD-MM-YYYY'),
                    moment().format('DD-MM-YYYY'),
                ]);
                setSchema((prev: any) => {
                    const updatedSchema = prev.schema.map((field: any) => {
                        if (field.name === "filters") {
                            field.fields = field.fields.map((ffield: any) => {
                                if (ffield.name === "date_and_search") {
                                    ffield.fields = ffield.fields.map((ff: any) => {
                                        if (ff.name === "date") {
                                            ff.value = [
                                                moment().subtract(7, 'days').format('DD-MM-YYYY'),
                                                moment().format('DD-MM-YYYY'),
                                            ];
                                        }
                                        return ff;
                                    });
                                }
                                return ffield;
                            });
                        }
                        return field;
                    });
                    return { ...prev, schema: updatedSchema };
                });
            }
        }
        if(e.name=== "date"){
            setDateRange(e.value)
        }
        if(e.name==="peak_hour_card"){
            change_modal_data(insightsData?.data?.peak_hour,"peak hour")
        }
        if(e.name==="call_completion_card"){
            change_modal_data(insightsData?.data?.call_completion_rate,"call completion rate")
        }
        if(e.name==="call_frequency_card"){
            change_modal_data(insightsData?.data?.caller_frequency,"caller frequency")
        }
        if(e.name==="missed_calls_card"){
            change_anomalies_modal_data(anomaliesData?.data?.missed_call_anomalies,"missed_call_anomalies","Missed Call Anomalies")
        }
        if(e.name==="agent_performance_card"){
            change_anomalies_modal_data(anomaliesData?.data?.agent_performance_anomalies,"agent_performance_anomalies","Agent Performance Anomalies")
        }
        if(e.name==="call_duration_card"){
            change_anomalies_modal_data(anomaliesData?.data?.call_duration_anomalies,"call_duration_anomalies","Call Duration Anomalies")
        }
    }
    
  return (
    <div className=''>
      <ElementExecutor
        data={schema}
        selectedRecord={(e: any) => {
          handleSelectedRecord(e);
        }}/>
    </div>
  )
}

export default CallsInsights
