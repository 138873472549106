"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ColorPickerElement = void 0;
const react_1 = __importStar(require("react"));
const antd_1 = require("antd");
const ColorPickerElement = (props) => {
    const [val, setVal] = (0, react_1.useState)(props.value);
    const [formatHex, setFormatHex] = (0, react_1.useState)('hex');
    (0, react_1.useEffect)(() => {
        if (props.value) {
            setVal(props.value);
        }
    }, [props.value]);
    const onHandleChange = (newValue) => {
        const hexString = newValue.toHexString();
        props.onChange && props.onChange(hexString);
        setVal(hexString);
    };
    // console.log(val);
    return (react_1.default.createElement("div", { className: props.containerClassName },
        props.label && (react_1.default.createElement("p", { className: props.labelClassName },
            props.label,
            ' ',
            props.required ? react_1.default.createElement("span", { style: { color: 'red' } }, "*") : '')),
        react_1.default.createElement("br", null),
        react_1.default.createElement("div", null,
            react_1.default.createElement(antd_1.ColorPicker, { format: formatHex, className: props.className, disabled: props.disabled, value: val, onChange: onHandleChange, onFormatChange: setFormatHex }),
            react_1.default.createElement("span", null, val))));
};
exports.ColorPickerElement = ColorPickerElement;
